import React, { useState } from 'react';
import Main from '../Main';
import { Col, Form, Row } from 'react-bootstrap';
import searchIcon from '../../assets/images/Dashboard/search-icon.svg'
import calendar from '../../assets/images/Dashboard/calendar-icon.svg'
import '../../assets/css/dashboard.scss'
import '../../assets/css/customermanagement.scss'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import Download from '../MainSection/Download/Download';
import IntegrationLogTable from './IntegrationLogTable';
import ViewIntegrationModal from './ViewIntegrationModal';

const IntegrationLog = () => {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [showColumnFilterModal, setShowColumnFilterModal] = useState(false);
    const [showValueFilterModal, setShowValueFilterModal] = useState(false);
    const [showCustomerViewDetails, setShowCustomerViewDetails] = useState(false);
    const [businessUnitModal, setBusinessUnitModal] = useState(false);
    const [branchPlantModal, setBranchPlantModal] = useState(false);

    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleViewDetails = () => {
        setShowCustomerViewDetails(!showCustomerViewDetails);
    }

    return (
        <div className="customer-management-container">
            <Main>
                <div className='components-download-container'>
                    <Download
                        showDashboardLibraryButton={false}
                        showSaveCustomViewButton={false}
                        showAnalyticsButton={false}
                        showDownloadDropdown={true}
                    />
                </div>
                <div className="customermanagement-content-container">
                    <div className="customermanagement-content">
                        <Row className='customermanagement-btns-container'>
                            <Col md={3} className='customermanagement-backbtn-div'>
                                <span>Integration Log</span>
                            </Col>
                            <Col md={9} className='customermanagement-search-filter'>
                                <Col md={4} className='filters-col'>
                                    <div className='customermanagement-search'>
                                        <Form.Control type="search" placeholder='Search' />
                                        <img src={searchIcon} alt="" className='p-2' />
                                    </div>
                                </Col>
                                {/* <Col md={2} className='filters-col'> */}
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder="Select Modules"
                                        // options={statusOptions}
                                        style={{ width: "100%" }}
                                    />
                                {/* </Col> */}
                                <Col md={2} className='filters-col'>
                                    <div className='calendar-container'>
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            selectsRange
                                            placeholderText='From'
                                            startDate={fromDate}
                                            // onChange={(data) => setSelectSearchDrop(data, "date")}
                                            maxDate={new Date()}
                                        />
                                        <img src={calendar} alt="" />
                                    </div>
                                </Col>
                                <Col md={2} className='filters-col'>
                                    <div className='calendar-container'>
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            selectsRange
                                            placeholderText='To'
                                            endDate={toDate}
                                            // onChange={(data) => setSelectSearchDrop(data, "date")}
                                            maxDate={new Date()}
                                        />
                                        <img src={calendar} alt="" />
                                    </div>
                                </Col>
                                {/* <SbAddBtn btnName="Add Complaints" onClickEffect={handleOpenModal} /> */}
                            </Col>
                        </Row>
                        <div className='customermanagement-table-div'>
                            <IntegrationLogTable
                                handleOpenModal={handleOpenModal}
                                handleViewDetails={handleViewDetails}
                            />
                        </div>
                    </div>
                </div>
            </Main>
            {
                isModalOpen && <ViewIntegrationModal
                    open={isModalOpen}
                    handleCloseModal={handleCloseModal}
                />
            }
            {/* {showColumnFilterModal && (
                <CustomerFilterModal
                    showColumnFilterModal={showColumnFilterModal}
                    handleCloseColumnFilterModal={handleCloseColumnFilterModal}
                />
            )}
            {showValueFilterModal && (
                <CustomerValueFilterModal
                    showValueFilterModal={showValueFilterModal}
                    handleCloseValueFilterModal={handleCloseValueFilterModal}
                />
            )}
            {showCustomerViewDetails && (
                <CustomerViewDetails
                    showCustomerViewDetails={showCustomerViewDetails}
                    handleCloseViewDetails={handleCloseViewDetails}
                />
            )} */}
        </div >
    );
};

export default IntegrationLog;