import React from 'react'
import Main from '../../Main'
import { Col, Row } from 'react-bootstrap'
import '../../../assets/css/dashboard.scss'
import totalCustomer from '../../../assets/images/Dashboard/total-customer-img.svg'
import activeInactive from '../../../assets/images/Dashboard/total-active-inactive-img.svg'
import customerUnderValidity from '../../../assets/images/Dashboard/total-customer-under-validity.svg'
import customerLicenseExperation from '../../../assets/images/Dashboard/customer-licence-expiration.svg'
import Download from '../../MainSection/Download/Download'
import BarChart from '../../Graphs/BarChart'
import RelationshipTypeChart from '../../Graphs/RelationshipTypeChart'
import FunnelChart from '../../Graphs/FunnelChart'
import LicenceSpliteUpChart from '../../Graphs/LicenceSpliteUpChart'
import CustomerStatusChart from '../../Graphs/CustomerStatusChart'
import AssignedModules from '../../Graphs/AssignedModules'
import { useNavigate } from 'react-router-dom'

function DashBoard() {
    const labels = ['Active', 'Inactive', 'New'];
    const colors = [
        'rgba(58, 201, 119, 1)', // Active
        'rgba(255, 94, 94, 1)',  // Inactive
        'rgba(255, 159, 0, 1)'  // New
    ];
    const data = [25, 20, 13];

    const Rlabels = ['Headquarter', 'Business Unit', 'Branch Office', 'Storage Location'];
    const Rcolors = [
        'rgba(58, 201, 119, 1)', // Headquarter
        'rgba(255, 94, 94, 1)',  // Business Unit
        'rgba(255, 159, 0, 1)',  // Branch Office
        'rgba(52, 58, 64, 1)',  // Storage Location
    ];
    const Rdata = [25, 20, 13, 30];

    const funnelColors = ['rgba(0, 133, 163, 1)', 'rgba(3, 170, 207, 1)', 'rgba(6, 201, 244, 1)'];
    const funnelLabels = ['LC Available', 'LC Used', 'LC Provider'];
    const funnelData = [
        { value: 200 },
        { value: 150 },
        { value: 100 },
    ];
    const navigate = useNavigate();
    const handleDashboardLibraryClick = () => {
        navigate('/dashboard-library')
    }
    const handleBack = () => {
        navigate(-1);
    };

    const handleNavigation = () => {
        navigate('/customermanagement')
    }
    return (
        <div className='dashboard-container'>
            <Main>
                <div className='components-download-container'>
                    <Download moduleName="Customer Dashboard"
                        showBackButton={true}
                        showDashboardLibraryButton={true}
                        showSaveCustomViewButton={true}
                        showAnalyticsButton={false}
                        showDownloadDropdown={true}
                        onClick={handleDashboardLibraryClick}
                        onBackClick={handleBack}
                    />
                </div>
                <Row className='dashboard-cards-container'>
                    <Col md={3} className='p-0 pe-3 dashboard-cards-cols'>
                        <div className='dashboard-cards' onClick={handleNavigation}>
                            <div className='dashboard-cards-counts'>
                                <p>Total Customer</p>
                                <b>200</b>
                            </div>
                            <div className='dashboard-cards-image'>
                                <img src={totalCustomer} alt="" />
                            </div>
                        </div>
                    </Col>
                    <Col md={3} className='p-0 pe-3 dashboard-cards-cols'>
                        <div className='dashboard-cards' onClick={handleNavigation}>
                            <div className='dashboard-cards-counts'>
                                <p>Active / InActive</p>
                                <b>50 / 100</b>
                            </div>
                            <div className='dashboard-cards-image' style={{ backgroundColor: "rgba(255, 94, 94, 0.2)" }}>
                                <img src={activeInactive} alt="" />
                            </div>
                        </div>
                    </Col>
                    <Col md={3} className='p-0 pe-3 dashboard-cards-cols'>
                        <div className='dashboard-cards' onClick={handleNavigation}>
                            <div className='dashboard-cards-counts'>
                                <p>Customer Under Validity</p>
                                <b>200</b>
                            </div>
                            <div className='dashboard-cards-image' style={{ backgroundColor: "rgba(255, 159, 0, 0.2)" }}>
                                <img src={customerUnderValidity} alt="" />
                            </div>
                        </div>
                    </Col>
                    {/* <Col md={3} className='p-0 dashboard-cards-cols'>
                        <div className='dashboard-cards' onClick={handleNavigation}>
                            <div className='dashboard-cards-counts'>
                                <p>Customer License Expiration</p>
                                <b>200</b>
                            </div>
                            <div className='dashboard-cards-image' style={{ backgroundColor: "rgba(52, 58, 64, 0.2)" }}>
                                <img src={customerLicenseExperation} alt="" />
                            </div>
                        </div>
                    </Col> */}

                    <Col md={3} className="p-0 dashboard-cards-cols">
                        <div className="dashboard-cards" onClick={handleNavigation}>
                            <div className="dashboard-cards-counts">
                                <p>Customer License Expiration</p>
                                <b>20</b>
                                <p className="dashboard-expiring-text">Expiring in 30 days</p>
                                <div className="progress dashboard-progress">
                                    <div
                                        className="dashboard-progress-bar"
                                        role="progressbar"
                                        style={{ width: "30%", backgroundColor: "#343A40" }}
                                        aria-valuenow="30"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    ></div>
                                </div>
                            </div>
                            <div className="dashboard-cards-image" style={{ backgroundColor: "rgba(52, 58, 64, 0.2)" }}>
                                <img src={customerLicenseExperation} alt="License Expiration Icon" />
                            </div>
                        </div>
                    </Col>

                </Row>
                <Row className='dashboard-charts'>
                    <Col xs={12} sm={12} md={6} lg={6} className='dashboard-charts-div p-0'>
                        <div className='charts-container'>
                            <BarChart
                                moduleName="Customer Details"
                                showTypeNameMonth={false}
                                showYear={true}
                                label="Customer Details"
                            />
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} className='dashboard-charts-div p-0 '>
                        <div className='charts-container'>
                            <RelationshipTypeChart
                                moduleName="Relationship Type"
                                labels={Rlabels}
                                colors={Rcolors}
                                data={Rdata}
                                showYear={true}
                                showCompany={true}
                            />
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} className='dashboard-charts-div p-0 pe-4'>
                        <div style={{ height: "100%" }}>
                            <FunnelChart
                                moduleName="No of Licence" colors={funnelColors} labels={funnelLabels} data={funnelData}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className='dashboard-charts pt-4 pb-4'>
                    <Col md={6} className='dashboard-charts-div p-0'>
                        <div className='charts-container'>
                            <LicenceSpliteUpChart />
                        </div>
                    </Col>
                    <Col md={3} className='dashboard-charts-div p-0'>
                        <div className='charts-container'>
                            <CustomerStatusChart
                                labels={labels}
                                colors={colors}
                                data={data}
                            />
                        </div>
                    </Col>
                    <Col md={3} className='dashboard-charts-div p-0 pe-4'>
                        <AssignedModules />
                    </Col>
                </Row>
            </Main>
        </div>
    )
}

export default DashBoard

