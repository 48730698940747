import React, { useEffect, useState } from 'react';
import Main from '../../Main';
import { Col, Dropdown, Row } from 'react-bootstrap';
import columnFilter from '../../../assets/images/CustomerManagement/columnFilter.svg'
import valueFilter from '../../../assets/images/CustomerManagement/valueFilter.svg'
import '../../../assets/css/dashboard.scss'
import '../../../assets/css/customermanagement.scss'
import 'react-datepicker/dist/react-datepicker.css';
import { SbAddBtn, SbFilterBtn } from '../../Buttons/Buttons';
import Select from 'react-select';
import AddDesignation from './AddDesignation';
import DesignationTable from './DesignationTable';
import Download from '../../MainSection/Download/Download';
import { useNavigate } from 'react-router-dom';
import DesignationColumnFilter from './DesignationColumnFilter';
import DesignationValueFilter from './DesignationValueFilter';
import { useDispatch, useSelector } from 'react-redux';
import { loadDesignationData, loadSingleDesignationData, postDesignationDatas } from '../../../redux/action';
import * as HeaderAuthentication from '../../HeaderAuthentication'
import { ADDED_BY_ID } from '../../Constants';

const statusOptions = [
    { value: "All", label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "In-Process", label: "In-Process" },
    { value: "Resolved", label: "Resolved" },
    { value: "Re-Open", label: "Re-Open" },
    { value: "Cancel", label: "Cancel" },
]
const Designation = () => {

    let dispatch = useDispatch();

    const { designationData } = useSelector((state) => state.data);
    const { singleDesignationData } = useSelector((state) => state.data);

    const [loading, setLoading] = useState(true);
    const getDesgData = () => {
        dispatch(loadDesignationData(setLoading));
    }
    useEffect(() => {
        getDesgData()
    }, []);

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [showColumnFilterModal, setShowColumnFilterModal] = useState(false);
    const [showValueFilterModal, setShowValueFilterModal] = useState(false);
    const [showCustomerViewDetails, setShowCustomerViewDetails] = useState(false);
    const [businessUnitModal, setBusinessUnitModal] = useState(false);
    const [branchPlantModal, setBranchPlantModal] = useState(false);
    const [desgID, setDesgID] = useState("")

    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };
    const handleOpenModal = (desId) => {
        setIsModalOpen(true);
        if (desId) {
            dispatch(loadSingleDesignationData(desId))
            setDesgID(desId);
        } else {
            setDesgID("");
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleStatusChange = (customer, statusLabel) => {
        const statusID = statusLabel === "Active" ? 10 : 11; // 10 for Active, 11 for Inactive
        
        // console.log(customer,'customer')
        const data = {
            DesignationID: customer.DesignationID,
            DesignationName: customer.DesignationName,
            StatusID: statusID,
            DepartmentID: customer.DepartmentID,
            ReportingDesignationID: customer.ReportingDesignationID,
            DesignationDescription: customer.DesignationDescription,
            AddedByID: ADDED_BY_ID
        };

        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data),
        };

        // Dispatch the action to update the department status
        dispatch(postDesignationDatas(requestOption, handleCloseModal, getDesgData, customer.DesignationID));
        // console.log(data, 'data'); // For debugging purposes
    };

    const handleOpenColumnFilterModal = () => {
        setShowFilterMenu(false);
        setShowColumnFilterModal(true);
    };

    const handleCloseColumnFilterModal = () => {
        setShowColumnFilterModal(false);
    };
    const handleOpenValueFilterModal = () => {
        setShowFilterMenu(false);
        setShowValueFilterModal(true);
    };

    const handleCloseValueFilterModal = () => {
        setShowValueFilterModal(false);
    };

    const handleViewDetails = () => {
        setShowCustomerViewDetails(!showCustomerViewDetails);
    }
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div className="customer-management-container">
            <Main>
                <div className='components-download-container'>
                    <Download
                        showBackButton={true}
                        moduleName="Settings / Designation"
                        onBackClick={handleBack}
                    />
                </div>
                <div className='customermanagement-content-container'>
                    <div className="customermanagement-content">
                        <Row className='customermanagement-btns-container'>
                            <Col md={3} className='customermanagement-backbtn-div'>
                                {/* <img src={backbtn} alt="" /> */}
                                <span>Job Title & Designation</span>
                            </Col>
                            <Col md={9} className='customermanagement-search-filter'>
                                <div className='customermanagement-header-btns filter-btn'>
                                    <SbFilterBtn btnName="Filters" onClickEffect={toggleFilterMenu} />
                                    {showFilterMenu && (
                                        <Dropdown.Menu show className='filter-dropdown-options'>
                                            <Dropdown.Item onClick={handleOpenColumnFilterModal}>
                                                <img src={columnFilter} alt="" />&nbsp;&nbsp;
                                                Column Filter
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={handleOpenValueFilterModal}>
                                                <img src={valueFilter} alt="" />&nbsp;&nbsp;
                                                Value Filter
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    )}
                                </div>
                                <Col md={3} className='filters-col'>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder="Search Status"
                                        // options={statusOptions}
                                        style={{ width: "100%" }}
                                    />
                                </Col>
                                <SbAddBtn btnName="Add" onClickEffect={() => { handleOpenModal("") }} />
                            </Col>
                        </Row>
                        <div className='customermanagement-table-div'>
                            <DesignationTable
                                handleOpenModal={handleOpenModal}
                                handleViewDetails={handleViewDetails}
                                designationData={designationData}
                                loading={loading}
                                handleStatusChange={handleStatusChange}
                            />
                        </div>
                    </div>
                </div>
            </Main>
            {
                isModalOpen && <AddDesignation
                    open={isModalOpen}
                    handleCloseModal={handleCloseModal}
                    getDesgData={getDesgData}
                    desgID={desgID}
                    singleDesignationData={singleDesignationData}
                />
            }
            {showColumnFilterModal && (
                <DesignationColumnFilter
                    showColumnFilterModal={showColumnFilterModal}
                    handleCloseColumnFilterModal={handleCloseColumnFilterModal}
                />
            )}
            {showValueFilterModal && (
                <DesignationValueFilter
                    showValueFilterModal={showValueFilterModal}
                    handleCloseValueFilterModal={handleCloseValueFilterModal}
                />
            )}
            {/* {showCustomerViewDetails && (
                <CustomerViewDetails
                    showCustomerViewDetails={showCustomerViewDetails}
                    handleCloseViewDetails={handleCloseViewDetails}
                />
            )} */}
        </div >
    );
};

export default Designation;