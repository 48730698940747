import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';
import { loadApprovalLevelDropdownData, loadDepartmentDropdownData, postLeaveApprovalDatas } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import * as HeaderAuthentication from '../../HeaderAuthentication'
import { ADDED_BY_ID } from "../../Constants";

const enablingOptions = [
    { value: 52, label: "Active" },
    { value: 53, label: "Inactive" }
]

const leaveApprovalOrderOptions = [
    { value: 'Sequential', label: "Sequential" },
    { value: 'Parallel', label: "Parallel" }
]

const AddLeaveApproval = (props) => {

    let dispatch = useDispatch();
    const singleLeaveApprovalData = props.singleLeaveApprovalData;
    const leaveApproval = props.leaveApproval;
    const leaveApprovalId = props.leaveApprovalId;
    const { approvalLevelDropdown } = useSelector((state) => state.data);
    const { departmentDropdown } = useSelector((state) => state.data);
    const [leaveApprovalData, setLeaveApprovalData] = useState(
        {
            LevelID: null,
            Title: "",
            DepartmentID: null,
            Order: "",
            Condition: "",
            StatusID: null,
            AddedByID: ADDED_BY_ID
        }
    )

    const [errors, setErrors] = useState({
        LevelID: "",
        Title: "",
        DepartmentID: "",
        Order: "",
        Condition: "",
    });

    useEffect(() => {
        if (leaveApprovalId && singleLeaveApprovalData && singleLeaveApprovalData.length > 0) {
            const singleApproval = singleLeaveApprovalData[0]; // Access the first item

            // Set the state with the data from singleLeaveApprovalData
            setLeaveApprovalData({
                LeaveApprovalID: leaveApprovalId,
                LevelID: singleApproval.LeaveApprovalID,
                Title: singleApproval.LeavesApprovalTitle,
                DepartmentID: singleApproval.LeavesApprovalDepartmentID,
                Order: singleApproval.LeavesApprovalOrder,
                Condition: singleApproval.LeavesApprovalCondition,
                StatusID: singleApproval.StatusID,
                AddedByID: ADDED_BY_ID
            });
        }
    }, [leaveApprovalId, singleLeaveApprovalData]);

    const handleChange = (field) => (selectedOption) => {
        setLeaveApprovalData((prevData) => ({
            ...prevData,
            [field]: selectedOption ? selectedOption.value : null
        }));
        // Clear error message when the user selects a value
        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: ""
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLeaveApprovalData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        // Clear error message when the user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: ""
        }));
    };

    useEffect(() => {
        dispatch(loadApprovalLevelDropdownData());
        dispatch(loadDepartmentDropdownData());
    }, []);

    const validateForm = () => {
        let formErrors = {};

        // Check if all required fields are filled
        if (!leaveApprovalData.LevelID) formErrors.LevelID = "Approval Level is required.";
        if (!leaveApprovalData.Title) formErrors.Title = "Title is required.";
        if (!leaveApprovalData.DepartmentID) formErrors.DepartmentID = "Department is required.";
        if (!leaveApprovalData.Order) formErrors.Order = "Approval Order is required.";
        if (!leaveApprovalData.Condition) formErrors.Condition = "Condition is required.";
        if (!leaveApprovalData.StatusID) formErrors.StatusID = "Status is required.";

        setErrors(formErrors);

        // Return false if there are errors, true if the form is valid
        return Object.keys(formErrors).length === 0;
    };

    const AddLeaveApprovalData = (e) => {
        e.preventDefault();
        if (validateForm()) {
            let requestOption = {
                method: "POST",
                mode: "cors",
                headers: HeaderAuthentication.postExcomatixAuth,
                body: JSON.stringify(leaveApprovalData),
            };
            dispatch(postLeaveApprovalDatas(requestOption, props.handleCloseModal, leaveApproval, leaveApprovalId));
            console.log(leaveApprovalData, 'leaveApprovalData')
        }
    }

    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{leaveApprovalId ? "Edit Leave Approval" : "Add Leave Approval"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Approval Levels</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={approvalLevelDropdown}
                                            placeholder="Select"
                                            value={approvalLevelDropdown.find(option => option.value === leaveApprovalData.LevelID)}
                                            onChange={handleChange("LevelID")}
                                        />
                                    </div>
                                    {errors.LevelID && <span className="text-danger">{errors.LevelID}</span>}
                                </Col>
                                <Col md={8}>
                                    <div>
                                        <label>Title</label>
                                        <Form.Control
                                            type="text"
                                            name="Title"
                                            value={leaveApprovalData.Title}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    {errors.Title && <span className="text-danger">{errors.Title}</span>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Department</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={departmentDropdown}
                                            placeholder="Select"
                                            value={departmentDropdown.find(option => option.value === leaveApprovalData.DepartmentID)}
                                            onChange={handleChange("DepartmentID")}
                                        />
                                    </div>
                                    {errors.DepartmentID && <span className="text-danger">{errors.DepartmentID}</span>}
                                </Col>
                                <Col md={8}>
                                    <div>
                                        <label>Approval Order</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={leaveApprovalOrderOptions}
                                            placeholder="Select"
                                            value={leaveApprovalOrderOptions.find(option => option.value === leaveApprovalData.Order)}
                                            onChange={handleChange("Order")}
                                        />
                                    </div>
                                    {errors.Order && <span className="text-danger">{errors.Order}</span>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Leave Condition</label>
                                        <Form.Control
                                            type="text"
                                            name="Condition"
                                            value={leaveApprovalData.Condition}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    {errors.Condition && <span className="text-danger">{errors.Condition}</span>}
                                </Col>
                            </Row>
                            {leaveApprovalId && <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Status</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={enablingOptions}
                                            placeholder="Select"
                                            value={enablingOptions.find(option => option.value === leaveApprovalData.StatusID)}
                                            onChange={handleChange("StatusID")}
                                        />
                                    </div>
                                </Col>
                            </Row>}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={AddLeaveApprovalData} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddLeaveApproval;
