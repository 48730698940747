import React, { useState } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import companySmallLogo from './assets/images/Sidebar/company-small-logo.svg';
import forgotpasswordImg from './assets/images/Login/forgot-password-img.svg';
import { postForgotPassword } from './redux/action';
import * as HeaderAuthentication from '../src/components/HeaderAuthentication'
import successImg from '../src/assets/images/Login/successImg.svg';
import failedImg from '../src/assets/images/Login/failedImg.svg';

function Forgetpassword() {
    let dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const navigateToResetPassword = () => {
        window.location.href = '#/reset-password'
    }

    const handleForgotPassword = (e) => {
        e.preventDefault();

        const data = {
            EmailID: email
        }
        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data),
        };
        dispatch(postForgotPassword(requestOption, setSuccessMessage, setErrorMessage));
        console.log(data, 'data')
    }

    return (
        <div className="signin-main-container">
            <Row className="lm-signin-login-row">
                <Col md={6} className='lm-signin-form-col forgot-password-form-col'>
                    <img
                        src={companySmallLogo}
                        alt="Company Logo"
                        className='company-small-logo'
                    />
                    {!successMessage && (
                        <>
                            <h4 className="lm-wlcm-txt">Forgot Password</h4>
                            <p className='pb-4 forgot-password-reset-text'>Enter your registered email address to receive a password reset link</p>
                        </>
                    )}
                    <Form className='lm-login-form'>
                        {!successMessage && (
                            <div>
                                <div className='input-box'>
                                    <div className='input-box1'>
                                        <label>Email</label>
                                        <Form.Control
                                            type="email"
                                            id="EmailID"
                                            name="EmailID"
                                            value={email}
                                            onChange={(e) => { setEmail(e.target.value) }}
                                        />
                                    </div>
                                </div>

                                <div className='lm-login-form-btn'>
                                    {/* <p className='lm-pswd-reset'>Forgot password?</p> */}
                                    <Button onClick={handleForgotPassword}>Send Email</Button>
                                </div>
                            </div>
                        )}
                        {/* Display error or success message */}
                        {errorMessage && (
                            <div className="mt-3 text-danger">
                                {/* <img src={failedImg} alt="failed" className="mr-2 p-4" /> */}
                                {errorMessage}
                            </div>
                        )}

                        {successMessage && (
                            <div className="mt-3 text-success d-flex flex-column justify-content-center align-items-center ">
                                <img src={successImg} alt="success" className="mr-2 p-4" />
                                {successMessage}
                            </div>
                        )}
                    </Form>


                </Col>
                <Col md={6} className='forget-password-second-col'>
                    <div className='lm-bg-img-div'>
                        <img src={forgotpasswordImg} alt="" className='lm-bg-img' />
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Forgetpassword;
