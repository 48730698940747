import { useState } from 'react';
import { Col, Modal, Row, Form, Button } from 'react-bootstrap';
import searchIcon from '../../../assets/images/Dashboard/search-icon.svg';
import SbCancelBtn, { SbResetBtn, SBSaveUpdateBtn } from '../../Buttons/Buttons';

const DesignationColumnFilter = ({ showColumnFilterModal, handleCloseColumnFilterModal }) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [displayItems, setDisplayItems] = useState([]);

    const items = [
        "Company", "Name", "Relationship Type", "No.of Licence",
        "Address", "Start Date / End Date", "Customer Category", "Assigned Modules"
    ];

    const handleSelectItem = (item) => {
        if (!displayItems.includes(item)) {
            setDisplayItems(prev => [...prev, item]);
        }
        setSelectedItems(prev => prev.includes(item) ? prev.filter(i => i !== item) : [...prev, item]);
    };

    const handleRemoveDisplayItem = (item) => {
        setDisplayItems(prev => prev.filter(i => i !== item));
        setSelectedItems(prev => prev.filter(i => i !== item));
    };

    const handleReset = () => {
        setSelectedItems([]);
        setDisplayItems([]);
    };

    return (
        <Modal show={showColumnFilterModal} onHide={handleCloseColumnFilterModal} className='add-customer-modal'>
            <Modal.Header closeButton>
                <Modal.Title>Column Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <p className='mb-2'>Select Items</p>
                        <div className='customermanagement-filter-search'>
                            <Form.Control type="search" placeholder='Search' />
                            <img src={searchIcon} alt="search icon" />
                        </div>
                        <div className='item-list'>
                            {items.map(item => (
                                <Form.Check
                                    key={item}
                                    type="checkbox"
                                    label={item}
                                    checked={selectedItems.includes(item)}
                                    onChange={() => handleSelectItem(item)}
                                />
                            ))}
                        </div>
                    </Col>
                    <Col md={6}>
                        <p className='mb-2'>Display Items</p>
                        <div className='customermanagement-filter-search'>
                            <Form.Control type="search" placeholder='Search' />
                            <img src={searchIcon} alt="search icon" />
                        </div>
                        <div className='item-list'>
                            {displayItems.map(item => (
                                <div key={item} className='display-item'>
                                    {item}
                                    <button onClick={() => handleRemoveDisplayItem(item)}>X</button>
                                </div>
                            ))}
                        </div>
                    </Col>
                </Row>
                <Row className='customer-rest-container'>
                    <Col className='customer-rest-col'>
                        <SbResetBtn btnName="Reset" onClickEffect={handleReset} />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <SbCancelBtn btnName="Cancel" onClickEffect={handleCloseColumnFilterModal} />
                <SBSaveUpdateBtn btnName="Save" />
            </Modal.Footer>
        </Modal>
    );
};

export default DesignationColumnFilter;
