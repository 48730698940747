import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import companySmallLogo from './assets/images/Sidebar/company-small-logo.svg';
import eyeClose from './assets/images/Login/eye-close.svg';
import eyeOpen from './assets/images/Login/eye-open.svg';
import resetPasswordImg from './assets/images/Login/reset-password-img.svg';
import { postResetPasswordDatas } from './redux/action';
import * as HeaderAuthentication from '../src/components/HeaderAuthentication'
import { useLocation } from 'react-router-dom';
import { baseURL } from './components/BaseUrl';

function ResetPassword() {
    let dispatch = useDispatch();

    // Extract query parameters
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const EI = params.get('EI');
    const TP = params.get('TP');
    const TK = params.get('TK');

    // Separate state for password and confirm password visibility
    const [passwordType, setPasswordType] = useState("password"); // New Password visibility
    const [confirmPasswordType, setConfirmPasswordType] = useState("password"); // Confirm Password visibility
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [validationError, setValidationError] = useState({
        password: "",
        confirmPassword: ""
    });
    const [decreptedEmail, setDecreptedEmail] = useState("");

    // Regular expression for password validation
    const passwordValidationRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.{8,15}$).*$/;

    // Toggle visibility of password fields
    const togglePassword = (field) => {
        if (field === 'new') {
            setPasswordType(passwordType === "password" ? "text" : "password");
        } else if (field === 'confirm') {
            setConfirmPasswordType(confirmPasswordType === "password" ? "text" : "password");
        }
    };

    // Handle password field change
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        // Clear password error message when the user starts typing
        setValidationError((prevErrors) => ({
            ...prevErrors,
            password: "",
        }));
    };

    // Handle confirm password field change
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        // Clear confirm password error message when the user starts typing
        setValidationError((prevErrors) => ({
            ...prevErrors,
            confirmPassword: "",
        }));
    };

    const navigateToSignInPage = () => {
        setTimeout(() => {
            window.location.href = '#/'
        }, 1500); // Delay
    }

    useEffect(() => {
        // Make API call to decrypt the email
        fetch(`${baseURL}/accountservices/api/account/PostDecrypt`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "DecryptValue": EI })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                // Assuming the response contains the decrypted email in a field like 'DecryptedEmail'
                if (resp && resp.Msg) {
                    setDecreptedEmail(resp.Msg); // Set the decrypted email
                } else {
                    setErrorMessage("Failed to retrieve decrypted email.");
                }
            })
            .catch((err) => {
                setErrorMessage("Error fetching decrypted email.");
            });
    }, [EI]);

    const handleResetPassword = (e) => {
        e.preventDefault();

        // Reset validation errors
        setValidationError({
            password: "",
            confirmPassword: "",
        });

        let hasError = false;

        // Check if password is empty
        if (!password) {
            setValidationError((prevErrors) => ({
                ...prevErrors,
                password: "Password is required",
            }));
            hasError = true;
        } else if (!passwordValidationRegex.test(password)) {
            setValidationError((prevErrors) => ({
                ...prevErrors,
                password: "Password must be 8-15 characters, include at least one uppercase letter and one special character",
            }));
            hasError = true;
        }

        // Check if confirm password is empty
        if (!confirmPassword) {
            setValidationError((prevErrors) => ({
                ...prevErrors,
                confirmPassword: "Confirm Password is required",
            }));
            hasError = true;
        }

        // Check if passwords do not match
        if (password !== confirmPassword) {
            setValidationError((prevErrors) => ({
                ...prevErrors,
                confirmPassword: "Passwords do not match",
            }));
            hasError = true;
        }

        // If there are validation errors, prevent API call
        if (hasError) {
            return;
        }

        const data = {
            EmailID: EI,
            SigninType: TP,
            ResetPswdFlag: TK,
            Password: password,
        }
        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data),
        };
        dispatch(postResetPasswordDatas(requestOption, setSuccessMessage, setErrorMessage, navigateToSignInPage));
    }

    return (
        <div className="signin-main-container">
            <Row className="lm-signin-login-row">
                <Col md={6} className='lm-signin-form-col forgot-password-form-col'>
                    <img
                        src={companySmallLogo}
                        alt="Company Logo"
                        className='company-small-logo'
                    />
                    <h4 className="lm-wlcm-txt">Forgot Password</h4>
                    <p className='pb-4 forgot-password-reset-text'>Set your account new password</p>
                    <Form className='lm-login-form'>
                        <div className='input-box'>
                            <div className='input-box1'>
                                <label>Email</label>
                                <Form.Control
                                    type="email"
                                    id="EmailID"
                                    name="EmailID"
                                    value={decreptedEmail} // Set the email from the query parameter
                                    disabled // Disable the email field
                                />
                            </div>
                            <div style={{ position: "relative" }}>
                                <div className='input-box2'>
                                    <label>New Password</label>
                                    <Form.Control
                                        type={passwordType}
                                        id="Password"
                                        name="Password"
                                        value={password}
                                        onChange={handlePasswordChange} // Store password
                                        required
                                    />
                                </div>
                                <div className="pass-hide-show" onClick={() => togglePassword('new')}>
                                    {passwordType === "password" ? (
                                        <img src={eyeClose} alt="" />
                                    ) : (
                                        <img src={eyeOpen} alt="" />
                                    )}
                                </div>
                            </div>
                            {validationError.password && (
                                <div className="text-danger pt-2 d-flex">
                                    {validationError.password}
                                </div>
                            )}
                            <div style={{ position: "relative" }}>
                                <div className='input-box2'>
                                    <label>Confirm Password</label>
                                    <Form.Control
                                        type={confirmPasswordType}
                                        id="confirmPasswordType"
                                        name="confirmPasswordType"
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange} // Store confirm password
                                        required
                                    />
                                </div>
                                <div className="pass-hide-show" onClick={() => togglePassword('confirm')}>
                                    {confirmPasswordType === "password" ? (
                                        <img src={eyeClose} alt="" />
                                    ) : (
                                        <img src={eyeOpen} alt="" />
                                    )}
                                </div>
                            </div>
                            {validationError.confirmPassword && (
                                <div className="text-danger pt-2 d-flex">
                                    {validationError.confirmPassword}
                                </div>
                            )}
                        </div>

                        <div className='lm-login-form-btn'>
                            {/* <p className='lm-pswd-reset'>Forgot password?</p> */}
                            <Button onClick={handleResetPassword}>Submit</Button>
                        </div>
                        {/* Error message */}
                        {errorMessage && <div className="text-danger pt-2">{errorMessage}</div>}

                        {/* Success message */}
                        {successMessage && <div className="text-success pt-2">{successMessage}</div>}
                    </Form>
                </Col>
                <Col md={6} className='forget-password-second-col'>
                    <div className='lm-bg-img-div'>
                        <img src={resetPasswordImg} alt="" className='lm-bg-img' />
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default ResetPassword;
