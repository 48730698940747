import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SbApprovalBtn, SBSaveUpdateBtn } from "../Buttons/Buttons";
import customerImg from '../../assets/images/CustomerManagement/customer-img.svg'
import Select from 'react-select';
import { useEffect, useRef, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import calendar from '../../assets/images/Dashboard/calendar-icon.svg'
import * as HeaderAuthentication from '../HeaderAuthentication'
import { loadCountryCodeDropdownData, loadDepartmentDropdownData, loadReportingDesignationDropdownData, loadReportingToDropdownData, loadRolesDropdownData, postDepartmentDatas, postUserManagementDatas } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Company_ID } from "../Constants";

const options = [
    { label: "Functional Approval", value: "functional_approval" },
    { label: "Financial Approval", value: "financial_approval" },
    { label: "Complaints Approval", value: "complaints_approval" },
    { label: "Escalation Approval", value: "escalation_approval" },
    { label: "Leave Approval", value: "leave_approval" },
];

const statusOptions = [
    { value: 16, label: "Active" },
    { value: 17, label: "Inactive" },
]
const AddUserModal = (props) => {

    let dispatch = useDispatch();
    const userID = props.userID;
    const singleUserData = props.singleUserData;
    const userManagenemtData = props.userManagenemtData;
    const { departmentDropdown } = useSelector((state) => state.data);
    const { rolesDropdown } = useSelector((state) => state.data);
    const { reportingToDropdown } = useSelector((state) => state.data);
    const { getCountryCodeDropdownData } = useSelector((state) => state.data);
    const { reportingDesignationDropdown } = useSelector((state) => state.data);

    const [userData, setUserData] = useState({
        File: "",
        FileName: "",
        IsFileUpdated: false, // Default value is false
        UserCode: "",
        CustomerID: Company_ID,
        FirstName: "",
        MiddleName: "",
        LastName: "",
        EmailID: "",
        CountryCodeID: null,
        Phone: "",
        WorkAddress: "",
        ZipCode: "",
        DOB: "",
        SecondaryPhoneCountryCodeID: null,
        SecondaryPhone: "",
        DesignationID: "",
        DepartmentID: "",
        DOJ: "",
        TotalExperience: "",
        ProbationPeriod: "",
        ReportingToID: "",
        HomeAddress: "",
        UserRoles: [
            // {
            //     RoleID: null
            // }
        ],
    });
    const [fromDate, setFromDate] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
    const [selectedApprovalTypes, setSelectedApprovalTypes] = useState([]);
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [errors, setErrors] = useState({});

    const handleSetApproval = () => {
        setShowApprovalModal(true);
    };

    const handleChange = (field, value) => {
        setUserData((prev) => ({ ...prev, [field]: value }));
        setErrors((prev) => ({ ...prev, [field]: null }));  // Clear error when user starts typing
    };

    // Handle the country code select change
    const handleChangeCountryCode = (selectedOption) => {
        handleChange("CountryCodeID", selectedOption.value);
    };

    // Handle emergency phone code select change
    const handleChangeEmergencyCode = (selectedOption) => {
        handleChange("SecondaryPhoneCountryCodeID", selectedOption.value);
    };

    // Handle the status select change
    const handleChangeStatus = (selectedOption) => {
        setSelectedStatus(selectedOption);
    };

    // Handle the date picker change for DOB, DOJ, etc.
    const handleDateChange = (field, date) => {
        handleChange(field, date);
    };

    const handleRoleChange = (selectedOptions) => {
        const updatedRoles = selectedOptions.map((option) => ({ RoleID: option.value }));
        handleChange("UserRoles", updatedRoles);
    };

    const fileInputRef = useRef(null);

    const [selectedImg, setSelectedImg] = useState(null); // State for the selected image
    const [imgChange, setImgChange] = useState(false);

    const handleUploadClick = () => {
        fileInputRef.current.click(); // Trigger the file input click
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Get the file selected by the user
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                // The full base64 string includes the 'data:image/png;base64,' part
                const base64String = reader.result;

                // Get the filename from the file object
                const fileName = file.name;  // Get the file name

                // Set the fileName as prefix, so that it contains only the file name
                handleChange("FileName", fileName);  // Send the file name to your backend

                // Store the full base64 data in the File parameter (excluding the prefix)
                const base64Data = base64String.split(',')[1];  // Extract the actual base64 data part
                handleChange("File", base64Data);  // Save the base64 data in the File field

                // Update the state to display the image preview using full base64 string
                setSelectedImg(base64String);  // Display the image preview in the UI
                setImgChange(true);
            };
            reader.readAsDataURL(file);  // Convert the image to base64 format
        }
    };

    useEffect(() => {
        dispatch(loadDepartmentDropdownData());
        dispatch(loadRolesDropdownData());
        dispatch(loadReportingToDropdownData());
        dispatch(loadCountryCodeDropdownData());
        dispatch(loadReportingDesignationDropdownData());
    }, [])

    useEffect(() => {
        if (userID && singleUserData && singleUserData.length > 0) {
            const user = singleUserData[0];  // Get the first user from the array
            setUserData({
                UserID: userID,
                FileName: user.FileName || '',
                FilePath: user.FilePath || '',
                IsFileUpdated: false,  // Default to false
                UserCode: user.UserCode || '',
                CustomerID: user.CustomerID || '',
                FirstName: user.FirstName || '',
                MiddleName: user.MiddleName || '',
                LastName: user.LastName || '',
                EmailID: user.EmailID || '',
                CountryCodeID: user.CountryCodeID || '',
                Phone: user.Phone || '',
                WorkAddress: user.WorkAddress || '',
                ZipCode: user.ZipCode || '',
                HomeAddress: user.HomeAddress || '',
                DOB: user.DOB ? moment(user.DOB).toDate() : '',
                SecondaryPhoneCountryCodeID: user.SecondaryPhoneCountryCodeID || '',
                SecondaryPhone: user.SecondaryPhone || '',
                DesignationID: user.DesignationID || '',
                DepartmentID: user.DepartmentID || '',
                DOJ: user.DOJ ? moment(user.DOJ).toDate() : '',
                TotalExperience: user.TotalExperience || '',
                ProbationPeriod: user.ProbationPeriod || '',
                ReportingToID: user.ReportingToID || '',
                StatusID: user.StatusID || '',
                UserRoles: Array.isArray(user.UserRoles) ? user.UserRoles : [] // Ensure UserRoles is an array
            });
            // Set the image if it exists
            if (user.FilePath) {
                setSelectedImg(user.FilePath);  // Use the FilePath from the API for the preview
            }
        }
    }, [userID, singleUserData]);

    const validateForm = () => {
        const requiredFields = [
            "FirstName", "LastName", "EmailID", "Phone", "WorkAddress", "ZipCode",
            "DOB", "SecondaryPhone", "DesignationID", "DepartmentID", "DOJ",
            "TotalExperience", "ProbationPeriod", "ReportingToID", "HomeAddress", "UserRoles"
        ];

        let newErrors = {};
        for (let field of requiredFields) {
            if (!userData[field] || (Array.isArray(userData[field]) && userData[field].length === 0)) {
                newErrors[field] = "This field is required.";
            }
        }

        // // Check if the image is uploaded if necessary
        // if (!userData.File) {
        //     newErrors.File = "Please upload an image.";
        // }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;  // Return false if there are errors
    };

    const AddUserManagementData = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;  // If validation fails, prevent form submission
        }

        const updatedUserData = { ...userData, IsFileUpdated: imgChange };
        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(updatedUserData),
        };
        dispatch(postUserManagementDatas(requestOption, props.handleClose, userManagenemtData, userID, imgChange, setImgChange));
    }

    return (
        <>
            <Modal show={props.open} onHide={props.handleClose} className='add-customer-modal add-user-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col className='customer-form-fields-image-div'>
                            <img
                                // src={selectedImg || customerImg}
                                // src={selectedImg || (singleUserData && singleUserData[0] && singleUserData[0].FilePath) || customerImg}
                                src={
                                    selectedImg // Check if an image has been selected
                                        ? selectedImg // Display the selected image (base64 string or URL)
                                        : userData.FilePath // Check if there is an image from the server
                                            ? userData.FilePath.includes('http') // If it's a URL, display it
                                                ? userData.FilePath // If it's a valid URL, display it
                                                : `data:image/png;base64,${userData.FilePath}` // If it's base64, prepend the MIME type
                                            : customerImg // Fallback to customerImg if no selected image or server image
                                }
                                alt="" style={{ width: "100px", height: "100px", cursor: "pointer" }}
                                onClick={handleUploadClick}
                            />
                            <input type="file"
                                hidden
                                ref={fileInputRef}
                                onChange={handleFileChange}
                            />
                            <p>Upload Image</p>
                        </Col>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>First Name</label>
                                        <Form.Control
                                            type="text"
                                            value={userData.FirstName}
                                            onChange={(e) => handleChange("FirstName", e.target.value)}
                                            required
                                        />
                                    </div>
                                    {errors.FirstName && <div className="text-danger">{errors.FirstName}</div>}
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Middle Name</label>
                                        <Form.Control
                                            type="text"
                                            value={userData.MiddleName}
                                            onChange={(e) => handleChange("MiddleName", e.target.value)}
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Last Name</label>
                                        <Form.Control
                                            type="text"
                                            value={userData.LastName}
                                            onChange={(e) => handleChange("LastName", e.target.value)}
                                            required
                                        />
                                    </div>
                                    {errors.LastName && <div className="text-danger">{errors.LastName}</div>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Email ID</label>
                                        <Form.Control
                                            type="email"
                                            value={userData.EmailID}
                                            onChange={(e) => handleChange("EmailID", e.target.value)}
                                            required
                                        />
                                    </div>
                                    {errors.EmailID && <div className="text-danger">{errors.EmailID}</div>}
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Contact Number</label>
                                        <div className='customer-contact-fields-div'>
                                            <Select
                                                // className='customer-contact-select '
                                                className="react-select-container customer-contact-select"
                                                classNamePrefix="react-select"
                                                value={getCountryCodeDropdownData?.find(option => option.value === userData.CountryCodeID)}
                                                onChange={handleChangeCountryCode}
                                                options={getCountryCodeDropdownData}
                                                placeholder="Select"
                                            />
                                            <Form.Control
                                                type="tel"
                                                value={userData.Phone}
                                                onChange={(e) => handleChange("Phone", e.target.value)}
                                                required
                                            />
                                        </div>
                                        {errors.Phone && <div className="text-danger">{errors.Phone}</div>}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Work Address</label>
                                        <Form.Control
                                            type="text"
                                            value={userData.WorkAddress}
                                            onChange={(e) => handleChange("WorkAddress", e.target.value)}
                                            required
                                        />
                                    </div>
                                    {errors.WorkAddress && <div className="text-danger">{errors.WorkAddress}</div>}
                                </Col>

                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Zip Code</label>
                                        <Form.Control
                                            type="number"
                                            value={userData.ZipCode}
                                            onChange={(e) => handleChange("ZipCode", e.target.value)}
                                            required
                                        />
                                    </div>
                                    {errors.ZipCode && <div className="text-danger">{errors.ZipCode}</div>}
                                </Col>
                                <Col md={4}>
                                    <div className='add-customer-calendar-container'>
                                        <label>Date of Birth</label>
                                        {/* <div className='add-customer-calendar-container'> */}
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd-MM-yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            // selectsRange
                                            placeholderText='DD/MM/YYYY'
                                            dropdownMode="select"
                                            selected={userData.DOB ? moment(userData.DOB, 'DD-MM-YYYY').toDate() : null} // Convert string to Date object
                                            onChange={(date) => handleDateChange("DOB", date)}
                                            maxDate={new Date()}
                                            required
                                        />
                                        <img src={calendar} alt="" />
                                        {/* </div> */}
                                    </div>
                                    {errors.DOB && <div className="text-danger">{errors.DOB}</div>}
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Emergency Contact</label>
                                        <div className='customer-contact-fields-div'>
                                            <Select
                                                // className='customer-contact-select '
                                                className="react-select-container customer-contact-select"
                                                classNamePrefix="react-select"
                                                options={getCountryCodeDropdownData}
                                                value={getCountryCodeDropdownData?.find(option => option.value === userData.SecondaryPhoneCountryCodeID)}
                                                onChange={handleChangeEmergencyCode}
                                                placeholder="+91"
                                            />
                                            <Form.Control
                                                type="tel"
                                                value={userData.SecondaryPhone}
                                                onChange={(e) => handleChange("SecondaryPhone", e.target.value)}
                                                required
                                            />
                                        </div>
                                        {errors.SecondaryPhone && <div className="text-danger">{errors.SecondaryPhone}</div>}
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Job Title</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={reportingDesignationDropdown}
                                            value={reportingDesignationDropdown?.find(option => option.value === userData.DesignationID)}
                                            onChange={(selectedOption) => handleChange("DesignationID", selectedOption.value)}
                                            placeholder="Select"
                                            required
                                        />
                                    </div>
                                    {errors.DesignationID && <div className="text-danger">{errors.DesignationID}</div>}
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Role</label>
                                        <MultiSelect
                                            options={rolesDropdown || []}
                                            value={userData.UserRoles?.map(role =>
                                                rolesDropdown?.find(option => option.value === role.RoleID) || {} // Safe fallback to an empty object if not found
                                            ) || []}
                                            onChange={handleRoleChange}
                                            required
                                        />
                                    </div>
                                    {errors.RoleID && <div className="text-danger">{errors.RoleID}</div>}
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Department</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={departmentDropdown}
                                            value={departmentDropdown?.find(option => option.value === userData.DepartmentID)}
                                            onChange={(selectedOption) => handleChange("DepartmentID", selectedOption.value)}
                                            placeholder="Select"
                                            required
                                        />
                                    </div>
                                    {errors.DepartmentID && <div className="text-danger">{errors.DepartmentID}</div>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className='add-customer-calendar-container'>
                                        <label>Joining Date</label>
                                        {/* <div className='add-customer-calendar-container'> */}
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd-MM-yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            // selectsRange
                                            dropdownMode="select"
                                            placeholderText='DD/MM/YYYY'
                                            selected={userData.DOJ ? moment(userData.DOJ, 'DD-MM-YYYY').toDate() : null} // Convert string to Date object
                                            onChange={(date) => handleDateChange("DOJ", date)}
                                            maxDate={new Date()}
                                            required
                                        />
                                        <img src={calendar} alt="" />
                                        {/* </div> */}
                                    </div>
                                    {errors.DOJ && <div className="text-danger">{errors.DOJ}</div>}
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Total Experience DOJ(Years)</label>
                                        <Form.Control
                                            type="number"
                                            value={userData.TotalExperience}
                                            onChange={(e) => handleChange("TotalExperience", e.target.value)}
                                            required
                                        />
                                    </div>
                                    {errors.TotalExperience && <div className="text-danger">{errors.TotalExperience}</div>}
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Probation Period (Months)</label>
                                        <Form.Control
                                            type="number"
                                            value={userData.ProbationPeriod}
                                            onChange={(e) => handleChange("ProbationPeriod", e.target.value)}
                                            required
                                        />
                                    </div>
                                    {errors.ProbationPeriod && <div className="text-danger">{errors.ProbationPeriod}</div>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Reporting To</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            value={reportingToDropdown?.find(option => option.value === userData.ReportingToID)}
                                            onChange={(selectedOption) => handleChange("ReportingToID", selectedOption.value)}
                                            options={reportingToDropdown}
                                            placeholder="Select"
                                            required
                                        />
                                    </div>
                                    {errors.ReportingToID && <div className="text-danger">{errors.ReportingToID}</div>}
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Home Address</label>
                                        <Form.Control
                                            type="text"
                                            value={userData.HomeAddress}
                                            onChange={(e) => handleChange("HomeAddress", e.target.value)}
                                            required
                                        />
                                    </div>
                                    {errors.HomeAddress && <div className="text-danger">{errors.HomeAddress}</div>}
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Status</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            value={selectedStatus}
                                            onChange={handleChangeStatus}
                                            options={statusOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>

                            </Row>
                            <Row>

                                <Col md={8}>
                                    <label>Approval Type</label>
                                    <MultiSelect
                                        options={options}
                                        value={selectedApprovalTypes}
                                        onChange={setSelectedApprovalTypes}
                                        labelledBy="Select Approval Type(s)"
                                    />
                                </Col>
                                <Col md={4} className="d-flex justify-content-start align-items-end">
                                    <SbApprovalBtn btnName="Set Approval" onClickEffect={handleSetApproval} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Modal.Body >
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={AddUserManagementData} />
                </Modal.Footer>
            </Modal >

            <Modal show={showApprovalModal} onHide={() => setShowApprovalModal(false)} className="add-user-submodal">
                <Modal.Header closeButton>
                    <Modal.Title>Set Approval</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedApprovalTypes.map(option => (
                        <div key={option.value} className="approval-option mb-3">
                            <label>{option.label} Level</label>
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                placeholder={option.label}
                            />
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddUserModal;