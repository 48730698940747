import React, { useState } from 'react';
import SortIcon from '../../assets/images/Dashboard/chevron-down.svg';
import { Modal } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import SbCancelBtn, { SBSaveUpdateBtn } from './Buttons';

const PriorityStatusBadgeWithDropdown = ({ onStatusChange }) => {
    const [selectedStatus, setSelectedStatus] = useState('01');
    const [showModal, setShowModal] = useState(false); // State for modal visibility
    const [newStatus, setNewStatus] = useState(''); // State to hold the new status selected

    // Generate dropdown values from 01 to 10
    const dropdownValues = Array.from({ length: 10 }, (_, i) => (i + 1).toString().padStart(2, '0'));

    // Handle the change in status
    const handleStatusChange = (status) => {
        setNewStatus(status); // Store the new selected status
        setShowModal(true); // Show the modal
    };

    // Confirm status change
    const confirmStatusChange = () => {
        // setSelectedStatus(newStatus); // Change the status
        if (onStatusChange) {
            onStatusChange(newStatus); // Notify parent component of the status change
        }
        setShowModal(false); // Close the modal
    };

    // Cancel status change
    const cancelStatusChange = () => {
        setShowModal(false); // Just close the modal without changing the status
    };

    return (
        <div className="status-badge-with-dropdown" style={{ position: 'relative', display: 'inline-block' }}>
            <span className="status-badge-for-enabling status-badge-for-priority">
                {selectedStatus}
                <span className="dropdown-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={SortIcon} alt="dropdown icon" />
                </span>
                <ul className="dropdown-menu" aria-labelledby="statusDropdown">
                    {dropdownValues.map((value) => (
                        <li key={value}>
                            <button
                                className="dropdown-item"
                                onClick={() => handleStatusChange(value)}
                            >
                                {value}
                            </button>
                        </li>
                    ))}
                </ul>
            </span>

            {/* Modal for confirmation */}
            <Modal show={showModal} onHide={cancelStatusChange} className='add-customer-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Priority Status Change</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to change the priority status to {newStatus}?</p>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="cancel" onClickEffect={cancelStatusChange} />
                    <SBSaveUpdateBtn btnName="Yes" onClickEffect={confirmStatusChange} />
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default PriorityStatusBadgeWithDropdown;
