import React, { useEffect, useState } from 'react';
import Main from '../../Main';
import { Col, Dropdown, Row } from 'react-bootstrap';
import columnFilter from '../../../assets/images/CustomerManagement/columnFilter.svg'
import valueFilter from '../../../assets/images/CustomerManagement/valueFilter.svg'
import '../../../assets/css/dashboard.scss'
import '../../../assets/css/customermanagement.scss'
import 'react-datepicker/dist/react-datepicker.css';
import { SbAddBtn, SbFilterBtn } from '../../Buttons/Buttons';
import Select from 'react-select';
import ComplaintsApprovalTable from './ComplaintsApprovalTable';
import AddComplaintsApproval from './AddComplaintsApproval';
import Download from '../../MainSection/Download/Download';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadSettingsComplaintsApprovalData, loadSettingsSingleComplaintsApprovalData, postComplaintsApprovalDatas } from '../../../redux/action';
import ComplaintsApprovalColumnFilter from './ComplaintsApprovalColumnFilter';
import { ADDED_BY_ID } from '../../Constants';
import * as HeaderAuthentication from '../../HeaderAuthentication'

const ComplaintsApproval = () => {
    let dispatch = useDispatch();

    const { complaintsApprovalData } = useSelector((state) => state.data);
    const { singleComplaintsApprovalData } = useSelector((state) => state.data);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [showColumnFilterModal, setShowColumnFilterModal] = useState(false);
    const [showValueFilterModal, setShowValueFilterModal] = useState(false);
    const [showCustomerViewDetails, setShowCustomerViewDetails] = useState(false);
    const [businessUnitModal, setBusinessUnitModal] = useState(false);
    const [branchPlantModal, setBranchPlantModal] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([
        "COMPLAINTS LEVEL", "TITLE", "DEPARTMENT", "COMPLAINTS ORDER", "COMPLAINTS CONDITIONS", "STATUS", "ACTION"
    ]);
    const [complaintsApprovalId, setComplaintsApprovalId] = useState("")

    const complaintsApprovalDatas = () => {
        dispatch(loadSettingsComplaintsApprovalData());
    }

    useEffect(() => {
        complaintsApprovalDatas();
    }, [])

    useEffect(() => {
        dispatch(loadSettingsSingleComplaintsApprovalData());
    }, [])

    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };
    const handleOpenModal = (complaintsApprovalId) => {
        setIsModalOpen(true);
        if (complaintsApprovalId) {
            dispatch(loadSettingsSingleComplaintsApprovalData(complaintsApprovalId));
            setComplaintsApprovalId(complaintsApprovalId);
        } else {
            setComplaintsApprovalId("");
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleOpenColumnFilterModal = () => {
        setShowFilterMenu(false);
        setShowColumnFilterModal(true);
    };

    const handleCloseColumnFilterModal = () => {
        setShowColumnFilterModal(false);
    };
    const handleOpenValueFilterModal = () => {
        setShowFilterMenu(false);
        setShowValueFilterModal(true);
    };

    const handleCloseValueFilterModal = () => {
        setShowValueFilterModal(false);
    };

    const handleViewDetails = () => {
        setShowCustomerViewDetails(!showCustomerViewDetails);
    }

    const handleStatusChange = (customer, statusLabel) => {
        const statusID = statusLabel === "Active" ? 46 : 47; // 50 for Active, 51 for Inactive

        // console.log(customer, 'customer')
        const data = {
            ComplaintApprovalID: customer.ComplaintApprovalID,
            LevelID: customer.ComplaintsApprovalLevelID,
            Title: customer.ComplaintsApprovalTitle,
            DepartmentID: customer.ComplaintsApprovalDepartmentID,
            Order: customer.ComplaintsApprovalOrder,
            Condition: customer.ComplaintsApprovalCondition,
            StatusID: statusID,
            AddedByID: ADDED_BY_ID
        };

        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data),
        };

        // Dispatch the action to update the department status
        dispatch(postComplaintsApprovalDatas(requestOption, handleCloseModal, complaintsApprovalDatas, customer.ComplaintApprovalID));
        // console.log(data, 'data'); // For debugging purposes
    };

    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };

    const handleColumnSelectionChange = (updatedSelectedColumns) => {
        setSelectedColumns(updatedSelectedColumns); // Update the selected columns
    };
    return (
        <div className="customer-management-container">
            <Main>
                <div className='components-download-container'>
                    <Download
                        showBackButton={true}
                        moduleName="Settings / Complaints Approval"
                        onBackClick={handleBack}
                    />
                </div>
                <div className='customermanagement-content-container'>
                    <div className="customermanagement-content">
                        <Row className='customermanagement-btns-container'>
                            <Col md={3} className='customermanagement-backbtn-div'>
                                {/* <img src={backbtn} alt="" /> */}
                                <span>Complaints Approval</span>
                            </Col>
                            <Col md={9} className='customermanagement-search-filter'>
                                <div className='customermanagement-header-btns filter-btn'>
                                    <SbFilterBtn btnName="Filters" onClickEffect={toggleFilterMenu} />
                                    {showFilterMenu && (
                                        <Dropdown.Menu show className='filter-dropdown-options'>
                                            <Dropdown.Item onClick={handleOpenColumnFilterModal}>
                                                <img src={columnFilter} alt="" />&nbsp;&nbsp;
                                                Column Filter
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={handleOpenValueFilterModal}>
                                                <img src={valueFilter} alt="" />&nbsp;&nbsp;
                                                Value Filter
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    )}
                                </div>
                                <Col md={3} className='filters-col'>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder="Search Status"
                                        // options={statusOptions}
                                        style={{ width: "100%" }}
                                    />
                                </Col>
                                <SbAddBtn btnName="Add" onClickEffect={() => { handleOpenModal("") }} />
                            </Col>
                        </Row>
                        <div className='customermanagement-table-div'>
                            <ComplaintsApprovalTable
                                handleOpenModal={handleOpenModal}
                                handleViewDetails={handleViewDetails}
                                complaintsApprovalData={complaintsApprovalData}
                                selectedColumns={selectedColumns}
                                handleStatusChange={handleStatusChange}
                            />
                        </div>
                    </div>
                </div>
            </Main>
            {
                isModalOpen && <AddComplaintsApproval
                    open={isModalOpen}
                    handleCloseModal={handleCloseModal}
                    complaintsApprovalDatas={complaintsApprovalDatas}
                    complaintsApprovalId={complaintsApprovalId}
                    singleComplaintsApprovalData={singleComplaintsApprovalData}
                />
            }
            {showColumnFilterModal && (
                <ComplaintsApprovalColumnFilter
                    showColumnFilterModal={showColumnFilterModal}
                    handleCloseColumnFilterModal={handleCloseColumnFilterModal}
                    selectedColumns={selectedColumns}
                    handleSaveColumns={handleColumnSelectionChange}
                />
            )}
            {/* {showValueFilterModal && (
                <CustomerValueFilterModal
                    showValueFilterModal={showValueFilterModal}
                    handleCloseValueFilterModal={handleCloseValueFilterModal}
                />
            )}
            {showCustomerViewDetails && (
                <CustomerViewDetails
                    showCustomerViewDetails={showCustomerViewDetails}
                    handleCloseViewDetails={handleCloseViewDetails}
                />
            )} */}
        </div >
    );
};

export default ComplaintsApproval;