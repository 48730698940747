import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'; // Import moment
import PaginationComponent from '../PaginationComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadSingleWorkquesData } from '../../redux/action';
import { Col, Row } from 'react-bootstrap';
import Download from '../MainSection/Download/Download';
import Main from '../Main';
import backbtn from '../../assets/images/Dashboard/back-btn.svg'
import noDataFound from '../../assets/images/no-data-found.svg'

const WorkQueueDetail = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const location = useLocation(); // UseLocation to get route data like workqueueID
    const workqueueID = location.state?.workqueueID; // Assuming the workqueueID is passed in the route state
    const { singleWorkqueuesData } = useSelector((state) => state.data);

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [paginatedData, setPaginatedData] = useState([]);

    // Fetching data based on workqueueID when the component mounts or workqueueID changes
    useEffect(() => {
        if (workqueueID) {
            console.log("Dispatching load data for Workqueue ID:", workqueueID);
            dispatch(loadSingleWorkquesData(workqueueID)); // Dispatch action to load data
        }
    }, [workqueueID, dispatch]);

    useEffect(() => {
        if (singleWorkqueuesData) {
            setPaginatedData(singleWorkqueuesData[0]?.ModuleField); // Initialize paginated data
        }
    }, [singleWorkqueuesData]);

    // Pagination logic
    useEffect(() => {
        const start = page * rowsPerPage;
        const end = start + rowsPerPage;
        setPaginatedData(singleWorkqueuesData[0]?.ModuleField.slice(start, end));
    }, [singleWorkqueuesData, page, rowsPerPage]);

    const handlePageChange = (newPage) => {
        setPage(newPage - 1); // Convert 1-based to 0-based index for pagination
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset to first page
    };

    // Function to format date if it's a valid date
    const formatDate = (date) => {
        return date && moment(date).isValid() ? moment(date).format('DD/MM/YYYY') : 'NA';
    };

    // Function to display "NA" if the field value is empty, null, or undefined
    const getFieldValue = (fieldValue) => {
        if (!fieldValue || fieldValue === '') {
            return 'NA'; // Return "NA" if fieldValue is empty, null, or undefined
        }
        return fieldValue;
    };

    const handleBack = () => {
        navigate(-1);
    }

    return (
        <div className="customer-management-container">
            <Main>
                <div className='components-download-container'>
                    <Download
                        showDashboardLibraryButton={false}
                        showSaveCustomViewButton={false}
                        showAnalyticsButton={false}
                        showDownloadDropdown={true}
                    />
                </div>
                <div className="customermanagement-content-container">
                    <div className="customermanagement-content">
                        <Row className='customermanagement-btns-container'>
                            <Col md={3} className='customermanagement-backbtn-div'>
                                <img src={backbtn} alt="" onClick={handleBack} style={{ cursor: "pointer" }} />&nbsp;&nbsp;
                                <span>Work Queues</span>
                            </Col>
                        </Row>

                        <div className='customermanagement-table-div'>
                            <TableContainer component={Paper} sx={{ maxHeight: 440 }} className='table-container'>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {/* Render table headers dynamically from FieldName */}
                                            {singleWorkqueuesData && singleWorkqueuesData[0]?.ModuleField.map((field, index) => (
                                                <TableCell key={index} sx={{ minWidth: 150 }}>
                                                    {field.FieldName}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* Check if there is no data */}
                                        {(!singleWorkqueuesData || singleWorkqueuesData.length === 0) ? (
                                            <TableRow>
                                                <TableCell colSpan={singleWorkqueuesData ? singleWorkqueuesData[0]?.ModuleField.length : 1} align="center">
                                                    <img src={noDataFound} alt="No Data" style={{ height: "250px", width: "280px" }} />
                                                    <p>No data found</p>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            singleWorkqueuesData.map((rowData, rowIndex) => (
                                                <TableRow key={rowIndex}>
                                                    {rowData?.ModuleField?.map((field, fieldIndex) => (
                                                        <TableCell key={fieldIndex}>
                                                            {/* Check if the FieldValue is a date and format it */}
                                                            {field.FieldName.includes("DOB") || field.FieldName.includes("DOJ")
                                                                ? formatDate(field.FieldValue) // Format dates
                                                                : getFieldValue(field.FieldValue)} {/* Display FieldValue or NA */}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* Pagination Component */}
                            {singleWorkqueuesData && singleWorkqueuesData.length > 0 && (
                                <PaginationComponent
                                    totalItems={singleWorkqueuesData ? singleWorkqueuesData.length : 0}
                                    itemsPerPage={rowsPerPage}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleRowsPerPageChange}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </Main>
        </div>
    );
};

export default WorkQueueDetail;
