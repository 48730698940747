import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "../../../assets/css/modulemanagement.scss";
import Select from 'react-select';

const modulesData1 = [
    {
        category: "Leave Management",
        status: true,
        items: ["Dashboards", "Leave Management Module"],
    },
    {
        category: "Complaints and Escalation",
        status: true,
        items: ["Dashboards", "Complains and Escalation Module"],
    },
    {
        category: "AMC or SLA Module",
        status: false,
        items: ["Dashboards", "AMC or SLA Module"],
    },
    {
        category: "Ticketing Interface",
        status: true,
        items: ["Dashboards", "Ticketing Module"],
    },
    {
        category: "Planning & Forecasting",
        status: true,
        items: ["Dashboards", "Planning & Forecasting Module"],
    },
    {
        category: "Inventory Management",
        status: false,
        items: ["Dashboards", "Inventory Optimization Module", "Criticality Analytics Module"],
    },
];

const modulesData2 = [
    {
        category: "Warehousing",
        status: true,
        items: ["Dashboards", "Basic Warehouse Transactions", "Repair & Return", "Preservation Module", "Quality Module", "Asset Module", "Laptop Tracking", "Advanced Analytics (AI/ML)", "Order Management"],
    },
    {
        category: "Category Management",
        status: true,
        items: ["Dashboards"],
    },
    {
        category: "Investment Recovery",
        status: false,
        items: ["Dashboards", "Investment Recovery Module"],
    },
    {
        category: "Logistics & Delivery",
        status: true,
        items: ["Dashboards", "Logistics & Delivery Module", "ASN Module"],
    },
];

const MenubarTable = () => {
    const [modules1, setModules1] = useState(modulesData1);
    const [modules2, setModules2] = useState(modulesData2);

    const toggleModuleStatus = (index, column) => {
        const modules = column === 1 ? [...modules1] : [...modules2];
        modules[index].status = !modules[index].status;
        column === 1 ? setModules1(modules) : setModules2(modules);
    };

    return (
        <div className="module-management-container">
            <Row className="justify-content-between">
                <Col md={4}>
                    <h6>Menu Bar Enable / Disable</h6>
                </Col>
                <Col md={3}>
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        // value={selectedOption}
                        //   onChange={handleChange}
                        // options={contactOptions}
                        placeholder="Select Client"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6} className="modules-grid1">
                    {modules1.map((module, index) => (
                        <div className="module-container p-3 pb-0" key={index}>
                            <div className="modulename-header-div">
                                <p>{module.category}</p>
                                <div className="custom-toggle-switch">
                                    <input
                                        type="checkbox"
                                        className="custom-toggle-input"
                                        id={`custom-switch-1-${index}`}
                                        checked={module.status}
                                        onChange={() => toggleModuleStatus(index, 1)}
                                    />
                                    <label
                                        className={`custom-toggle-label ${module.status ? "enabled" : "disabled"}`}
                                        htmlFor={`custom-switch-1-${index}`}
                                    >
                                        {module.status ? "Enable" : "Disable"}
                                    </label>
                                </div>
                            </div>
                            <div className="module-names-container">
                                {module.items.map((item, idx) => (
                                    <div className="form-check" key={idx}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`item-1-${index}-${idx}`}
                                            checked={module.status}
                                            readOnly
                                        />
                                        <label className="form-check-label" htmlFor={`item-1-${index}-${idx}`}>
                                            {item}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <hr />
                        </div>
                    ))}
                </Col>
                <Col md={6} className="modules-grid2">
                    {modules2.map((module, index) => (
                        <div className="module-container p-3 pb-0" key={index}>
                            <div className="modulename-header-div">
                                <p>{module.category}</p>
                                <div className="custom-toggle-switch">
                                    <input
                                        type="checkbox"
                                        className="custom-toggle-input"
                                        id={`custom-switch-2-${index}`}
                                        checked={module.status}
                                        onChange={() => toggleModuleStatus(index, 2)}
                                    />
                                    <label
                                        className={`custom-toggle-label ${module.status ? "enabled" : "disabled"}`}
                                        htmlFor={`custom-switch-2-${index}`}
                                    >
                                        {module.status ? "Enable" : "Disable"}
                                    </label>
                                </div>
                            </div>
                            <div className="module-names-container">
                                {module.items.map((item, idx) => (
                                    <div className="form-check" key={idx}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`item-2-${index}-${idx}`}
                                            checked={module.status}
                                            readOnly
                                        />
                                        <label className="form-check-label" htmlFor={`item-2-${index}-${idx}`}>
                                            {item}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <hr />
                        </div>
                    ))}
                </Col>
            </Row>
        </div>
    );
};

export default MenubarTable;
