import React, { useEffect, useState } from 'react';
import CustomerManagementTable from './CustomerManagementTable';
import Main from '../Main';
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import backbtn from '../../assets/images/Dashboard/back-btn.svg'
import filterIcon from '../../assets/images/Dashboard/filter-icon.svg'
import searchIcon from '../../assets/images/Dashboard/search-icon.svg'
import calendar from '../../assets/images/Dashboard/calendar-icon.svg'
import columnFilter from '../../assets/images/CustomerManagement/columnFilter.svg'
import valueFilter from '../../assets/images/CustomerManagement/valueFilter.svg'
import '../../assets/css/dashboard.scss'
import '../../assets/css/customermanagement.scss'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AddCustomerModal from './AddCustomerModal';
import { SbAddBtn, SbFilterBtn } from '../Buttons/Buttons';
import CustomerFilterModal from './CustomerFilterModal';
import CustomerValueFilterModal from './CustomerValueFilterModal';
import CustomerViewDetails from './CustomerViewDetails';
import Download from '../MainSection/Download/Download';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadCompanyData, loadSingleCompanyData } from '../../redux/action';

const CustomerManagement = () => {

    let dispatch = useDispatch();

    const { companyData } = useSelector((state) => state.data);
    const { singleCustomerData } = useSelector((state) => state.data);

    const [loading, setLoading] = useState(true);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [showColumnFilterModal, setShowColumnFilterModal] = useState(false);
    const [showValueFilterModal, setShowValueFilterModal] = useState(false);
    const [showCustomerViewDetails, setShowCustomerViewDetails] = useState(false);
    const [businessUnitModal, setBusinessUnitModal] = useState(false);
    const [branchPlantModal, setBranchPlantModal] = useState(false);
    const [companyCode, setCompanyCode] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [selectedColumns, setSelectedColumns] = useState([
        "ID", "COMPANY CODE", "COMPANY", "NAME", "NO.OF.LICENSE",
        "EMAIL", "ADDRESS", "START DATE", "END DATE", "STATUS", "ACTION"
    ]);

    const customerManagementData = () => {
        dispatch(loadCompanyData(setLoading));
    }
    useEffect(() => {
        customerManagementData();
    }, []);

    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };
    const handleOpenModal = (comCode, comId) => {
        if (comId && comCode) {
            dispatch(loadSingleCompanyData(comCode))
            setCompanyCode(comCode);
            setCompanyId(comId); // Reset or set to null
        } else if (comCode && !comId) {
            dispatch(loadSingleCompanyData(comCode))
            setCompanyCode(comCode);
            setCompanyId("");
        } else {
            setCompanyCode("");
            setCompanyId("");
        }

        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleOpenColumnFilterModal = () => {
        setShowFilterMenu(false);
        setShowColumnFilterModal(true);
    };

    const handleCloseColumnFilterModal = () => {
        setShowColumnFilterModal(false);
    };
    const handleOpenValueFilterModal = () => {
        setShowFilterMenu(false);
        setShowValueFilterModal(true);
    };

    const handleCloseValueFilterModal = () => {
        setShowValueFilterModal(false);
    };

    const handleViewDetails = (comCode) => {
        setShowCustomerViewDetails(!showCustomerViewDetails);
        if (comCode) {
            dispatch(loadSingleCompanyData(comCode))
            setCompanyCode(comCode);
        } else {
            setCompanyCode("");
        }
    }
    const handleCloseViewDetails = () => {
        setShowCustomerViewDetails(false);
    }
    const handleOpenBusinessUnitModal = () => {
        setIsModalOpen(false);
        setBusinessUnitModal(true);
    }
    const handleCloseBusinessUnitModal = () => {
        setBusinessUnitModal(false);
    }
    const handleBackHeadQuarters = () => {
        setBusinessUnitModal(false);
        setIsModalOpen(true);
    }
    const handleOpenBranchPlantModal = () => {
        setBusinessUnitModal(false);
        setBranchPlantModal(true);
    }
    const handleCloseBranchPlantModal = () => {
        setBranchPlantModal(false);
    }
    const handleBackBusinessUnit = () => {
        setBusinessUnitModal(true);
        setBranchPlantModal(false);
    }

    const handleColumnSelectionChange = (updatedSelectedColumns) => {
        setSelectedColumns(updatedSelectedColumns); // Update the selected columns
    };

    const navigate = useNavigate();
    const handleAnalyticsClick = () => {
        navigate('/dashboard')
    }

    return (
        <div className="customer-management-container">
            {!showCustomerViewDetails && <Main>
                <div className='components-download-container'>
                    <Download
                        showDashboardLibraryButton={false}
                        showSaveCustomViewButton={false}
                        showAnalyticsButton={true}
                        showDownloadDropdown={true}
                        onClick={handleAnalyticsClick}
                    />
                </div>
                <div className='customermanagement-content-container'>
                    <div className="customermanagement-content">
                        <Row className='customermanagement-btns-container'>
                            <Col md={3} className='customermanagement-backbtn-div'>
                                {/* <img src={backbtn} alt="" /> */}
                                <span>Customer Management</span>
                            </Col>
                            <Col md={9} className='customermanagement-search-filter'>
                                {/* <div className='customermanagement-header-btns filter-btn'>
                                <Button><img src={filterIcon} alt="" className='filter-img' />Filters</Button>
                            </div> */}
                                <div className='customermanagement-header-btns filter-btn'>
                                    <SbFilterBtn btnName="Filters" onClickEffect={toggleFilterMenu} />
                                    {showFilterMenu && (
                                        <Dropdown.Menu show className='filter-dropdown-options'>
                                            <Dropdown.Item onClick={handleOpenColumnFilterModal}>
                                                <img src={columnFilter} alt="" />&nbsp;&nbsp;
                                                Column Filter
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={handleOpenValueFilterModal}>
                                                <img src={valueFilter} alt="" />&nbsp;&nbsp;
                                                Value Filter
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    )}
                                </div>
                                <Col md={3} className='filters-col'>
                                    <div className='customermanagement-search'>
                                        <Form.Control type="search" placeholder='Search' />
                                        <img src={searchIcon} alt="" className='p-1' />
                                    </div>
                                </Col>
                                <Col md={2} className='filters-col'>
                                    <div className='calendar-container'>
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            selectsRange
                                            dropdownMode="select"
                                            placeholderText='From'
                                            startDate={fromDate}
                                            // onChange={(data) => setSelectSearchDrop(data, "date")}
                                            maxDate={new Date()}
                                        />
                                        <img src={calendar} alt="" />
                                    </div>
                                </Col>
                                <Col md={2} className='filters-col'>
                                    <div className='calendar-container'>
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            selectsRange
                                            placeholderText='To'
                                            dropdownMode="select"
                                            endDate={toDate}
                                            // onChange={(data) => setSelectSearchDrop(data, "date")}
                                            maxDate={new Date()}
                                        />
                                        <img src={calendar} alt="" />
                                    </div>
                                </Col>
                                {/* <div className='customermanagement-header-btns add-btn' onClick={handleOpenModal}>
                                <Button><img src={add} alt="" className='filter-img' />Add Customer</Button>
                            </div> */}
                                <SbAddBtn btnName="Add Customer" onClickEffect={() => { handleOpenModal("") }} />
                            </Col>
                        </Row>
                        <div className='customermanagement-table-div'>
                            <CustomerManagementTable
                                handleOpenModal={handleOpenModal}
                                handleViewDetails={handleViewDetails}
                                handleOpenValueFilterModal={handleOpenValueFilterModal}
                                companyData={companyData}
                                loading={loading}
                                selectedColumns={selectedColumns}
                            />
                        </div>
                    </div>
                </div>
            </Main>}
            {
                isModalOpen && <AddCustomerModal
                    open={isModalOpen}
                    handleClose={handleCloseModal}
                    singleCustomerData={singleCustomerData}
                    companyCode={companyCode}
                    customerManagementData={customerManagementData}
                    companyId={companyId}
                />
            }
            {
                showColumnFilterModal && (
                    <CustomerFilterModal
                        showColumnFilterModal={showColumnFilterModal}
                        handleCloseColumnFilterModal={handleCloseColumnFilterModal}
                        selectedColumns={selectedColumns}
                        handleSaveColumns={handleColumnSelectionChange}
                    />
                )
            }
            {
                showValueFilterModal && (
                    <CustomerValueFilterModal
                        showValueFilterModal={showValueFilterModal}
                        handleCloseValueFilterModal={handleCloseValueFilterModal}
                    />
                )
            }
            {
                showCustomerViewDetails && (
                    <CustomerViewDetails
                        showCustomerViewDetails={showCustomerViewDetails}
                        handleCloseViewDetails={handleCloseViewDetails}
                        handleAnalyticsClick={handleAnalyticsClick}
                        singleCustomerData={singleCustomerData}
                        companyCode={companyCode}
                        setShowCustomerViewDetails={setShowCustomerViewDetails}
                    />
                )
            }
        </div >
    );
};

export default CustomerManagement;