import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Menu,
    MenuItem
} from '@mui/material';
import SortIcon from '../../assets/images/Dashboard/chevron-down.svg';
import ascToDesc from '../../assets/images/CustomerManagement/ascToDesc.svg';
import descToAsc from '../../assets/images/CustomerManagement/descToAsc.svg';
import SbActionDropdownButton from '../Buttons/SbActionDropdownButton';
import { SbAddBtn, SbFilterBtn } from '../Buttons/Buttons';
import Select, { components } from 'react-select';
import calendar from '../../assets/images/Dashboard/calendar-icon.svg';
import { Col, Dropdown, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import searchIcon from '../../assets/images/Dashboard/search-icon.svg'
import columnFilter from '../../assets/images/CustomerManagement/columnFilter.svg'
import valueFilter from '../../assets/images/CustomerManagement/valueFilter.svg'
import AddSLA from './AddSLA';
import UpdatedViewDetails from './UpdatedViewDetails';
import sortScoreImg from '../../assets/images/sort-score-img.svg'
import PaginationComponent from '../PaginationComponent';
import SlaValueFilterModal from './SlaValueFilterModal';
import { useDispatch, useSelector } from 'react-redux';
import { loadAssignedToSlaData } from '../../redux/action';
import moment from 'moment';

const columns = [
    { id: 'SLAID', label: 'SLA NO', align: 'left', minWidth: '100px' },
    { id: 'SLAType', label: 'TYPE', align: 'left', minWidth: '100px' },
    { id: 'CustomerName', label: 'COMPANY NAME', align: 'left', minWidth: '160px' },
    { id: 'StartDate/EndDate', label: 'START DATE / END DATE', align: 'left', minWidth: '200px' },
    { id: 'TargetPercentage', label: 'TARGET %', align: 'left', minWidth: '120px' },
    { id: 'AssignedTo', label: 'ASSIGNED TO', align: 'left', minWidth: '140px' },
    { id: 'TargetValue', label: 'TARGET VALUE', align: 'left', minWidth: '150px' },
    { id: 'actualValue', label: 'ACTUAL VALUE', align: 'left', minWidth: '150px' },
    { id: 'reportDate', label: 'REPORT DATE', align: 'left', minWidth: '150px' },
    { id: 'AchievedPercentage', label: 'ACHIEVED %', align: 'left', minWidth: '130px' },
    { id: 'department', label: 'DEPARTMENT', align: 'left', minWidth: '100px' },
    { id: 'assignedScore', label: 'ASSIGNED SCORE', align: 'left', minWidth: '160px' },
    { id: 'Status', label: 'STATUS', align: 'left', minWidth: '150px' },
    { id: 'action', label: 'ACTION', align: 'left', minWidth: '80px', sortable: false },
];

const sortScoreOptions = [
    { value: "All", label: "All" },
    { value: "Below <40%", label: "Below <40%" },
    { value: "40-70%", label: "40-70%" },
    { value: "Above >70%", label: "Above >70%" },
]
const customStyles = {
    option: (provided, state) => {
        let color;
        switch (state.data.label) {
            case 'Below <40%':
                color = 'rgba(255, 94, 94, 1)';
                break;
            case '40-70%':
                color = 'rgba(240, 207, 43, 1)';
                break;
            case 'Above >70%':
                color = 'rgba(58, 201, 119, 1)';
                break;
            default:
                color = 'rgba(52, 58, 64, 1)';
        }
        return {
            ...provided,
            color,
            cursor: 'pointer',
        };
    }
};
const IndicatorSeparator = () => null;
const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <img src={sortScoreImg} alt="Indicator" style={{ width: '20px', height: '20px' }} />
        </components.DropdownIndicator>
    );
};
const AssignToTable = (props) => {

    let dispatch = useDispatch();
    const { assignedToSlaData = [] } = useSelector((state) => state.data);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Set initial rows per page
    const [paginatedData, setPaginatedData] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleRequestSort = (event, property) => {
        setCurrentSortColumn(property);
        setAnchorEl(event.currentTarget);
    };

    const handleSortOrderChange = (order) => {
        setOrder(order);
        setOrderBy(currentSortColumn);
        setAnchorEl(null);
    };

    const assignedToSlaDatas = () => {
        dispatch(loadAssignedToSlaData(setLoading));
    }
    useEffect(() => {
        assignedToSlaDatas();
    }, []);

    const sortedData = React.useMemo(() => {
        const stabilizedData = assignedToSlaData.map((el, index) => [el, index]);
        stabilizedData.sort((a, b) => {
            const orderModifier = order === 'asc' ? 1 : -1;
            if (a[0][orderBy] < b[0][orderBy]) {
                return -1 * orderModifier;
            }
            if (a[0][orderBy] > b[0][orderBy]) {
                return 1 * orderModifier;
            }
            return a[1] - b[1];
        });
        return stabilizedData.map((el) => el[0]);
    }, [assignedToSlaData, order, orderBy]);

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [showUpdatedViewModal, setShowUpdatedViewModal] = useState(false);
    const [showColumnFilterModal, setShowColumnFilterModal] = useState(false);
    const [showValueFilterModal, setShowValueFilterModal] = useState(false);
    const [clickedCell, setClickedCell] = useState({ rowId: null, columnId: null }); // Track clicked cell

    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenViewModal = () => {
        setShowUpdatedViewModal(!showUpdatedViewModal)
    }

    const handleCloseViewModal = () => {
        setShowUpdatedViewModal(false)
    }

    const handleOpenColumnFilterModal = () => {
        setShowFilterMenu(false);
        setShowColumnFilterModal(true);
    };

    const handleCloseColumnFilterModal = () => {
        setShowColumnFilterModal(false);
    };
    const handleOpenValueFilterModal = () => {
        setShowFilterMenu(false);
        setShowValueFilterModal(true);
    };

    const handleCloseValueFilterModal = () => {
        setShowValueFilterModal(false);
    };

    // Pagination logic
    useEffect(() => {
        const start = page * rowsPerPage; // Updated page logic to 0-based
        const end = start + rowsPerPage;
        setPaginatedData(sortedData.slice(start, end));
    }, [sortedData, page, rowsPerPage]);

    const handlePageChange = (newPage) => {
        setPage(newPage - 1); // Handle 1-based to 0-based page index for slicing
    };
    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset to the first page when rows per page changes
    };

    return (
        <>
            <Row className='customermanagement-btns-container'>
                <Col md={3} className='customermanagement-backbtn-div'>
                    <span>Service Level Agreement</span>
                </Col>
                <Col md={9} className='customermanagement-search-filter'>
                    <div className='customermanagement-header-btns filter-btn'>
                        <SbFilterBtn btnName="Filters" onClickEffect={toggleFilterMenu} />
                        {showFilterMenu && (
                            <Dropdown.Menu show className='filter-dropdown-options'>
                                <Dropdown.Item onClick={handleOpenColumnFilterModal}>
                                    <img src={columnFilter} alt="Column Filter" />&nbsp;&nbsp;
                                    Column Filter
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleOpenValueFilterModal}>
                                    <img src={valueFilter} alt="Value Filter" />&nbsp;&nbsp;
                                    Value Filter
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        )}
                    </div>
                    <Col md={2} className='filters-col'>
                        <div className='customermanagement-search'>
                            <Form.Control type="search" placeholder='Search' />
                            <img src={searchIcon} alt="Search" className='p-2' />
                        </div>
                    </Col>
                    <Col md={2} className='filters-col'>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder="Sort Score"
                            options={sortScoreOptions}
                            style={{ width: "100%" }}
                            styles={customStyles}
                            components={{ DropdownIndicator, IndicatorSeparator }}
                        />
                    </Col>
                    <Col md={2} className='filters-col'>
                        <div className='calendar-container'>
                            <DatePicker
                                className="select from-date"
                                dateFormat="dd/MM/yyyy"
                                selectsStart
                                placeholderText='From'
                                startDate={fromDate}
                                endDate={toDate}
                                onChange={date => handleFromDateChange(date)}
                                maxDate={new Date()}
                            />
                            <img src={calendar} alt="Calendar" />
                        </div>
                    </Col>
                    <Col md={2} className='filters-col'>
                        <div className='calendar-container'>
                            <DatePicker
                                className="select to-date"
                                dateFormat="dd/MM/yyyy"
                                selectsEnd
                                placeholderText='To'
                                startDate={fromDate}
                                endDate={toDate}
                                onChange={date => handleToDateChange(date)}
                                maxDate={new Date()}
                            />
                            <img src={calendar} alt="Calendar" />
                        </div>
                    </Col>
                    <SbAddBtn btnName="Add SLA" onClickEffect={handleOpenModal} />
                </Col>
            </Row>
            <div className='customermanagement-table-div'>
                <TableContainer component={Paper} sx={{ maxHeight: 440 }} className='table-container'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }} className='table-header-div'>
                                            {column.label}
                                            {column.sortable !== false && (
                                                <IconButton
                                                    size="small"
                                                    onClick={(event) => handleRequestSort(event, column.id)}
                                                >
                                                    <img src={SortIcon} alt="Sort" />
                                                </IconButton>
                                            )}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData.map((customer) => (
                                <TableRow key={customer.id}>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            className={clickedCell.rowId === customer.slaNo && clickedCell.columnId === column.id ? 'clicked-value' : ''}
                                            onMouseDown={() => setClickedCell({ rowId: customer.slaNo, columnId: column.id })}
                                            onMouseUp={() => setClickedCell({ rowId: null, columnId: null })}
                                            onMouseLeave={() => setClickedCell({ rowId: null, columnId: null })}
                                        >
                                            {column.id === 'status' ? (
                                                <span
                                                    className={`status-badge ${customer.status === 'Completed' ? 'complete-status no-dropdown-badge' :
                                                        customer.status === 'In-Progress' ? 'inprogress-status no-dropdown-badge' :
                                                            customer.status === 'Hold' ? 'pending-status no-dropdown-badge' :
                                                                customer.status === 'Cancel' ? 'cancel-status no-dropdown-badge' :
                                                                    ''
                                                        }`}
                                                >
                                                    {customer.status}
                                                </span>
                                            ) : column.id === 'assignedScore' ? (
                                                <span
                                                    className={`status-badge ${customer.assignedScore <= 1 ? 'cancel-status no-dropdown-badge' :
                                                        customer.assignedScore >= 2 && customer.assignedScore <= 3 ? 'pending-status no-dropdown-badge' :
                                                            customer.assignedScore >= 4 && customer.assignedScore <= 5 ? 'complete-status no-dropdown-badge' :
                                                                ''
                                                        }`}
                                                >
                                                    {customer.assignedScore}
                                                </span>
                                            ) : column.id === 'action' ? (
                                                <SbActionDropdownButton
                                                    options={[{ type: 'View' }]}
                                                    openAddModal={handleOpenModal}
                                                    addBulkModel={handleOpenViewModal}
                                                />
                                            ) :
                                                column.id === 'StartDate/EndDate' ? (
                                                    // Format and display the StartDate and EndDate
                                                    <>
                                                        <span>
                                                            {moment(customer.StartDate).format('DD-MM-YYYY')} /
                                                            {moment(customer.EndDate).format('DD-MM-YYYY')}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <span
                                                        onClick={() => {
                                                            setClickedCell({ rowId: customer.slaNo, columnId: column.id }); // Track clicked cell
                                                            handleOpenValueFilterModal(); // Open modal
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {customer[column.id]}
                                                    </span>
                                                )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                    >
                        <MenuItem onClick={() => handleSortOrderChange('asc')}>
                            <img src={ascToDesc} alt="" />&nbsp;&nbsp;
                            Ascending to Descending
                        </MenuItem>
                        <MenuItem onClick={() => handleSortOrderChange('desc')}>
                            <img src={descToAsc} alt="" />&nbsp;&nbsp;
                            Descending to Ascending
                        </MenuItem>
                    </Menu>
                </TableContainer>
                {/* Use PaginationComponent */}
                <PaginationComponent
                    totalItems={assignedToSlaData.length}
                    itemsPerPage={rowsPerPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
                {isModalOpen && <AddSLA open={isModalOpen} handleClose={handleCloseModal} />}
                {showUpdatedViewModal && <UpdatedViewDetails open={showUpdatedViewModal} handleClose={handleCloseViewModal} />}
                {/* {
                    showColumnFilterModal && (
                        <SlaColumnFilterModal
                            showColumnFilterModal={showColumnFilterModal}
                            handleCloseColumnFilterModal={handleCloseColumnFilterModal}
                        />
                    )
                } */}
                {
                    showValueFilterModal && (
                        <SlaValueFilterModal
                            showValueFilterModal={showValueFilterModal}
                            handleCloseValueFilterModal={handleCloseValueFilterModal}
                        />
                    )
                }
            </div>
        </>
    );
};

export default AssignToTable;



