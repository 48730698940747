import React from 'react';
import { Modal } from 'react-bootstrap';
import SortIcon from '../../assets/images/Dashboard/chevron-down.svg';
import SbCancelBtn, { SBSaveUpdateBtn } from './Buttons';

const StatusBadgeWithDropdown = ({
    customer,
    activeText = 'Active',
    inactiveText = 'Inactive',
    confirmModalTitle = 'Confirmation',
    confirmModalBody = 'Do you want to change the status?',
    cancelButtonText = 'No',
    confirmButtonText = 'Yes',
    onStatusChange
}) => {
    const [showModal, setShowModal] = React.useState(false);
    const [modalText, setModalText] = React.useState('');
    const [statusToChange, setStatusToChange] = React.useState(''); // Store the status that will be updated
    const [selectedStatus, setSelectedStatus] = React.useState(customer.Status);

    const handleStatusChange = (status) => {
        setModalText(`Do you want to ${status.toLowerCase()} this status?`);
        setStatusToChange(status); // Set the status to change when user clicks
        setShowModal(true); // Show the confirmation modal
    };

    const handleCloseModal = () => {
        setShowModal(false); // Close the modal without updating anything
    };

    const handleConfirmChange = () => {
        console.log(`Status changed to: ${statusToChange}`);
        setSelectedStatus(statusToChange); // Update the selected status only after confirmation
        if (onStatusChange) {
            onStatusChange(statusToChange); // Notify parent component of the status change
        }
        setShowModal(false); // Close the modal after the status is changed
    };

    React.useEffect(() => {
        setSelectedStatus(customer.Status); // Ensure it reflects the initial status when customer data changes
    }, [customer]);

    return (
        <div className="status-badge-with-dropdown" style={{ position: 'relative', display: 'inline-block' }}>
            <span
                className={`status-badge-for-enabling ${selectedStatus === activeText ? 'complete-status' : 'cancel-status'}`}
            >
                {selectedStatus}
                <span className="dropdown-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={SortIcon} alt="dropdown icon" />
                </span>
                <ul className="dropdown-menu" aria-labelledby="statusDropdown">
                    <li>
                        <button
                            className="dropdown-item"
                            onClick={() => handleStatusChange(activeText)} // Show modal when Active is selected
                        >
                            {activeText}
                        </button>
                    </li>
                    <li>
                        <button
                            className="dropdown-item"
                            onClick={() => handleStatusChange(inactiveText)} // Show modal when Inactive is selected
                        >
                            {inactiveText}
                        </button>
                    </li>
                </ul>
            </span>

            {/* Confirmation Modal */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{confirmModalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalText || confirmModalBody}
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName={cancelButtonText} onClickEffect={handleCloseModal} />
                    <SBSaveUpdateBtn btnName={confirmButtonText} onClickEffect={handleConfirmChange} />
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default StatusBadgeWithDropdown;
