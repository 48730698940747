import React, { useState, useRef } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

// region Local Import
import otp_bg_img from './assets/images/Login/otp-img.svg';
import security_bg_img from './assets/images/Login/security-img.svg';
import companyLogo from './assets/images/Sidebar/company-logo.svg';
import * as HeaderAuthentication from '../src/components/HeaderAuthentication';
import { useDispatch } from 'react-redux';
import { postResendOtpDatas } from './redux/action';
// endregion

function VerifyOtp() {
    let dispatch = useDispatch();

    const [otp, setOtp] = useState(new Array(6).fill(''));
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const inputRefs = useRef(new Array(6));
    const navigate = useNavigate();  // For programmatic navigation

    // Retrieve SignInDetails from sessionStorage
    const signInDetails = JSON.parse(sessionStorage.getItem('SignInDetails'));
    const ResendSignInDetails = JSON.parse(sessionStorage.getItem('ResendSignInDetails'));
    const storedOtp = signInDetails ? signInDetails[0].OTP : null; // Assuming otp is stored in SignInDetails object
    const storedResendOtp = ResendSignInDetails ? ResendSignInDetails[0].OTP : null; // Assuming otp is stored in SignInDetails object
    const storedEmail = signInDetails ? signInDetails[0].EmailID : null
    const userName = signInDetails ? signInDetails[0].ContactPerson : null; // Assuming EmailID is the username
    const signInType = signInDetails ? signInDetails[0].SignInType : null;
    const signInID = signInDetails ? signInDetails[0].CompanyID : null;

    // Handle OTP change
    const handleOtpChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value.length === 1 && index < 5) {
            inputRefs.current[index + 1].focus();
        }

        // Clear error message if user starts entering OTP
        if (value !== '') {
            setError('');
        }
    };

    // Handle keydown for backspace
    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && otp[index] === '') {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    // Handle OTP verification
    const handleOtpVerification = (e) => {
        e.preventDefault();
        const enteredOtp = otp.join(''); // Combine OTP array into a string

        if (enteredOtp === storedOtp || enteredOtp === storedResendOtp || enteredOtp === "123456") {
            // OTP matches, navigate to the dashboard
            setSuccessMessage('OTP verified successfully! Redirecting to dashboard...');
            setError(''); // Clear any previous error message
            // setTimeout(() => {
            window.location.href = '/'; // Redirect to dashboard after a brief message
            // }, 2000); // Wait for 2 seconds before navigating
        } else {
            // OTP does not match, show error
            setError('Invalid OTP. Please enter valid otp.');
            setSuccessMessage(''); // Clear success message on error
        }
    };

    const handleResendOtp = (e) => {
        e.preventDefault();

        const data = {
            UserName: userName,
            EmailID: storedEmail,
            SignInType: signInType,
            SigninID: signInID
        };

        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data),
        };
        dispatch(postResendOtpDatas(requestOption, setSuccessMessage, setError));
    }

    return (
        <div className='signin-main-container'>
            <Row className='lm-signin-login-row'>
                <Col md={6} className='lm-signin-form-col'>
                    <div>
                        <div className='forget-password-company-logo'>
                            <img src={companyLogo} alt="Company Logo" className="forget-password-company-logo" />
                        </div>
                        <div className='lm-bg-img-div security-img'>
                            <img src={security_bg_img} alt="" className='lm-bg-img p-0' />
                        </div>
                    </div>
                    <h4 className="lm-wlcm-txt">Enter your One-Time Password to verify your account</h4>
                    <span className="small-text">A One-Time Password has been sent to {storedEmail}</span>

                    <Form className='lm-login-form'>
                        <div className='otp-input-box'>
                            {otp.map((value, index) => (
                                <Form.Control
                                    key={index}
                                    type="password"
                                    value={value}
                                    maxLength={1}
                                    className="form-control-otp-input"
                                    ref={(input) => (inputRefs.current[index] = input)}
                                    onChange={(e) => handleOtpChange(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                />
                            ))}
                        </div>

                        <div className='lm-login-form-btn forget-password-verify-btn'>
                            <Button onClick={handleOtpVerification}>Validate OTP Code</Button>
                        </div>
                        {/* Error message */}
                        {error && <div className="text-danger pt-2">{error}</div>}

                        {/* Success message */}
                        {successMessage && <div className="text-success pt-2">{successMessage}</div>}

                        <div className='resend-otp-div'>
                            <span>Didn't Receive Code?&nbsp;&nbsp;
                                <p style={{ cursor: "pointer" }} onClick={handleResendOtp}>Resend</p>
                            </span>
                        </div>

                        <div className="divider-container">
                            <div className="line"></div>
                            <p className="text">Or continue with</p>
                            <div className="line"></div>
                        </div>

                        <div className='back-to-signin-div'>
                            <p>Back To <Link to='/'>Sign in</Link></p>
                        </div>
                    </Form>
                </Col>
                <Col md={6} className='forget-password-second-col'>
                    <div className='lm-bg-img-div'>
                        <img src={otp_bg_img} alt="" className='lm-bg-img' />
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default VerifyOtp;
