import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Menu,
    MenuItem
} from '@mui/material';
import SortIcon from '../../assets/images/Dashboard/chevron-down.svg';
import ascToDesc from '../../assets/images/CustomerManagement/ascToDesc.svg';
import descToAsc from '../../assets/images/CustomerManagement/descToAsc.svg';
import { SbFilterBtn } from '../Buttons/Buttons';
import calendar from '../../assets/images/Dashboard/calendar-icon.svg';
import { Col, Dropdown, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import searchIcon from '../../assets/images/Dashboard/search-icon.svg'
import columnFilter from '../../assets/images/CustomerManagement/columnFilter.svg'
import valueFilter from '../../assets/images/CustomerManagement/valueFilter.svg'
import NoOfLicenseExperingModal from './NoOfLicenseExperingModal';
import AssignToModal from './AssignToModal';
import DescriptionModal from './DescriptionModal';
import PriorityStatusBadgeWithDropdown from '../Buttons/PriorityStatusBadgeWithDropdown';
import PaginationComponent from '../PaginationComponent';
import WqColumnFilterModal from './WqViewAssignColumnFilterModal';
import WqValueFilterModal from './WqValueFilterModal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadWorkquesData, updateWorkqueuesPriorityStatus } from '../../redux/action';
import noDataFound from '../../assets/images/no-data-found.svg'
import Skeleton from 'react-loading-skeleton';
import * as HeaderAuthentication from '../HeaderAuthentication'
import { ADDED_BY_ID } from '../Constants';
import moment from 'moment/moment';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import WqViewAssignColumnFilterModal from './WqViewAssignColumnFilterModal';

const columns = [
    { id: 'WorkQueuePriority', label: 'PRIORITY STATUS', align: 'left', minWidth: '160px' },
    { id: 'WorkQueueRuleName', label: 'RULE', align: 'left', minWidth: '120px' },
    { id: 'Module', label: 'MODULES', align: 'left', minWidth: '180px' },
    { id: 'AssignedTo', label: 'ASSIGN TO', align: 'left', minWidth: '120px' },
    { id: 'WorkQueueCount', label: 'CURRENT COUNT', align: 'left', minWidth: '160px' },
    { id: 'AssignedDate', label: 'ASSIGN DATE', align: 'left', minWidth: '150px' },
    { id: 'WorkQueueDescription', label: 'DESCRIPTION', align: 'left', minWidth: '150px' },
];

const SortableHead = SortableContainer(({ children }) => {
    return (
        <TableHead>
            <TableRow>{children}</TableRow>
        </TableHead>
    );
});

const SortableCell = SortableElement(({ value }) => {
    return <TableCell>{value}</TableCell>;
});

const WorkQueuesViewAssignedTable = (props) => {
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const getStoredColumnsOrder = () => {
        const savedOrder = localStorage.getItem('workqueueViewAssignedColumnsOrder');
        return savedOrder ? JSON.parse(savedOrder) : columns.map((col) => col.id);
    };

    const { workqueuesData = [] } = useSelector((state) => state.data);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [actionAnchorEl, setActionAnchorEl] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedColumns, setSelectedColumns] = useState([
        "PRIORITY STATUS", "RULE", "MODULES", "ASSIGN TO", "CURRENT COUNT", "ASSIGN DATE", "DESCRIPTION"
    ]);

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Set initial rows per page
    const [paginatedData, setPaginatedData] = useState([]);
    const [columnsOrder, setColumnsOrder] = useState(getStoredColumnsOrder());

    const workqueuesDatas = () => {
        dispatch(loadWorkquesData(setLoading));
    }
    useEffect(() => {
        workqueuesDatas();
    }, []);

    // Get selected columns (based on `selectedColumns` prop)
    const filteredColumns = columns.filter((column) =>
        selectedColumns.includes(column.label)
    );

    const handleRequestSort = (event, property) => {
        setCurrentSortColumn(property);
        setAnchorEl(event.currentTarget);
    };

    const handleSortOrderChange = (order) => {
        setOrder(order);
        setOrderBy(currentSortColumn);
        setAnchorEl(null);
    };

    const handleActionClick = (event, customer) => {
        setSelectedCustomer(customer);
        setActionAnchorEl(event.currentTarget);
    };

    const handleActionClose = () => {
        setActionAnchorEl(false);
    };

    const handleMenuItemClick = (action) => {
        if (action === 'edit') {
            console.log('Edit Details for:', selectedCustomer);
            // Implement edit functionality here
        } else if (action === 'view') {
            console.log('View Details for:', selectedCustomer);
            // Implement view functionality here
        }
        handleActionClose();
    };

    const onReorderEnd = ({ oldIndex, newIndex }) => {
        const newOrder = Array.from(columnsOrder);
        const [moved] = newOrder.splice(oldIndex, 1);
        newOrder.splice(newIndex, 0, moved);
        setColumnsOrder(newOrder);
        localStorage.setItem('workqueueViewAssignedColumnsOrder', JSON.stringify(newOrder)); // Store new order
    };

    const sortedData = React.useMemo(() => {
        const stabilizedData = workqueuesData.map((el, index) => [el, index]);
        stabilizedData.sort((a, b) => {
            const orderModifier = order === 'asc' ? 1 : -1;
            if (a[0][orderBy] < b[0][orderBy]) {
                return -1 * orderModifier;
            }
            if (a[0][orderBy] > b[0][orderBy]) {
                return 1 * orderModifier;
            }
            return a[1] - b[1];
        });
        return stabilizedData.map((el) => el[0]);
    }, [workqueuesData, order, orderBy]);

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [showAssignToModal, setShowAssignToModal] = useState(false);
    const [showDescriptionModal, setShowDescriptionModal] = useState(false);
    const [showColumnFilterModal, setShowColumnFilterModal] = useState(false);
    const [showValueFilterModal, setShowValueFilterModal] = useState(false);
    const [clickedCell, setClickedCell] = useState({ rowId: null, columnId: null }); // Track clicked cell

    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleOpenColumnFilterModal = () => {
        setShowFilterMenu(false);
        setShowColumnFilterModal(true);
    };

    const handleCloseColumnFilterModal = () => {
        setShowColumnFilterModal(false);
    };
    const handleOpenValueFilterModal = () => {
        setShowFilterMenu(false);
        setShowValueFilterModal(true);
    };

    const handleCloseValueFilterModal = () => {
        setShowValueFilterModal(false);
    };

    const handleOpenAssignModal = () => {
        setShowAssignToModal(true);
    }

    const handleCloseAssignModal = () => {
        setShowAssignToModal(false);
    }
    const handleOpenDescriptionModal = () => {
        setShowDescriptionModal(true);
    }

    const handleCloseDescriptionModal = () => {
        setShowDescriptionModal(false);
    }

    const handleWorkQueueCountClick = (customer) => {
        setSelectedCustomer(customer);
        setIsModalOpen(true);
    };

    // Pagination logic
    useEffect(() => {
        const start = page * rowsPerPage; // Updated page logic to 0-based
        const end = start + rowsPerPage;
        setPaginatedData(sortedData.slice(start, end));
    }, [sortedData, page, rowsPerPage]);

    const handlePageChange = (newPage) => {
        setPage(newPage - 1); // Handle 1-based to 0-based page index for slicing
    };
    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset to the first page when rows per page changes
    };

    const handleColumnSelectionChange = (updatedSelectedColumns) => {
        setSelectedColumns(updatedSelectedColumns); // Update the selected columns
    };

    const handleStatusChange = (customer, statusLabel) => {
        let statusID;
        // Check if statusLabel is a valid number between 1 and 10
        if (statusLabel >= 1 && statusLabel <= 10) {
            statusID = statusLabel;  // Directly use the value from the dropdown
        } else {
            console.error("Invalid status value. Must be between 1 and 10.");
            return;  // Exit the function if the status is invalid
        }
        console.log(customer, 'customer')
        const data = {
            WorkQueueID: customer.WorkQueueID,
            Priority: parseInt(statusID),
            AddedByID: ADDED_BY_ID
        };

        // console.log(data, 'data');
        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data),
        };

        // Dispatch the action to update the department status
        dispatch(updateWorkqueuesPriorityStatus(requestOption, handleCloseModal, workqueuesDatas));
        // console.log(data, 'data'); // For debugging purposes
    };

    return (
        <>
            <Row className='customermanagement-btns-container'>
                <Col md={3} className='customermanagement-backbtn-div'>
                    <span>Work Queues</span>
                </Col>
                <Col md={9} className='customermanagement-search-filter'>
                    <div className='customermanagement-header-btns filter-btn'>
                        <SbFilterBtn btnName="Filters" onClickEffect={toggleFilterMenu} />
                        {showFilterMenu && (
                            <Dropdown.Menu show className='filter-dropdown-options'>
                                <Dropdown.Item onClick={handleOpenColumnFilterModal}>
                                    <img src={columnFilter} alt="Column Filter" />&nbsp;&nbsp;
                                    Column Filter
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleOpenValueFilterModal}>
                                    <img src={valueFilter} alt="Value Filter" />&nbsp;&nbsp;
                                    Value Filter
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        )}
                    </div>
                    <Col md={3} className='filters-col'>
                        <div className='customermanagement-search'>
                            <Form.Control type="search" placeholder='Search' />
                            <img src={searchIcon} alt="Search" />
                        </div>
                    </Col>
                    <Col md={3} className='filters-col'>
                        <div className='calendar-container'>
                            <DatePicker
                                className="select from-date"
                                dateFormat="dd/MM/yyyy"
                                selectsStart
                                placeholderText='From'
                                startDate={fromDate}
                                endDate={toDate}
                                onChange={date => handleFromDateChange(date)}
                                maxDate={new Date()}
                            />
                            <img src={calendar} alt="Calendar" />
                        </div>
                    </Col>
                    <Col md={3} className='filters-col'>
                        <div className='calendar-container'>
                            <DatePicker
                                className="select to-date"
                                dateFormat="dd/MM/yyyy"
                                selectsEnd
                                placeholderText='To'
                                startDate={fromDate}
                                endDate={toDate}
                                onChange={date => handleToDateChange(date)}
                                maxDate={new Date()}
                            />
                            <img src={calendar} alt="Calendar" />
                        </div>
                    </Col>
                    {/* <SbAddBtn btnName="Add SLA" onClickEffect={handleOpenModal} /> */}
                </Col>
            </Row>
            <div className='customermanagement-table-div'>
                <TableContainer component={Paper} sx={{ maxHeight: 440 }} className='table-container'>
                    <Table>
                        <SortableHead axis="x" onSortEnd={onReorderEnd}>
                            {columnsOrder.map((columnId, index) => {
                                const column = columns.find(col => col.id === columnId);
                                if (!column) return null; // Skip if column doesn't exist

                                // Only display columns that are in filteredColumns
                                if (!filteredColumns.find(col => col.id === columnId)) {
                                    return null;
                                }
                                return (
                                    <SortableCell key={column.id} index={index} value={
                                        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', minWidth: column.minWidth }} className='table-header-div'>
                                            {column.label}
                                            {column.sortable !== false && (
                                                <IconButton
                                                    size="small"
                                                    onClick={(event) => handleRequestSort(event, column.id)}
                                                >
                                                    <img src={SortIcon} alt="Sort" />
                                                </IconButton>
                                            )}
                                        </div>
                                    } />
                                );
                            })}
                        </SortableHead>
                        <TableBody>
                            {
                                loading ? (
                                    [...Array(rowsPerPage)].map((_, index) => (
                                        <TableRow key={index}>
                                            {columns.map((column) => (
                                                <TableCell key={column.id} align={column.align}>
                                                    <Skeleton height={40} />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))
                                ) : paginatedData.length === 0 ? (
                                    // Display "No Data Found" row if no data is present in the paginated data
                                    <TableRow>
                                        <TableCell colSpan={columns.length} align="center">
                                            <img src={noDataFound} alt="" style={{ height: "250px", width: "280px" }} />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    paginatedData.map((customer) => (
                                        <TableRow key={customer.id}>
                                            {columnsOrder.map((columnId) => {
                                                const column = columns.find((col) => col.id === columnId);
                                                if (!column || !filteredColumns.find(col => col.id === columnId)) {
                                                    return null; // Skip if column doesn't exist or not selected
                                                }
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        onClick={
                                                            column.id === 'WorkQueueCount'
                                                                ? () => handleWorkQueueCountClick(customer)
                                                                : column.id === 'WorkQueueDescription'
                                                                    ? () => handleOpenDescriptionModal(customer)
                                                                    : undefined
                                                        }
                                                        style={column.id === 'WorkQueueCount' || column.id === 'WorkQueueDescription' ? { cursor: 'pointer' } : {}}
                                                    >
                                                        {
                                                            column.id === 'AssignedDate' ? (
                                                                // Format the AssignedDate with moment
                                                                moment(customer.AssignedDate).format('DD/MM/YYYY')
                                                            ) : column.id === 'WorkQueuePriority' ? (
                                                                <PriorityStatusBadgeWithDropdown
                                                                    onStatusChange={(status) => { handleStatusChange(customer, status) }}
                                                                />
                                                            ) : (
                                                                // customer[column.id]
                                                                customer[column.id] !== undefined && customer[column.id] !== null && customer[column.id] !== ""
                                                                    ? customer[column.id]
                                                                    : 'NA'
                                                            )}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    )))}
                        </TableBody>
                    </Table>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                    >
                        <MenuItem onClick={() => handleSortOrderChange('asc')}>
                            <img src={ascToDesc} alt="" />&nbsp;&nbsp;
                            Ascending to Descending
                        </MenuItem>
                        <MenuItem onClick={() => handleSortOrderChange('desc')}>
                            <img src={descToAsc} alt="" />&nbsp;&nbsp;
                            Descending to Ascending
                        </MenuItem>
                    </Menu>
                </TableContainer>
                {/* Use PaginationComponent */}
                <PaginationComponent
                    totalItems={workqueuesData.length}
                    itemsPerPage={rowsPerPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
                {isModalOpen && <NoOfLicenseExperingModal open={isModalOpen} handleClose={handleCloseModal} />}
                {showAssignToModal && <AssignToModal open={showAssignToModal} handleClose={handleCloseAssignModal} />}
                {showDescriptionModal && <DescriptionModal open={showDescriptionModal} handleClose={handleCloseDescriptionModal} />}
                {
                    showColumnFilterModal && (
                        <WqViewAssignColumnFilterModal
                            showColumnFilterModal={showColumnFilterModal}
                            handleCloseColumnFilterModal={handleCloseColumnFilterModal}
                            selectedColumns={selectedColumns}
                            handleSaveColumns={handleColumnSelectionChange}
                        />
                    )
                }
                {
                    showValueFilterModal && (
                        <WqValueFilterModal
                            showValueFilterModal={showValueFilterModal}
                            handleCloseValueFilterModal={handleCloseValueFilterModal}
                        />
                    )
                }
            </div>
        </>
    );
};

export default WorkQueuesViewAssignedTable;
