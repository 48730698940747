import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import edit from '../../../assets/images/Dashboard/edit-img.svg';
import add from '../../../assets/images/Dashboard/add.svg'

const FieldTable = () => {
    const initialModules = [
        {
            status: true,
            items: [
                { name: "Field 1", checked: true },
                { name: "Field 2", checked: true },
                { name: "Field 3", checked: false },
                { name: "Field 4", checked: true },
                { name: "Field 5", checked: false },
                { name: "Field 6", checked: false },
                { name: "Field 7", checked: true },
                { name: "Field 8", checked: true },
                { name: "Field 9", checked: false },
                { name: "Field 10", checked: true },
                { name: "Field 11", checked: false },
                { name: "Field 12", checked: false },
            ],
        },
    ];

    const initialCustomFields = [
        { name: "Field 1", checked: false },
        { name: "Field 2", checked: false },
        { name: "Field 3", checked: false },
        { name: "Field 4", checked: false },
        { name: "Field 5", checked: false },
        { name: "Field 6", checked: false },
        { name: "Field 7", checked: false },
        { name: "Field 8", checked: false },
        { name: "Field 9", checked: false },
        { name: "Field 10", checked: false },
        { name: "Field 11", checked: false },
        { name: "Field 12", checked: false },
    ];

    const [modules, setModules] = useState(initialModules);
    const [customFields, setCustomFields] = useState(initialCustomFields);
    const [editingIndex, setEditingIndex] = useState(null); // Track the index of the field being edited
    const [editingValue, setEditingValue] = useState(""); // Track the value of the input field

    const handleCheckboxChange = (moduleIndex, itemIndex) => {
        const updatedModules = [...modules];
        updatedModules[moduleIndex].items[itemIndex].checked = !updatedModules[moduleIndex].items[itemIndex].checked;
        setModules(updatedModules);
    };

    const handleCustomFieldChange = (index) => {
        const updatedCustomFields = [...customFields];
        updatedCustomFields[index].checked = !updatedCustomFields[index].checked;
        setCustomFields(updatedCustomFields);
    };

    const handleEditClick = (index) => {
        setEditingIndex(index);
        setEditingValue(customFields[index].name);
    };

    const handleInputChange = (e) => {
        setEditingValue(e.target.value);
    };

    const handleInputBlur = (index) => {
        const updatedCustomFields = [...customFields];
        updatedCustomFields[index].name = editingValue;
        setCustomFields(updatedCustomFields);
        setEditingIndex(null); // Exit edit mode
    };

    return (
        <Row className="module-management-container">
            <Row className="justify-content-between">
                <Col md={4} className="p-0">
                    <h6>Fields Enable / Disable</h6>
                </Col>
                <Col md={3} className="p-0">
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Select Client"
                    />
                </Col>
            </Row>
            <Row className="module-management-select">
                <Col md={3}>
                    <div>
                        <label>Module</label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder="Customer Management"
                        />
                    </div>
                </Col>
                <Col md={3}>
                    <div>
                        <label>Sub-Modules</label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder="Customer Management"
                        />
                    </div>
                </Col>
            </Row>
            <div className="fields-container">
                <Row className="fields-container-inner">
                    {modules[0].items.map((item, itemIndex) => (
                        <Col md={4} key={itemIndex} className="modules-grid mb-2">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`module-item-${itemIndex}`}
                                    checked={item.checked}
                                    onChange={() => handleCheckboxChange(0, itemIndex)}
                                />
                                <label className="form-check-label" htmlFor={`module-item-${itemIndex}`}>
                                    {item.name}
                                </label>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>

            <div className="pt-2 p-0">
                <Row className="pt-2 pb-2">
                    <Col md={12}>
                        <h6>Custom Fields</h6>
                    </Col>
                </Row>
                <div className="fields-container">
                    <Row className="fields-container-inner">
                        {customFields.map((field, index) => (
                            <Col md={4} key={index} className="modules-grid mb-2">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`custom-item-${index}`}
                                        checked={field.checked}
                                        onChange={() => handleCustomFieldChange(index)}
                                    />
                                    {editingIndex === index ? (
                                        <div className="menubar-fields-text-box-div">
                                            <input
                                                type="text"
                                                value={editingValue}
                                                onChange={handleInputChange}
                                                onBlur={() => handleInputBlur(index)}
                                                autoFocus
                                                className="form-control"
                                            />
                                            <img src={add} alt="" className="add-custom-icon" />
                                        </div>
                                    ) : (
                                        <>
                                            <label className="form-check-label" htmlFor={`custom-item-${index}`}>
                                                {field.name}
                                            </label>&nbsp;&nbsp;
                                            <img
                                                src={edit}
                                                alt="Edit"
                                                onClick={() => handleEditClick(index)}
                                                style={{ cursor: 'pointer' }}
                                                className="custom-field-edit-icon"
                                            />
                                        </>
                                    )}

                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>

            <Row className="mt-4">
                <Col className="field-table-btn-container">
                    <SbCancelBtn btnName="Cancel" />
                    <SBSaveUpdateBtn btnName="Save" />
                </Col>
            </Row>
        </Row>
    );
};

export default FieldTable;
