import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import { loadApprovalLevelDropdownData, loadDepartmentDropdownData, postEscalationApprovalDatas } from "../../../redux/action";
import * as HeaderAuthentication from '../../HeaderAuthentication';
import { ADDED_BY_ID } from "../../Constants";

const enablingOptions = [
    { value: 48, label: "Active" },
    { value: 49, label: "Inactive" }
]
const escalationOrderOptions = [
    { value: 'Sequential', label: "Sequential" },
    { value: 'Parallel', label: "Parallel" }
]

const AddEscalationApproval = (props) => {
    let dispatch = useDispatch();
    const escalationApprovalDatas = props.escalationApprovalDatas;
    const escalationApprovalId = props.escalationApprovalId;
    const singleEscalationApprovalData = props.singleEscalationApprovalData;
    const { departmentDropdown } = useSelector((state) => state.data);
    const { approvalLevelDropdown } = useSelector((state) => state.data);

    const [escalationApprovalData, setEscalationApprovalData] = useState(
        {
            LevelID: "",
            Title: "",
            DepartmentID: null,
            Order: "",
            Condition: "",
            StatusID: null,
            AddedByID: ADDED_BY_ID
        }
    )

    const [errors, setErrors] = useState({
        LevelID: "",
        Title: "",
        DepartmentID: "",
        Order: "",
        Condition: "",
    });

    useEffect(() => {
        dispatch(loadDepartmentDropdownData());
        dispatch(loadApprovalLevelDropdownData());
    }, [])

    // Populate form fields when singleEscalationApprovalData changes
    useEffect(() => {
        if (escalationApprovalId && singleEscalationApprovalData && singleEscalationApprovalData.length > 0) {
            const singleApproval = singleEscalationApprovalData[0]; // Access the first item in the array

            setEscalationApprovalData({
                EscalationApprovalID: escalationApprovalId,
                LevelID: singleApproval.EscalationsApprovalLevelID,
                Title: singleApproval.EscalationsApprovalTitle,
                DepartmentID: singleApproval.EscalationsApprovalDepartmentID,
                Order: singleApproval.EscalationsApprovalOrder,
                Condition: singleApproval.EscalationsApprovalCondition,
                StatusID: singleApproval.StatusID,
                AddedByID: ADDED_BY_ID
            });
        }
    }, [escalationApprovalId, singleEscalationApprovalData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEscalationApprovalData(prevState => ({
            ...prevState,
            [name]: value
        }));

        // Clear error when user starts typing
        if (value.trim() !== "") {
            setErrors(prevState => ({
                ...prevState,
                [name]: ""
            }));
        }
    };

    // const handleSelectChange = (name, selectedOption) => {
    //     setEscalationApprovalData(prevState => ({
    //         ...prevState,
    //         [name]: selectedOption ? selectedOption.value : null
    //     }));
    // };

    const handleSelectChange = (name, selectedOption) => {
        const value = selectedOption ? selectedOption.value : null;
        setEscalationApprovalData(prevState => ({
            ...prevState,
            [name]: value
        }));

        // Clear error when user selects a value
        if (value !== null) {
            setErrors(prevState => ({
                ...prevState,
                [name]: ""
            }));
        }
    };

    const validateForm = () => {
        let isValid = true;
        let validationErrors = { ...errors };

        // Validate each field
        if (!escalationApprovalData.LevelID) {
            validationErrors.LevelID = "Approval Level is required";
            isValid = false;
        }
        if (!escalationApprovalData.Title) {
            validationErrors.Title = "Title is required";
            isValid = false;
        }
        if (!escalationApprovalData.DepartmentID) {
            validationErrors.DepartmentID = "Department is required";
            isValid = false;
        }
        if (!escalationApprovalData.Order) {
            validationErrors.Order = "Escalation Order is required";
            isValid = false;
        }
        if (!escalationApprovalData.Condition) {
            validationErrors.Condition = "Escalation Condition is required";
            isValid = false;
        }

        setErrors(validationErrors);
        return isValid;
    };

    const AddEscalationApprovalData = (e) => {
        e.preventDefault();
        if (validateForm()) {
            let requestOption = {
                method: "POST",
                mode: "cors",
                headers: HeaderAuthentication.postExcomatixAuth,
                body: JSON.stringify(escalationApprovalData),
            };
            dispatch(postEscalationApprovalDatas(requestOption, props.handleCloseModal, escalationApprovalDatas, escalationApprovalId));
            // console.log(escalationApprovalData, 'escalationApprovalData')
        }
    }

    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{escalationApprovalId ? "Edit Escalation Approval" : "Add Escalation Approval"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Approval Levels</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={approvalLevelDropdown}
                                            placeholder="Select"
                                            value={approvalLevelDropdown.find(option => option.value === escalationApprovalData.LevelID)}
                                            onChange={(selectedOption) => handleSelectChange('LevelID', selectedOption)}
                                        />
                                    </div>
                                    {errors.LevelID && <div className="text-danger">{errors.LevelID}</div>}
                                </Col>
                                <Col md={8}>
                                    <div>
                                        <label>Title</label>
                                        <Form.Control
                                            type="text"
                                            name="Title"
                                            value={escalationApprovalData.Title}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    {errors.Title && <div className="text-danger">{errors.Title}</div>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Department</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={departmentDropdown}
                                            placeholder="Select"
                                            value={departmentDropdown.find(option => option.value === escalationApprovalData.DepartmentID)}
                                            onChange={(selectedOption) => handleSelectChange('DepartmentID', selectedOption)}
                                        />
                                    </div>
                                    {errors.DepartmentID && <div className="text-danger">{errors.DepartmentID}</div>}
                                </Col>
                                <Col md={8}>
                                    <div>
                                        <label>Escalation Order</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={escalationOrderOptions}
                                            placeholder="Select"
                                            value={escalationOrderOptions.find(option => option.value === escalationApprovalData.Order)}
                                            onChange={(selectedOption) => handleSelectChange('Order', selectedOption)}
                                        />
                                    </div>
                                    {errors.Order && <div className="text-danger">{errors.Order}</div>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Escalation Conditions</label>
                                        <Form.Control
                                            type="text"
                                            name="Condition"
                                            value={escalationApprovalData.Condition}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    {errors.Condition && <div className="text-danger">{errors.Condition}</div>}
                                </Col>
                            </Row>
                            {escalationApprovalId && <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Status</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={enablingOptions}
                                            placeholder="Select"
                                            value={enablingOptions.find(option => option.value === escalationApprovalData.StatusID)}
                                            onChange={(selectedOption) => handleSelectChange('StatusID', selectedOption)}
                                        />
                                    </div>
                                </Col>
                            </Row>}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={AddEscalationApprovalData} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddEscalationApproval;
