import * as types from "./actionType";

const initialState = {
    // Customer Management
    assignedModulesDropdown: [],
    companyData: [],
    singleCustomerData: {},
    getCountryCodeDropdownData: [],
    getCompanyDropdown: [],
    getGeneratedCompanyCode: [],
    getCompanyCustomFields: [],
    getCustomerClassificationDropdown: [],
    // Customer Management

    // User Management
    userData: [],
    singleUserData: {},
    rolesDropdown: [],
    reportingToDropdown: [],
    slocDropdown: [],
    branchDropdown: [],
    // User Management

    // Complaint/Escalation 
    complainEscalationData: [],
    singleComplaintEscalationData: {},
    issueTypeDropdownData: [],
    complaintEscalationDashboardCardData: {},
    complaintEscalationBarChartTicketData: [],
    complaintEscalationPriorityStatusData: [],
    complaintEscalationStatusData: {},
    complaintEscalationBranchStatusData: [],
    complaintEscalationIssueTypeData: [],
    // Complaint/Escalation 

    // Service Level Agreement
    assignedToSlaData: [],
    singleAssignedToSlaData: {},
    assignedToSlaTypeDropdownData: [],
    getSlaCategoryDropdown: [],
    // Service Level Agreement

    // Workqueues
    workqueuesData: [],
    singleWorkqueuesData: [],
    userDropdownBasedDepartment: [],
    // Workqueues

    // Settings Department
    departmentData: [],
    singleDepartmentData: [],
    // Settings Department

    // Settings Customer Classification
    customerClassificationData: [],
    singleCustomerClassificationData: {},
    // Settings Customer Classification

    // Settings Designation
    designationData: [],
    departmentDropdown: [],
    reportingDesignationDropdown: [],
    singleDesignationData: {},
    // Settings Designation

    // Settings Issue Types
    issueTypesData: [],
    singleIssueTypesData: {},
    // Settings Issue Types

    // Settings SLA
    SettingSlaData: [],
    SingleSettingSlaData: {},
    // Settings SLA

    // Settings Module
    moduleData: [],
    singleModuleData: {},
    // Settings Module

    // Settings Sub Module
    subModuleData: [],
    singleSubModuleData: {},
    subModuleDropdown: [],
    // Settings Sub Module

    // Settings System Numbering
    systemNumberingData: [],
    // Settings System Numbering

    // Settings Work Queues Rules
    settingsWorkQueuesRulesData: [],
    settingsSingleWorkQueuesRulesData: [],
    getSettingsWorkQuesRulesFieldNameData: [],
    // Settings Work Queues Rules

    // Settings Role
    getRolesData: [],
    getSingleRolesData: {},
    // Settings Role

    // Settings Custom Fields
    getCustomFields: [],
    getSingleCustomFields: {},
    // Settings Custom Fields

    // Settings Leave Policy
    getLeavePolicyData: [],
    getSingleLeavePolicyData: {},
    // Settings Leave Policy

    // Settings Holiday Policy
    getHolidayPolicyData: [],
    getSingleHolidayPolicyData: [],
    getCountryNameDropdown: [],
    // Settings Holiday Policy

    // Settings Financial Approval
    financialApprovalData: [],
    singleFinancialApprovalData: {},
    approvalLevelDropdown: [],
    // Settings Financial Approval

    // Settings Functional Approval
    functionalApprovalData: [],
    singleFunctionalApprovalData: {},
    // Settings Functional Approval

    // Settings Leave Approval
    leaveApprovalData: [],
    singleLeaveApprovalData: {},
    // Settings Leave Approval

    // Settings Escalation Approval
    escalationApprovalData: [],
    singleEscalationApprovalData: {},
    // Settings Escalation Approval

    // Settings Complaints Approval
    complaintsApprovalData: [],
    singleComplaintsApprovalData: {},
    // Settings Complaints Approval

    loading: true,
}

const designReducers = (state = initialState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {

        // SignIn Page
        case types.post_SignIn_Data:
        case types.post_resendOtp_Data:
        // SignIn Page

        // Verify Company
        // eslint-disable-next-line no-fallthrough
        case types.post_Resend_Verify_Email:
        // Verify Company

        // Forgot Password 
        // eslint-disable-next-line no-fallthrough
        case types.post_Forgot_Password:
        // Forgot Password 

        // Reset Password
        // eslint-disable-next-line no-fallthrough
        case types.post_Reset_Password:
        // Reset Password

        // eslint-disable-next-line no-fallthrough
        case types.post_Customer_Data:
        case types.post_Custom_Fields_Value_For_Company:
        case types.get_Assigned_Modules:
            return {
                ...state,
                assignedModulesDropdown: action.payload,
                loading: false,
            };
        case types.get_Company_Data:
            return {
                ...state,
                companyData: action.payload,
                loading: false,
            };
        case types.get_Single_Company_Data:
            return {
                ...state,
                singleCustomerData: action.payload,
                loading: false,
            };
        case types.get_Country_Code:
            return {
                ...state,
                getCountryCodeDropdownData: action.payload,
                loading: false,
            };
        case types.get_Company_Dropdown:
            return {
                ...state,
                getCompanyDropdown: action.payload,
                loading: false,
            };
        case types.get_generated_Company_Code:
            return {
                ...state,
                getGeneratedCompanyCode: action.payload,
                loading: false,
            };
        case types.get_Custom_Fields_For_Company:
            return {
                ...state,
                getCompanyCustomFields: action.payload,
                loading: false,
            };
        case types.get_Customer_Classification_Dropdown:
            return {
                ...state,
                getCustomerClassificationDropdown: action.payload,
                loading: false,
            };

        // User Management
        case types.post_User_Data:
        case types.post_User_Sloc_Data:
        case types.get_Role_Dropdown_Data:
            return {
                ...state,
                rolesDropdown: action.payload,
                loading: false,
            };
        case types.get_ReportingTo_Dropdown_Data:
            return {
                ...state,
                reportingToDropdown: action.payload,
                loading: false,
            };
        case types.get_User_Data:
            return {
                ...state,
                userData: action.payload,
                loading: false,
            };
        case types.get_Single_User_Data:
            return {
                ...state,
                singleUserData: action.payload,
                loading: false,
            };
        case types.get_Sloc_Dropdown_Data:
            return {
                ...state,
                slocDropdown: action.payload,
                loading: false,
            };
        case types.get_Branch_Dropdown_Data:
            return {
                ...state,
                branchDropdown: action.payload,
                loading: false,
            };
        // User Management

        // Complaint/Escalation 
        case types.post_Complaint_Escalation_Data:
        case types.upload_File:
        case types.get_Complaint_Data:
            return {
                ...state,
                complainEscalationData: action.payload,
                loading: false,
            };
        case types.get_Single_Complaint_Data:
            return {
                ...state,
                singleComplaintEscalationData: action.payload,
                loading: false,
            };
        case types.get_IssueType_Dropdown_Data:
            return {
                ...state,
                issueTypeDropdownData: action.payload,
                loading: false,
            };
        case types.get_Complaint_Escalation_Dashboard_CardData:
            return {
                ...state,
                complaintEscalationDashboardCardData: action.payload,
                loading: false,
            };
        case types.get_Complaint_Escalation_Ticket_Data:
            return {
                ...state,
                complaintEscalationBarChartTicketData: action.payload,
                loading: false,
            };
        case types.get_Complaint_Escalation_PriorityStatus_Data:
            return {
                ...state,
                complaintEscalationPriorityStatusData: action.payload,
                loading: false,
            };
        case types.get_Complaint_Escalation_Status_Data:
            return {
                ...state,
                complaintEscalationStatusData: action.payload,
                loading: false,
            };
        case types.get_Complaint_Escalation_BranchStatus_Data:
            return {
                ...state,
                complaintEscalationBranchStatusData: action.payload,
                loading: false,
            };
        case types.get_Complaint_Escalation_IssueType_Data:
            return {
                ...state,
                complaintEscalationIssueTypeData: action.payload,
                loading: false,
            };
        // Complaint/Escalation 

        // Service Level Agreement
        case types.post_AssignedTo_SLA_Data:
        case types.get_AssignedTo_SLA_Data:
            return {
                ...state,
                assignedToSlaData: action.payload,
                loading: false,
            };
        case types.get_Single_AssignedTo_SLA_Data:
            return {
                ...state,
                singleAssignedToSlaData: action.payload,
                loading: false,
            };
        case types.get_AssignedTo_SLA_Type_Dropdown:
            return {
                ...state,
                assignedToSlaTypeDropdownData: action.payload,
                loading: false,
            };
        case types.get_SLA_Category_Dropdown:
            return {
                ...state,
                getSlaCategoryDropdown: action.payload,
                loading: false,
            };
        // Service Level Agreement

        // Workqueues
        case types.update_Workqueue_Priority_Status:
        case types.post_Set_AssignTo_Data:
        case types.get_Workqueue_Data:
            return {
                ...state,
                workqueuesData: action.payload,
                loading: false,
            };
        case types.get_Single_Workqueue_Data:
            return {
                ...state,
                singleWorkqueuesData: action.payload,
                loading: false,
            };
        case types.get_User_Dropdown_Based_Department:
            return {
                ...state,
                userDropdownBasedDepartment: action.payload,
                loading: false,
            };
        // Workqueues

        // Settings Department
        case types.post_Department_Data:
        case types.get_Department_Data:
            return {
                ...state,
                departmentData: action.payload,
                loading: false,
            }
        case types.get_Single_Department_Data:
            return {
                ...state,
                singleDepartmentData: action.payload,
                loading: false,
            }
        // Settings Department

        // Settings Customer Classification
        case types.post_Customer_Classification_Data:
        case types.get_Customer_Classification_Data:
            return {
                ...state,
                customerClassificationData: action.payload,
                loading: false,
            }
        case types.get_Single_Customer_Classification_Data:
            return {
                ...state,
                singleCustomerClassificationData: action.payload,
                loading: false,
            }
        // Settings Customer Classification

        // Settings Designation
        case types.post_Designation_Data:
        case types.get_Designation_Data:
            return {
                ...state,
                designationData: action.payload,
                loading: false,
            }
        case types.get_Department_Dropdown:
            return {
                ...state,
                departmentDropdown: action.payload,
                loading: false,
            }
        case types.get_Reporting_Designation_Dropdown:
            return {
                ...state,
                reportingDesignationDropdown: action.payload,
                loading: false,
            }
        case types.get_Single_Designation_Data:
            return {
                ...state,
                singleDesignationData: action.payload,
                loading: false,
            }
        // Settings Designation

        // Settings IssueTypes
        case types.post_Issue_Types_Data:
        case types.get_Issue_Type_Data:
            return {
                ...state,
                issueTypesData: action.payload,
                loading: false,
            }
        case types.get_Single_Issue_Types_Data:
            return {
                ...state,
                singleIssueTypesData: action.payload,
                loading: false,
            }
        // Settings IssueTypes

        // Settings SLA
        case types.post_Settings_SLA_Data:
        case types.get_Settings_SLA_Data:
            return {
                ...state,
                SettingSlaData: action.payload,
                loading: false,
            }
        case types.get_Single_Settings_SLA_Data:
            return {
                ...state,
                SingleSettingSlaData: action.payload,
                loading: false,
            }
        // Settings SLA

        // Settings Module
        case types.post_Modules_Data:
        case types.get_Module_Data:
            return {
                ...state,
                moduleData: action.payload,
                loading: false,
            }
        case types.get_Single_Module_Data:
            return {
                ...state,
                singleModuleData: action.payload,
                loading: false,
            }
        // Settings Module

        // Settings Sub Module
        case types.post_Sub_Modules_Data:
        case types.get_Sub_Module_Data:
            return {
                ...state,
                subModuleData: action.payload,
                loading: false,
            }
        case types.get_Single_Sub_Module_Data:
            return {
                ...state,
                singleSubModuleData: action.payload,
                loading: false,
            }
        case types.get_SubModule_Dropdown:
            return {
                ...state,
                subModuleDropdown: action.payload,
                loading: false,
            }
        // Settings Sub Module

        // Settings System Numbering
        case types.post_System_Numbering:
        case types.get_System_Numbering:
            return {
                ...state,
                systemNumberingData: action.payload,
                loading: false,
            }
        // Settings System Numbering


        // Settings Work Queues Rules
        case types.post_Settings_WorkQueues_Rule_Data:
        case types.post_Run_WorkQueue_Data:
        case types.get_Settings_WorkQueues_Rule:
            return {
                ...state,
                settingsWorkQueuesRulesData: action.payload,
                loading: false,
            }
        case types.get_Settings_Single_WorkQueues_Rule:
            return {
                ...state,
                settingsSingleWorkQueuesRulesData: action.payload,
                loading: false,
            }
        case types.get_Settings_WorkQueues_FieldName_Rule:
            return {
                ...state,
                getSettingsWorkQuesRulesFieldNameData: action.payload,
                loading: false,
            }
        // Settings Work Queues Rules

        // Settings Role
        case types.post_Roles_Data:
        case types.get_Roles_Data:
            return {
                ...state,
                getRolesData: action.payload,
                loading: false,
            }
        case types.get_Single_Roles_Data:
            return {
                ...state,
                getSingleRolesData: action.payload,
                loading: false,
            }
        // Settings Role

        // Settings Custom Fields
        case types.post_CustomFields_Data:
        case types.get_CustomFields_Data:
            return {
                ...state,
                getCustomFields: action.payload,
                loading: false,
            }
        case types.get_Single_CustomFields_Data:
            return {
                ...state,
                getSingleCustomFields: action.payload,
                loading: false,
            }
        // Settings Custom Fields

        // Settings Leave Policy
        case types.post_Leave_Policy_Data:
        case types.get_Leave_Policy_Data:
            return {
                ...state,
                getLeavePolicyData: action.payload,
                loading: false,
            }
        case types.get_Single_Leave_Policy_Data:
            return {
                ...state,
                getSingleLeavePolicyData: action.payload,
                loading: false,
            }
        // Settings Leave Policy

        // Settings Holiday Policy
        case types.post_Holiday_Policy_Data:
        case types.get_Holiday_Policy_Data:
            return {
                ...state,
                getHolidayPolicyData: action.payload,
                loading: false,
            }
        case types.get_SingleHoliday_Policy_Data:
            return {
                ...state,
                getSingleHolidayPolicyData: action.payload,
                loading: false,
            }
        case types.get_Country_Name_Dropdown:
            return {
                ...state,
                getCountryNameDropdown: action.payload,
                loading: false,
            }
        // Settings Holiday Policy

        // Settings Financial Approval
        case types.post_Financial_Approval_Data:
        case types.get_Financial_Approval_Data:
            return {
                ...state,
                financialApprovalData: action.payload,
                loading: false,
            }
        case types.get_Approval_Level_Dropdown:
            return {
                ...state,
                approvalLevelDropdown: action.payload,
                loading: false,
            }
        case types.get_Single_Financial_Approval_Data:
            return {
                ...state,
                singleFinancialApprovalData: action.payload,
                loading: false,
            }
        // Settings Financial Approval

        // Settings Functional Approval
        case types.post_Functional_Approval_Data:
        case types.get_Functional_Approval_Data:
            return {
                ...state,
                functionalApprovalData: action.payload,
                loading: false,
            }
        case types.get_Single_Functional_Approval_Data:
            return {
                ...state,
                singleFunctionalApprovalData: action.payload,
                loading: false,
            }
        // Settings Functional Approval

        // Settings Leave Approval
        case types.post_Leave_Approval_Data:
        case types.get_Leave_Approval_Data:
            return {
                ...state,
                leaveApprovalData: action.payload,
                loading: false,
            }
        case types.get_Single_Leave_Approval_Data:
            return {
                ...state,
                singleLeaveApprovalData: action.payload,
                loading: false,
            }
        // Settings Leave Approval

        // Settings Escalation Approval
        case types.post_Escalation_Approval_Data:
        case types.get_Escalation_Approval_Data:
            return {
                ...state,
                escalationApprovalData: action.payload,
                loading: false,
            }
        case types.get_Single_Escalation_Approval_Data:
            return {
                ...state,
                singleEscalationApprovalData: action.payload,
                loading: false,
            }
        // Settings Escalation Approval

        // Settings Complaints Approval
        case types.post_Complaints_Approval_Data:
        case types.get_Complaints_Approval_Data:
            return {
                ...state,
                complaintsApprovalData: action.payload,
                loading: false,
            }
        case types.get_Single_Complaints_Approval_Data:
            return {
                ...state,
                singleComplaintsApprovalData: action.payload,
                loading: false,
            }
        // Settings Complaints Approval

        // eslint-disable-next-line no-fallthrough
        default:
            return state;
    }
}

export default designReducers;