import { ListGroup, Modal } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../Buttons/Buttons";

const ViewIntegrationModal = (props) => {
    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-user-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>View Integration Log</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="customermanagement-view-details-btns-container border-0 p-0">
                        <div className="customermanagement-view-details-first-col border-0">
                            <ListGroup variant="flush" className='head-quarters-list-items'>
                                <ListGroup.Item className='mt-3'><p>Integration Point</p> <p>: </p><p>Post AMDoc</p></ListGroup.Item>
                                <ListGroup.Item><p>Time Stamp</p> <p>:</p><p>06/09/2024 (11:21am)</p></ListGroup.Item>
                                <ListGroup.Item><p>Reference Number</p><p>:</p> <p>10006057</p></ListGroup.Item>
                                <ListGroup.Item><p>Result</p><p>:</p> <p>Success</p></ListGroup.Item>
                                <ListGroup.Item><p>Posting Data</p> <p>:</p><p>https://flodataest.i2trax.com/api/sendMessage</p></ListGroup.Item>
                                <ListGroup.Item><p>Return Data</p> <p>:</p><p>ID: 100, Name: Jagadish, Description: Description Name,ID: 100, Name: Jagadish, Description: Description Name,ID: 100, Name: Jagadish, Description: Description Name</p></ListGroup.Item>
                                <ListGroup.Item><p>Error Message</p> <p>:</p><p></p></ListGroup.Item>
                            </ListGroup>
                        </div>
                    </div>
                </Modal.Body >
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={props.handleNextStep} />
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default ViewIntegrationModal;