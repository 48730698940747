import React, { useEffect, useState } from 'react';
import Main from '../../Main';
import { Col, Dropdown, Row } from 'react-bootstrap';
import columnFilter from '../../../assets/images/CustomerManagement/columnFilter.svg'
import valueFilter from '../../../assets/images/CustomerManagement/valueFilter.svg'
import '../../../assets/css/dashboard.scss'
import '../../../assets/css/customermanagement.scss'
import 'react-datepicker/dist/react-datepicker.css';
import { SbAddBtn, SbFilterBtn } from '../../Buttons/Buttons';
import Select from 'react-select';
import AddIssueType from './AddIssueType';
import IssueTypeTable from './IssueTypeTable';
import Download from '../../MainSection/Download/Download';
import { useNavigate } from 'react-router-dom';
import IssueTypeColumnFilterModal from './IssueTypeColumnFilterModal';
import IssueTypeValueFilterModal from './IssueTypeValueFilterModal';
import { useDispatch, useSelector } from 'react-redux';
import { loadIssueTypesData, loadSingleIssueTypesData, postIssueTypesDatas } from '../../../redux/action';
import { ADDED_BY_ID } from '../../Constants';
import * as HeaderAuthentication from '../../HeaderAuthentication'

const IssueType = () => {

    let dispatch = useDispatch();

    const { issueTypesData } = useSelector((state) => state.data);
    const { singleIssueTypesData } = useSelector((state) => state.data);

    const [loading, setLoading] = useState(true);
    const issueTypes = () => {
        dispatch(loadIssueTypesData(setLoading));
    }

    useEffect(() => {
        issueTypes();
    }, [])

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [showColumnFilterModal, setShowColumnFilterModal] = useState(false);
    const [showValueFilterModal, setShowValueFilterModal] = useState(false);
    const [showCustomerViewDetails, setShowCustomerViewDetails] = useState(false);
    const [issueTypeId, setIssueTypeId] = useState("");

    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    const handleOpenModal = (issueTypeID) => {
        setIsModalOpen(true);
        if (issueTypeID) {
            dispatch(loadSingleIssueTypesData(issueTypeID))
            setIssueTypeId(issueTypeID);
        } else {
            setIssueTypeId("");
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleStatusChange = (customer, statusLabel) => {
        const statusID = statusLabel === "Active" ? 12 : 13; // 12 for Active, 13 for Inactive
       
        console.log(customer,'customer')
        const data = {
            IssueTypeID: customer.IssueTypeID,
            IssueTypeName: customer.IssueTypeName,
            IssueTypeDescription: customer.IssueTypeDescription,
            StatusID: statusID,
            AddedByID: ADDED_BY_ID
        }
        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data),
        };

        // Dispatch the action to update the department status
        dispatch(postIssueTypesDatas(requestOption, handleCloseModal, issueTypes, customer.IssueTypeID));
        // console.log(data, 'data'); // For debugging purposes
    };

    const handleOpenColumnFilterModal = () => {
        setShowFilterMenu(false);
        setShowColumnFilterModal(true);
    };

    const handleCloseColumnFilterModal = () => {
        setShowColumnFilterModal(false);
    };
    const handleOpenValueFilterModal = () => {
        setShowFilterMenu(false);
        setShowValueFilterModal(true);
    };

    const handleCloseValueFilterModal = () => {
        setShowValueFilterModal(false);
    };

    const handleViewDetails = () => {
        setShowCustomerViewDetails(!showCustomerViewDetails);
    }
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };
    return (
        <div className="customer-management-container">
            <Main>
                <div className='components-download-container'>
                    <Download
                        showBackButton={true}
                        moduleName="Settings / Issue Type"
                        onBackClick={handleBack}
                    />
                </div>
                <div className='customermanagement-content-container'>
                    <div className="customermanagement-content">
                        <Row className='customermanagement-btns-container'>
                            <Col md={3} className='customermanagement-backbtn-div'>
                                {/* <img src={backbtn} alt="" /> */}
                                <span>Issue Type</span>
                            </Col>
                            <Col md={9} className='customermanagement-search-filter'>
                                <div className='customermanagement-header-btns filter-btn'>
                                    <SbFilterBtn btnName="Filters" onClickEffect={toggleFilterMenu} />
                                    {showFilterMenu && (
                                        <Dropdown.Menu show className='filter-dropdown-options'>
                                            <Dropdown.Item onClick={handleOpenColumnFilterModal}>
                                                <img src={columnFilter} alt="" />&nbsp;&nbsp;
                                                Column Filter
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={handleOpenValueFilterModal}>
                                                <img src={valueFilter} alt="" />&nbsp;&nbsp;
                                                Value Filter
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    )}
                                </div>
                                <Col md={3} className='filters-col'>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder="Search Status"
                                        // options={statusOptions}
                                        style={{ width: "100%" }}
                                    />
                                </Col>
                                <SbAddBtn btnName="Add" onClickEffect={()=>{handleOpenModal("")}} />
                            </Col>
                        </Row>
                        <div className='customermanagement-table-div'>
                            <IssueTypeTable
                                handleOpenModal={handleOpenModal}
                                handleViewDetails={handleViewDetails}
                                issueTypesData={issueTypesData}
                                loading={loading}
                                handleStatusChange={handleStatusChange}
                            />
                        </div>
                    </div>
                </div>
            </Main>
            {
                isModalOpen && <AddIssueType
                    open={isModalOpen}
                    handleCloseModal={handleCloseModal}
                    issueTypes={issueTypes}
                    issueTypeId={issueTypeId}
                    singleIssueTypesData={singleIssueTypesData}
                />
            }
            {showColumnFilterModal && (
                <IssueTypeColumnFilterModal
                    showColumnFilterModal={showColumnFilterModal}
                    handleCloseColumnFilterModal={handleCloseColumnFilterModal}
                />
            )}
            {showValueFilterModal && (
                <IssueTypeValueFilterModal
                    showValueFilterModal={showValueFilterModal}
                    handleCloseValueFilterModal={handleCloseValueFilterModal}
                />
            )}
            {/* {showCustomerViewDetails && (
                <CustomerViewDetails
                    showCustomerViewDetails={showCustomerViewDetails}
                    handleCloseViewDetails={handleCloseViewDetails}
                />
            )} */}
        </div >
    );
};

export default IssueType;