import React, { useState } from "react";
import "../../../assets/css/modulemanagement.scss";
import Main from "../../Main";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import FieldTable from "./FieldTable";
import MenubarTable from "./MenubarTable";
import Download from "../../MainSection/Download/Download";
import { useNavigate } from "react-router-dom";

const ModuleManagement = () => {
    const [activeKey, setActiveKey] = useState('menubar');
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };
    return (
        <div className="customer-management-container">
            <Main>
                <div className='components-download-container'>
                <Download
                        showBackButton={true}
                        moduleName="Settings / Menu Bar Enable-Disable"
                        onBackClick={handleBack}
                    />
                </div>
                <Row className='m-0 work-queue-tabs'>
                    <Col className='p-0'>
                        <Tab.Container activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                            <Nav variant="tabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="menubar">
                                        Menu Bar
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="field">
                                        Fields
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <div className="customer-management-container">
                                <div className='customermanagement-content-container pt-0'>
                                    <Tab.Content className="customermanagement-content">
                                        <Tab.Pane eventKey="menubar">
                                            <MenubarTable />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="field">
                                            <FieldTable />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </Col>
                </Row>
            </Main>
        </div>
    );
};

export default ModuleManagement;
