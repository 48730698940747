import React, { useState } from 'react';
import Main from '../Main';
import { Col, Dropdown, Form, Row } from 'react-bootstrap';
import searchIcon from '../../assets/images/Dashboard/search-icon.svg'
import calendar from '../../assets/images/Dashboard/calendar-icon.svg'
import columnFilter from '../../assets/images/CustomerManagement/columnFilter.svg'
import valueFilter from '../../assets/images/CustomerManagement/valueFilter.svg'
import '../../assets/css/dashboard.scss'
import '../../assets/css/customermanagement.scss'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SbAddBtn, SbFilterBtn } from '../Buttons/Buttons';
import Select from 'react-select';
import AuditTrialTable from './AuditTrialTable';
import Download from '../MainSection/Download/Download';

const statusOptions = [
    { value: "All", label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "In-Process", label: "In-Process" },
    { value: "Resolved", label: "Resolved" },
    { value: "Re-Open", label: "Re-Open" },
    { value: "Cancel", label: "Cancel" },
]
const AuditTrial = () => {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [showColumnFilterModal, setShowColumnFilterModal] = useState(false);
    const [showValueFilterModal, setShowValueFilterModal] = useState(false);
    const [showCustomerViewDetails, setShowCustomerViewDetails] = useState(false);
    const [businessUnitModal, setBusinessUnitModal] = useState(false);
    const [branchPlantModal, setBranchPlantModal] = useState(false);

    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleOpenColumnFilterModal = () => {
        setShowFilterMenu(false);
        setShowColumnFilterModal(true);
    };

    const handleCloseColumnFilterModal = () => {
        setShowColumnFilterModal(false);
    };
    const handleOpenValueFilterModal = () => {
        setShowFilterMenu(false);
        setShowValueFilterModal(true);
    };

    const handleCloseValueFilterModal = () => {
        setShowValueFilterModal(false);
    };

    const handleViewDetails = () => {
        setShowCustomerViewDetails(!showCustomerViewDetails);
    }

    return (
        <div className="customer-management-container">
            <Main>
                <div className='components-download-container'>
                    <Download
                        showDashboardLibraryButton={false}
                        showSaveCustomViewButton={false}
                        showAnalyticsButton={false}
                        showDownloadDropdown={true}
                    />
                </div>
                <div className="customermanagement-content-container">
                    <div className="customermanagement-content">
                        <Row className='customermanagement-btns-container'>
                            <Col md={3} className='customermanagement-backbtn-div'>
                                {/* <img src={backbtn} alt="" /> */}
                                <span>Audit Trial</span>
                            </Col>
                            <Col md={9} className='customermanagement-search-filter'>
                                <Col md={4} className='filters-col'>
                                    <div className='customermanagement-search'>
                                        <Form.Control type="search" placeholder='Search' />
                                        <img src={searchIcon} alt="" />
                                    </div>
                                </Col>
                                {/* <Col md={2} className='filters-col'> */}
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder="Select Modules"
                                        // options={statusOptions}
                                        style={{ width: "100%" }}
                                    />
                                {/* </Col> */}
                                <Col md={2} className='filters-col'>
                                    <div className='calendar-container'>
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            selectsRange
                                            placeholderText='From'
                                            startDate={fromDate}
                                            // onChange={(data) => setSelectSearchDrop(data, "date")}
                                            maxDate={new Date()}
                                        />
                                        <img src={calendar} alt="" />
                                    </div>
                                </Col>
                                <Col md={2} className='filters-col'>
                                    <div className='calendar-container'>
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            selectsRange
                                            placeholderText='To'
                                            endDate={toDate}
                                            // onChange={(data) => setSelectSearchDrop(data, "date")}
                                            maxDate={new Date()}
                                        />
                                        <img src={calendar} alt="" />
                                    </div>
                                </Col>
                                {/* <SbAddBtn btnName="Add Complaints" onClickEffect={handleOpenModal} /> */}
                            </Col>
                        </Row>
                        <div className='customermanagement-table-div'>
                            <AuditTrialTable
                                handleOpenModal={handleOpenModal}
                                handleViewDetails={handleViewDetails}
                            />
                        </div>
                    </div>
                </div>
            </Main>
            {/* {
                isModalOpen && <AddComplaintEsaclation
                    open={isModalOpen}
                    handleCloseModal={handleCloseModal}
                />
            } */}
            {/* {showColumnFilterModal && (
                <CustomerFilterModal
                    showColumnFilterModal={showColumnFilterModal}
                    handleCloseColumnFilterModal={handleCloseColumnFilterModal}
                />
            )}
            {showValueFilterModal && (
                <CustomerValueFilterModal
                    showValueFilterModal={showValueFilterModal}
                    handleCloseValueFilterModal={handleCloseValueFilterModal}
                />
            )}
            {showCustomerViewDetails && (
                <CustomerViewDetails
                    showCustomerViewDetails={showCustomerViewDetails}
                    handleCloseViewDetails={handleCloseViewDetails}
                />
            )} */}
        </div >
    );
};

export default AuditTrial;