import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import './assets/css/main.scss'
import Signin from './Signin';
import DashBoard from './components/ContentSection/DashBoard/DashBoard';
import CustomerManagement from './components/CustomerManagement/CustomerManagement';
import UserManagement from './components/UserManagement/UserManagement';
import Leaves from './components/Leaves/Leaves';
import LeaveDetails from './components/Leaves/LeaveDetails';
import ComplaintEscalation from './components/ComplaintEscalation/ComplaintEscalation';
import SLA from './components/SLA/SLA';
import WorkQueues from './components/WorkQueues/WorkQueues';
import AuditTrial from './components/SystemLogging/AuditTrial';
import Settings from './components/Settings/Settings';
import Roles from './components/Settings/Roles/Roles';
import Department from './components/Settings/Department/Department';
import Designation from './components/Settings/Designation/Designation';
import CustomerClassification from './components/Settings/CustomerClassification/CustomerClassification';
import RelationShipType from './components/Settings/RelationShipType/RelationShipType';
import IssueType from './components/Settings/IssueType/IssueType';
import CustomFields from './components/Settings/CustomFields/CustomFields';
import ModuleManagement from './components/Settings/ModuleManagement/ModuleManagement';
import FunctionalApproval from './components/Settings/FunctionalApproval/FunctionalApproval';
import FinancialApproval from './components/Settings/FinancialApproval/FinancialApproval';
import ComplaintsApproval from './components/Settings/ComplaintsApproval/ComplaintsApproval';
import EscalationApproval from './components/Settings/EscalationApproval/EscalationApproval';
import LeaveApproval from './components/Settings/LeaveApproval/LeaveApproval';
import SlaCategories from './components/Settings/SlaCategories/SlaCategories';
import SystemNumbering from './components/Settings/SystemNumbering/SystemNumbering';
import OrganizationChart from './components/Settings/OrganizationChart/OrganizationChart';
import WqRules from './components/Settings/WqRules/WqRules';
import LeaveHolidayPolicy from './components/Settings/LeaveHolidayPolicy/LeaveHolidayPolicy';
import UserProfileForm from './components/MainSection/Header/UserProfileForm';
import LicenceDashboard from './components/UserManagement/LicenceDashboard';
import AttendanceAndLeavesDashboard from './components/Leaves/AttendanceAndLeavesDashboard';
import ComplaintEscalationDashboard from './components/ComplaintEscalation/ComplaintEscalationDashboard';
import ServiceLevelAgreementDashboard from './components/SLA/ServiceLevelAgreementDashboard';
import ExceptionLog from './components/SystemLogging/ExceptionLog';
import IntegrationLog from './components/SystemLogging/IntegrationLog';
import DashboardLibrary from './components/DashboardLibrary/DashboardLibrary';
import DimpuSignin from './DimpuSignin';
import Modules from './components/Settings/Modules/Modules';
import SubModules from './components/Settings/SubModules.js/SubModules';
import VerifyCompany from './VerifyCompany';
import VerifyOtp from './VerifyOtp';
import ResetPassword from './ResetPassword';
import WorkQueueDetail from './components/WorkQueues/WorkQueueDetail';
import Forgetpassword from './Forgetpassword';

function App() {

  const Success = sessionStorage.getItem("Success");
  // Define a state variable to hold the window height
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Router>
      <div className="App" style={{ height: windowHeight }}>
        {Success !== '1' ? <Routes >
          <Route path="/" element={<Signin />} />
          <Route path="*" element={<Signin />} />
          <Route path="/verifyOtp" element={<VerifyOtp />} />
          <Route path='/verifyaccount' element={<VerifyCompany />} />
          <Route path='/forgetpassword' element={<Forgetpassword />} />
          <Route path='/reset-password' element={<ResetPassword />} />
        </Routes>
          :
          <Routes>
            <Route path="/" element={<DashBoard />} />
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/customermanagement" element={<CustomerManagement />} />
            <Route path="/usermanagement" element={<UserManagement />} />
            <Route path="/licenceDashboard" element={<LicenceDashboard />} />
            <Route path="/leaves" element={<Leaves />} />
            <Route path="/leaveDetails" element={<LeaveDetails />} />
            <Route path="/complaint" element={<ComplaintEscalation />} />
            <Route path="/sla" element={<SLA />} />
            <Route path="/workqueues" element={<WorkQueues />} />
            <Route path="/workqueues/:id" element={<WorkQueueDetail />} />
            <Route path="/audit-trial" element={<AuditTrial />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/settings/roles" element={<Roles />} />
            <Route path="/settings/department" element={<Department />} />
            <Route path="/settings/designation" element={<Designation />} />
            <Route path="/settings/customerClassification" element={<CustomerClassification />} />
            <Route path="/settings/relationshipType" element={<RelationShipType />} />
            <Route path="/settings/issueType" element={<IssueType />} />
            <Route path="/settings/customFields" element={<CustomFields />} />
            <Route path="/settings/moduleMangement" element={<ModuleManagement />} />
            <Route path="/settings/functionalApproval" element={<FunctionalApproval />} />
            <Route path="/settings/financialApproval" element={<FinancialApproval />} />
            <Route path="/settings/complaintsApproval" element={<ComplaintsApproval />} />
            <Route path="/settings/escalationApproval" element={<EscalationApproval />} />
            <Route path="/settings/leaveApproval" element={<LeaveApproval />} />
            <Route path="/settings/slaCategories" element={<SlaCategories />} />
            <Route path="/settings/systemNumbering" element={<SystemNumbering />} />
            <Route path="/settings/organizationChart" element={<OrganizationChart />} />
            <Route path="/settings/wqRules" element={<WqRules />} />
            <Route path="/settings/modules" element={<Modules />} />
            <Route path="/settings/sub-modules" element={<SubModules />} />
            <Route path="/settings/holidays" element={<LeaveHolidayPolicy />} />
            <Route path="/userProfile" element={<UserProfileForm />} />
            <Route path="/attendanceDashboard" element={<AttendanceAndLeavesDashboard />} />
            <Route path="/complaintDashboard" element={<ComplaintEscalationDashboard />} />
            <Route path="/slaDashboard" element={<ServiceLevelAgreementDashboard />} />
            <Route path="/exception-log" element={<ExceptionLog />} />
            <Route path="/integration-log" element={<IntegrationLog />} />
            <Route path="/dashboard-library" element={<DashboardLibrary />} />
            <Route path="/dimpu" element={<DimpuSignin />} />
          </Routes>}
      </div >
    </Router >
  );
}

export default App;
