import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, styled } from "@mui/material";
import * as HeaderAuthentication from '../../HeaderAuthentication'
import { useDispatch } from "react-redux";
import { postRolesDatas } from "../../../redux/action";
import { ADDED_BY_ID } from "../../Constants";

const enablingOptions = [
    { value: 24, label: "Active" },
    { value: 25, label: "Inactive" }
]
const CustomCheckbox = styled(Checkbox)({
    color: '#00c853', // Green checkmark color
    '&.Mui-checked': {
        color: '#00c853', // Green checkmark when checked
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20, // Size of the checkbox
        backgroundColor: 'white', // White background
        borderRadius: 4, // Optional: to make the checkbox corners slightly rounded
    },
});
const AddRoles = (props) => {

    let dispatch = useDispatch();

    const getRoles = props.getRoles;
    const roleId = props.roleId;
    const getSingleRolesData = props.getSingleRolesData;

    const [roleData, setRoleData] = useState({
        RoleName: "",
        Description: "",
        StatusID: null,
        AddedByID: ADDED_BY_ID
    });

    const [errors, setErrors] = useState({
        RoleName: "",
        Description: "",
    });

    // Initialize state for modules
    const [modules, setModules] = useState([
        { name: "Leave Management", view: true, create: false, update: true, financialApproval: true, transactionalApproval: true },
        { name: "Complaints and Escalation", view: true, create: true, update: true, financialApproval: true, transactionalApproval: true },
        { name: "AMC or SLA Module", view: true, create: true, update: true, financialApproval: true, transactionalApproval: true },
        { name: "Ticketing Interface", view: true, create: true, update: true, financialApproval: true, transactionalApproval: true },
        { name: "Planning & Forecasting", view: true, create: true, update: true, financialApproval: true, transactionalApproval: true },
        { name: "Inventory", view: true, create: true, update: true, financialApproval: false, transactionalApproval: true },
        { name: "Warehousing", view: true, create: true, update: true, financialApproval: false, transactionalApproval: true },
        { name: "Category Management", view: true, create: false, update: true, financialApproval: true, transactionalApproval: true },
        { name: "Investment Recovery", view: true, create: true, update: true, financialApproval: true, transactionalApproval: true },
        { name: "Logistics & Delivery", view: true, create: true, update: true, financialApproval: false, transactionalApproval: true },
    ]);

    useEffect(() => {
        if (roleId && getSingleRolesData && getSingleRolesData.length > 0) {
            setRoleData({
                RoleID: roleId,
                RoleName: getSingleRolesData[0].RoleName,
                Description: getSingleRolesData[0].Description,
                StatusID: getSingleRolesData[0].StatusID,
                AddedByID: ADDED_BY_ID,
            });
        }
    }, [roleId, getSingleRolesData]);

    // Handle checkbox change
    const handleCheckboxChange = (index, permission) => {
        const updatedModules = [...modules];
        updatedModules[index][permission] = !updatedModules[index][permission];
        setModules(updatedModules);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRoleData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        // Clear the error message for the field the user is typing into
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    // Handle Select dropdown change
    const handleSelectChange = (selectedOption) => {
        setRoleData((prevData) => ({
            ...prevData,
            StatusID: selectedOption ? selectedOption.value : null, // Update StatusID
        }));
    };

    // Validate fields
    const validateForm = () => {
        let tempErrors = {};
        let isValid = true;

        if (!roleData.RoleName) {
            tempErrors.RoleName = "Role Name is required";
            isValid = false;
        }

        if (!roleData.Description) {
            tempErrors.Description = "Role Description is required";
            isValid = false;
        }

        setErrors(tempErrors);
        return isValid;
    };

    const AddRolesData = (e) => {
        e.preventDefault();
        if (validateForm()) {
            let requestOption = {
                method: "POST",
                mode: "cors",
                headers: HeaderAuthentication.postExcomatixAuth,
                body: JSON.stringify(roleData),
            };
            dispatch(postRolesDatas(requestOption, props.handleCloseModal, getRoles, roleId));
            // console.log(roleData, 'roleData')
        }
    }

    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{roleId ? 'Edit Roles' : 'Add Roles'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Role Name <span className="required-asterisk">*</span></label>
                                        <Form.Control
                                            type="text"
                                            name="RoleName"
                                            value={roleData.RoleName}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    {errors.RoleName && <small className="text-danger">{errors.RoleName}</small>}
                                </Col>
                                <Col md={8}>
                                    <div>
                                        <label>Role Description <span className="required-asterisk">*</span></label>
                                        <Form.Control
                                            type="text"
                                            name="Description"
                                            value={roleData.Description}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    {errors.Description && <small className="text-danger">{errors.Description}</small>}
                                </Col>
                            </Row>
                            <Row className="className='add-role-table-div">
                                <h6 className="pt-3 fw-bold">Assign Permissions to Modules</h6>
                                <TableContainer component={Paper} sx={{ maxHeight: 440 }} className='table-container add-role-table-container' style={{ boxShadow: "none" }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow style={{ zIndex: "1" }}>
                                                <TableCell className='table-header-div'>Name</TableCell>
                                                <TableCell align="center" className='table-header-div'>View</TableCell>
                                                <TableCell align="center" className='table-header-div'>Create</TableCell>
                                                <TableCell align="center" className='table-header-div'>Update</TableCell>
                                                <TableCell align="center" className='table-header-div'>Financial Approval</TableCell>
                                                <TableCell align="center" className='table-header-div'>Transactional Approval</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {modules.map((module, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{module.name}</TableCell>
                                                    <TableCell align="center">
                                                        <CustomCheckbox
                                                            checked={module.view}
                                                            onChange={() => handleCheckboxChange(index, 'view')}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <CustomCheckbox
                                                            checked={module.create}
                                                            onChange={() => handleCheckboxChange(index, 'create')}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <CustomCheckbox
                                                            checked={module.update}
                                                            onChange={() => handleCheckboxChange(index, 'update')}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <CustomCheckbox
                                                            checked={module.financialApproval}
                                                            onChange={() => handleCheckboxChange(index, 'financialApproval')}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <CustomCheckbox
                                                            checked={module.transactionalApproval}
                                                            onChange={() => handleCheckboxChange(index, 'transactionalApproval')}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Row>
                            <Row>
                                {roleId && <Col md={4}>
                                    <div>
                                        <label>Active / Inactive</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={enablingOptions}
                                            placeholder="Select"
                                            value={enablingOptions.find(option => option.value === roleData.StatusID)}
                                            onChange={handleSelectChange}
                                        />
                                    </div>
                                </Col>}
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={AddRolesData} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddRoles;
