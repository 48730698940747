import React, { useState } from 'react';
import Main from '../Main';
import { Button, Col, Dropdown, Form, Row, Tab, Nav } from 'react-bootstrap';
import searchIcon from '../../assets/images/Dashboard/search-icon.svg';
import calendar from '../../assets/images/Dashboard/calendar-icon.svg';
import columnFilter from '../../assets/images/CustomerManagement/columnFilter.svg';
import valueFilter from '../../assets/images/CustomerManagement/valueFilter.svg';
import add from '../../assets/images/Dashboard/add.svg';
import '../../assets/css/dashboard.scss';
import '../../assets/css/customermanagement.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SbAddBtn, SbFilterBtn } from '../Buttons/Buttons';
import Select from 'react-select';
import AssignToTable from './AssignToTable';
import UpdatedValueTable from './UpdatedValueTable';
import Download from '../MainSection/Download/Download';
import { useNavigate } from 'react-router-dom';

const statusOptions = [
    { value: "All", label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "In-Process", label: "In-Process" },
    { value: "Resolved", label: "Resolved" },
    { value: "Re-Open", label: "Re-Open" },
    { value: "Cancel", label: "Cancel" },
];

const SLA = () => {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [showColumnFilterModal, setShowColumnFilterModal] = useState(false);
    const [showValueFilterModal, setShowValueFilterModal] = useState(false);
    const [showCustomerViewDetails, setShowCustomerViewDetails] = useState(false);
    const [activeKey, setActiveKey] = useState('assignTo'); // Default active tab

    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenColumnFilterModal = () => {
        setShowFilterMenu(false);
        setShowColumnFilterModal(true);
    };

    const handleOpenValueFilterModal = () => {
        setShowFilterMenu(false);
        setShowValueFilterModal(true);
    };

    const handleViewDetails = () => {
        setShowCustomerViewDetails(!showCustomerViewDetails);
    };

    const navigate = useNavigate();
    const handleAnalyticsClick = () => {
        navigate('/slaDashboard')
    }

    return (
        <div className="customer-management-container">
            <Main>
                <div className='components-download-container'>
                    <Download
                        showDashboardLibraryButton={false}
                        showSaveCustomViewButton={false}
                        showAnalyticsButton={true}
                        showDownloadDropdown={true}
                        onClick={handleAnalyticsClick}
                    />
                </div>
                {/* <div className="customermanagement-content"> */}
                <Row className='m-0 work-queue-tabs'>
                    <Col className='p-0'>
                        <Tab.Container activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                            <Nav variant="tabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="assignTo">
                                        Assign To
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="updateValue">
                                        Update Value
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <div className="customer-management-container">
                                <div className='customermanagement-content-container pt-0'>
                                    <Tab.Content className="customermanagement-content">
                                        <Tab.Pane eventKey="assignTo">
                                            <AssignToTable />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="updateValue">
                                            <UpdatedValueTable />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </Col>
                </Row>

                {/* </div> */}
            </Main>
        </div>
    );
};

export default SLA;
