import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Menu,
    MenuItem,
    TablePagination
} from '@mui/material';
import SortIcon from '../../assets/images/Dashboard/chevron-down.svg';
import ascToDesc from '../../assets/images/CustomerManagement/ascToDesc.svg';
import descToAsc from '../../assets/images/CustomerManagement/descToAsc.svg';
import SbActionDropdownButton from '../Buttons/SbActionDropdownButton';
import addIcon from '../../assets/images/Dashboard/blackAddImg.svg';
import PaginationComponent from '../PaginationComponent';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Skeleton from 'react-loading-skeleton';
import noDataFound from '../../assets/images/no-data-found.svg'
const columns = [
    { id: 'UserID', label: 'ID', align: 'left', minWidth: '80px' },
    { id: 'FirstName', label: 'NAME', align: 'left', minWidth: '300px' },
    { id: 'EmailID', label: 'Email.ID', align: 'left', minWidth: '200px' },
    { id: 'HomeAddress', label: 'ADDRESS', align: 'left', minWidth: '250px' },
    { id: 'Phone', label: 'CONTACT', align: 'left', minWidth: '100px' },
    // { id: 'country', label: 'COUNTRY', align: 'left', minWidth: '120px' },
    { id: 'RoleName', label: 'ROLE', align: 'left', minWidth: '250px' },
    // { id: 'assignedModules', label: 'ASSIGNED MODULES', align: 'left', minWidth: '200px' },
    { id: 'ReportingTo', label: 'REPORTING TO', align: 'left', minWidth: '250px' },
    { id: 'Status', label: 'STATUS', align: 'left', minWidth: '130px' },
    { id: 'setSloc', label: 'SET SLOC', align: 'left', minWidth: '120px' },
    { id: 'action', label: 'ACTION', align: 'left', minWidth: '80px', sortable: false },
];

const SortableHead = SortableContainer(({ children }) => {
    return (
        <TableHead>
            <TableRow>{children}</TableRow>
        </TableHead>
    );
});

const SortableCell = SortableElement(({ value }) => {
    return <TableCell>{value}</TableCell>;
});

const UserManagementTable = (props) => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const userData = props.userData || [];

    // Get selected columns (based on `selectedColumns` prop)
    const filteredColumns = columns.filter((column) =>
        props.selectedColumns.includes(column.label)
    );

    const getStoredColumnsOrder = () => {
        const savedOrder = localStorage.getItem('userManagementColumnsOrder');
        return savedOrder ? JSON.parse(savedOrder) : columns.map((col) => col.id);
    };

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [actionAnchorEl, setActionAnchorEl] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [clickedCell, setClickedCell] = useState({ rowId: null, columnId: null }); // Track clicked cell
    const [columnsOrder, setColumnsOrder] = useState(getStoredColumnsOrder());

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Set initial rows per page
    const [paginatedData, setPaginatedData] = useState([]);

    const handleRequestSort = (event, property) => {
        setCurrentSortColumn(property);
        setAnchorEl(event.currentTarget);
    };

    const handleSortOrderChange = (order) => {
        setOrder(order);
        setOrderBy(currentSortColumn);
        setAnchorEl(null);
    };

    const handleActionClick = (event, customer) => {
        setSelectedCustomer(customer);
        setActionAnchorEl(event.currentTarget);
    };

    const handleActionClose = () => {
        setActionAnchorEl(false);
    };

    const onReorderEnd = ({ oldIndex, newIndex }) => {
        const newOrder = Array.from(columnsOrder);
        const [moved] = newOrder.splice(oldIndex, 1);
        newOrder.splice(newIndex, 0, moved);
        setColumnsOrder(newOrder);
        localStorage.setItem('userManagementColumnsOrder', JSON.stringify(newOrder)); // Store new order
    };

    const sortedData = React.useMemo(() => {
        const stabilizedData = userData.map((el, index) => [el, index]);
        stabilizedData.sort((a, b) => {
            const orderModifier = order === 'asc' ? 1 : -1;
            if (a[0][orderBy] < b[0][orderBy]) {
                return -1 * orderModifier;
            }
            if (a[0][orderBy] > b[0][orderBy]) {
                return 1 * orderModifier;
            }
            return a[1] - b[1];
        });
        return stabilizedData.map((el) => el[0]);
    }, [userData, order, orderBy]);

    // Pagination logic
    useEffect(() => {
        const start = page * rowsPerPage; // Updated page logic to 0-based
        const end = start + rowsPerPage;
        setPaginatedData(sortedData.slice(start, end));
    }, [sortedData, page, rowsPerPage]);

    const handlePageChange = (newPage) => {
        setPage(newPage - 1); // Handle 1-based to 0-based page index for slicing
    };
    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset to the first page when rows per page changes
    };

    return (
        <>
            <TableContainer component={Paper} sx={{ maxHeight: 440 }} className='table-container'>
                <Table>
                    {/* <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }} className='table-header-div'>
                                        {column.label}
                                        {column.sortable !== false && (
                                            <IconButton
                                                size="small"
                                                onClick={(event) => handleRequestSort(event, column.id)}
                                            >
                                                <img src={SortIcon} alt="Sort" />
                                            </IconButton>
                                        )}
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead> */}
                    <SortableHead axis="x" onSortEnd={onReorderEnd}>
                        {columnsOrder.map((columnId, index) => {
                            const column = columns.find(col => col.id === columnId);
                            if (!column) return null; // Skip if column doesn't exist

                            // Only display columns that are in filteredColumns
                            if (!filteredColumns.find(col => col.id === columnId)) {
                                return null;
                            }
                            return (
                                <SortableCell key={column.id} index={index} value={
                                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', minWidth: column.minWidth }} className='table-header-div'>
                                        {column.label}
                                        {column.sortable !== false && (
                                            <IconButton
                                                size="small"
                                                onClick={(event) => handleRequestSort(event, column.id)}
                                            >
                                                <img src={SortIcon} alt="Sort" />
                                            </IconButton>
                                        )}
                                    </div>
                                } />
                            );
                        })}
                    </SortableHead>
                    <TableBody>
                        {
                            props.loading ? (
                                [...Array(rowsPerPage)].map((_, index) => (
                                    <TableRow key={index}>
                                        {columns.map((column) => (
                                            <TableCell key={column.id} align={column.align}>
                                                <Skeleton height={40} />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : paginatedData.length === 0 ? (
                                // Display "No Data Found" row if no data is present in the paginated data
                                <TableRow>
                                    <TableCell colSpan={columns.length} align="center">
                                        <img src={noDataFound} alt="" style={{ height: "250px", width: "280px" }} />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                paginatedData.map((customer) => (
                                    <TableRow key={customer.id} className='table-row-data-container'>
                                        {columnsOrder.map((columnId) => {
                                            const column = columns.find((col) => col.id === columnId);
                                            if (!column || !filteredColumns.find(col => col.id === columnId)) {
                                                return null; // Skip if column doesn't exist or not selected
                                            }
                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    className={clickedCell.rowId === customer.id && clickedCell.columnId === column.id ? 'clicked-value' : ''}
                                                    onMouseDown={() => setClickedCell({ rowId: customer.id, columnId: column.id })}
                                                    onMouseUp={() => setClickedCell({ rowId: null, columnId: null })}
                                                    onMouseLeave={() => setClickedCell({ rowId: null, columnId: null })}
                                                >
                                                    {column.id === 'FirstName' ? (
                                                        // Concatenate FirstName, MiddleName, and LastName
                                                        <>
                                                            {customer.FirstName}
                                                            {customer.MiddleName && customer.MiddleName !== "" ? ` ${customer.MiddleName}` : ""}
                                                            {customer.LastName ? ` ${customer.LastName}` : ""}
                                                        </>
                                                    ) :
                                                        column.id === 'RoleName' ? (
                                                            customer.UserRoles && customer.UserRoles.length > 0 ? (
                                                                customer.UserRoles.map((role, index) => (
                                                                    <span key={role.UserRoleID}>
                                                                        {role.RoleName}
                                                                        {index < customer.UserRoles.length - 1 && ", "} {/* Add a comma between roles */}
                                                                    </span>
                                                                ))
                                                            ) : (
                                                                "No roles"
                                                            )
                                                        ) :
                                                            column.id === 'Status' ? (
                                                                <span
                                                                    className={`status-badge ${customer.Status === 'Active' ? 'complete-status no-dropdown-badge' : 'cancel-status no-dropdown-badge'}`}
                                                                >
                                                                    {customer.Status}
                                                                </span>
                                                            ) : column.id === 'setSloc' ? (
                                                                <span className='sloc-div' onClick={() => { props.handleOpenSloc(customer.UserID) }}>
                                                                    <img src={addIcon} alt="addIcon" />&nbsp;&nbsp;
                                                                    {customer.UserSloc.length} sloc
                                                                </span>
                                                            ) : column.id === 'action' ? (
                                                                <SbActionDropdownButton
                                                                    openAddModal={() => { props.handleOpenModal(customer.UserID) }}
                                                                    addBulkModel={() => { props.handleOpenViewModal(customer.UserID) }}
                                                                />
                                                            ) : (
                                                                // customer[column.id]
                                                                <span
                                                                    onClick={() => {
                                                                        setClickedCell({ rowId: customer.id, columnId: column.id }); // Track clicked cell
                                                                        props.handleOpenValueFilterModal(); // Open modal
                                                                    }}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    {customer[column.id]}
                                                                </span>
                                                            )}
                                                </TableCell>
                                            )
                                        }
                                        )}
                                    </TableRow>
                                )
                                ))}
                    </TableBody>
                </Table>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    className='header-menu-items'
                >
                    <MenuItem onClick={() => handleSortOrderChange('asc')} className='header-menu-options'>
                        <img src={ascToDesc} alt="" />&nbsp;&nbsp;
                        Ascending to Descending
                    </MenuItem>
                    <MenuItem onClick={() => handleSortOrderChange('desc')} className='header-menu-options'>
                        <img src={descToAsc} alt="" />&nbsp;&nbsp;
                        Descending to Ascending
                    </MenuItem>
                </Menu>
            </TableContainer>
            {/* Use PaginationComponent */}
            <PaginationComponent
                totalItems={userData.length}
                itemsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </>
    );
};

export default UserManagementTable;