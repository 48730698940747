import React, { useState } from 'react';
import Main from '../Main';
import { Col, Form, Row } from 'react-bootstrap';
import searchIcon from '../../assets/images/Dashboard/search-icon.svg';
import calendar from '../../assets/images/Dashboard/calendar-icon.svg';
import leaveBalance from '../../assets/images/leave-balance.svg';
import '../../assets/css/dashboard.scss';
import '../../assets/css/customermanagement.scss';
import '../../assets/css/usermanagement.scss';
import '../../assets/css/leaves.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LeavesTable from './LeavesTable';
import SbDropdownButton from '../Buttons/SbDropdownButton';
import { SbAddBtn } from '../Buttons/Buttons';
import ApplyLeavesModal from './ApplyLeavesModal';
import LeaveBalanceDetails from './LeaveBalanceDetails';
import Download from '../MainSection/Download/Download';
import { useNavigate } from 'react-router-dom';

const Leaves = () => {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [showColumnFilterModal, setShowColumnFilterModal] = useState(false);
    const [showValueFilterModal, setShowValueFilterModal] = useState(false);
    const [showCustomerViewDetails, setShowCustomerViewDetails] = useState(false);
    const [showLeaveBalanceDetails, setShowLeaveBalanceDetails] = useState(false);
    const [showLeaveOverAllDetails, setShowLeaveOverAllDetails] = useState(false);
    const [activeCard, setActiveCard] = useState(''); // State to track the active card

    const handleCardClick = (cardType) => {
        setActiveCard(cardType);
        if (cardType === 'LeaveBalance') {
            setShowLeaveBalanceDetails(true);
            setShowLeaveOverAllDetails(false);
        } else if (cardType === 'OverAll') {
            setShowLeaveOverAllDetails(true);
            setShowLeaveBalanceDetails(false);
        }
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleViewDetails = () => {
        setShowCustomerViewDetails(!showCustomerViewDetails);
    };

    const handleDateChange = (date, type) => {
        if (type === 'from') {
            setFromDate(date);
        } else if (type === 'to') {
            setToDate(date);
        }
    };

    const navigate = useNavigate();
    const handleAnalyticsClick = () => {
        navigate('/attendanceDashboard')
    }
    const handleBack = () => {
        // navigate(-1);
        if (showLeaveBalanceDetails || showLeaveOverAllDetails) {
            // Reset both details views
            setShowLeaveBalanceDetails(false);
            setShowLeaveOverAllDetails(false);
            // Navigate to the main Leaves & Holidays page
            navigate('/leaves'); // Adjust this path if necessary
        } else {
            navigate(-1); // Go back to the previous page if no detail view is open
        }
    };

    return (
        <div className="customer-management-container">
            <Main>
                <div className='components-download-container'>
                    <Download
                        showBackButton={true}
                        moduleName={!showLeaveBalanceDetails ? "Leaves & Holidays" : "Leaves Details"}
                        showDashboardLibraryButton={false}
                        showSaveCustomViewButton={false}
                        showAnalyticsButton={true}
                        showDownloadDropdown={true}
                        onClick={handleAnalyticsClick}
                        onBackClick={handleBack}
                    />
                </div>
                <Row className='leaves-cards-container'>
                    <Col
                        md={2}
                        className={`leave-cards  ${activeCard === 'OverAll' ? 'active-card' : ''}`}
                        onClick={() => handleCardClick('OverAll')}
                    >
                        <div className='leave-cards-counts'>
                            <b className='overAll-count'>200</b>
                            <p className='leave-overall-p-div'>Overall Users</p>
                        </div>
                    </Col>
                    <Col md={2} className='leave-cards'>
                        <div className='leave-cards-counts'>
                            <b className='pending-leave-count'>50</b>
                            <p className='leave-pending-p-div'>Pending Leaves</p>
                        </div>
                    </Col>
                    <Col md={2} className='leave-cards'>
                        <div className='leave-cards-counts'>
                            <b className='approve-leave-count'>20</b>
                            <p className='leave-approved-p-div'>Approved Leaves</p>
                        </div>
                    </Col>
                    <Col md={2} className='leave-cards'>
                        <div className='leave-cards-counts'>
                            <b className='rejected-leave-count'>30</b>
                            <p className='leave-rejected-p-div'>Rejected Leaves</p>
                        </div>
                    </Col>
                    <Col
                        md={2}
                        className={`leave-cards ${activeCard === 'LeaveBalance' ? 'active-card' : ''}`}
                        onClick={() => handleCardClick('LeaveBalance')}
                    >
                        <div className='leave-cards-counts'>
                            <b className='leave-balance'>
                                <img src={leaveBalance} alt="" />
                            </b>
                            <p className='leave-balance-p-div'>Leave Balance</p>
                        </div>
                        {/* <div className='dashboard-cards-image' style={{ backgroundColor: "rgba(52, 58, 64, 0.2)" }}>
                            <img src={customerLicenseExperation} alt="" />
                        </div> */}
                    </Col>
                </Row>
                <div className='customermanagement-content-container'>

                    {!showLeaveBalanceDetails && (
                        <div className="customermanagement-content">
                            <Row className='customermanagement-btns-container'>
                                <Col md={3} className='customermanagement-backbtn-div'>
                                    <span>Overall User</span>
                                </Col>
                                <Col md={9} className='customermanagement-search-filter'>
                                    <Col md={5} className='filters-col'>
                                        <div className='customermanagement-search'>
                                            <Form.Control type="search" placeholder='Search' />
                                            <img src={searchIcon} alt="Search" />
                                        </div>
                                    </Col>
                                    <Col md={2} className='filters-col'>
                                        <div className='calendar-container'>
                                            <DatePicker
                                                className="select from-date"
                                                id="date-range-picker"
                                                dateFormat="dd/MM/yyyy"
                                                showMonthDropdown
                                                showYearDropdown
                                                selectsStart
                                                dropdownMode="select"
                                                placeholderText='From'
                                                selected={fromDate}
                                                onChange={(date) => handleDateChange(date, 'from')}
                                                maxDate={new Date()}
                                            />
                                            <img src={calendar} alt="Calendar" />
                                        </div>
                                    </Col>
                                    <Col md={2} className='filters-col'>
                                        <div className='calendar-container'>
                                            <DatePicker
                                                className="select from-date"
                                                id="date-range-picker"
                                                dateFormat="dd/MM/yyyy"
                                                showMonthDropdown
                                                showYearDropdown
                                                selectsEnd
                                                placeholderText='To'
                                                dropdownMode="select"
                                                selected={toDate}
                                                onChange={(date) => handleDateChange(date, 'to')}
                                                maxDate={new Date()}
                                            />
                                            <img src={calendar} alt="Calendar" />
                                        </div>
                                    </Col>
                                    {/* hide for super admin below add button */}
                                    {/* <SbAddBtn btnName="Apply Leaves" onClickEffect={handleOpenModal} /> */}
                                </Col>
                            </Row>
                            <div className='customermanagement-table-div  leave-details-table'>
                                {!showLeaveOverAllDetails && !showLeaveBalanceDetails &&
                                    <LeavesTable
                                        handleOpenModal={handleOpenModal}
                                        handleViewDetails={handleViewDetails}
                                    />
                                }
                            </div>
                        </div>
                    )}
                    <div className='customermanagement-table-div  leave-details-table'>
                        {showLeaveBalanceDetails && !showLeaveOverAllDetails &&
                            <LeaveBalanceDetails />
                        }
                    </div>
                    {showLeaveOverAllDetails && !showLeaveBalanceDetails && <LeavesTable
                        handleOpenModal={handleOpenModal}
                        handleViewDetails={handleViewDetails}
                    />}
                </div>
            </Main>
            {isModalOpen && <ApplyLeavesModal open={isModalOpen} handleClose={handleCloseModal} />}
        </div>
    );
};

export default Leaves;
