import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';
import { postLeavePolicyDatas } from "../../../redux/action";
import { useDispatch } from "react-redux";
import * as HeaderAuthentication from '../../HeaderAuthentication'

const enablingOptions = [
    { value: 30, label: "Active" },
    { value: 31, label: "Inactive" }
]

const selectOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
]

const carryForwardExpiryOptions = [
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
]

const countryOptions = [
    { value: "US", label: "United States" },
    { value: "CA", label: "Canada" },
    { value: "IN", label: "India" },
    { value: "GB", label: "United Kingdom" },
    { value: "AU", label: "Australia" },
    { value: "FR", label: "France" },
    { value: "DE", label: "Germany" },
    { value: "IT", label: "Italy" },
    { value: "ES", label: "Spain" },
    { value: "JP", label: "Japan" },
    { value: "BR", label: "Brazil" },
    { value: "MX", label: "Mexico" }
];

const AddLeavePolicy = (props) => {

    let dispatch = useDispatch();
    const leavePolicyId = props.leavePolicyId;
    const getSingleLeavePolicyData = props.getSingleLeavePolicyData;
    const leavePolicyData = props.leavePolicyData;
    // Initializing state for all form fields
    const [formData, setFormData] = useState({
        LeaveTypeName: '',
        ShortName: '',
        MaxDays: null,
        Description: '',
        Country: '',
        AllowHalfDayLeave: null,
        IsPaidLeave: null,
        IsCancellable: null,
        AutoApprove: null,
        NotifyReporting: null,
        NotifyUser: null,
        ExcludeWeekend: null,
        ExcludeHolidays: null,
        AllowAttachment: null,
        AllowCarryforward: null,
        CarryforwardExpiryMonths: null,
        CarryforwardExpiryNotification: null,
        AllowPrevDaysLeave: null,
        ReqBeforeDays: null,
        AttachmentReqDays: null,
        MaxCarryforward: null,
        StatusID: null,
        // Status: null
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (leavePolicyId && getSingleLeavePolicyData) {
            const policyData = getSingleLeavePolicyData[0]; // Extract the first policy

            setFormData({
                LeavePolicyID: leavePolicyId,
                LeaveTypeName: policyData?.LeaveTypeName || '',
                ShortName: policyData?.ShortName || '',
                MaxDays: policyData?.MaxDays || null,
                Description: policyData?.Description || '',
                Country: policyData?.Country || '',
                AllowHalfDayLeave: policyData?.AllowHalfDayLeave !== null ? policyData?.AllowHalfDayLeave : false, // Ensure false if null
                IsPaidLeave: policyData?.IsPaidLeave !== null ? policyData?.IsPaidLeave : false, // Ensure false if null
                IsCancellable: policyData?.IsCancellable !== null ? policyData?.IsCancellable : false, // Ensure false if null
                AutoApprove: policyData?.AutoApprove !== null ? policyData?.AutoApprove : false, // Ensure false if null
                NotifyReporting: policyData?.NotifyReporting !== null ? policyData?.NotifyReporting : false, // Ensure false if null
                NotifyUser: policyData?.NotifyUser !== null ? policyData?.NotifyUser : false, // Ensure false if null
                ExcludeWeekend: policyData?.ExcludeWeekend !== null ? policyData?.ExcludeWeekend : false, // Ensure false if null
                ExcludeHolidays: policyData?.ExcludeHolidays !== null ? policyData?.ExcludeHolidays : false, // Ensure false if null
                AllowAttachment: policyData?.AllowAttachment !== null ? policyData?.AllowAttachment : false, // Ensure false if null
                AllowCarryforward: policyData?.AllowCarryforward !== null ? policyData?.AllowCarryforward : false, // Ensure false if null
                CarryforwardExpiryMonths: policyData?.CarryforwardExpiryMonths || null,
                CarryforwardExpiryNotification: policyData?.CarryforwardExpiryNotification !== null ? policyData?.CarryforwardExpiryNotification : false, // Ensure false if null
                AllowPrevDaysLeave: policyData?.AllowPrevDaysLeave !== null ? policyData?.AllowPrevDaysLeave : false, // Ensure false if null
                ReqBeforeDays: policyData?.ReqBeforeDays || null,
                AttachmentReqDays: policyData?.AttachmentReqDays || null,
                MaxCarryforward: policyData?.MaxCarryforward || null,
                StatusID: policyData?.StatusID || null,
                // Status: policyData?.Status || null, // Can leave Status as null if no value is provided
            });
        }
    }, [leavePolicyId, getSingleLeavePolicyData]);

    // const handleChange = (key, value) => {
    //     // If the key corresponds to an integer field, ensure the value is stored as a number
    //     if (key === 'ReqBeforeDays' || key === 'AttachmentReqDays' || key === 'MaxCarryforward') {
    //         value = parseInt(value, 10); // Convert the value to an integer
    //     }
    //     setFormData(prevData => ({
    //         ...prevData,
    //         [key]: value
    //     }));
    // };

    const handleChange = (key, value) => {
        // If the key corresponds to an integer field, ensure the value is stored as a number
        if (key === 'ReqBeforeDays' || key === 'AttachmentReqDays' || key === 'MaxCarryforward') {
            value = parseInt(value, 10); // Convert the value to an integer
        }

        // Check for MaxDays validation
        if (key === 'MaxDays') {
            if (value > 60) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    MaxDays: 'Max Days cannot exceed 60.'
                }));
            } else if (value <= 0) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    MaxDays: 'Max Days must be a positive number.'
                }));
            } else {
                // Clear the error if value is valid
                setErrors(prevErrors => {
                    const newErrors = { ...prevErrors };
                    delete newErrors.MaxDays; // Remove the MaxDays error if valid
                    return newErrors;
                });
            }
        }

        // Clear any errors for other fields if needed
        if (key !== 'MaxDays') {
            setErrors(prevErrors => {
                const newErrors = { ...prevErrors };
                delete newErrors[key]; // Remove error for other fields
                return newErrors;
            });
        }

        // Update form data state
        setFormData(prevData => ({
            ...prevData,
            [key]: value
        }));
    };

    const handleSelectChange = (key, selectedOption) => {
        handleChange(key, selectedOption ? selectedOption.value : null);
    };

    const validateForm = () => {
        const newErrors = {};

        // Check required fields
        if (!formData.LeaveTypeName) {
            newErrors.LeaveTypeName = "Leave Type Name is required.";
        }
        if (!formData.ShortName) {
            newErrors.ShortName = "Short Name is required.";
        }
        if (formData.MaxDays === null || formData.MaxDays <= 0) {
            newErrors.MaxDays = "Max Days must be a positive number.";
        }
        if (!formData.Country) {
            newErrors.Country = "Country is required.";
        }

        // Check boolean fields (must be selected)
        if (formData.AllowHalfDayLeave === null) {
            newErrors.AllowHalfDayLeave = "Allow Half Day Leave is required.";
        }
        if (formData.IsPaidLeave === null) {
            newErrors.IsPaidLeave = "Is Paid Leave is required.";
        }
        if (formData.IsCancellable === null) {
            newErrors.IsCancellable = "Is Cancellable is required.";
        }
        if (formData.AutoApprove === null) {
            newErrors.AutoApprove = "Auto Approve is required.";
        }
        if (formData.NotifyReporting === null) {
            newErrors.NotifyReporting = "Notify Reporting is required.";
        }
        if (formData.NotifyUser === null) {
            newErrors.NotifyUser = "Notify User is required.";
        }
        if (formData.ExcludeWeekend === null) {
            newErrors.ExcludeWeekend = "Exclude Weekend is required.";
        }
        if (formData.ExcludeHolidays === null) {
            newErrors.ExcludeHolidays = "Exclude Holidays is required.";
        }
        if (formData.AllowAttachment === null) {
            newErrors.AllowAttachment = "Allow Attachment is required.";
        }
        if (formData.AllowCarryforward === null) {
            newErrors.AllowCarryforward = "Allow Carryforward is required.";
        }
        if (formData.CarryforwardExpiryMonths === null || formData.CarryforwardExpiryMonths <= 0) {
            newErrors.CarryforwardExpiryMonths = "Carryforward Expiry Months must be a positive number.";
        }
        if (formData.CarryforwardExpiryNotification === null) {
            newErrors.CarryforwardExpiryNotification = "Carryforward Expiry Notification is required.";
        }
        if (formData.AllowPrevDaysLeave === null) {
            newErrors.AllowPrevDaysLeave = "Allow Prev Days Leave is required.";
        }
        if (formData.ReqBeforeDays === null || formData.ReqBeforeDays <= 0) {
            newErrors.ReqBeforeDays = "Req Before Days must be a positive number.";
        }
        if (formData.AttachmentReqDays === null || formData.AttachmentReqDays <= 0) {
            newErrors.AttachmentReqDays = "Attachment Req Days must be a positive number.";
        }
        if (formData.MaxCarryforward === null || formData.MaxCarryforward <= 0) {
            newErrors.MaxCarryforward = "Max Carryforward must be a positive number.";
        }

        // Return the errors object
        return newErrors;
    };

    const AddLeavePolicyData = (e) => {
        e.preventDefault();

        const formErrors = validateForm();

        if (Object.keys(formErrors).length > 0) {
            // If there are errors, set them in the state to show them on the form
            setErrors(formErrors);
            return;
        }

        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(formData),
        };
        dispatch(postLeavePolicyDatas(requestOption, props.handleCloseModal, leavePolicyData, leavePolicyId));
        console.log(formData, 'formData')
    }

    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{leavePolicyId ? "Edit Leave Policy" : "Add Leave Policy"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label> Leave Type Name <span className="required-asterisk">*</span></label>
                                        <Form.Control
                                            type="text"
                                            value={formData.LeaveTypeName}
                                            onChange={(e) => handleChange('LeaveTypeName', e.target.value)}
                                        />
                                        {errors.LeaveTypeName && <div className="text-danger">{errors.LeaveTypeName}</div>}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label> Short Name <span className="required-asterisk">*</span></label>
                                        <Form.Control
                                            type="text"
                                            value={formData.ShortName}
                                            onChange={(e) => handleChange('ShortName', e.target.value)}
                                        />
                                        {errors.ShortName && <div className="text-danger">{errors.ShortName}</div>}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Max no of Days <span className="required-asterisk">*</span></label>
                                        {/* <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={maxNoOfDays}
                                            placeholder="Select"
                                            value={maxNoOfDays.find(option => option.value === formData.MaxDays)}
                                            onChange={(selectedOption) => handleSelectChange('MaxDays', selectedOption)}
                                        /> */}
                                        <Form.Control
                                            type="number"
                                            value={formData.MaxDays}
                                            onChange={(e) => handleChange('MaxDays', e.target.value)}
                                        />
                                        {errors.MaxDays && <div className="text-danger">{errors.MaxDays}</div>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Description <span className="required-asterisk">*</span></label>
                                        <Form.Control
                                            as="textarea"
                                            rows="2"
                                            value={formData.Description}
                                            onChange={(e) => handleChange('Description', e.target.value)}
                                        />
                                        {errors.Description && <div className="text-danger">{errors.Description}</div>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Country <span className="required-asterisk">*</span></label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={countryOptions}
                                            placeholder="Select"
                                            value={countryOptions.find(option => option.value === formData.Country)}
                                            onChange={(selectedOption) => handleSelectChange('Country', selectedOption)}
                                        />
                                        {errors.Country && <div className="text-danger">{errors.Country}</div>}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Allow Half Day Leave <span className="required-asterisk">*</span></label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={selectOptions}
                                            placeholder="Select"
                                            value={selectOptions.find(option => option.value === formData.AllowHalfDayLeave)}
                                            onChange={(selectedOption) => handleSelectChange('AllowHalfDayLeave', selectedOption)}
                                        />
                                        {errors.AllowHalfDayLeave && <div className="text-danger">{errors.AllowHalfDayLeave}</div>}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Is Paid Leave <span className="required-asterisk">*</span></label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={selectOptions}
                                            placeholder="Select"
                                            value={selectOptions.find(option => option.value === formData.IsPaidLeave)}
                                            onChange={(selectedOption) => handleSelectChange('IsPaidLeave', selectedOption)}
                                        />
                                        {errors.IsPaidLeave && <div className="text-danger">{errors.IsPaidLeave}</div>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Is Cancellable <span className="required-asterisk">*</span></label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={selectOptions}
                                            placeholder="Select"
                                            value={selectOptions.find(option => option.value === formData.IsCancellable)}
                                            onChange={(selectedOption) => handleSelectChange('IsCancellable', selectedOption)}
                                        />
                                        {errors.IsCancellable && <div className="text-danger">{errors.IsCancellable}</div>}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Auto Approve <span className="required-asterisk">*</span></label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={selectOptions}
                                            placeholder="Select"
                                            value={selectOptions.find(option => option.value === formData.AutoApprove)}
                                            onChange={(selectedOption) => handleSelectChange('AutoApprove', selectedOption)}
                                        />
                                        {errors.AutoApprove && <div className="text-danger">{errors.AutoApprove}</div>}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Notify Reporting <span className="required-asterisk">*</span></label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={selectOptions}
                                            placeholder="Select"
                                            value={selectOptions.find(option => option.value === formData.NotifyReporting)}
                                            onChange={(selectedOption) => handleSelectChange('NotifyReporting', selectedOption)}
                                        />
                                        {errors.NotifyReporting && <div className="text-danger">{errors.NotifyReporting}</div>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Notify User <span className="required-asterisk">*</span></label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={selectOptions}
                                            placeholder="Select"
                                            value={selectOptions.find(option => option.value === formData.NotifyUser)}
                                            onChange={(selectedOption) => handleSelectChange('NotifyUser', selectedOption)}
                                        />
                                        {errors.NotifyUser && <div className="text-danger">{errors.NotifyUser}</div>}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Exclude Weekend <span className="required-asterisk">*</span></label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={selectOptions}
                                            placeholder="Select"
                                            value={selectOptions.find(option => option.value === formData.ExcludeWeekend)}
                                            onChange={(selectedOption) => handleSelectChange('ExcludeWeekend', selectedOption)}
                                        />
                                        {errors.ExcludeWeekend && <div className="text-danger">{errors.ExcludeWeekend}</div>}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Exclude Holidays <span className="required-asterisk">*</span></label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={selectOptions}
                                            placeholder="Select"
                                            value={selectOptions.find(option => option.value === formData.ExcludeHolidays)}
                                            onChange={(selectedOption) => handleSelectChange('ExcludeHolidays', selectedOption)}
                                        />
                                        {errors.ExcludeHolidays && <div className="text-danger">{errors.ExcludeHolidays}</div>}
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Allow Attachment <span className="required-asterisk">*</span></label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={selectOptions}
                                            placeholder="Select"
                                            value={selectOptions.find(option => option.value === formData.AllowAttachment)}
                                            onChange={(selectedOption) => handleSelectChange('AllowAttachment', selectedOption)}
                                        />
                                        {errors.AllowAttachment && <div className="text-danger">{errors.AllowAttachment}</div>}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Allow Carryforward <span className="required-asterisk">*</span></label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={selectOptions}
                                            placeholder="Select"
                                            value={selectOptions.find(option => option.value === formData.AllowCarryforward)}
                                            onChange={(selectedOption) => handleSelectChange('AllowCarryforward', selectedOption)}
                                        />
                                        {errors.AllowCarryforward && <div className="text-danger">{errors.AllowCarryforward}</div>}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Carryforward Expiry (Months) <span className="required-asterisk">*</span></label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={carryForwardExpiryOptions}
                                            placeholder="Select"
                                            value={carryForwardExpiryOptions.find(option => option.value === formData.CarryforwardExpiryMonths)}
                                            onChange={(selectedOption) => handleSelectChange('CarryforwardExpiryMonths', selectedOption)}
                                        />
                                        {errors.CarryforwardExpiryMonths && <div className="text-danger">{errors.CarryforwardExpiryMonths}</div>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Carryforword Expiry Notification <span className="required-asterisk">*</span></label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={selectOptions}
                                            placeholder="Select"
                                            value={selectOptions.find(option => option.value === formData.CarryforwardExpiryNotification)}
                                            onChange={(selectedOption) => handleSelectChange('CarryforwardExpiryNotification', selectedOption)}
                                        />
                                        {errors.CarryforwardExpiryNotification && <div className="text-danger">{errors.CarryforwardExpiryNotification}</div>}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Allow PrevDays Leave <span className="required-asterisk">*</span></label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={selectOptions}
                                            placeholder="Select"
                                            value={selectOptions.find(option => option.value === formData.AllowPrevDaysLeave)}
                                            onChange={(selectedOption) => handleSelectChange('AllowPrevDaysLeave', selectedOption)}
                                        />
                                        {errors.AllowPrevDaysLeave && <div className="text-danger">{errors.AllowPrevDaysLeave}</div>}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Req Before Days <span className="required-asterisk">*</span></label>
                                        <Form.Control
                                            type="number"
                                            value={formData.ReqBeforeDays}
                                            onChange={(e) => handleChange('ReqBeforeDays', e.target.value)}
                                        />
                                        {errors.ReqBeforeDays && <div className="text-danger">{errors.ReqBeforeDays}</div>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Attachment Req Days <span className="required-asterisk">*</span></label>
                                        <Form.Control
                                            type="number"
                                            value={formData.AttachmentReqDays}
                                            onChange={(e) => handleChange('AttachmentReqDays', e.target.value)}
                                        />
                                        {errors.AttachmentReqDays && <div className="text-danger">{errors.AttachmentReqDays}</div>}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Max Carry Forward <span className="required-asterisk">*</span></label>
                                        <Form.Control
                                            type="number"
                                            value={formData.MaxCarryforward}
                                            onChange={(e) => handleChange('MaxCarryforward', e.target.value)}
                                        />
                                        {errors.MaxCarryforward && <div className="text-danger">{errors.MaxCarryforward}</div>}
                                    </div>
                                </Col>
                                {leavePolicyId && <Col md={4}>
                                    <div>
                                        <label>Status</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={enablingOptions}
                                            placeholder="Select"
                                            // value={formData.Status}
                                            value={enablingOptions.find(option => option.value === formData.StatusID)}
                                            onChange={(selectedOption) => handleSelectChange('StatusID', selectedOption)}
                                        />
                                    </div>
                                </Col>}
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={AddLeavePolicyData} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddLeavePolicy;
