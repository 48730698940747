import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';
import * as HeaderAuthentication from '../../HeaderAuthentication'
import { useDispatch } from "react-redux";
import { postIssueTypesDatas } from "../../../redux/action";
import { ADDED_BY_ID } from "../../Constants";

const enablingOptions = [
    { value: "12", label: "Active" },
    { value: "13", label: "Inactive" }
]
const AddIssueType = (props) => {

    let dispatch = useDispatch();
    const issueTypes = props.issueTypes;
    const singleIssueTypesData = props.singleIssueTypesData;
    const issueTypeId = props.issueTypeId;
    const [issueTypeName, setIssueTypeName] = useState("");
    const [issueTypeDescription, setIssueTypeDescription] = useState("");
    const [status, setStatus] = useState(null);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (issueTypeId && singleIssueTypesData) {
            setIssueTypeName(singleIssueTypesData[0]?.IssueTypeName);
            setIssueTypeDescription(singleIssueTypesData[0]?.IssueTypeDescription);
            const statusOption = enablingOptions.find(option => option.label === singleIssueTypesData[0]?.Status);
            setStatus(statusOption || null);
        } else {
            // Reset fields for adding new classification
            setIssueTypeName("");
            setIssueTypeDescription("");
            setStatus(null);
        }
    }, [issueTypeId, singleIssueTypesData]);

    const AddIssueTypesData = (e) => {
        e.preventDefault();
        const newErrors = {};

        // Validate required fields
        if (!issueTypeName) newErrors.issueTypeName = "Issue Type is required.";
        if (!issueTypeDescription) newErrors.issueTypeDescription = "Description is required.";

        // Check for errors
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        const data = {
            IssueTypeID: issueTypeId,
            IssueTypeName: issueTypeName,
            IssueTypeDescription: issueTypeDescription,
            StatusID: parseInt(status?.value),
            AddedByID: ADDED_BY_ID
        }
        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data),
        };
        dispatch(postIssueTypesDatas(requestOption, props.handleCloseModal, issueTypes, issueTypeId));
    }

    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{issueTypeId ? 'Edit Issue Type' : 'Add Issue Type'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Issue Type <span className="required-asterisk">*</span></label>
                                        <Form.Control
                                            type="text"
                                            value={issueTypeName}
                                            // onChange={(e) => setIssueTypeName(e.target.value)}
                                            onChange={(e) => {
                                                setIssueTypeName(e.target.value);
                                                setErrors(prev => ({ ...prev, issueTypeName: "" })); // Clear specific error
                                            }}
                                            isInvalid={!!errors.issueTypeName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.issueTypeName}
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Description <span className="required-asterisk">*</span></label>
                                        <Form.Control
                                            as="textarea"
                                            Row="3"
                                            value={issueTypeDescription}
                                            // onChange={(e) => setIssueTypeDescription(e.target.value)}
                                            onChange={(e) => {
                                                setIssueTypeDescription(e.target.value);
                                                setErrors(prev => ({ ...prev, issueTypeDescription: "" })); // Clear specific error
                                            }}
                                            isInvalid={!!errors.issueTypeDescription}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.issueTypeDescription}
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                            </Row>
                            {issueTypeId && <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Status</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={enablingOptions}
                                            placeholder="Select"
                                            value={status}
                                            onChange={(selectedOption) => setStatus(selectedOption)}
                                        />
                                    </div>
                                </Col>
                            </Row>}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={AddIssueTypesData} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddIssueType;
