// import React, { useState } from "react";
// import { Col, Form, Modal, Row } from "react-bootstrap";
// import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
// import Select from 'react-select';
// import DatePicker from "react-datepicker";
// import calendar from '../../../assets/images/Dashboard/calendar-icon.svg'
// import { postHolidayPolicyDatas } from "../../../redux/action";
// import { useDispatch } from "react-redux";
// import * as HeaderAuthentication from '../../HeaderAuthentication';
// import { ADDED_BY_ID } from "../../Constants";

// const enablingOptions = [
//     { value: 44, label: "Active" },
//     { value: 45, label: "Inactive" }
// ]

// const AddYearlyHolidays = (props) => {

//     let dispatch = useDispatch();

//     const [holidayData, setHolidayData] = useState(
//         {
//             HolidayName: "",
//             HolidayFromDate: "",
//             HolidayToDate: "",
//             HolidayCountryID: null,
//             HolidayDescription: "",
//             HolidayStatusID: null,
//             AddedByID: ADDED_BY_ID
//         }
//     )
//     const addHolidayPolicyData = (e) => {
//         e.preventDefault();

//         let requestOption = {
//             method: "POST",
//             mode: "cors",
//             headers: HeaderAuthentication.postExcomatixAuth,
//             // body: JSON.stringify(complaintData),
//         };
//         dispatch(postHolidayPolicyDatas(requestOption, props.handleCloseModal,));
//     }

//     return (
//         <>
//             <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Add Set Up Yearly Holidays</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <Row className='customer-form-fields'>
//                         <Col>
//                             <Row>
//                                 <Col md={12}>
//                                     <div>
//                                         <label>Holiday Name</label>
//                                         <Form.Control
//                                             type="text"
//                                         />
//                                     </div>
//                                 </Col>
//                             </Row>
//                             <Row>
//                                 <Col md={6}>
//                                     <div className='add-customer-calendar-container'>
//                                         <label>From Date</label>
//                                         {/* <div className='add-customer-calendar-container'> */}
//                                         <DatePicker
//                                             className="select from-date"
//                                             id="date-range-picker"
//                                             dateFormat="dd/MM/yyyy"
//                                             showMonthDropdown
//                                             showYearDropdown
//                                             selectsStart
//                                             dropdownMode="select"
//                                             placeholderText='Start Date'
//                                             // startDate={fromDate}
//                                             // onChange={(data) => setSelectSearchDrop(data, "date")}
//                                             maxDate={new Date()}
//                                         />
//                                         <img src={calendar} alt="" />
//                                         {/* </div> */}
//                                     </div>
//                                 </Col>
//                                 <Col md={6}>
//                                     <div className='add-customer-calendar-container'>
//                                         <label>To Date</label>
//                                         {/* <div className='add-customer-calendar-container'> */}
//                                         <DatePicker
//                                             className="select from-date"
//                                             id="date-range-picker"
//                                             dateFormat="dd/MM/yyyy"
//                                             selectsRange
//                                             placeholderText='End Date'
//                                             // startDate={fromDate}
//                                             // onChange={(data) => setSelectSearchDrop(data, "date")}
//                                             maxDate={new Date()}
//                                         />
//                                         <img src={calendar} alt="" />
//                                         {/* </div> */}
//                                     </div>
//                                 </Col>
//                             </Row>
//                             <Row>
//                                 <Col md={12}>
//                                     <div>
//                                         <label>Location</label>
//                                         <Select
//                                             className="react-select-container"
//                                             classNamePrefix="react-select"
//                                             // options={enablingOptions}
//                                             placeholder="Select"
//                                         />
//                                     </div>
//                                 </Col>
//                             </Row>
//                             <Row>
//                                 <Col md={12}>
//                                     <div>
//                                         <label>Description</label>
//                                         <Form.Control
//                                             type="text"
//                                         />
//                                     </div>
//                                 </Col>
//                             </Row>
//                             <Row>
//                                 <Col md={4}>
//                                     <div>
//                                         <label>Status</label>
//                                         <Select
//                                             className="react-select-container"
//                                             classNamePrefix="react-select"
//                                             options={enablingOptions}
//                                             placeholder="Select"
//                                         />
//                                     </div>
//                                 </Col>
//                             </Row>
//                         </Col>
//                     </Row>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
//                     <SBSaveUpdateBtn btnName="Submit" onClickEffect={addHolidayPolicyData} />
//                 </Modal.Footer>
//             </Modal>
//         </>
//     );
// }

// export default AddYearlyHolidays;


import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import calendar from '../../../assets/images/Dashboard/calendar-icon.svg'
import { loadCountryNameDropdownData, postHolidayPolicyDatas } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import * as HeaderAuthentication from '../../HeaderAuthentication';
import { ADDED_BY_ID } from "../../Constants";
import moment from "moment";

const enablingOptions = [
    { value: 44, label: "Active" },
    { value: 45, label: "Inactive" }
]

const AddYearlyHolidays = (props) => {

    let dispatch = useDispatch();
    const holidayPolicyData = props.holidayPolicyData;
    const getSingleHolidayPolicyData = props.getSingleHolidayPolicyData;
    console.log(getSingleHolidayPolicyData, 'getSingleHolidayPolicyData')
    const holidayId = props.holidayId;
    const { getCountryNameDropdown } = useSelector((state) => state.data);

    const [holidayData, setHolidayData] = useState({
        HolidayName: "",
        HolidayFromDate: "",
        HolidayToDate: "",
        HolidayCountryID: null,
        HolidayDescription: "",
        HolidayStatusID: null,
        AddedByID: ADDED_BY_ID
    });

    const [errors, setErrors] = useState({
        HolidayName: "",
        HolidayFromDate: "",
        HolidayToDate: "",
        HolidayCountryID: "",
        HolidayDescription: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setHolidayData(prevData => ({
            ...prevData,
            [name]: value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ""  // Clear error when the user starts typing
        }));
    };

    const handleSelectChange = (selectedOption, fieldName) => {
        setHolidayData(prevData => ({
            ...prevData,
            [fieldName]: selectedOption ? selectedOption.value : null
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [fieldName]: ""  // Clear error when the user selects a value
        }));
    };

    const handleDateChange = (date, fieldName) => {
        if (date) {
            const formattedDate = moment(date).format('YYYY-MMM-DD');
            setHolidayData(prevData => ({
                ...prevData,
                [fieldName]: formattedDate
            }));
        } else {
            setHolidayData(prevData => ({
                ...prevData,
                [fieldName]: null
            }));
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            [fieldName]: ""  // Clear error when the user selects a date
        }));
    };

    useEffect(() => {
        dispatch(loadCountryNameDropdownData())
    }, []);

    useEffect(() => {
        if (holidayId && getSingleHolidayPolicyData && getSingleHolidayPolicyData.length > 0) {
            // Get the first item from the array (since it's likely that we are dealing with a single holiday policy)
            const holiday = getSingleHolidayPolicyData[0];

            const { HolidayName, HolidayFromDate, HolidayToDate, CountryID, Description, StatusID } = holiday;

            setHolidayData({
                HolidayID: holidayId,
                HolidayName: HolidayName || "",
                HolidayFromDate: moment(HolidayFromDate).format('YYYY-MM-DD'), // Format dates as 'YYYY-MM-DD'
                HolidayToDate: moment(HolidayToDate).format('YYYY-MM-DD'), // Format dates as 'YYYY-MM-DD'
                HolidayCountryID: CountryID || null,
                HolidayDescription: Description || "",
                HolidayStatusID: StatusID || null,
                AddedByID: ADDED_BY_ID
            });
        }
    }, [holidayId, getSingleHolidayPolicyData]);

    const validateForm = () => {
        const newErrors = {};

        // Check if fields are empty and add error messages
        if (!holidayData.HolidayName) newErrors.HolidayName = "Holiday name is required";
        if (!holidayData.HolidayFromDate) newErrors.HolidayFromDate = "Start date is required";
        if (!holidayData.HolidayToDate) newErrors.HolidayToDate = "End date is required";
        if (!holidayData.HolidayCountryID) newErrors.HolidayCountryID = "Location is required";
        if (!holidayData.HolidayDescription) newErrors.HolidayDescription = "Description is required";

        setErrors(newErrors);

        // Return true if no errors, otherwise false
        return Object.keys(newErrors).length === 0;
    };

    const addHolidayPolicyData = (e) => {
        e.preventDefault();

        // Validate the form before submission
        if (!validateForm()) {
            return;  // Stop if form is invalid
        }

        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(holidayData), // Send the holidayData as JSON in the request
        };
        dispatch(postHolidayPolicyDatas(requestOption, props.handleCloseModal, holidayPolicyData, holidayId));
        console.log(holidayData, 'holidayData')
    };

    return (
        <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
            <Modal.Header closeButton>
                <Modal.Title>{holidayId ? "Edit Set Up Yearly Holidays" : "Add Set Up Yearly Holidays"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='customer-form-fields'>
                    <Col>
                        <Row>
                            <Col md={12}>
                                <div>
                                    <label>Holiday Name <span className="required-asterisk">*</span></label>
                                    <Form.Control
                                        type="text"
                                        name="HolidayName"
                                        value={holidayData.HolidayName}
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.HolidayName && (
                                    <div className="text-danger">{errors.HolidayName}</div>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <div className='add-customer-calendar-container'>
                                    <label className="pb-2 pt-2">From Date <span className="required-asterisk">*</span></label>
                                    <DatePicker
                                        className="select from-date"
                                        id="date-range-picker"
                                        dateFormat="dd/MM/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        selectsStart
                                        dropdownMode="select"
                                        placeholderText='Start Date'
                                        // maxDate={new Date()}
                                        selected={holidayData.HolidayFromDate}
                                        onChange={(date) => handleDateChange(date, "HolidayFromDate")}
                                    />
                                    <img src={calendar} alt="" />
                                </div>
                                {errors.HolidayFromDate && (
                                    <div className="text-danger">{errors.HolidayFromDate}</div>
                                )}
                            </Col>
                            <Col md={6}>
                                <div className='add-customer-calendar-container'>
                                    <label className="pb-2 pt-2">To Date <span className="required-asterisk">*</span></label>
                                    <DatePicker
                                        className="select from-date"
                                        id="date-range-picker"
                                        dateFormat="dd/MM/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        selectsEnd
                                        dropdownMode="select"
                                        placeholderText='End Date'
                                        // maxDate={new Date()}
                                        selected={holidayData.HolidayToDate}
                                        onChange={(date) => handleDateChange(date, "HolidayToDate")}
                                    />
                                    <img src={calendar} alt="" />
                                </div>
                                {errors.HolidayToDate && (
                                    <div className="text-danger">{errors.HolidayToDate}</div>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div>
                                    <label>Location <span className="required-asterisk">*</span></label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder="Select"
                                        options={getCountryNameDropdown}
                                        value={getCountryNameDropdown.find(option => option.value === holidayData.HolidayCountryID)}
                                        onChange={(selectedOption) => handleSelectChange(selectedOption, "HolidayCountryID")}
                                    />
                                </div>
                                {errors.HolidayCountryID && (
                                    <div className="text-danger">{errors.HolidayCountryID}</div>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div>
                                    <label>Description <span className="required-asterisk">*</span></label>
                                    <Form.Control
                                        type="text"
                                        name="HolidayDescription"
                                        value={holidayData.HolidayDescription}
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.HolidayDescription && (
                                    <div className="text-danger">{errors.HolidayDescription}</div>
                                )}
                            </Col>
                        </Row>
                        {holidayId && <Row>
                            <Col md={4}>
                                <div>
                                    <label>Status</label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={enablingOptions}
                                        placeholder="Select"
                                        value={enablingOptions.find(option => option.value === holidayData.HolidayStatusID)}
                                        onChange={(selectedOption) => handleSelectChange(selectedOption, "HolidayStatusID")}
                                    />
                                </div>
                            </Col>
                        </Row>}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                <SBSaveUpdateBtn btnName="Submit" onClickEffect={addHolidayPolicyData} />
            </Modal.Footer>
        </Modal>
    );
}

export default AddYearlyHolidays;

