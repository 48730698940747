import React, { useEffect, useState } from 'react';
import Main from '../../Main';
import { Col, Dropdown, Row } from 'react-bootstrap';
import columnFilter from '../../../assets/images/CustomerManagement/columnFilter.svg'
import valueFilter from '../../../assets/images/CustomerManagement/valueFilter.svg'
import '../../../assets/css/dashboard.scss'
import '../../../assets/css/customermanagement.scss'
import 'react-datepicker/dist/react-datepicker.css';
import { SbAddBtn, SbFilterBtn } from '../../Buttons/Buttons';
import Select from 'react-select';
import SystemNumberingTable from './SystemNumberingTable';
import AddSystemNumbering from './AddSystemNumbering';
import Download from '../../MainSection/Download/Download';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadSystemNumberingData } from '../../../redux/action';

const SystemNumbering = () => {

    const dispatch = useDispatch();
    const { systemNumberingData } = useSelector((state) => state.data);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [showColumnFilterModal, setShowColumnFilterModal] = useState(false);
    const [showValueFilterModal, setShowValueFilterModal] = useState(false);
    const [showCustomerViewDetails, setShowCustomerViewDetails] = useState(false);
    const [businessUnitModal, setBusinessUnitModal] = useState(false);
    const [branchPlantModal, setBranchPlantModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const systemNumbering = () => {
        dispatch(loadSystemNumberingData(setLoading))
    }

    useEffect(() => {
        systemNumbering();
    }, [])

    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleOpenColumnFilterModal = () => {
        setShowFilterMenu(false);
        setShowColumnFilterModal(true);
    };

    const handleCloseColumnFilterModal = () => {
        setShowColumnFilterModal(false);
    };
    const handleOpenValueFilterModal = () => {
        setShowFilterMenu(false);
        setShowValueFilterModal(true);
    };

    const handleCloseValueFilterModal = () => {
        setShowValueFilterModal(false);
    };

    const handleViewDetails = () => {
        setShowCustomerViewDetails(!showCustomerViewDetails);
    }
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };
    return (
        <div className="customer-management-container">
            <Main>
                <div className='components-download-container'>
                    <Download
                        showBackButton={true}
                        moduleName="Settings / System Numbering"
                        onBackClick={handleBack}
                    />
                </div>
                <div className='customermanagement-content-container'>
                    <div className="customermanagement-content">
                        <Row className='customermanagement-btns-container'>
                            <Col md={3} className='customermanagement-backbtn-div'>
                                {/* <img src={backbtn} alt="" /> */}
                                <span>System Numbering</span>
                            </Col>
                            <Col md={9} className='customermanagement-search-filter'>
                                <div className='customermanagement-header-btns filter-btn'>
                                    <SbFilterBtn btnName="Filters" onClickEffect={toggleFilterMenu} />
                                    {showFilterMenu && (
                                        <Dropdown.Menu show className='filter-dropdown-options'>
                                            <Dropdown.Item onClick={handleOpenColumnFilterModal}>
                                                <img src={columnFilter} alt="" />&nbsp;&nbsp;
                                                Column Filter
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={handleOpenValueFilterModal}>
                                                <img src={valueFilter} alt="" />&nbsp;&nbsp;
                                                Value Filter
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    )}
                                </div>
                                <Col md={3} className='filters-col'>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder="Search Status"
                                        // options={statusOptions}
                                        style={{ width: "100%" }}
                                    />
                                </Col>
                                <SbAddBtn btnName="Add" onClickEffect={handleOpenModal} />
                            </Col>
                        </Row>
                        <div className='customermanagement-table-div'>
                            <SystemNumberingTable
                                handleOpenModal={handleOpenModal}
                                handleViewDetails={handleViewDetails}
                                systemNumberingData={systemNumberingData}
                                loading={loading}
                            />
                        </div>
                    </div>
                </div>
            </Main>
            {
                isModalOpen && <AddSystemNumbering
                    open={isModalOpen}
                    handleCloseModal={handleCloseModal}
                    systemNumbering={systemNumbering}
                />
            }
            {/* {showColumnFilterModal && (
                <CustomerFilterModal
                    showColumnFilterModal={showColumnFilterModal}
                    handleCloseColumnFilterModal={handleCloseColumnFilterModal}
                />
            )}
            {showValueFilterModal && (
                <CustomerValueFilterModal
                    showValueFilterModal={showValueFilterModal}
                    handleCloseValueFilterModal={handleCloseValueFilterModal}
                />
            )}
            {showCustomerViewDetails && (
                <CustomerViewDetails
                    showCustomerViewDetails={showCustomerViewDetails}
                    handleCloseViewDetails={handleCloseViewDetails}
                />
            )} */}
        </div >
    );
};

export default SystemNumbering;