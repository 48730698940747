import React, { useEffect, useState } from 'react';
import Main from '../../Main';
import { Col, Dropdown, Row } from 'react-bootstrap';
import columnFilter from '../../../assets/images/CustomerManagement/columnFilter.svg'
import valueFilter from '../../../assets/images/CustomerManagement/valueFilter.svg'
import '../../../assets/css/dashboard.scss'
import '../../../assets/css/customermanagement.scss'
import 'react-datepicker/dist/react-datepicker.css';
import { SbAddBtn, SbFilterBtn } from '../../Buttons/Buttons';
import Select from 'react-select';
import WqRulesTable from './WqRulesTable';
import AddWqRules from './AddWqRules';
import Download from '../../MainSection/Download/Download';
import { useNavigate } from 'react-router-dom';
import WRColumnFilterModal from './WRColumnFilterModal';
import WRValueFilterModal from './WRValueFilterModal';
import { useDispatch, useSelector } from 'react-redux';
import { loadSettingsSingleWorkQueuesRulesData, loadSettingsWorkQueuesRulesData, loadSettingsWorkQueuesRulesFieldNameData, postSettingsRunWorkQueuesDatas, postSettingsWorkQueuesDatas } from '../../../redux/action';
import * as HeaderAuthentication from '../../HeaderAuthentication'
import { ADDED_BY_ID } from '../../Constants';

const WqRules = () => {

    let dispatch = useDispatch();

    const { settingsWorkQueuesRulesData } = useSelector((state) => state.data);
    const { settingsSingleWorkQueuesRulesData } = useSelector((state) => state.data);

    const [loading, setLoading] = useState(true);

    const getSettingsWorkQueuesRulesData = () => {
        dispatch(loadSettingsWorkQueuesRulesData(setLoading));
    }
    useEffect(() => {
        getSettingsWorkQueuesRulesData();
    }, []);

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [showColumnFilterModal, setShowColumnFilterModal] = useState(false);
    const [showValueFilterModal, setShowValueFilterModal] = useState(false);
    const [showCustomerViewDetails, setShowCustomerViewDetails] = useState(false);
    const [businessUnitModal, setBusinessUnitModal] = useState(false);
    const [branchPlantModal, setBranchPlantModal] = useState(false);
    const [workqueueId, setWorkqueueId] = useState("")
    const [selectedColumns, setSelectedColumns] = useState([
        "MODULE NAME", "RULE NAME", "DESCRIPTION", "CRITERIA", "RUN QUERY", "STATUS", "ACTION"
    ]);

    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };
    const handleOpenModal = (workqueueID) => {
        setIsModalOpen(true);
        if (workqueueID) {
            dispatch(loadSettingsSingleWorkQueuesRulesData(workqueueID));
            setWorkqueueId(workqueueID);
        } else {
            setWorkqueueId("");
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleStatusChange = (customer, statusLabel) => {
        const statusID = statusLabel === "Active" ? 20 : 21; // 20 for Active, 21 for Inactive

        // console.log(customer, 'customer')

        // Map the WorkQueueRules from the customer object
        const workQueueRules = customer.WorkQueueRules.map(rule => ({
            WorkQueueID: rule.WorkQueueID,
            ModuleFieldID: rule.ModuleFieldID,           // Extract ModuleFieldID
            IsCustom: rule.IsCustom,                     // Extract IsCustom
            Operator: rule.Operator,                     // Extract Operator
            Value: rule.Value,                           // Extract Value
            StatusID: rule.StatusID                      // Extract StatusID
        }));

        const data = {
            WorkQueueID: customer.WorkQueueID,
            WorkQueueRuleName: customer.WorkQueueRuleName,
            StatusID: statusID,
            ModuleID: customer.ModuleID,
            WorkQueueDescription: customer.WorkQueueDescription,
            WorkQueueRules: workQueueRules
        }

        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data),
        };

        // Dispatch the action to update the department status
        dispatch(postSettingsWorkQueuesDatas(requestOption, handleCloseModal, getSettingsWorkQueuesRulesData, customer.WorkQueueID));
        console.log(data, 'data'); // For debugging purposes
    };

    const handleOpenColumnFilterModal = () => {
        setShowFilterMenu(false);
        setShowColumnFilterModal(true);
    };

    const handleRun = (id) => {
        const data = {
            WorkQueueID: id,
            AddedByID: ADDED_BY_ID
        };

        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data),
        };
        dispatch(postSettingsRunWorkQueuesDatas(requestOption, handleCloseModal, getSettingsWorkQueuesRulesData));
        // console.log(data, 'data')
    }

    const handleCloseColumnFilterModal = () => {
        setShowColumnFilterModal(false);
    };
    const handleOpenValueFilterModal = () => {
        setShowFilterMenu(false);
        setShowValueFilterModal(true);
    };

    const handleCloseValueFilterModal = () => {
        setShowValueFilterModal(false);
    };

    const handleViewDetails = () => {
        setShowCustomerViewDetails(!showCustomerViewDetails);
    }

    const handleColumnSelectionChange = (updatedSelectedColumns) => {
        setSelectedColumns(updatedSelectedColumns); // Update the selected columns
    };

    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };
    return (
        <div className="customer-management-container">
            <Main>
                <div className='components-download-container'>
                    <Download
                        showBackButton={true}
                        moduleName="Settings / Work Queues Rule"
                        onBackClick={handleBack}
                    />
                </div>
                <div className="customermanagement-content-container">
                    <div className="customermanagement-content">
                        <Row className='customermanagement-btns-container'>
                            <Col md={3} className='customermanagement-backbtn-div'>
                                {/* <img src={backbtn} alt="" /> */}
                                <span>Work Queues Rule</span>
                            </Col>
                            <Col md={9} className='customermanagement-search-filter'>
                                <div className='customermanagement-header-btns filter-btn'>
                                    <SbFilterBtn btnName="Filters" onClickEffect={toggleFilterMenu} />
                                    {showFilterMenu && (
                                        <Dropdown.Menu show className='filter-dropdown-options'>
                                            <Dropdown.Item onClick={handleOpenColumnFilterModal}>
                                                <img src={columnFilter} alt="" />&nbsp;&nbsp;
                                                Column Filter
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={handleOpenValueFilterModal}>
                                                <img src={valueFilter} alt="" />&nbsp;&nbsp;
                                                Value Filter
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    )}
                                </div>
                                <Col md={3} className='filters-col'>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder="Search Status"
                                        // options={statusOptions}
                                        style={{ width: "100%" }}
                                    />
                                </Col>
                                <SbAddBtn btnName="Add" onClickEffect={() => { handleOpenModal() }} />
                            </Col>
                        </Row>
                        <div className='customermanagement-table-div'>
                            <WqRulesTable
                                handleOpenModal={handleOpenModal}
                                handleViewDetails={handleViewDetails}
                                settingsWorkQueuesRulesData={settingsWorkQueuesRulesData}
                                loading={loading}
                                selectedColumns={selectedColumns}
                                handleStatusChange={handleStatusChange}
                                handleRun={handleRun}
                            />
                        </div>
                    </div>
                </div>
            </Main>
            {
                isModalOpen && <AddWqRules
                    open={isModalOpen}
                    handleCloseModal={handleCloseModal}
                    settingsSingleWorkQueuesRulesData={settingsSingleWorkQueuesRulesData}
                    workqueueId={workqueueId}
                    getSettingsWorkQueuesRulesData={getSettingsWorkQueuesRulesData}
                />
            }
            {showColumnFilterModal && (
                <WRColumnFilterModal
                    showColumnFilterModal={showColumnFilterModal}
                    handleCloseColumnFilterModal={handleCloseColumnFilterModal}
                    selectedColumns={selectedColumns}
                    handleSaveColumns={handleColumnSelectionChange}
                />
            )}
            {showValueFilterModal && (
                <WRValueFilterModal
                    showValueFilterModal={showValueFilterModal}
                    handleCloseValueFilterModal={handleCloseValueFilterModal}
                />
            )}
            {/* {showCustomerViewDetails && (
                <CustomerViewDetails
                    showCustomerViewDetails={showCustomerViewDetails}
                    handleCloseViewDetails={handleCloseViewDetails}
                />
            )} */}
        </div >
    );
};

export default WqRules;