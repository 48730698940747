import React from 'react'

function BodyContent(props) {
    return (
        <div>
            <div className="page-wrapper">
                <div className={`main-body ${props.toggleshift}`}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default BodyContent
