import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';
import * as HeaderAuthentication from '../../HeaderAuthentication'
import { useDispatch, useSelector } from "react-redux";
import { loadApprovalLevelDropdownData, loadDepartmentDropdownData, postFunctionalApprovalDatas } from "../../../redux/action";
import { ADDED_BY_ID } from "../../Constants";

const enablingOptions = [
    { value: 50, label: "Active" },
    { value: 51, label: "Inactive" }
]

const functionalApprovalOrderOptions = [
    { value: 'Sequential', label: "Sequential" },
    { value: 'Parallel', label: "Parallel" }
]

const AddFunctionalApproval = (props) => {
    let dispatch = useDispatch();
    const functionalApprovalDatas = props.functionalApprovalDatas;
    const functionalApprovalId = props.functionalApprovalId;
    const singleFunctionalApprovalData = props.singleFunctionalApprovalData;

    const { approvalLevelDropdown } = useSelector((state) => state.data);
    const { departmentDropdown } = useSelector((state) => state.data);
    const [functionalApprovalData, setFunctionalApprovalData] = useState(
        {
            LevelID: null,
            Title: "",
            DepartmentID: null,
            Order: "",
            Condition: "",
            StatusID: null,
            AddedByID: ADDED_BY_ID
        }
    )

    const [errors, setErrors] = useState({
        LevelID: "",
        Title: "",
        DepartmentID: "",
        Order: "",
        Condition: "",
    });

    useEffect(() => {
        dispatch(loadApprovalLevelDropdownData());
        dispatch(loadDepartmentDropdownData());
    }, []);

    useEffect(() => {
        if (functionalApprovalId && singleFunctionalApprovalData && singleFunctionalApprovalData.length > 0) {
            const singleApproval = singleFunctionalApprovalData[0]; // Access the first item in the array

            setFunctionalApprovalData({
                FunctionalApprovalID: functionalApprovalId,
                LevelID: singleApproval.FunctionalApprovalLevelID,
                Title: singleApproval.FunctionalApprovalTitle,
                DepartmentID: singleApproval.FunctionalApprovalDepartmentID,
                Order: singleApproval.FunctionalApprovalOrder,
                Condition: singleApproval.FunctionalApprovalCondition,
                StatusID: singleApproval.StatusID,
                AddedByID: ADDED_BY_ID
            });
        }
    }, [functionalApprovalId, singleFunctionalApprovalData]);

    const handleChange = (field) => (selectedOption) => {
        setFunctionalApprovalData((prevData) => ({
            ...prevData,
            [field]: selectedOption ? selectedOption.value : null
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: "" // Clear error when user changes the value
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFunctionalApprovalData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "" // Clear error when user types in the input
        }));
    };

    const validateForm = () => {
        let formIsValid = true;
        let newErrors = {};

        // Check each field for validation
        if (!functionalApprovalData.LevelID) {
            formIsValid = false;
            newErrors.LevelID = "Approval Level is required";
        }
        if (!functionalApprovalData.Title) {
            formIsValid = false;
            newErrors.Title = "Title is required";
        }
        if (!functionalApprovalData.DepartmentID) {
            formIsValid = false;
            newErrors.DepartmentID = "Department is required";
        }
        if (!functionalApprovalData.Order) {
            formIsValid = false;
            newErrors.Order = "Approval Order is required";
        }
        if (!functionalApprovalData.Condition) {
            formIsValid = false;
            newErrors.Condition = "Approval Condition is required";
        }

        setErrors(newErrors);
        return formIsValid;
    };

    const AddFunctionalApprovalData = (e) => {
        e.preventDefault();
        if (validateForm()) {
            let requestOption = {
                method: "POST",
                mode: "cors",
                headers: HeaderAuthentication.postExcomatixAuth,
                body: JSON.stringify(functionalApprovalData),
            };
            dispatch(postFunctionalApprovalDatas(requestOption, props.handleCloseModal, functionalApprovalDatas, functionalApprovalId));
            // console.log(functionalApprovalData,'functionalApprovalData')
        }
    }

    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{functionalApprovalId ? "Edit Functional Approval" : "Add Functional Approval"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Approval Levels</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={approvalLevelDropdown}
                                            placeholder="Select"
                                            value={approvalLevelDropdown.find(option => option.value === functionalApprovalData.LevelID)}
                                            onChange={handleChange("LevelID")}
                                        />
                                    </div>
                                    {errors.LevelID && <small className="text-danger">{errors.LevelID}</small>}
                                </Col>
                                <Col md={8}>
                                    <div>
                                        <label>Title</label>
                                        <Form.Control
                                            type="text"
                                            name="Title"
                                            value={functionalApprovalData.Title}  // Bind value to state
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    {errors.Title && <small className="text-danger">{errors.Title}</small>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Department</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={departmentDropdown}
                                            placeholder="Select"
                                            value={departmentDropdown.find(option => option.value === functionalApprovalData.DepartmentID)}
                                            onChange={handleChange("DepartmentID")}
                                        />
                                    </div>
                                    {errors.DepartmentID && <small className="text-danger">{errors.DepartmentID}</small>}
                                </Col>
                                <Col md={8}>
                                    <div>
                                        <label>Approval Order</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={functionalApprovalOrderOptions}
                                            placeholder="Select"
                                            value={functionalApprovalOrderOptions.find(option => option.value === functionalApprovalData.Order)}
                                            onChange={handleChange("Order")}
                                        />
                                    </div>
                                    {errors.Order && <small className="text-danger">{errors.Order}</small>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Approval Condition</label>
                                        <Form.Control
                                            type="text"
                                            name="Condition"
                                            value={functionalApprovalData.Condition}  // Bind value to state
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    {errors.Condition && <small className="text-danger">{errors.Condition}</small>}
                                </Col>
                            </Row>
                            {functionalApprovalId && <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Status</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={enablingOptions}
                                            placeholder="Select"
                                            value={enablingOptions.find(option => option.value === functionalApprovalData.StatusID)}
                                            onChange={handleChange("StatusID")}
                                        />
                                    </div>
                                </Col>
                            </Row>}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={AddFunctionalApprovalData} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddFunctionalApproval;
