// import { Col, Form, Modal, Row } from "react-bootstrap";
// import SbCancelBtn, { SbAddBtn, SBSaveUpdateBtn } from "../Buttons/Buttons";
// import Select from 'react-select';
// import { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import * as HeaderAuthentication from '../HeaderAuthentication';
// import { loadSlocDropdownData, loadBranchDropdownData } from "../../redux/action";
// import { postUserManagementSlocDatas } from "../../redux/action";
// import deleteImg from '../../assets/images/Settings/deleteImage.svg'

// const AddSlocModal = (props) => {
//     let dispatch = useDispatch();
//     const singleUserData = props.singleUserData; // This is an array
//     const userID = props.userID; // Assuming userID is passed to the component
//     const userManagenemtData = props.userManagenemtData;
//     const { slocDropdown } = useSelector((state) => state.data); // Fetch Sloc dropdown data
//     const { branchDropdown } = useSelector((state) => state.data); // Fetch Branch dropdown data

//     // State for storing the User Name
//     const [userName, setUserName] = useState("");
//     const [storageLocation, setStorageLocation] = useState([{ BranchID: null, CustSlocID: null }]);
//     const [slocData, setSlocData] = useState({
//         "UserID": null, // Initially null, to be updated later
//         "UserSloc": [
//             {
//                 "BranchID": null,
//                 "CustSlocID": null
//             }
//         ]
//     });
//     const [errorMessage, setErrorMessage] = useState({}); // State to hold error messages for each field

//     useEffect(() => {
//         if (singleUserData && singleUserData.length > 0) {
//             const user = singleUserData[0]; // Access the first user
//             const fullName = `${user.FirstName} ${user.MiddleName || ""} ${user.LastName || ""}`.trim();
//             setUserName(fullName); // Set the full name

//             // Set the UserID to the slocData
//             setSlocData(prevState => ({
//                 ...prevState,
//                 UserID: user.UserID // Set the UserID from the first user
//             }));

//             // Extract the active sloc (filter based on the "Status" field)
//             const activeSloc = user.UserSloc.find(sloc => sloc.Status === "Active");

//             // If there is an active Sloc, update the storageLocation state
//             if (activeSloc) {
//                 setStorageLocation([{
//                     BranchID: activeSloc.BranchID,
//                     CustSlocID: activeSloc.CustSlocID
//                 }]);

//                 // Update slocData with the active Sloc details
//                 setSlocData(prevState => ({
//                     ...prevState,
//                     UserSloc: [{
//                         BranchID: activeSloc.BranchID,
//                         CustSlocID: activeSloc.CustSlocID
//                     }]
//                 }));
//             }
//         }
//     }, [singleUserData]);

//     // Handler for adding a new storage location
//     const handleAddStorage = () => {
//         setStorageLocation(prevUnits => [...prevUnits, { BranchID: null, CustSlocID: null }]); // Adding a new empty object as a placeholder
//     };

//     // Handler for updating storage location details
//     const handleSelectChange = (selectedValue, index, field) => {
//         const selectedValueId = selectedValue ? selectedValue.value : null; // Handle the case where selectedValue might be null

//         // Create a copy of the storageLocation to modify
//         const updatedStorageLocation = [...storageLocation];

//         // Update the current selected field with the selected value
//         updatedStorageLocation[index][field] = selectedValueId;

//         // Clear the error message for this field if a valid selection is made
//         setErrorMessage(prevErrors => {
//             const updatedErrors = { ...prevErrors };
//             delete updatedErrors[index]?.[field];  // Clear the specific field error
//             return updatedErrors;
//         });

//         // Check if both BranchID and CustSlocID are the same for any other entry
//         const isDuplicatePair = updatedStorageLocation.some((unit, idx) => {
//             // Skip the current index (the one being modified)
//             return idx !== index &&
//                 unit.BranchID === updatedStorageLocation[index].BranchID &&
//                 unit.CustSlocID === updatedStorageLocation[index].CustSlocID;
//         });

//         if (isDuplicatePair) {
//             // If both BranchID and CustSlocID are the same for another entry, set a duplicate error message
//             setErrorMessage(prevErrors => ({
//                 ...prevErrors,
//                 [index]: { ...prevErrors[index], duplicate: 'The combination of Branch and Sloc is already assigned. Please select other values.' }
//             }));
//         } else {
//             // If no duplicate pair, clear the duplicate error message
//             setErrorMessage(prevErrors => {
//                 const updatedErrors = { ...prevErrors };
//                 delete updatedErrors[index]?.duplicate;
//                 return updatedErrors;
//             });
//         }

//         // Update the storageLocation state with the new values
//         setStorageLocation(updatedStorageLocation);

//         // Also updating the slocData state
//         const updatedSlocData = { ...slocData };
//         updatedSlocData.UserSloc = updatedStorageLocation;
//         setSlocData(updatedSlocData);
//     };

//     // Delete handler for storage location
//     const handleDeleteStorage = (index) => {
//         // Remove the item at the specified index
//         const updatedStorageLocation = storageLocation.filter((_, idx) => idx !== index);

//         // Update state
//         setStorageLocation(updatedStorageLocation);

//         // Also update the slocData to reflect the changes
//         const updatedSlocData = { ...slocData };
//         updatedSlocData.UserSloc = updatedStorageLocation;
//         setSlocData(updatedSlocData);
//     };

//     // Load dropdown data when component mounts
//     useEffect(() => {
//         dispatch(loadSlocDropdownData());
//         dispatch(loadBranchDropdownData());
//     }, [dispatch]);

//     // Validate form before submission
//     const validateForm = () => {
//         let isValid = true;
//         let updatedErrors = {};

//         storageLocation.forEach((unit, index) => {
//             if (!unit.BranchID) {
//                 isValid = false;
//                 updatedErrors[index] = { ...updatedErrors[index], BranchID: 'Please select a Branch.' };
//             }
//             if (!unit.CustSlocID) {
//                 isValid = false;
//                 updatedErrors[index] = { ...updatedErrors[index], CustSlocID: 'Please select a Sloc.' };
//             }
//         });

//         setErrorMessage(updatedErrors);
//         return isValid;
//     };

//     const AddUserManagementSlocData = (e) => {
//         e.preventDefault();

//         // Validate before submission
//         if (!validateForm()) {
//             return; // If the form is invalid, don't proceed with the submission
//         }

//         let requestOption = {
//             method: "POST",
//             mode: "cors",
//             headers: HeaderAuthentication.postExcomatixAuth,
//             body: JSON.stringify(slocData),
//         };
//         dispatch(postUserManagementSlocDatas(requestOption, props.handleCloseSlocModal, userManagenemtData));
//         console.log(slocData, 'slocData');
//     };

//     return (
//         <>
//             <Modal show={props.openSlocModal} onHide={props.handleCloseSlocModal} className='add-customer-modal add-user-modal'>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Select Storage Locations (05)</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <Row className='customer-form-fields'>
//                         <Col>
//                             <Row>
//                                 <Col md={12}>
//                                     <div>
//                                         <label>User Name</label>
//                                         <Form.Control
//                                             type="text"
//                                             value={userName} // Bind the userName to the input field
//                                             readOnly // Making it read-only as it's fetched automatically
//                                         />
//                                     </div>
//                                 </Col>
//                             </Row>
//                             {storageLocation.map((unit, index) => (
//                                 <>
//                                     <Row>
//                                         {index > 0 && <hr className='mt-4' />}
//                                         {index > 0 && <Col className="d-flex justify-content-end">
//                                             <img
//                                                 src={deleteImg}
//                                                 alt="Delete"
//                                                 style={{ cursor: 'pointer' }}
//                                                 onClick={() => handleDeleteStorage(index)} // Trigger delete on click
//                                             />
//                                         </Col>}
//                                     </Row>
//                                     <Row key={index}>
//                                         <Col md={6}>
//                                             <div>
//                                                 <label>Plant / Branch</label>
//                                                 <Select
//                                                     className="react-select-container"
//                                                     classNamePrefix="react-select"
//                                                     value={unit.BranchID ? { value: unit.BranchID, label: branchDropdown.find(branch => branch.value === unit.BranchID)?.label } : null}
//                                                     onChange={(selectedOption) => handleSelectChange(selectedOption, index, "BranchID")}
//                                                     placeholder="Select"
//                                                     options={branchDropdown} // Directly using branchDropdown
//                                                 />
//                                                 {/* Error message for Branch */}
//                                                 {errorMessage[index]?.BranchID && (
//                                                     <div className="text-danger">{errorMessage[index].BranchID}</div>
//                                                 )}
//                                             </div>
//                                         </Col>
//                                         <Col md={6}>
//                                             <div>
//                                                 <label>Slocs (02)</label>
//                                                 <Select
//                                                     className="react-select-container"
//                                                     classNamePrefix="react-select"
//                                                     value={unit.CustSlocID ? { value: unit.CustSlocID, label: slocDropdown.find(sloc => sloc.value === unit.CustSlocID)?.label } : null}
//                                                     onChange={(selectedOption) => handleSelectChange(selectedOption, index, "CustSlocID")}
//                                                     placeholder="Select"
//                                                     options={slocDropdown} // Directly using slocDropdown
//                                                 />
//                                                 {/* Error message for Sloc */}
//                                                 {errorMessage[index]?.CustSlocID && (
//                                                     <div className="text-danger">{errorMessage[index].CustSlocID}</div>
//                                                 )}

//                                             </div>
//                                         </Col>
//                                         {/* Error message for duplicate pair */}
//                                         {errorMessage[index]?.duplicate && (
//                                             <div className="text-danger">{errorMessage[index].duplicate}</div>
//                                         )}
//                                     </Row>
//                                 </>
//                             ))}
//                             <Row>
//                                 <Col className='business-unit-btn'>
//                                     <SbAddBtn btnName="Add Branch" onClickEffect={handleAddStorage} />
//                                 </Col>
//                             </Row>
//                         </Col>
//                     </Row>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseSlocModal} />
//                     <SBSaveUpdateBtn btnName="Add Slocs" onClickEffect={AddUserManagementSlocData} />
//                 </Modal.Footer>
//             </Modal>
//         </>
//     );
// };

// export default AddSlocModal;




import React, { useState, useEffect } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import SbCancelBtn, { SbAddBtn, SBSaveUpdateBtn } from '../Buttons/Buttons';
import Select from 'react-select';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import deleteImg from '../../assets/images/Settings/deleteImage.svg';  // Assuming delete image
import { useDispatch, useSelector } from 'react-redux';
import { loadBranchDropdownData, loadSlocDropdownData, postUserManagementSlocDatas } from '../../redux/action';
import * as HeaderAuthentication from '../HeaderAuthentication';

const AddSlocModal = (props) => {
    const dispatch = useDispatch();
    const { singleUserData, userID, userManagenemtData } = props;
    const { slocDropdown, branchDropdown } = useSelector((state) => state.data);

    const [userName, setUserName] = useState("");
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedSloc, setSelectedSloc] = useState(null);
    const [storageLocation, setStorageLocation] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (singleUserData && singleUserData.length > 0) {
            const user = singleUserData[0];
            if (user && user.FirstName) {
                setUserName(user.FirstName);
            }
            setStorageLocation(user.UserSloc);
        }
    }, [singleUserData]);

    useEffect(() => {
        dispatch(loadSlocDropdownData());
        dispatch(loadBranchDropdownData());
    }, [dispatch]);

    const handleBranchChange = (branch) => {
        setSelectedBranch(branch);
        setErrorMessage(""); // Clear error message when branch is changed
    };

    const handleSlocChange = (sloc) => {
        setSelectedSloc(sloc);
        setErrorMessage(""); // Clear error message when sloc is changed
    };

    const handleAddStorage = () => {
        if (selectedBranch && selectedSloc) {
            if (selectedBranch.value === selectedSloc.value) {
                setErrorMessage("Branch and Sloc cannot be the same.");
                return;
            }

            const isDuplicate = storageLocation.some(
                (location) =>
                    location.BranchID === selectedBranch.value &&
                    location.CustSlocID === selectedSloc.value
            );

            if (isDuplicate) {
                setErrorMessage("This Branch-Sloc combination already exists.");
                return;
            }

            setErrorMessage(""); // Clear error if no issues

            const newStorageLocation = {
                BranchID: selectedBranch.value,
                BranchName: selectedBranch.label,
                CustSlocID: selectedSloc.value,
                StorageLocationName: selectedSloc.label,
            };

            setStorageLocation([...storageLocation, newStorageLocation]);
            setSelectedBranch(null);
            setSelectedSloc(null);
        } else {
            alert("Please select both Branch and Sloc");
        }
    };

    const handleRemoveStorage = (index) => {
        const updatedStorageLocation = [...storageLocation];
        updatedStorageLocation.splice(index, 1);
        setStorageLocation(updatedStorageLocation);
    };

    const AddUserManagementSlocData = (e) => {
        e.preventDefault();

        if (storageLocation.length === 0) {
            setErrorMessage("No storage locations added. Please add a storage location before saving.");
            return;
        } else {
            setErrorMessage("");
        }

        const slocData = {
            UserID: userID,
            UserSloc: storageLocation.map(location => ({
                BranchID: location.BranchID,
                CustSlocID: location.CustSlocID,
            })),
        };

        const requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(slocData),
        };

        dispatch(postUserManagementSlocDatas(requestOption, props.handleCloseSlocModal, userManagenemtData));
    };

    return (
        <Modal show={props.openSlocModal} onHide={props.handleCloseSlocModal} className="add-customer-modal">
            <Modal.Header closeButton>
                <Modal.Title>Add Storage Locations</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="customer-form-fields customer-branch-plant-form-fields">
                    <Col>
                        <Row>
                            <Col md={12}>
                                <div>
                                    <label>Username</label>
                                    <Form.Control
                                        type="text"
                                        value={userName}
                                        onChange={(e) => setUserName(e.target.value)}
                                        placeholder="Enter Username"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div className="wq-modal-table-container">
                            <Row>
                                <Col md={6}>
                                    <div>
                                        <label>Branch</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            value={selectedBranch ? { value: selectedBranch.value, label: selectedBranch.label } : null}
                                            onChange={handleBranchChange}
                                            options={branchDropdown}
                                            placeholder="Select Branch"
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div>
                                        <label>Sloc</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            value={selectedSloc ? { value: selectedSloc.value, label: selectedSloc.label } : null}
                                            onChange={handleSlocChange}
                                            options={slocDropdown}
                                            placeholder="Select Sloc"
                                        />
                                    </div>
                                </Col>
                            </Row>

                            {/* Display Error Message */}
                            {errorMessage && (
                                <Row>
                                    <Col md={12} className="text-danger text-center">
                                        {errorMessage}
                                    </Col>
                                </Row>
                            )}

                            <Row>
                                <Col className="d-flex justify-content-center pt-4">
                                    <SbAddBtn btnName="Add Storage Location" onClickEffect={handleAddStorage} />
                                </Col>
                            </Row>

                            <Row className="add-sloc-table-div">
                                <TableContainer
                                    component={Paper}
                                    sx={{ maxHeight: 440 }}
                                    className="table-container add-sloc-table-container"
                                    style={{ boxShadow: "none" }}
                                >
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="table-header-div">Branch</TableCell>
                                                <TableCell className="table-header-div">Sloc</TableCell>
                                                <TableCell className="table-header-div">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {storageLocation.map((location, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{location.BranchName}</TableCell>
                                                    <TableCell>
                                                        {location.StorageLocationName}
                                                        {location.Status === "Inactive" && (
                                                            <span style={{ color: "red", fontStyle: "italic" }}> (Inactive)</span>
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        <img
                                                            src={deleteImg}
                                                            alt="Delete"
                                                            onClick={() => handleRemoveStorage(index)}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseSlocModal} />
                <SBSaveUpdateBtn btnName="Save" onClickEffect={AddUserManagementSlocData} />
            </Modal.Footer>
        </Modal>
    );
};

export default AddSlocModal;









