import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';
import * as HeaderAuthentication from '../../HeaderAuthentication'
import { postDepartmentDatas } from "../../../redux/action";
import { useDispatch } from "react-redux";
import { ADDED_BY_ID } from "../../Constants";

const enablingOptions = [
    { value: 3, label: "Active" },
    { value: 4, label: "Inactive" }
]

const AddDepartment = (props) => {
    let dispatch = useDispatch();
    const deptData = props.deptData;
    const singleDepartmentData = props.singleDepartmentData;
    const deptId = props.deptId;

    const [departmentName, setDepartmentName] = useState("");
    const [selectedOption, setSelectedOption] = useState(null);
    const [error, setError] = useState("");

    const handleDepartmentNameChange = (e) => {
        setDepartmentName(e.target.value);
        if (e.target.value) {
            setError(""); // Clear error if input is valid
        }
    };

    const handleSelectChange = (option) => {
        setSelectedOption(option);
    };

    useEffect(() => {
        if (deptId && singleDepartmentData) {
            setDepartmentName(singleDepartmentData[0]?.DepartmentName);
            const statusOption = enablingOptions.find(option => option.label === singleDepartmentData[0]?.Status);
            setSelectedOption(statusOption || null);
        } else {
            // Reset fields for adding new classification
            setDepartmentName("");
            setSelectedOption(null);
        }
    }, [deptId, singleDepartmentData]);

    const AddDepartmentData = (e) => {
        e.preventDefault();
        if (!departmentName) {
            setError("Department Name is required.");
            return;
        }
        const data = {
            DepartmentName: departmentName,
            DeptID: deptId,
            StatusID: parseInt(selectedOption?.value),
            AddedByID: ADDED_BY_ID

        }
        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data),
        };
        dispatch(postDepartmentDatas(requestOption, props.handleCloseModal, deptData, deptId));
    }

    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{deptId? 'Edit Department' : 'Add Department'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Department Name <span className="required-asterisk">*</span></label>
                                        <Form.Control
                                            type="text"
                                            value={departmentName}
                                            onChange={handleDepartmentNameChange}
                                            isInvalid={!!error}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {error}
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                            </Row>
                            {deptId && <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Enable / Disable</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={enablingOptions}
                                            placeholder="Select"
                                            value={selectedOption}
                                            onChange={handleSelectChange}
                                        />
                                    </div>
                                </Col>
                            </Row>}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={AddDepartmentData} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddDepartment;
