import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

const companyOptions = [
    { value: 'company-a', label: 'Company A' },
    { value: 'company-b', label: 'Company B' },
    { value: 'company-c', label: 'Company C' },
];

const branchOptions = [
    { value: 'branch-a', label: 'Branch A' },
    { value: 'branch-b', label: 'Branch B' },
    { value: 'branch-c', label: 'Branch C' },
];

const modules = [
    { label: 'Leave Management', isActive: true },
    { label: 'Complaints and Escalation', isActive: false },
    { label: 'AMC or SLA Module', isActive: true },
    { label: 'Ticketing Interface', isActive: false },
    { label: 'Planning & Forecasting', isActive: true },
    { label: 'Inventory', isActive: true },
    { label: 'Warehousing', isActive: false },
    { label: 'Category Management', isActive: true },
    { label: 'Investment Recovery', isActive: false },
    { label: 'Logistics & Delivery', isActive: true },
];

const AssignedModules = () => {
    const [selectedCompany, setSelectedCompany] = useState(companyOptions[0]);
    const [selectedBranch, setSelectedBranch] = useState(branchOptions[0]);

    const handleCompanySelect = (company) => {
        setSelectedCompany(company);
    };

    const handleBranchSelect = (branch) => {
        setSelectedBranch(branch);
    };

    return (
        <div className='barchart-container relation-type-chart-container'>
            <div>
                <h6>Assigned Modules</h6>
                <div className='relation-type-chart-btns'>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-company">
                            {selectedCompany.label}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {companyOptions.map(option => (
                                <Dropdown.Item
                                    key={option.value}
                                    onClick={() => handleCompanySelect(option)}
                                    active={selectedCompany.value === option.value}
                                >
                                    {option.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-branch">
                            {selectedBranch.label}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {branchOptions.map(option => (
                                <Dropdown.Item
                                    key={option.value}
                                    onClick={() => handleBranchSelect(option)}
                                    active={selectedBranch.value === option.value}
                                >
                                    {option.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className='assigned-module-div'>
                {modules.map((module, index) => (
                    <div key={index} className='assigned-module-names'>
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                backgroundColor: '#000',
                                marginRight: '10px',
                                borderRadius: '2px',
                            }}
                        ></div>
                        <span style={{ color: '#000', fontSize: '14px' }}>
                            {module.label}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AssignedModules;
