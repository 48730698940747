import React, { useState, useEffect } from 'react';
import { Col, Modal, Row, Form } from 'react-bootstrap';
import searchIcon from '../../../assets/images/Dashboard/search-icon.svg';
import SbCancelBtn, { SbResetBtn, SBSaveUpdateBtn } from '../../Buttons/Buttons';

const LeaveApprovalColumnFilter = ({
    showColumnFilterModal,
    handleCloseColumnFilterModal,
    selectedColumns,
    handleSaveColumns
}) => {
    const [localSelectedColumns, setLocalSelectedColumns] = useState(selectedColumns);
    const [displayItems, setDisplayItems] = useState(selectedColumns);  // Initially populate with selectedColumns
    const [searchQuery, setSearchQuery] = useState('');

    const items = [
        "LEAVE LEVEL", "TITLE", "DEPARTMENT", "LEAVE ORDER", 
        "STATUS", "ACTION"
    ];

    // Handle item selection
    const handleSelectItem = (item) => {
        if (!localSelectedColumns.includes(item)) {
            // Add item to selected columns
            setLocalSelectedColumns(prev => [...prev, item]);
            // Add to display items only when selected
            setDisplayItems(prev => [...prev, item]);
        } else {
            // If already selected, remove it from display items (not selected list)
            setDisplayItems(prev => prev.filter(i => i !== item));
            setLocalSelectedColumns(prev => prev.filter(i => i !== item));
        }
    };

    // Handle save action to pass back the selected columns
    const handleSave = () => {
        handleSaveColumns(localSelectedColumns); // Update parent component with selected columns
        handleCloseColumnFilterModal();  // Close modal after saving
    };

    // Handle reset action
    const handleReset = () => {
        setLocalSelectedColumns([]);
        setDisplayItems([]);
    };

    // Handle search query change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Filter items based on the search query
    const filteredItems = items.filter(item =>
        item.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Remove item from both display and selected columns
    const handleRemoveDisplayItem = (item) => {
        // Remove from displayItems
        setDisplayItems(prev => prev.filter(i => i !== item));
        // Remove from localSelectedColumns
        setLocalSelectedColumns(prev => prev.filter(i => i !== item));
    };

    // Re-sync selected columns when modal is shown
    useEffect(() => {
        if (showColumnFilterModal) {
            // Sync the selectedColumns when the modal opens
            setLocalSelectedColumns(selectedColumns);
            setDisplayItems(selectedColumns);
        }
    }, [showColumnFilterModal, selectedColumns]);

    return (
        <Modal show={showColumnFilterModal} onHide={handleCloseColumnFilterModal} className='add-customer-modal'>
            <Modal.Header closeButton>
                <Modal.Title>Column Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <p className='mb-2'>Select Items</p>
                        <div className='customermanagement-filter-search'>
                            <Form.Control
                                type="search"
                                placeholder='Search'
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <img src={searchIcon} alt="search icon" />
                        </div>
                        <div className='item-list'>
                            {filteredItems.map(item => (
                                <Form.Check
                                    key={item}
                                    type="checkbox"
                                    label={item}
                                    checked={localSelectedColumns.includes(item)}
                                    onChange={() => handleSelectItem(item)}
                                />
                            ))}
                        </div>
                    </Col>

                    {/* Display Selected Items */}
                    <Col md={6}>
                        <p className='mb-2'>Display Items</p>
                        <div className='customermanagement-filter-search'>
                            <Form.Control type="search" placeholder='Search' />
                            <img src={searchIcon} alt="search icon" />
                        </div>
                        <div className='item-list'>
                            {displayItems.map(item => (
                                <div key={item} className='display-item'>
                                    {item}
                                    <button onClick={() => handleRemoveDisplayItem(item)}>X</button>
                                </div>
                            ))}
                        </div>
                    </Col>
                </Row>
                <Row className='customer-rest-container'>
                    <Col className='customer-rest-col'>
                        <SbResetBtn btnName="Reset" onClickEffect={handleReset} />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <SbCancelBtn btnName="Cancel" onClickEffect={handleCloseColumnFilterModal} />
                <SBSaveUpdateBtn btnName="Save" onClickEffect={handleSave} />
            </Modal.Footer>
        </Modal>
    );
};

export default LeaveApprovalColumnFilter;
