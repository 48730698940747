import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../Buttons/Buttons";
import DatePicker from "react-datepicker";
import calendar from '../../assets/images/Dashboard/calendar-icon.svg'
import Select from 'react-select';
import { useEffect, useRef, useState } from "react";
import attachmentImg from '../../assets/images/attachmentImg.svg'
import { loadAssignedToSlaTypeDropdownData, loadCompanyDropdownData, loadReportingToDropdownData, loadSlaCategoryDropdownData, postAssignedToSlaDatas } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import * as HeaderAuthentication from '../HeaderAuthentication';
import moment from "moment";

const currencyOptions = [
    { value: "Dollar", label: "$ dollar" },
    { value: "Date", label: "Date" },
    { value: "Percentage", label: "Percentage" },
    { value: "Number", label: "Number" },
];

const AddSLA = (props) => {

    let dispatch = useDispatch();

    const assignedToSlaDatas = props.assignedToSlaDatas;
    const slaId = props.slaId;
    const singleAssignedToSlaData = props.singleAssignedToSlaData;

    const { assignedToSlaTypeDropdownData } = useSelector((state) => state.data);
    const { getCompanyDropdown } = useSelector((state) => state.data);
    const { getSlaCategoryDropdown } = useSelector((state) => state.data);
    const { reportingToDropdown } = useSelector((state) => state.data);
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
    const [selectedValueType, setSelectedValueType] = useState(null);
    const [baseline, setBaseline] = useState("");  // Baseline value
    const [targetPercent, setTargetPercent] = useState("");
    const [targetValue, setTargetValue] = useState("");  // Target value
    const [imgChange, setImgChange] = useState(false)

    const [slaData, setSlaData] = useState({
        SLATypeID: null,
        CustomerID: null,
        SLACategoryID: null,
        ValueType: "",
        BaseLine: "",
        TargetPercentage: "",
        TargetValue: "",
        StartDate: "",
        EndDate: "",
        AssignedToID: null,
        Remarks: "",
        FileName: "",
        File: "",
        IsFileUpdated: false, // Default value is false
    });

    // Handle the change of Value Type
    const handleValueTypeChange = (selectedOption) => {
        setSelectedValueType(selectedOption ? selectedOption.value : null);
        setSlaData({ ...slaData, ValueType: selectedOption ? selectedOption.value : "" });
    };

    // Handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            convertFileToBase64(file);  // Convert the selected file to base64
        }
    };

    // Function to convert the file to base64 and set the FileName and File data
    const convertFileToBase64 = (file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result.split(',')[1];  // Remove the data URL part
            setSlaData({
                ...slaData,
                FileName: file.name,  // File name as is
                File: base64String    // Base64 encoded file
            });
        };
        reader.readAsDataURL(file);  // Read the file as a data URL (Base64)
        setImgChange(true);
    };

    // Trigger file input click when attachment button is clicked
    const handleAttachmentClick = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        dispatch(loadAssignedToSlaTypeDropdownData());
        dispatch(loadCompanyDropdownData());
        dispatch(loadSlaCategoryDropdownData());
        dispatch(loadReportingToDropdownData());
    }, []);

    useEffect(() => {
        if (slaId && singleAssignedToSlaData && singleAssignedToSlaData.length > 0) {
            const singleApproval = singleAssignedToSlaData[0]; // Access the first item in the array

            setSlaData({
                ...slaData,
                SLAID: slaId,
                SLATypeID: singleApproval.SLATypeID,
                CustomerID: singleApproval.CustomerID,
                SLACategoryID: singleApproval.SLACategoryID,
                ValueType: singleApproval.ValueType,
                BaseLine: singleApproval.BaseLine,
                TargetPercentage: singleApproval.TargetPercentage,
                TargetValue: singleApproval.TargetValue,
                StartDate: moment(singleApproval.StartDate).format("YYYY-MM-DD"),
                EndDate: moment(singleApproval.EndDate).format("YYYY-MM-DD"),
                AssignedToID: singleApproval.AssignedToID,
                Remarks: singleApproval.Remarks,
                FileName: singleApproval.FileName,
                // File: singleApproval.FilePath || "", // Extract file name from the URL if available
                IsFileUpdated: false,  // Default to false
            });

            // Initialize selectedValueType based on ValueType in the data
            setSelectedValueType(singleApproval.ValueType);

            // Set Baseline and Target Value based on SLA type if required
            if (singleApproval.ValueType === "Dollar" || singleApproval.ValueType === "Percentage" || singleApproval.ValueType === "Number") {
                setBaseline(singleApproval.BaseLine);
                setTargetPercent(singleApproval.TargetPercentage);
            } else {
                // Handle Date type value, if applicable
            }
        }
    }, [slaId, singleAssignedToSlaData]);

    // Function to calculate Target Value based on Baseline and Target%
    const calculateTargetValue = () => {
        if (baseline && targetPercent) {
            let calculatedTargetValue = 0;

            if (selectedValueType === "Dollar") {
                // For Dollar, the Target Value is the same as Target%
                calculatedTargetValue = parseFloat(targetPercent);
            } else if (selectedValueType === "Percentage" || selectedValueType === "Number") {
                // For Percentage or Number, calculate percentage of Baseline
                calculatedTargetValue = parseFloat(baseline) * (parseFloat(targetPercent) / 100);
            }

            setTargetValue(calculatedTargetValue);
            setSlaData({ ...slaData, TargetValue: calculatedTargetValue.toString() });  // Update TargetValue in slaData
        }
    };

    // Update Target Value whenever Baseline or Target% changes
    useEffect(() => {
        calculateTargetValue();
    }, [baseline, targetPercent, selectedValueType]);

    // Handle changes for Baseline
    const handleBaselineChange = (e) => {
        setBaseline(e.target.value);
        setSlaData({ ...slaData, BaseLine: e.target.value });
    };

    // Handle changes for Target%
    const handleTargetPercentChange = (e) => {
        setTargetPercent(e.target.value);
        setSlaData({ ...slaData, TargetPercentage: e.target.value });
    };

    // Handle changes for other form fields (e.g., SLATypeID, CustomerID, etc.)
    const handleSelectChange = (field) => (selectedOption) => {
        setSlaData({ ...slaData, [field]: selectedOption ? selectedOption.value : null });
    };

    const handleDateChange = (field, date) => {
        setSlaData({
            ...slaData,
            [field]: date ? moment(date).format('YYYY-MM-DD') : null, // Save date in 'YYYY-MM-DD' format
        });
    };

    const AddSLAData = (e) => {
        e.preventDefault();

        const updatedSlaData = { ...slaData, IsFileUpdated: imgChange };
        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(updatedSlaData),
        };
        dispatch(postAssignedToSlaDatas(requestOption, props.handleClose, assignedToSlaDatas, slaId));
        // console.log(slaData, 'slaData')
    };
    return (
        <>
            <Modal show={props.open} onHide={props.handleClose} className='add-customer-modal add-user-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{slaId ? "Edit Service Level Agreement" : "Add Service Level Agreement"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>SLA Type</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            value={assignedToSlaTypeDropdownData.find(option => option.value === slaData.SLATypeID)}
                                            onChange={handleSelectChange("SLATypeID")}
                                            options={assignedToSlaTypeDropdownData}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Company Name</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            value={getCompanyDropdown.find(option => option.value === slaData.CustomerID)}
                                            onChange={handleSelectChange("CustomerID")}
                                            options={getCompanyDropdown}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>SLA Category</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            value={getSlaCategoryDropdown.find(option => option.value === slaData.SLACategoryID)}
                                            onChange={handleSelectChange("SLACategoryID")}
                                            options={getSlaCategoryDropdown}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Value Type</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            value={currencyOptions.find(option => option.value === selectedValueType)}
                                            onChange={handleValueTypeChange}
                                            options={currencyOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                {/* Conditionally rendered fields */}
                                {selectedValueType === "Date" && (
                                    <>
                                        <Col md={4}>
                                            <div className='add-customer-calendar-container'>
                                                <label>Baseline</label>
                                                <DatePicker
                                                    className="select from-date"
                                                    id="date-range-picker"
                                                    dateFormat="dd/MM/yyyy"
                                                    selectsRange
                                                    placeholderText='Baseline'
                                                    maxDate={new Date()}
                                                />
                                                <img src={calendar} alt="" />
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className='add-customer-calendar-container'>
                                                <label>Targe%</label>
                                                <DatePicker
                                                    className="select from-date"
                                                    id="date-range-picker"
                                                    dateFormat="dd/MM/yyyy"
                                                    selectsRange
                                                    placeholderText='Target%'
                                                    maxDate={new Date()}
                                                />
                                                <img src={calendar} alt="" />
                                            </div>
                                        </Col>
                                    </>
                                )}
                                {selectedValueType !== "Date" && (
                                    <>
                                        <Col md={4}>
                                            <div>
                                                <label>Baseline</label>
                                                <Form.Control
                                                    type="number"
                                                    value={baseline}  // Bind value to state
                                                    onChange={handleBaselineChange}  // Handle onChange
                                                />
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div>
                                                <label>Targe%</label>
                                                <Form.Control
                                                    type="number"
                                                    value={targetPercent}  // Bind value to state
                                                    onChange={handleTargetPercentChange}  // Handle onChange
                                                />
                                            </div>
                                        </Col>
                                    </>
                                )}
                            </Row>
                            <Row>
                                {selectedValueType === "Date" && (
                                    <>
                                        <Col md={4}>
                                            <div className='add-customer-calendar-container'>
                                                <label>Target Value</label>
                                                <DatePicker
                                                    className="select from-date"
                                                    id="date-range-picker"
                                                    dateFormat="dd/MM/yyyy"
                                                    selectsRange
                                                    placeholderText='Target Value'
                                                    maxDate={new Date()}
                                                />
                                                <img src={calendar} alt="" />
                                            </div>
                                        </Col>
                                    </>
                                )}
                                {selectedValueType !== "Date" && (
                                    <>
                                        <Col md={4}>
                                            <div>
                                                <label>Target Value</label>
                                                <Form.Control
                                                    type="number"
                                                    value={targetValue}  // Bind value to state
                                                    readOnly
                                                />
                                            </div>
                                        </Col>
                                    </>
                                )}
                                <Col md={4} className="d-flex justify-content-start align-items-end">
                                    <div className="dropdown add-single-bulk attachment-section">
                                        <label>&nbsp;</label>
                                        <button
                                            className="btn btn-add-save-update attachment-btn"
                                            id="adduser-single-blulk"
                                            onClick={handleAttachmentClick}
                                        >
                                            <img src={attachmentImg} alt="" />&nbsp;&nbsp;
                                            <span>Attachment</span>
                                            <div className="attachment-note">
                                                <small>(jpg, PDF, word doc) Less than 5MB</small>
                                            </div>
                                        </button>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                        {selectedFile && <div className="attachment-note" style={{ fontSize: "0.8rem" }}><small>{selectedFile.name}</small></div>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className='add-customer-calendar-container'>
                                        <label>Start Date</label>
                                        {/* <div className='add-customer-calendar-container'> */}
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText='Start Date'
                                            selected={slaData.StartDate ? moment(slaData.StartDate, 'YYYY-MM-DD').toDate() : null} // Convert string to Date object
                                            onChange={(date) => handleDateChange("StartDate", date)}
                                            maxDate={new Date()}
                                        />
                                        <img src={calendar} alt="" />
                                        {/* </div> */}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className='add-customer-calendar-container'>
                                        <label>End Date</label>
                                        {/* <div className='add-customer-calendar-container'> */}
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText='End Date'
                                            selected={slaData.EndDate ? moment(slaData.EndDate, 'YYYY-MM-DD').toDate() : null} // Convert string to Date object
                                            onChange={(date) => handleDateChange("EndDate", date)}
                                            maxDate={new Date()}
                                        />
                                        <img src={calendar} alt="" />
                                        {/* </div> */}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Assigned To</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            value={reportingToDropdown.find(option => option.value === slaData.AssignedToID)}
                                            onChange={handleSelectChange("AssignedToID")}
                                            options={reportingToDropdown}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Description</label>
                                        <Form.Control as="textarea" rows={2} value={slaData.Remarks}
                                            onChange={(e) => setSlaData({ ...slaData, Remarks: e.target.value })} />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Modal.Body >
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={AddSLAData} />
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default AddSLA;