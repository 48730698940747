import { Col, Form, ListGroup, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SbApprovalBtn, SBSaveUpdateBtn } from "../Buttons/Buttons";
import customerImg from '../../assets/images/CustomerManagement/customer-img.svg'
import Select from 'react-select';
import { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";

const options = [
    { label: "Functional Approval", value: "functional_approval" },
    { label: "Financial Approval", value: "financial_approval" },
    { label: "Complaints Approval", value: "complaints_approval" },
    { label: "Escalation Approval", value: "escalation_approval" },
    { label: "Leave Approval", value: "leave_approval" },
    { label: "Leave Approval", value: "leave_approval" },
    { label: "Leave Approval", value: "leave_approval" },
    { label: "Leave Approval", value: "leave_approval" },
];

const roleOptions = [
    { label: "Role", value: "Role" }
]
const contactOptions = [
    { label: "+91", value: "+91" }
]
const ViewExceptionModal = (props) => {
    const [selected, setSelected] = useState([]);
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const handleSetApproval = () => {
        setShowApprovalModal(true);
    };

    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-user-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>View Exception Log</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="customermanagement-view-details-btns-container border-0 p-0">
                        <div className="customermanagement-view-details-first-col border-0">
                            <ListGroup variant="flush" className='head-quarters-list-items'>
                                <ListGroup.Item className='mt-3'><p>Level</p> <p>: </p><p>Error</p></ListGroup.Item>
                                <ListGroup.Item><p>Date</p> <p>:</p><p>06/09/2024 (11:21am)</p></ListGroup.Item>
                                <ListGroup.Item><p>Thread</p><p>:</p> <p>Logistic Api.Logistic Api.Mobile Api</p></ListGroup.Item>
                                <ListGroup.Item><p>Exception</p> <p>:</p><p></p></ListGroup.Item>
                                <ListGroup.Item><p>Thread</p> <p>:</p><p>43</p></ListGroup.Item>
                                <ListGroup.Item><p>Error Message</p> <p>:</p><p>System. objectDisposedexSystem. objectDisposedex
                                    System. objectDisposedex System. objectDisposedex
                                    System. objectDisposedexSystem. objectDisposedex
                                    System. objectDisposedex System. objectDisposedex</p>
                                </ListGroup.Item>
                            </ListGroup>
                        </div>
                    </div>
                </Modal.Body >
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={props.handleNextStep} />
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default ViewExceptionModal;