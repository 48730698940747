import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Menu,
    MenuItem
} from '@mui/material';
import SortIcon from '../../assets/images/Dashboard/chevron-down.svg'; // Sorting icon
import ascToDesc from '../../assets/images/CustomerManagement/ascToDesc.svg';
import descToAsc from '../../assets/images/CustomerManagement/descToAsc.svg';
import editPenImg from '../../assets/images/CustomerManagement/edit-pen-img.svg';
import SbStatusDropdown from '../Buttons/SbStatusDropdown';
import { SbAddBtn } from '../Buttons/Buttons';
import calendar from '../../assets/images/Dashboard/calendar-icon.svg';
import searchIcon from '../../assets/images/Dashboard/search-icon.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Col, Form, Row } from 'react-bootstrap';
import AddLeave from './AddLeave';
import { useNavigate } from 'react-router-dom';
import PaginationComponent from '../PaginationComponent';

// Sample data
const data = [
    {
        id: 1001,
        name: 'Karthik karthik',
        emailId: 'Karthikkarthik@gmail.com',
        department: "department",
        contact: '9999999999',
        earned: '02',
        floatingHoliday: '02',
        personalTimeOff: '02',
        sick: '02',
        casual: '02',
        compensatory: "02",
        totalAssigned: '10',
    },
    {
        id: 1001,
        name: 'Karthik karthik',
        emailId: 'Karthikkarthik@gmail.com',
        department: "department",
        contact: '9999999999',
        earned: '02',
        floatingHoliday: '02',
        personalTimeOff: '02',
        sick: '02',
        casual: '02',
        compensatory: "02",
        totalAssigned: '10',
    },
    {
        id: 1001,
        name: 'Karthik karthik',
        emailId: 'Karthikkarthik@gmail.com',
        department: "department",
        contact: '9999999999',
        earned: '02',
        floatingHoliday: '02',
        personalTimeOff: '02',
        sick: '02',
        casual: '02',
        compensatory: "02",
        totalAssigned: '10',
    },
    {
        id: 1001,
        name: 'Karthik karthik',
        emailId: 'Karthikkarthik@gmail.com',
        department: "department",
        contact: '9999999999',
        earned: '02',
        floatingHoliday: '02',
        personalTimeOff: '02',
        sick: '02',
        casual: '02',
        compensatory: "02",
        totalAssigned: '10',
    },
    {
        id: 1001,
        name: 'Karthik karthik',
        emailId: 'Karthikkarthik@gmail.com',
        department: "department",
        contact: '9999999999',
        earned: '02',
        floatingHoliday: '02',
        personalTimeOff: '02',
        sick: '02',
        casual: '02',
        compensatory: "02",
        totalAssigned: '10',
    },
    {
        id: 1001,
        name: 'Karthik karthik',
        emailId: 'Karthikkarthik@gmail.com',
        department: "department",
        contact: '9999999999',
        earned: '02',
        floatingHoliday: '02',
        personalTimeOff: '02',
        sick: '02',
        casual: '02',
        compensatory: "02",
        totalAssigned: '10',
    },
    {
        id: 1001,
        name: 'Karthik karthik',
        emailId: 'Karthikkarthik@gmail.com',
        department: "department",
        contact: '9999999999',
        earned: '02',
        floatingHoliday: '02',
        personalTimeOff: '02',
        sick: '02',
        casual: '02',
        compensatory: "02",
        totalAssigned: '10',
    },
    {
        id: 1001,
        name: 'Karthik karthik',
        emailId: 'Karthikkarthik@gmail.com',
        department: "department",
        contact: '9999999999',
        earned: '02',
        floatingHoliday: '02',
        personalTimeOff: '02',
        sick: '02',
        casual: '02',
        compensatory: "02",
        totalAssigned: '10',
    },
];

// Table columns
const columns = [
    { id: 'id', label: 'ID', align: 'left', minWidth: '80px', sortable: true },
    { id: 'name', label: 'NAME', align: 'left', minWidth: '150px', sortable: true },
    { id: 'emailId', label: 'Email.ID', align: 'left', minWidth: '200px', sortable: true },
    { id: 'department', label: 'DEPARTMENT', align: 'left', minWidth: '120px', sortable: true },
    { id: 'contact', label: 'CONTACT', align: 'left', minWidth: '100px', sortable: true },
    { id: 'earned', label: 'EARNED', align: 'left', minWidth: '100px', sortable: true },
    { id: 'floatingHoliday', label: 'FLOATING HOLIDAY', align: 'left', minWidth: '180px', sortable: true },
    { id: 'personalTimeOff', label: 'PERSONAL TIME OFF', align: 'left', minWidth: '180px', sortable: true },
    { id: 'sick', label: 'SICK', align: 'left', minWidth: '100px', sortable: true },
    { id: 'casual', label: 'CASUAL', align: 'left', minWidth: '100px', sortable: true },
    { id: 'compensatory', label: 'COMPENSATORY', align: 'left', minWidth: '150px', sortable: true },
    { id: 'totalAssigned', label: 'TOTAL ASSIGNED', align: 'left', minWidth: '160px', sortable: true },
    { id: 'action', label: 'ACTION', align: 'left', minWidth: '80px', sortable: false },
];

const LeaveBalanceDetails = () => {
    const navigate = useNavigate(); // Initialize navigate
    const [anchorEl, setAnchorEl] = useState(null);
    const [openAddLeave, setAddLeave] = useState(false);

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Set initial rows per page
    const [paginatedData, setPaginatedData] = useState([]);

    const handleSortIconClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSortOrderChange = (order) => {
        console.log(`Sorting order changed to ${order}`); // Handle sort order change if needed
        setAnchorEl(null);
    };
    const handleAddLeave = () => {
        setAddLeave(true);
    }
    const handleCloseAddLeave = () => {
        setAddLeave(false);
    }

    const handleEditLeave = () => {
        setAddLeave(true);
    }

    // Pagination logic
    useEffect(() => {
        const start = page * rowsPerPage; // Updated page logic to 0-based
        const end = start + rowsPerPage;
        setPaginatedData(data.slice(start, end));
    }, [data, page, rowsPerPage]);

    const handlePageChange = (newPage) => {
        setPage(newPage - 1); // Handle 1-based to 0-based page index for slicing
    };
    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset to the first page when rows per page changes
    };

    const handleNameClick = (id) => {
        navigate(`/leaveDetails`); // Navigate to leaveDetails with the specific ID
    };

    return (
        <div className="customer-management-container">
            <div className="customermanagement-content">
                <Row className='customermanagement-btns-container'>
                    <Col md={3} className='customermanagement-backbtn-div'>
                        <span>Leave Details</span>
                    </Col>
                    <Col md={9} className='customermanagement-search-filter'>
                        <div className='customermanagement-search'>
                            <Form.Control type="search" placeholder='Search' />
                            <img src={searchIcon} alt="Search" />
                        </div>
                        <div className='calendar-container'>
                            <DatePicker
                                className="select from-date"
                                id="date-range-picker"
                                dateFormat="dd/MM/yyyy"
                                showMonthDropdown
                                showYearDropdown
                                selectsRange
                                dropdownMode="select"
                                placeholderText='From'
                                maxDate={new Date()}
                            />
                            <img src={calendar} alt="Calendar" />
                        </div>
                        <div className='calendar-container'>
                            <DatePicker
                                className="select from-date"
                                id="date-range-picker"
                                dateFormat="dd/MM/yyyy"
                                showMonthDropdown
                                showYearDropdown
                                selectsRange
                                dropdownMode="select"
                                placeholderText='To'
                                maxDate={new Date()}
                            />
                            <img src={calendar} alt="Calendar" />
                        </div>
                        <SbAddBtn btnName="Add Leaves" onClickEffect={handleAddLeave} />
                    </Col>
                </Row>
                <TableContainer component={Paper} sx={{ maxHeight: 440 }} className='table-container'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }} className='table-header-div'>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {column.label}
                                            {column.sortable !== false && (
                                                <IconButton
                                                    size="small"
                                                    onClick={handleSortIconClick}
                                                >
                                                    <img src={SortIcon} alt="Sort" />
                                                </IconButton>
                                            )}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.map((row) => (
                                <TableRow key={row.id}>
                                    {columns.map((column) => (
                                        <TableCell key={column.id} align={column.align}>
                                            {column.id === 'name' ? (
                                                <span
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => handleNameClick(row.id)} // Navigate on name click
                                                >
                                                    {row[column.id]}
                                                </span>
                                            ) : column.id === 'action' ? (
                                                <img src={editPenImg} alt='Edit' onClick={handleEditLeave} />
                                            ) : (
                                                row[column.id]
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                    >
                        <MenuItem onClick={() => handleSortOrderChange('asc')}>
                            <img src={ascToDesc} alt="Asc to Desc" />&nbsp;&nbsp;
                            Ascending to Descending
                        </MenuItem>
                        <MenuItem onClick={() => handleSortOrderChange('desc')}>
                            <img src={descToAsc} alt="Desc to Asc" />&nbsp;&nbsp;
                            Descending to Ascending
                        </MenuItem>
                    </Menu>
                </TableContainer>
                {/* Use PaginationComponent */}
                <PaginationComponent
                    totalItems={data.length}
                    itemsPerPage={rowsPerPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            </div>
            {openAddLeave && <AddLeave openAddLeave={openAddLeave} handleCloseAddLeave={handleCloseAddLeave} />}
        </div>
    );
};

export default LeaveBalanceDetails;
