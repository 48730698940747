import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../Buttons/Buttons";
import searchIcon from '../../assets/images/Dashboard/search-icon.svg';
import copyImg from '../../assets/images/copy-img.svg';

const ShareModal = (props) => {

    return (
        <>
            <Modal show={props.open} onHide={props.handleClose} className='add-customer-modal add-user-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Share Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col md={12} className="pb-2">Only people with permission can see this view.</Col>
                    </Row>
                    <Row className='customer-form-fields'>
                        <Col md={12}>
                            <div>
                                <label>Share with people</label>
                                <div className='share-search'>
                                    <Form.Control type="search" placeholder='Search' />
                                    <img src={searchIcon} alt="searchIcon" className="search-icon" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='customer-form-fields'>
                        <Col md={12}>
                            <label>Share using a link</label>
                            <Row className="align-items-center">
                                <Col md={9}>
                                    <Form.Control type="text" placeholder='https://link-to-share.com' className='search-input' />
                                </Col>
                                <Col md={3}>
                                    <Button className='copy-btn'>
                                        <img src={copyImg} alt="copyImg" className='copy-icon' />&nbsp;&nbsp;Copy Link
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseAddLeave} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={props.handleNextStep} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ShareModal;
