import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Menu,
    MenuItem
} from '@mui/material';
import SortIcon from '../../../assets/images/Dashboard/chevron-down.svg';
import ascToDesc from '../../../assets/images/CustomerManagement/ascToDesc.svg';
import descToAsc from '../../../assets/images/CustomerManagement/descToAsc.svg';
import SbActionDropdownButton from '../../Buttons/SbActionDropdownButton';
import StatusBadgeWithDropdown from '../../Buttons/StatusBadgeWithDropdown';
import runImg from '../../../assets/images/Settings/runImg.svg'
import PaginationComponent from '../../PaginationComponent';
import noDataFound from '../../../assets/images/no-data-found.svg'
import Skeleton from 'react-loading-skeleton';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const columns = [
    { id: 'Module', label: 'MODULE NAME', align: 'left', minWidth: '150px' },
    { id: 'WorkQueueRuleName', label: 'RULE NAME', align: 'left', minWidth: '150px' },
    { id: 'WorkQueueDescription', label: 'DESCRIPTION', align: 'left', minWidth: '150px' },
    { id: 'criteria', label: 'CRITERIA', align: 'left', minWidth: '400px' },
    { id: 'Status', label: 'STATUS', align: 'left', minWidth: '80px' },
    { id: 'runQuery', label: 'RUN QUERY', align: 'left', minWidth: '120px' },
    { id: 'action', label: 'ACTION', align: 'left', minWidth: '80px', sortable: false },
];

const SortableHead = SortableContainer(({ children }) => {
    return (
        <TableHead>
            <TableRow>{children}</TableRow>
        </TableHead>
    );
});

const SortableCell = SortableElement(({ value }) => {
    return <TableCell>{value}</TableCell>;
});


const WqRulesTable = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const settingsWorkQueuesRulesData = props.settingsWorkQueuesRulesData || [];

    // Get selected columns (based on `selectedColumns` prop)
    const filteredColumns = columns.filter((column) =>
        props.selectedColumns.includes(column.label)
    );

    const getStoredColumnsOrder = () => {
        const savedOrder = localStorage.getItem('workQueueRulesColumnsOrder');
        return savedOrder ? JSON.parse(savedOrder) : columns.map((col) => col.id);
    };

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [actionAnchorEl, setActionAnchorEl] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [columnsOrder, setColumnsOrder] = useState(getStoredColumnsOrder());

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Set initial rows per page
    const [paginatedData, setPaginatedData] = useState([]);

    const handleRequestSort = (event, property) => {
        setCurrentSortColumn(property);
        setAnchorEl(event.currentTarget);
    };

    const handleSortOrderChange = (order) => {
        setOrder(order);
        setOrderBy(currentSortColumn);
        setAnchorEl(null);
    };

    const handleActionClick = (event, customer) => {
        setSelectedCustomer(customer);
        setActionAnchorEl(event.currentTarget);
    };

    const onReorderEnd = ({ oldIndex, newIndex }) => {
        const newOrder = Array.from(columnsOrder);
        const [moved] = newOrder.splice(oldIndex, 1);
        newOrder.splice(newIndex, 0, moved);
        setColumnsOrder(newOrder);
        localStorage.setItem('workQueueRulesColumnsOrder', JSON.stringify(newOrder)); // Store new order
    };

    const sortedData = React.useMemo(() => {
        const stabilizedData = settingsWorkQueuesRulesData.map((el, index) => [el, index]);
        stabilizedData.sort((a, b) => {
            const orderModifier = order === 'asc' ? 1 : -1;
            if (a[0][orderBy] < b[0][orderBy]) {
                return -1 * orderModifier;
            }
            if (a[0][orderBy] > b[0][orderBy]) {
                return 1 * orderModifier;
            }
            return a[1] - b[1];
        });
        return stabilizedData.map((el) => el[0]);
    }, [settingsWorkQueuesRulesData, order, orderBy]);

    // Pagination logic
    useEffect(() => {
        const start = page * rowsPerPage; // Updated page logic to 0-based
        const end = start + rowsPerPage;
        setPaginatedData(sortedData.slice(start, end));
    }, [sortedData, page, rowsPerPage]);

    const handlePageChange = (newPage) => {
        setPage(newPage - 1); // Handle 1-based to 0-based page index for slicing
    };
    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset to the first page when rows per page changes
    };

    // Function to format the criteria as [{FieldName: "Company", Operator: "=", Value: "Lobotus"}]
    const formatCriteria = (rules) => {
        const formattedRules = rules.map(rule => ({
            FieldName: rule.FieldName,
            Operator: rule.Operator,
            Value: rule.Value
        }));

        // Convert the object to a string representation
        return `[${formattedRules.map(rule =>
            `{FieldName: "${rule.FieldName}", Operator: "${rule.Operator}", Value: "${rule.Value}"}`
        ).join(", ")}]`;
    };

    return (
        <>
            <TableContainer component={Paper} sx={{ maxHeight: 440 }} className='table-container'>
                <Table>
                    {/* <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }} className='table-header-div'>
                                        {column.label}
                                        {column.sortable !== false && (
                                            <IconButton
                                                size="small"
                                                onClick={(event) => handleRequestSort(event, column.id)}
                                            >
                                                <img src={SortIcon} alt="Sort" />
                                            </IconButton>
                                        )}
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead> */}

                    <SortableHead axis="x" onSortEnd={onReorderEnd}>
                        {columnsOrder.map((columnId, index) => {
                            const column = columns.find(col => col.id === columnId);
                            if (!column) return null; // Skip if column doesn't exist

                            // Only display columns that are in filteredColumns
                            if (!filteredColumns.find(col => col.id === columnId)) {
                                return null;
                            }
                            return (
                                <SortableCell key={column.id} index={index} value={
                                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', minWidth: column.minWidth }} className='table-header-div'>
                                        {column.label}
                                        {column.sortable !== false && (
                                            <IconButton
                                                size="small"
                                                onClick={(event) => handleRequestSort(event, column.id)}
                                            >
                                                <img src={SortIcon} alt="Sort" />
                                            </IconButton>
                                        )}
                                    </div>
                                } />
                            );
                        })}
                    </SortableHead>
                    <TableBody>
                        {
                            props.loading ? (
                                [...Array(rowsPerPage)].map((_, index) => (
                                    <TableRow key={index}>
                                        {columns.map((column) => (
                                            <TableCell key={column.id} align={column.align}>
                                                <Skeleton height={40} />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : paginatedData.length === 0 ? (
                                // Display "No Data Found" row if no data is present in the paginated data
                                <TableRow>
                                    <TableCell colSpan={columns.length} align="center">
                                        <img src={noDataFound} alt="" style={{ height: "250px", width: "280px" }} />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                paginatedData.map((customer) => (
                                    <TableRow key={customer.id}>
                                        {columnsOrder.map((columnId) => {
                                            const column = columns.find((col) => col.id === columnId);
                                            if (!column || !filteredColumns.find(col => col.id === columnId)) {
                                                return null; // Skip if column doesn't exist or not selected
                                            }
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {
                                                        column.id === 'criteria' ? (
                                                            // Display the formatted criteria
                                                            formatCriteria(customer.WorkQueueRules)
                                                        ) :
                                                            column.id === 'Status' ? (
                                                                <StatusBadgeWithDropdown
                                                                    customer={customer}
                                                                    activeText="Active"
                                                                    inactiveText="Inactive"
                                                                    onStatusChange={(status) => { props.handleStatusChange(customer, status) }}
                                                                />
                                                            ) : column.id === 'action' ? (
                                                                <SbActionDropdownButton
                                                                    options={[{ type: 'Edit' }]}
                                                                    openAddModal={() => { props.handleOpenModal(customer.WorkQueueID) }}
                                                                    onClick={(event) => handleActionClick(event, customer)}
                                                                />
                                                            ) : column.id === 'runQuery' ? (
                                                                <div className='run-query-img' style={{cursor:"pointer"}} onClick={()=>{props.handleRun(customer.WorkQueueID)}}>
                                                                    <p> Run</p>
                                                                    <img src={runImg} alt='' />
                                                                </div>

                                                            ) : (
                                                                customer[column.id]
                                                            )}
                                                </TableCell>

                                            )
                                        })}
                                    </TableRow>
                                )
                                ))}
                    </TableBody>
                </Table>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem onClick={() => handleSortOrderChange('asc')}>
                        <img src={ascToDesc} alt="" />&nbsp;&nbsp;
                        Ascending to Descending
                    </MenuItem>
                    <MenuItem onClick={() => handleSortOrderChange('desc')}>
                        <img src={descToAsc} alt="" />&nbsp;&nbsp;
                        Descending to Ascending
                    </MenuItem>
                </Menu>
            </TableContainer>
            {/* Use PaginationComponent */}
            <PaginationComponent
                totalItems={settingsWorkQueuesRulesData.length}
                itemsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </>
    );
};

export default WqRulesTable;
