import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../Buttons/Buttons";
import DatePicker from "react-datepicker";
import calendar from '../../assets/images/Dashboard/calendar-icon.svg'
import Select from 'react-select';
import downloadImg from '../../assets/images/Dashboard/downloadImg.svg'

const UpdatedViewDetails = (props) => {

    return (
        <>
            <Modal show={props.open} onHide={props.handleClose} className='add-customer-modal add-user-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Updated View Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>SLA Type</label>
                                        <span>Customer</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Company Name</label>
                                        <span>Excomatix</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Checklist</label>
                                        <span>Cost Avoidance</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Value Type</label>
                                        <span>US$</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Baseline</label>
                                        <span>$100,000,000</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Targe%</label>
                                        <span>20%</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Target Value</label>
                                        <span>$40,00,000</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4} className="d-flex justify-content-start align-items-center">
                                    <div className="dropdown add-single-bulk attachment-section">
                                        {/* <label>&nbsp;</label> */}
                                        <button
                                            className="download-document-btn"
                                            id="adduser-single-blulk"
                                        >
                                            <div className="ps-2">
                                                <span>File Name</span>
                                                <div className="attachment-note">
                                                    <small>(5MB)</small>
                                                </div>
                                            </div>
                                            <img src={downloadImg} alt="" className="download-img" />
                                        </button>
                                        {/* {selectedFile && <div className="attachment-note"><small>{selectedFile.name}</small></div>} */}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} className="view-sla-data">
                                    <div className='add-customer-calendar-container'>
                                        <label>Start Date</label>
                                        <span>06-09-2024</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4} className="view-sla-data">
                                    <div className='add-customer-calendar-container'>
                                        <label>End Date</label>
                                        <span>06-09-2024</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Assigned To</label>
                                        <span>Linu</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Actual Value</label>
                                        <span>$10,00,000</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className='add-customer-calendar-container'>
                                        <label>Report Date</label>
                                        <span>26-09-2024</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Achieved %</label>
                                        <span>50%</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Department</label>
                                        <span>Procurement</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Assigned Score</label>
                                        <span>3.0</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Status</label>
                                        <span>Complete</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="view-sla-data">
                                        <label>Comments</label>
                                        <span className="description-text">CommentsCommentsCommentsCommentsCommentsCommentsCommentsCommentsCommentsCommentsCommentsCommentsCommentsCommentsCommentsCommentsCommentsComments</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Modal.Body >
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
                    <SBSaveUpdateBtn btnName="Ok" onClickEffect={props.handleNextStep} />
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default UpdatedViewDetails;