import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, styled } from "@mui/material";
import * as HeaderAuthentication from '../../HeaderAuthentication'
import { postDepartmentDatas, postModulesDatas } from "../../../redux/action";
import { useDispatch } from "react-redux";
import { ADDED_BY_ID } from "../../Constants";

const enablingOptions = [
    { value: "1", label: "Active" },
    { value: "2", label: "Inactive" }
]

const AddModules = (props) => {
    let dispatch = useDispatch();
    const modulesData = props.modulesData;
    const singleModuleData = props.singleModuleData;
    const moduleId = props.moduleId;

    const [moduleName, setModuleName] = useState("");
    const [selectedOption, setSelectedOption] = useState(null);

    const handleModuleNameChange = (e) => {
        setModuleName(e.target.value);
    };

    const handleSelectChange = (option) => {
        setSelectedOption(option);
    };

    useEffect(() => {
        if (moduleId && singleModuleData) {
            setModuleName(singleModuleData[0]?.Module);
            console.log(singleModuleData[0]?.moduleName)
            const statusOption = enablingOptions.find(option => option.label === singleModuleData[0]?.Status);
            setSelectedOption(statusOption || null);
        } else {
            // Reset fields for adding new classification
            setModuleName("");
            setSelectedOption(null);
        }
    }, [moduleId, singleModuleData]);

    const AddModulesData = (e) => {
        e.preventDefault();
        const data = {
            Modules_Name: moduleName,
            ModulesID: moduleId,
            StatusID: parseInt(selectedOption?.value),
            AddedByID: ADDED_BY_ID

        }
        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data),
        };
        dispatch(postModulesDatas(requestOption, props.handleCloseModal, modulesData, moduleId));
    }

    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Modules</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Module Name</label>
                                        <Form.Control
                                            type="text"
                                            value={moduleName}
                                            onChange={handleModuleNameChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {moduleId && <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Enable / Disable</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={enablingOptions}
                                            placeholder="Select"
                                            value={selectedOption}
                                            onChange={handleSelectChange}
                                        />
                                    </div>
                                </Col>
                            </Row>}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={AddModulesData} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddModules;
