// import React, { useState } from 'react';
// import ReactECharts from 'echarts-for-react';
// import { Dropdown } from 'react-bootstrap';

// // Example options for dropdowns
// const companyOptions = [
//     { value: 'company-a', label: 'Company A' },
//     { value: 'company-b', label: 'Company B' },
//     { value: 'company-c', label: 'Company C' },
// ];

// const LicenceSpliteUpChart = () => {
//     const [selectedCompany, setSelectedCompany] = useState(companyOptions[0]);

//     const handleCompanySelect = (company) => {
//         setSelectedCompany(company);
//     };

//     const option = {
//         title: {
//             text: 'Licence Split Up',
//             left: 'left',
//             textStyle: {
//                 fontSize: 16,
//             },
//         },
//         tooltip: {
//             trigger: 'axis',
//             axisPointer: {
//                 type: 'shadow',
//             },
//             formatter: (params) => {
//                 let tooltipContent = `${params[0].name}<br/>`;
//                 params.forEach((item) => {
//                     tooltipContent += `${item.marker} ${item.seriesName}: ${item.value}<br/>`;
//                 });
//                 return tooltipContent;
//             },
//         },
//         legend: {
//             data: ['Total Licence', 'Licence Assigned', 'Available Licence'],
//             top: 20,
//         },
//         grid: {
//             left: '3%',
//             right: '4%',
//             bottom: '3%',
//             containLabel: true,
//         },
//         xAxis: {
//             type: 'category',
//             data: ['Branch 1', 'Branch 2', 'Branch 3', 'Branch 4'],
//         },
//         yAxis: {
//             type: 'value',
//         },
//         series: [
//             {
//                 name: 'Total Licence',
//                 type: 'bar',
//                 data: [10, 12, 9, 14],
//                 itemStyle: {
//                     color: '#000000',
//                 },
//             },
//             {
//                 name: 'Licence Assigned',
//                 type: 'bar',
//                 data: [7, 8, 5, 10],
//                 itemStyle: {
//                     color: '#00FF00',
//                 },
//             },
//             {
//                 name: 'Available Licence',
//                 type: 'bar',
//                 data: [3, 4, 4, 4],
//                 itemStyle: {
//                     color: '#FF0000',
//                 },
//             },
//         ],
//     };

//     return (
//         <div style={{ border: '2px solid #007bff', borderRadius: '8px', padding: '15px', background: '#fff' }}>
//             <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
//                 <h6 style={{ margin: 0 }}>Licence Split Up</h6>
//                 <Dropdown>
//                     <Dropdown.Toggle id="dropdown-company">
//                         {selectedCompany.label}
//                     </Dropdown.Toggle>
//                     <Dropdown.Menu>
//                         {companyOptions.map(option => (
//                             <Dropdown.Item
//                                 key={option.value}
//                                 onClick={() => handleCompanySelect(option)}
//                                 active={selectedCompany.value === option.value}
//                             >
//                                 {option.label}
//                             </Dropdown.Item>
//                         ))}
//                     </Dropdown.Menu>
//                 </Dropdown>
//             </div>
//             <ReactECharts option={option} style={{ height: 250 }} />
//         </div>
//     );
// };

// export default LicenceSpliteUpChart;


import React, { useState, useRef, useEffect } from 'react';
import { Chart, BarElement, BarController, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { Dropdown } from 'react-bootstrap';

Chart.register(BarElement, BarController, CategoryScale, LinearScale, Tooltip, Legend);

// Example options for dropdowns
const companyOptions = [
    { value: 'company-a', label: 'Company A' },
    { value: 'company-b', label: 'Company B' },
    { value: 'company-c', label: 'Company C' },
];

const LicenceSpliteUpChart = () => {
    const [selectedCompany, setSelectedCompany] = useState(companyOptions[0]);
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    const handleCompanySelect = (company) => {
        setSelectedCompany(company);
    };

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');

        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        chartInstance.current = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ['Branch 1', 'Branch 2', 'Branch 3', 'Branch 4'],
                datasets: [
                    {
                        label: 'Total Licence',
                        data: [10, 12, 9, 14],
                        backgroundColor: '#000000',
                        barThickness: 6,
                        borderRadius: 5,
                        borderSkipped: false,
                    },
                    {
                        label: 'Licence Assigned',
                        data: [7, 8, 5, 10],
                        backgroundColor: '#00FF00',
                        barThickness: 6,
                        borderRadius: 5,
                        borderSkipped: false,
                    },
                    {
                        label: 'Available Licence',
                        data: [3, 4, 4, 4],
                        backgroundColor: '#FF0000',
                        barThickness: 6,
                        borderRadius: 5,
                        borderSkipped: false,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        stacked: false,
                        grid: {
                            display: false, // Hide vertical grid lines
                        },
                    },
                    y: {
                        beginAtZero: true,
                        grid: {
                            color: 'rgba(246, 246, 246, 1)', // Optional: Customize horizontal grid line color
                        },
                    },
                },
                plugins: {
                    legend: {
                        position: 'top',
                        onClick: (e, legendItem, legend) => {
                            // No action to hide the dataset when clicking the legend
                            return;
                        },
                    },
                    tooltip: {
                        callbacks: {
                            label: function (tooltipItem) {
                                return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
                            },
                        },
                    },
                },
            },
        });

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [selectedCompany]);

    return (
        <div className='barchart-container'>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <h6 style={{ margin: 0 }}>Licence Split Up</h6>
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-company">
                        {selectedCompany.label}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {companyOptions.map(option => (
                            <Dropdown.Item
                                key={option.value}
                                onClick={() => handleCompanySelect(option)}
                                active={selectedCompany.value === option.value}
                            >
                                {option.label}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className='barchart-canvas-container'>
                <canvas ref={chartRef} />
            </div>
        </div>
    );
};

export default LicenceSpliteUpChart;
