import React, { useRef, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import eyeClose from '../../../assets/images/Login/eye-close.svg';
import eyeOpen from '../../../assets/images/Login/eye-open.svg';

const SetPasswordModal = (props) => {
    const [oldPasswordType, setOldPasswordType] = useState("password");
    const [newPasswordType, setNewPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [showOtpInput, setShowOtpInput] = useState(false); // State to toggle between password and OTP views

    const [otp, setOtp] = useState(new Array(6).fill(''));
    const inputRefs = useRef(new Array(6));

    const togglePassword = (field) => {
        switch (field) {
            case 'old':
                setOldPasswordType(oldPasswordType === "password" ? "text" : "password");
                break;
            case 'new':
                setNewPasswordType(newPasswordType === "password" ? "text" : "password");
                break;
            case 'confirm':
                setConfirmPasswordType(confirmPasswordType === "password" ? "text" : "password");
                break;
            default:
                break;
        }
    };

    const handleGetOtpClick = () => {
        setShowOtpInput(true); // Show OTP input when Get OTP is clicked
    };

    const handleOtpChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value.length === 1 && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && otp[index] === '') {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    return (
        <Modal show={props.open} onHide={props.handleCloseModal} className='save-custom-view-modal'>
            <Modal.Header closeButton>
                <Modal.Title>Set Password</Modal.Title>
            </Modal.Header>
            <Modal.Body className={!showOtpInput ? '' : 'set-password-container'}>
                {!showOtpInput ? (
                    <>
                        <Row className='customer-view-form-fields'>
                            <Col md={4} className='user-profile-form-col'>
                                <Form.Group controlId="formOldPassword" className="profile-password-container">
                                    <Form.Label>Enter Old Password</Form.Label>
                                    <Form.Control type={oldPasswordType} placeholder="123456" />
                                    <div className="pass-hide-show" onClick={() => togglePassword('old')}>
                                        {oldPasswordType === "password" ? (
                                            <img src={eyeClose} alt="Show Password" />
                                        ) : (
                                            <img src={eyeOpen} alt="Hide Password" />
                                        )}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='customer-view-form-fields mt-2'>
                            <Col md={4} className='user-profile-form-col'>
                                <Form.Group controlId="formNewPassword" className="profile-password-container">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control type={newPasswordType} placeholder="123456" />
                                    <div className="pass-hide-show" onClick={() => togglePassword('new')}>
                                        {newPasswordType === "password" ? (
                                            <img src={eyeClose} alt="Show Password" />
                                        ) : (
                                            <img src={eyeOpen} alt="Hide Password" />
                                        )}
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md={4} className='user-profile-form-col'>
                                <Form.Group controlId="formConfirmPassword" className="profile-password-container">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control type={confirmPasswordType} placeholder="123456" />
                                    <div className="pass-hide-show" onClick={() => togglePassword('confirm')}>
                                        {confirmPasswordType === "password" ? (
                                            <img src={eyeClose} alt="Show Password" />
                                        ) : (
                                            <img src={eyeOpen} alt="Hide Password" />
                                        )}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <>
                        <p className="profile-otp-form-text">Enter your One-Time Password to verify your phone number</p>
                        <Form className='profile-otp-form'>
                            <div className='otp-input-box'>
                                {otp.map((value, index) => (
                                    <Form.Control
                                        key={index}
                                        type="password"
                                        value={value}
                                        maxLength={1}
                                        className="form-control-profile-otp-input"
                                        ref={(input) => (inputRefs.current[index] = input)}
                                        onChange={(e) => handleOtpChange(index, e.target.value)}
                                        onKeyDown={(e) => handleKeyDown(index, e)}
                                    />
                                ))}
                            </div>
                        </Form>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                {!showOtpInput ? (
                    <SBSaveUpdateBtn btnName="Get OTP" onClickEffect={handleGetOtpClick} />
                ) : (
                    <SBSaveUpdateBtn btnName="Submit" />
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default SetPasswordModal;
