import { Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../Buttons/Buttons";
import customerImg from '../../assets/images/CustomerManagement/customer-img.svg'

const options = [
    { label: "Functional Approval", value: "functional_approval" },
    { label: "Financial Approval", value: "financial_approval" },
    { label: "Complaints Approval", value: "complaints_approval" },
    { label: "Escalation Approval", value: "escalation_approval" },
    { label: "Leave Approval", value: "leave_approval" },
];


const ViewUserModal = (props) => {

    const singleUserData = props.singleUserData;

    const renderTextWithTooltip = (text) => {
        // Check if the text length exceeds 24 characters
        if (text && text.length > 24) {
            return (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{text}</Tooltip>}
                >
                    <span>{text.substring(0, 24)}...</span>
                </OverlayTrigger>
            );
        }
        return <span>{text}</span>;
    };
    return (
        <>
            <Modal show={props.viewModal} onHide={props.handleCloseViewModal} className='add-customer-modal add-user-modal' >
                <Modal.Header closeButton>
                    <Modal.Title>View User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col className='customer-form-fields-image-div'>
                            <img src={customerImg} alt="" />
                            <input type="file" hidden />
                            <p>Upload Image</p>
                        </Col>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>First Name</label>
                                        <span>{renderTextWithTooltip(singleUserData[0]?.FirstName)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Last Name</label>
                                        <span>{renderTextWithTooltip(singleUserData[0]?.LastName)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Email ID</label>
                                        <span>{renderTextWithTooltip(singleUserData[0]?.EmailID)}</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>

                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Contact Number</label>
                                        <div className='customer-contact-fields-div'>
                                            {/* <span>{(singleUserData[0].LastName)}</span> */}
                                            <span>{renderTextWithTooltip(singleUserData[0]?.Phone)}</span>
                                        </div>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Work Address</label>
                                        <span>{renderTextWithTooltip(singleUserData[0]?.WorkAddress)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Zip Code</label>
                                        <span>{renderTextWithTooltip(singleUserData[0]?.ZipCode)}</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Date of Birth</label>
                                        <span>{renderTextWithTooltip(singleUserData[0]?.DOB)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Emergency Contact</label>
                                        <div className='customer-contact-fields-div'>
                                            {/* <span>+91</span> */}
                                            <span>{renderTextWithTooltip(singleUserData[0]?.SecondaryPhone)}</span>
                                        </div>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Job Title</label>
                                        <span>{renderTextWithTooltip(singleUserData[0]?.JobTitle)}</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="view-sla-data">
                                        <label>Roles</label>
                                        <div>
                                            {/* Join roles in a single line, separated by commas */}
                                            {singleUserData[0]?.UserRoles && singleUserData[0]?.UserRoles.length > 0 ? (
                                                <span>
                                                    {singleUserData[0]?.UserRoles.map((role, index) => role.RoleName).join(', ')}
                                                </span>
                                            ) : (
                                                <span>No roles assigned</span>
                                            )}
                                        </div>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Department</label>
                                        <span>{renderTextWithTooltip(singleUserData[0]?.DepartmentName)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Joining Date</label>
                                        <span>{renderTextWithTooltip(singleUserData[0]?.DOJ)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Total Experience DOJ(Years)</label>
                                        <span>{renderTextWithTooltip(singleUserData[0]?.TotalExperience)}</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Probation Period (Months)</label>
                                        <span>{renderTextWithTooltip(singleUserData[0]?.ProbationPeriod)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Home Address</label>
                                        <span>{renderTextWithTooltip(singleUserData[0]?.HomeAddress)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Status</label>
                                        <span>{renderTextWithTooltip(singleUserData[0]?.Status)}</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Modal.Body >
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseViewModal} />
                    <SBSaveUpdateBtn btnName="Ok" onClickEffect={props.handleNextStep} />
                </Modal.Footer>
            </Modal >

            <Modal show={props.viewModal} onHide={props.handleCloseViewModal} className="add-user-submodal" >
                <Modal.Header closeButton>
                    <Modal.Title>Set Approval</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {options.map(option => (
                        <div key={option.value} className="customer-form-fields align-items-start">
                            <div className="view-sla-data">
                                <label>{option.label} Level</label><br/>
                                <span style={{fontSize:"0.8rem"}}>{option.label}</span>
                                <hr />
                            </div>
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ViewUserModal;