import React, { useEffect, useState } from 'react'
import Main from '../Main'
import { Col, Row } from 'react-bootstrap'
import '../../assets/css/dashboard.scss'
import totalComplaints from '../../assets/images/Complaint&Escalations/totalComplaints.svg'
import totalEscalations from '../../assets/images/Complaint&Escalations/totalEscalations.svg'
import totalPending from '../../assets/images/Complaint&Escalations/totalPending.svg'
import totalInProcess from '../../assets/images/Complaint&Escalations/totalInProcess.svg'
import totalResolved from '../../assets/images/Complaint&Escalations/totalResolved.svg'
import Download from '../MainSection/Download/Download'
import RelationshipTypeChart from '../Graphs/RelationshipTypeChart'
import FunnelChart from '../Graphs/FunnelChart'
import AttendanceStatusChart from '../Graphs/AttendanceStatusChart'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { loadCompanyDropdownData, loadComplaintEscalationBarChartTicketData, loadComplaintEscalationBranchStatusData, loadComplaintEscalationDashboardCardData, loadComplaintEscalationPriorityStatusData, loadComplaintEscalationStatusData } from '../../redux/action'

function ComplaintEscalationDashboard() {

    const signInDetails = JSON.parse(sessionStorage.getItem('SignInDetails'));
    const storedCompanyId = signInDetails ? signInDetails[0].CompanyID : null
    const storedCompanyName = signInDetails ? signInDetails[0].CompanyName : null

    const labels = ['High', 'Medium', 'Low'];
    const colors = [
        'rgba(255, 94, 94, 1)',  // High
        'rgba(255, 159, 0, 1)',  // Medium
        'rgba(58, 201, 119, 1)', // Low
    ];
    const data = [25, 20, 13];

    let dispatch = useDispatch();

    const { complaintEscalationDashboardCardData } = useSelector((state) => state.data);
    const { getCompanyDropdown } = useSelector((state) => state.data);
    const { complaintEscalationBarChartTicketData } = useSelector((state) => state.data);
    const { complaintEscalationStatusData } = useSelector((state) => state.data);
    const { complaintEscalationBranchStatusData } = useSelector((state) => state.data);

    const currentYear = new Date().getFullYear();

    const [selectedOption, setSelectedOption] = useState({
        ticketType: 'Complaint',
        // company: storedCompanyId || 'Company Name',
        company: { id: storedCompanyId, name: storedCompanyName },
        year: currentYear || 'Year'
    });

    // const handleSelect = (eventKey, type) => {
    //     setSelectedOption(prev => ({ ...prev, [type]: parseInt(eventKey) }));
    // };

    // const handleSelect = (eventKey, type) => {
    //     if (type === 'company') {
    //         const selectedCompany = getCompanyDropdown.find(company => company.value === eventKey);
    //         setSelectedOption(prev => ({ ...prev, company: { id: selectedCompany.value, name: selectedCompany.label } }));
    //     } else if (type === 'ticketType') {
    //         // Handling ticket type selection
    //         setSelectedOption(prev => ({ ...prev, ticketType: eventKey }));
    //     } else {
    //         setSelectedOption(prev => ({ ...prev, [type]: parseInt(eventKey) }));
    //     }
    // };


    // States for different graphs' selections
    const [selectedOptionForTicketDetails, setSelectedOptionForTicketDetails] = useState({
        ticketType: 'Complaint',
        company: { id: storedCompanyId, name: storedCompanyName } || { id: getCompanyDropdown.value, name: getCompanyDropdown.label },
        year: currentYear || 'Year'
    });

    const [selectedOptionForStatus, setSelectedOptionForStatus] = useState({
        ticketType: 'Complaint',
        company: { id: storedCompanyId, name: storedCompanyName },
        year: currentYear || 'Year'
    });

    const [selectedOptionForBranch, setSelectedOptionForBranch] = useState({
        company: { id: storedCompanyId, name: storedCompanyName },
        year: currentYear || 'Year'
    });

    // const handleSelect = (eventKey, type, graphType) => {
    //     if (graphType === 'TicketDetails') {
    //         setSelectedOptionForTicketDetails(prev => ({ ...prev, [type]: eventKey }));
    //     } else if (graphType === 'Status') {
    //         setSelectedOptionForStatus(prev => ({ ...prev, [type]: eventKey }));
    //     } else if (graphType === 'Branch') {
    //         setSelectedOptionForBranch(prev => ({ ...prev, [type]: eventKey }));
    //     }
    // };

    const handleSelect = (eventKey, type, graphType) => {
        if (graphType === 'TicketDetails') {
            if (type === 'company') {
                const selectedCompany = getCompanyDropdown.find(company => company.value === Number(eventKey));  // Convert eventKey to a number for comparison

                if (selectedCompany) {
                    setSelectedOptionForTicketDetails(prev => ({
                        ...prev,
                        company: { id: selectedCompany.value, name: selectedCompany.label }
                    }));
                } else {
                    console.log('No company found matching the eventKey');
                }
            } else {
                setSelectedOptionForTicketDetails(prev => ({ ...prev, [type]: eventKey }));
            }
        } else if (graphType === 'Status') {
            if (type === 'company') {
                const selectedCompany = getCompanyDropdown.find(company => company.value === Number(eventKey));  // Convert eventKey to a number for comparison

                if (selectedCompany) {
                    setSelectedOptionForStatus(prev => ({
                        ...prev,
                        company: { id: selectedCompany.value, name: selectedCompany.label }
                    }));
                } else {
                    console.log('No company found matching the eventKey');
                }
            } else {
                setSelectedOptionForStatus(prev => ({ ...prev, [type]: eventKey }));
            }
        } else if (graphType === 'Branch') {
            if (type === 'company') {
                const selectedCompany = getCompanyDropdown.find(company => company.value === Number(eventKey));  // Convert eventKey to a number for comparison

                if (selectedCompany) {
                    setSelectedOptionForBranch(prev => ({
                        ...prev,
                        company: { id: selectedCompany.value, name: selectedCompany.label }
                    }));
                } else {
                    console.log('No company found matching the eventKey');
                }
            } else {
                setSelectedOptionForBranch(prev => ({ ...prev, [type]: eventKey }));
            }
        }
    };

    useEffect(() => {
        if (selectedOptionForTicketDetails.company && selectedOptionForTicketDetails.year) {
            // Dispatch action with selectedOption (including companyId and year)
            dispatch(loadComplaintEscalationBarChartTicketData(selectedOptionForTicketDetails.company.id, selectedOptionForTicketDetails.year));
        }
    }, [selectedOptionForTicketDetails, dispatch]);

    useEffect(() => {
        if (selectedOptionForStatus.company && selectedOptionForStatus.year && selectedOptionForStatus.ticketType) {
            // Dispatch action with selectedOption (including companyId and year)
            dispatch(loadComplaintEscalationStatusData(selectedOptionForStatus.company.id, selectedOptionForStatus.year, selectedOptionForStatus.ticketType));
        }
    }, [selectedOptionForStatus, dispatch]);
    useEffect(() => {
        if (selectedOptionForBranch.company && selectedOptionForBranch.year) {
            // Dispatch action with selectedOption (including companyId and year)
            dispatch(loadComplaintEscalationBranchStatusData(selectedOptionForBranch.company.id, selectedOptionForBranch.year));
        }
    }, [selectedOptionForBranch, dispatch]);

    // Function to generate an array of years from 2020 to current year
    function generateYearRange(startYear) {
        const currentYear = new Date().getFullYear();  // Get current year dynamically
        const years = [];

        // Generate years from startYear to currentYear
        for (let year = startYear; year <= currentYear; year++) {
            years.push({ value: year, label: year });
        }

        return years;
    }

    const ComplaintLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const BranchLabels = ['Branch1', 'Branch2', 'Branch3', 'Branch4'];
    const datasets = [
        {
            type: 'bar',
            label: 'Pending',
            // data: [30, 40, 30, 40, 30, 40, 30, 40, 30, 40, 30, 40],
            data: [
                complaintEscalationStatusData?.JanPending,
                complaintEscalationStatusData?.FebPending,
                complaintEscalationStatusData?.MarPending,
                complaintEscalationStatusData?.AprPending,
                complaintEscalationStatusData?.MayPending,
                complaintEscalationStatusData?.JunPending,
                complaintEscalationStatusData?.JulPending,
                complaintEscalationStatusData?.AugPending,
                complaintEscalationStatusData?.SepPending,
                complaintEscalationStatusData?.OctPending,
                complaintEscalationStatusData?.NovPending,
                complaintEscalationStatusData?.DecPending,
            ],
            backgroundColor: 'rgba(240, 207, 43, 1)', // Yellow
            borderColor: 'rgba(240, 207, 43, 1)',
            borderWidth: 1,
            borderRadius: 4,
            barThickness: 8,
            borderSkipped: false,
        },
        {
            type: 'bar',
            label: 'Inprogress',
            // data: [20, 30, 20, 30, 20, 30, 20, 30, 20, 30, 20, 30],
            data: [
                complaintEscalationStatusData?.JanInProgress,
                complaintEscalationStatusData?.FebInProgress,
                complaintEscalationStatusData?.MarInProgress,
                complaintEscalationStatusData?.AprInProgress,
                complaintEscalationStatusData?.MayInProgress,
                complaintEscalationStatusData?.JunInProgress,
                complaintEscalationStatusData?.JulInProgress,
                complaintEscalationStatusData?.AugInProgress,
                complaintEscalationStatusData?.SepInProgress,
                complaintEscalationStatusData?.OctInProgress,
                complaintEscalationStatusData?.NovInProgress,
                complaintEscalationStatusData?.DecInProgress,
            ],
            backgroundColor: 'rgba(255, 94, 0, 1)', // Orange
            borderColor: 'rgba(255, 94, 0, 1)',
            borderWidth: 1,
            borderRadius: 4,
            barThickness: 8,
            borderSkipped: false,
        },
        {
            type: 'line',
            label: 'Resolved',
            // data: [10, 30, 20, 30, 25, 30, 20, 25, 30, 26, 25, 30],
            data: [
                complaintEscalationStatusData?.JanResolved,
                complaintEscalationStatusData?.FebResolved,
                complaintEscalationStatusData?.MarResolved,
                complaintEscalationStatusData?.AprResolved,
                complaintEscalationStatusData?.MayResolved,
                complaintEscalationStatusData?.JunResolved,
                complaintEscalationStatusData?.JulResolved,
                complaintEscalationStatusData?.AugResolved,
                complaintEscalationStatusData?.SepResolved,
                complaintEscalationStatusData?.OctResolved,
                complaintEscalationStatusData?.NovResolved,
                complaintEscalationStatusData?.DecResolved,
            ],
            backgroundColor: (context) => {
                const chart = context.chart;
                const ctx = chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
                gradient.addColorStop(0, 'rgba(58, 201, 119, 0.2)');
                gradient.addColorStop(1, 'rgba(58, 201, 119, 0)');
                return gradient;
            },
            borderColor: 'rgba(58, 201, 119, 1)',
            borderWidth: 2,
            fill: true,
            tension: 0.4,
        },
        {
            type: 'line',
            label: 'Reopen',
            // data: [15, 35, 28, 35, 29, 33, 28, 29, 35, 20, 30, 35],
            data: [
                complaintEscalationStatusData?.JanReOpened,
                complaintEscalationStatusData?.FebReOpened,
                complaintEscalationStatusData?.MarReOpened,
                complaintEscalationStatusData?.AprReOpened,
                complaintEscalationStatusData?.MayReOpened,
                complaintEscalationStatusData?.JunReOpened,
                complaintEscalationStatusData?.JulReOpened,
                complaintEscalationStatusData?.AugReOpened,
                complaintEscalationStatusData?.SepReOpened,
                complaintEscalationStatusData?.OctReOpened,
                complaintEscalationStatusData?.NovReOpened,
                complaintEscalationStatusData?.DecReOpened,
            ],
            backgroundColor: (context) => {
                const chart = context.chart;
                const ctx = chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
                gradient.addColorStop(0, 'rgba(21, 104, 225, 0.2)');
                gradient.addColorStop(1, 'rgba(21, 104, 225, 0)');
                return gradient;
            },
            borderColor: 'rgba(21, 104, 225, 1)',
            borderWidth: 2,
            fill: true,
            tension: 0.4,
        },
        {
            type: 'line',
            label: 'Cancel',
            // data: [10, 15, 25, 15, 20, 13, 21, 15, 25, 20, 24, 30],
            data: [
                complaintEscalationStatusData?.JanCancelled,
                complaintEscalationStatusData?.FebCancelled,
                complaintEscalationStatusData?.MarCancelled,
                complaintEscalationStatusData?.AprCancelled,
                complaintEscalationStatusData?.MayCancelled,
                complaintEscalationStatusData?.JunCancelled,
                complaintEscalationStatusData?.JulCancelled,
                complaintEscalationStatusData?.AugCancelled,
                complaintEscalationStatusData?.SepCancelled,
                complaintEscalationStatusData?.OctCancelled,
                complaintEscalationStatusData?.NovCancelled,
                complaintEscalationStatusData?.DecCancelled,
            ],
            borderColor: 'rgba(255, 94, 94, 1)', // Red
            borderWidth: 2,
            borderDash: [5, 5], // Dotted line style
            tension: 0.4,
        },
    ];


    const [datasetsOfBranch, setDatasetsOfBranch] = useState([]);
    const [branchLabels, setBranchLabels] = useState([]);
    // Process the data when it's fetched from the API
    useEffect(() => {
        if (complaintEscalationBranchStatusData) {
            // Extract Branch names
            const labels = complaintEscalationBranchStatusData.map(branch => branch.BranchName);
            setBranchLabels(labels);  // Set the branch names as labels
            // Transform API data into the format for the chart datasets
            const transformedData = complaintEscalationBranchStatusData.map((branch) => {
                return {
                    BranchName: branch.BranchName,
                    Pending: branch.PendingCount,
                    InProgress: branch.InProcessCount,
                    Resolved: branch.ResolvedCount,
                    Reopened: branch.ReOpenedCount,
                    Cancelled: branch.CancelledCount,
                };
            });

            // Prepare datasets based on transformed data
            const updatedDatasets = [
                {
                    type: 'bar',
                    label: 'Pending',
                    data: transformedData.map((item) => item.Pending),
                    backgroundColor: 'rgba(240, 207, 43, 1)', // Yellow
                    borderColor: 'rgba(240, 207, 43, 1)',
                    borderWidth: 1,
                    borderRadius: 4,
                    barThickness: 8,
                    borderSkipped: false,
                },
                {
                    type: 'bar',
                    label: 'In Progress',
                    data: transformedData.map((item) => item.InProgress),
                    backgroundColor: 'rgba(255, 94, 0, 1)', // Orange
                    borderColor: 'rgba(255, 94, 0, 1)',
                    borderWidth: 1,
                    borderRadius: 4,
                    barThickness: 8,
                    borderSkipped: false,
                },
                {
                    type: 'line',
                    label: 'Resolved',
                    data: transformedData.map((item) => item.Resolved),
                    backgroundColor: (context) => {
                        const chart = context.chart;
                        const ctx = chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
                        gradient.addColorStop(0, 'rgba(58, 201, 119, 0.2)');
                        gradient.addColorStop(1, 'rgba(58, 201, 119, 0)');
                        return gradient;
                    },
                    borderColor: 'rgba(58, 201, 119, 1)',
                    borderWidth: 2,
                    fill: true,
                    tension: 0.4,
                },
                {
                    type: 'line',
                    label: 'Reopened',
                    data: transformedData.map((item) => item.Reopened),
                    backgroundColor: (context) => {
                        const chart = context.chart;
                        const ctx = chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
                        gradient.addColorStop(0, 'rgba(21, 104, 225, 0.2)');
                        gradient.addColorStop(1, 'rgba(21, 104, 225, 0)');
                        return gradient;
                    },
                    borderColor: 'rgba(21, 104, 225, 1)',
                    borderWidth: 2,
                    fill: true,
                    tension: 0.4,
                },
                {
                    type: 'line',
                    label: 'Cancelled',
                    data: transformedData.map((item) => item.Cancelled),
                    borderColor: 'rgba(255, 94, 94, 1)', // Red
                    borderWidth: 2,
                    borderDash: [5, 5], // Dotted line style
                    tension: 0.4,
                },
            ];

            setDatasetsOfBranch(updatedDatasets);
        }
    }, [complaintEscalationBranchStatusData]);



    // const ticketDetailsDatasets = [
    //     {
    //         type: 'bar',
    //         label: 'Complaints',
    //         data: [100, 80, 100, 80, 100, 80, 100, 80, 100, 80, 100, 80],
    //         backgroundColor: 'rgba(154, 56, 56, 1)', // Dark Red
    //         borderColor: 'rgba(154, 56, 56, 1)',
    //         borderWidth: 1,
    //         borderRadius: 4,
    //         barThickness: 8,
    //         borderSkipped: false,
    //     },
    //     {
    //         type: 'bar',
    //         label: 'Escalations',
    //         data: [60, 40, 50, 60, 30, 40, 50, 45, 55, 50, 60, 60],
    //         backgroundColor: 'rgba(255, 94, 94, 1)', // Bright Red
    //         borderColor: 'rgba(255, 94, 94, 1)',
    //         borderWidth: 1,
    //         borderRadius: 4,
    //         barThickness: 8,
    //         borderSkipped: false,
    //     }
    // ];

    const mapApiDataToChartData = (data) => {
        return [
            {
                type: 'bar',
                label: 'Complaints',
                data: [
                    data.JanNoOfComplaints,
                    data.FebNoOfComplaints,
                    data.MarNoOfComplaints,
                    data.AprNoOfComplaints,
                    data.MayNoOfComplaints,
                    data.JunNoOfComplaints,
                    data.JulNoOfComplaints,
                    data.AugNoOfComplaints,
                    data.SepNoOfComplaints,
                    data.OctNoOfComplaints,
                    data.NovNoOfComplaints,
                    data.DecNoOfComplaints
                ],
                backgroundColor: 'rgba(154, 56, 56, 1)', // Dark Red
                borderColor: 'rgba(154, 56, 56, 1)',
                borderWidth: 1,
                borderRadius: 4,
                barThickness: 8,
                borderSkipped: false,
            },
            {
                type: 'bar',
                label: 'Escalations',
                data: [
                    data.JanNoOfEscalations,
                    data.FebNoOfEscalations,
                    data.MarNoOfEscalations,
                    data.AprNoOfEscalations,
                    data.MayNoOfEscalations,
                    data.JunNoOfEscalations,
                    data.JulNoOfEscalations,
                    data.AugNoOfEscalations,
                    data.SepNoOfEscalations,
                    data.OctNoOfEscalations,
                    data.NovNoOfEscalations,
                    data.DecNoOfEscalations
                ],
                backgroundColor: 'rgba(255, 94, 94, 1)', // Bright Red
                borderColor: 'rgba(255, 94, 94, 1)',
                borderWidth: 1,
                borderRadius: 4,
                barThickness: 8,
                borderSkipped: false,
            }
        ];
    };

    // Assuming complaintEscalationBarChartTicketData is an array with one object
    const chartData = complaintEscalationBarChartTicketData.length > 0 ? mapApiDataToChartData(complaintEscalationBarChartTicketData[0]) : [];

    const chartOptions = {
        responsive: true,
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += `${context.parsed.y}`;
                        }
                        return label;
                    }
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    stepSize: 25,
                    callback: function (value) {
                        if (value === 0 || value === 25 || value === 50 || value === 75 || value === 100) {
                            return value;
                        }
                    }
                }
            },
            y: {
                beginAtZero: true
            }
        }
    };
    const dropdownOptions = {
        // ticketType: {
        //     default: 'Ticket Type',
        //     items: [
        //         { value: 'Complaint', label: 'Complaint' },
        //         { value: 'Escalation', label: 'Escalation' },
        //     ],
        // },
        company: {
            default: 'Company Name',
            // items: [
            //     { value: 'Company Name', label: 'Company Name' },
            //     { value: 'Other Company', label: 'Other Company' },
            // ],
            items: getCompanyDropdown,
        },

        year: {
            default: 'Year',
            // items: [
            //     { value: '2023', label: '2023' },
            //     { value: '2024', label: '2024' },
            // ],
            items: generateYearRange(2022),
        },
    };

    const branchDropdownOptions = {
        ticketType: {
            default: 'Ticket Type',
            items: [
                { value: 'Complaint Ticket', label: 'Complaint Ticket' },
                { value: 'Escalation Ticket', label: 'Escalation Ticket' },
            ],
        },
        company: {
            default: 'Company Name',
            // items: [
            //     { value: 'Company Name', label: 'Company Name' },
            //     { value: 'Other Company', label: 'Other Company' },
            // ],
            items: getCompanyDropdown,
        },

        year: {
            default: 'Year',
            items: generateYearRange(2022), // Get years dynamically from 2022 to current year
        },
    };

    const funnelColors = ['rgba(0, 133, 163, 1)', 'rgba(3, 170, 207, 1)', 'rgba(6, 201, 244, 1)'];
    const funnelLabels = ['Service', 'Support', 'Technical'];
    const funnelData = [
        { value: 200 },
        { value: 150 },
        { value: 100 },
    ];
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };

    const handleNavigation = (ticketType, statusId) => {
        // navigate('/complaint');
        navigate('/complaint', { state: { ticketType, statusId } });
    }

    useEffect(() => {
        dispatch(loadComplaintEscalationDashboardCardData());
        dispatch(loadCompanyDropdownData());
        // dispatch(loadComplaintEscalationBarChartTicketData(year, companyName));
    }, [])

    return (
        <div className='dashboard-container'>
            <Main>
                <div className='components-download-container'>
                    <Download moduleName="Complaint / Escalation Dashboard"
                        showBackButton={true}
                        showDashboardLibraryButton={true}
                        showSaveCustomViewButton={true}
                        showAnalyticsButton={false}
                        showDownloadDropdown={true}
                        onBackClick={handleBack}
                    />
                </div>
                <Row className='dashboard-cards-container'>
                    <Col className='p-0 dashboard-cards-cols'>
                        <div className='dashboard-cards' onClick={() => handleNavigation('Complaint Ticket', 0)}>
                            <div className='dashboard-cards-counts'>
                                <p>Total Complaints</p>
                                <b>{complaintEscalationDashboardCardData.TotalComplaints}</b>
                            </div>
                            <div className='dashboard-cards-image' style={{ backgroundColor: "rgba(154, 56, 56, 0.2)" }}>
                                <img src={totalComplaints} alt="" />
                            </div>
                        </div>
                    </Col>
                    <Col className='pe-0 dashboard-cards-cols'>
                        <div className='dashboard-cards' onClick={() => handleNavigation('Escalation Ticket', 0)}>
                            <div className='dashboard-cards-counts'>
                                <p>Total Escalations</p>
                                <b>{complaintEscalationDashboardCardData.TotalEscalations}</b>
                            </div>
                            <div className='dashboard-cards-image' style={{ backgroundColor: "rgba(255, 94, 94, 0.2)" }}>
                                <img src={totalEscalations} alt="" />
                            </div>
                        </div>
                    </Col>
                    <Col className='pe-0 dashboard-cards-cols'>
                        <div className='dashboard-cards' onClick={() => handleNavigation("", 36)}>
                            <div className='dashboard-cards-counts'>
                                <p>Total Pending</p>
                                <b>{complaintEscalationDashboardCardData.TotalPending}</b>
                            </div>
                            <div className='dashboard-cards-image' style={{ backgroundColor: "rgba(255, 159, 0, 0.2)" }}>
                                <img src={totalPending} alt="" />
                            </div>
                        </div>
                    </Col>
                    <Col className='pe-0 dashboard-cards-cols'>
                        <div className='dashboard-cards' onClick={() => handleNavigation("", 37)}>
                            <div className='dashboard-cards-counts'>
                                <p>Total Inprocess</p>
                                <b>{complaintEscalationDashboardCardData.TotalInProcess}</b>
                            </div>
                            <div className='dashboard-cards-image' style={{ backgroundColor: "rgba(255, 94, 0, 0.2)" }}>
                                <img src={totalInProcess} alt="" />
                            </div>
                        </div>
                    </Col>
                    <Col className='pe-0 dashboard-cards-cols'>
                        <div className='dashboard-cards' onClick={() => handleNavigation("", 38)}>
                            <div className='dashboard-cards-counts'>
                                <p>Total Resolved</p>
                                <b>{complaintEscalationDashboardCardData.TotalResolved}</b>
                            </div>
                            <div className='dashboard-cards-image' style={{ backgroundColor: "rgba(58, 201, 119, 0.2)" }}>
                                <img src={totalResolved} alt="" />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='dashboard-charts'>
                    <Col md={6} className='dashboard-charts-div p-0 '>
                        <div className='charts-container'>
                            <AttendanceStatusChart
                                moduleName="Ticket Details"
                                labels={ComplaintLabels}
                                datasets={chartData}
                                dropdownOptions={dropdownOptions}
                                // defaultSelection={{ company: 'Company Name', year: 'Year' }}
                                // selectedOption={selectedOption}
                                // handleSelect={handleSelect}
                                selectedOption={selectedOptionForTicketDetails}
                                handleSelect={(eventKey, type) => handleSelect(eventKey, type, 'TicketDetails')}
                            />
                        </div>
                    </Col>
                    <Col md={3} className='dashboard-charts-div p-0 '>
                        <div className='charts-container'>
                            <RelationshipTypeChart
                                moduleName="Priority"
                                labels={labels}
                                colors={colors}
                                data={data}
                                showYear={true}
                                showCompany={true}
                            />
                        </div>
                    </Col>
                    <Col md={3} className='dashboard-charts-div p-0 pe-4'>
                        <div style={{ height: "100%" }}>
                            <FunnelChart
                                moduleName="Issue"
                                colors={funnelColors}
                                labels={funnelLabels}
                                data={funnelData}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className='dashboard-charts pt-4 pb-4'>
                    <Col md={6} className='dashboard-charts-div p-0'>
                        <div className='charts-container'>
                            <AttendanceStatusChart
                                moduleName="Status"
                                labels={ComplaintLabels}
                                datasets={datasets}
                                dropdownOptions={branchDropdownOptions}
                                // defaultSelection={{ ticketType: 'Ticket Type', company: 'Company Name', year: 'Year' }}
                                // selectedOption={selectedOption}
                                // handleSelect={handleSelect}
                                selectedOption={selectedOptionForStatus}
                                handleSelect={(eventKey, type) => handleSelect(eventKey, type, 'Status')}
                            />
                        </div>
                    </Col>
                    <Col md={6} className='dashboard-charts-div p-0 pe-2'>
                        <div className='charts-container'>
                            <AttendanceStatusChart
                                moduleName="Branch"
                                labels={branchLabels}
                                datasets={datasetsOfBranch}
                                dropdownOptions={dropdownOptions}
                                // defaultSelection={{ company: 'Company Name', year: 'Year' }}
                                // selectedOption={selectedOption}
                                // handleSelect={handleSelect}
                                selectedOption={selectedOptionForBranch}
                                handleSelect={(eventKey, type) => handleSelect(eventKey, type, 'Branch')}
                            />
                        </div>
                    </Col>
                </Row>
            </Main>
        </div>
    )
}

export default ComplaintEscalationDashboard

