import React, { useState } from 'react'
import BodyContent from './MainSection/BodyContent/BodyContent';
import Header from './MainSection/Header/Header';
import SideBar from './MainSection/SideBar/SideBar';
import { useLocation } from 'react-router-dom';

// region common inline style for div
const getDynamicStyles = (click) => ({
    marginLeft: click ? "18.5vw" : "6vw",
    width: `calc(100% - ${click ? "18.5vw" : "6vw"})`
});
// endregion


function Main(props) {
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });

    const location = useLocation();
    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    const [click, setClick] = useState(true);


    return (
        <div>
            <div className="lm-top-bar" style={getDynamicStyles(click)}>
                <Header />
            </div>

            {/* <SideBar menuCollapse={menuCollapse} toggleshift={toggleshift} /> */}
            <SideBar setClick={setClick} click={click} />
            <div
                // className='lm-main-body' 
                style={getDynamicStyles(click)} className={`lm-main-body ${click ? 'clicked' : ''}`}>
                <BodyContent toggleshift={toggleshift.style} children={props.children} />
            </div>
            {/* <div className="lm-footer" style={getDynamicStyles(click)}>
                <Footer />
            </div> */}

        </div>
    );
}

export default Main
