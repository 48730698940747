import React, { useEffect, useRef, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Menu,
    MenuItem
} from '@mui/material';
import SortIcon from '../../assets/images/Dashboard/chevron-down.svg';
import ascToDesc from '../../assets/images/CustomerManagement/ascToDesc.svg';
import descToAsc from '../../assets/images/CustomerManagement/descToAsc.svg';
import SbActionDropdownButton from '../Buttons/SbActionDropdownButton';
import SbCancelBtn, { SbAddBtn, SbFilterBtn, SBSaveUpdateBtn } from '../Buttons/Buttons';
import calendar from '../../assets/images/Dashboard/calendar-icon.svg';
import { Col, Dropdown, Form, Modal, ProgressBar, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import searchIcon from '../../assets/images/Dashboard/search-icon.svg'
import columnFilter from '../../assets/images/CustomerManagement/columnFilter.svg'
import valueFilter from '../../assets/images/CustomerManagement/valueFilter.svg'
import AddSLA from './AddSLA';
import ViewAssignedToSla from './ViewAssignedToSla';
import PaginationComponent from '../PaginationComponent';
import SlaValueFilterModal from './SlaValueFilterModal';
import SlaColumnFilterModal from './SlaColumnFilterModal';
import { useDispatch, useSelector } from 'react-redux';
import { loadAssignedToSlaData, loadSingleAssignedToSlaData } from '../../redux/action';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import noDataFound from '../../assets/images/no-data-found.svg';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const columns = [
    { id: 'SLAID', label: 'SLA NO', align: 'left', minWidth: '100px' },
    { id: 'SLAType', label: 'TYPE', align: 'left', minWidth: '120px' },
    { id: 'CustomerName', label: 'COMPANY NAME', align: 'left', minWidth: '180px' },
    { id: 'SLACategoryName', label: 'SLA CATEGORIES', align: 'left', minWidth: '200px' },
    { id: 'Remarks', label: 'DESCRIPTION', align: 'left', minWidth: '150px' },
    { id: 'ValueType', label: 'VALUE TYPE', align: 'left', minWidth: '150px' },
    { id: 'BaseLine', label: 'BASELINE', align: 'left', minWidth: '100px' },
    { id: 'TargetPercentage', label: 'TARGET', align: 'left', minWidth: '120px' },
    { id: 'TargetValue', label: 'TARGET VALUE', align: 'left', minWidth: '150px' },
    { id: 'StartDate/EndDate', label: 'START DATE / END DATE', align: 'left', minWidth: '220px' },
    { id: 'AssignedTo', label: 'ASSIGNED TO', align: 'left', minWidth: '300px' },
    { id: 'action', label: 'ACTION', align: 'left', minWidth: '80px', sortable: false },
];

const SortableHead = SortableContainer(({ children }) => {
    return (
        <TableHead>
            <TableRow>{children}</TableRow>
        </TableHead>
    );
});

const SortableCell = SortableElement(({ value }) => {
    return <TableCell>{value}</TableCell>;
});

const AssignToTable = () => {
    let dispatch = useDispatch();

    const getStoredColumnsOrder = () => {
        const savedOrder = localStorage.getItem('assignedToSlaColumnsOrder');
        return savedOrder ? JSON.parse(savedOrder) : columns.map((col) => col.id);
    };

    const { assignedToSlaData = [] } = useSelector((state) => state.data);
    const { singleAssignedToSlaData } = useSelector((state) => state.data);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [actionAnchorEl, setActionAnchorEl] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedColumns, setSelectedColumns] = useState([
        "SLA NO", "TYPE", "COMPANY NAME", "SLA CATEGORIES", "DESCRIPTION", "VALUE TYPE", "BASELINE", "TARGET", "TARGET VALUE", "START DATE / END DATE", "ASSIGNED TO", "ACTION"
    ]);
    const [columnsOrder, setColumnsOrder] = useState(getStoredColumnsOrder());

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Set initial rows per page
    const [paginatedData, setPaginatedData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Get selected columns (based on `selectedColumns` prop)
    const filteredColumns = columns.filter((column) =>
        selectedColumns.includes(column.label)
    );

    const handleRequestSort = (event, property) => {
        setCurrentSortColumn(property);
        setAnchorEl(event.currentTarget);
    };

    const handleSortOrderChange = (order) => {
        setOrder(order);
        setOrderBy(currentSortColumn);
        setAnchorEl(null);
    };

    const assignedToSlaDatas = () => {
        dispatch(loadAssignedToSlaData(setLoading));
    }
    useEffect(() => {
        assignedToSlaDatas();
    }, []);

    const onReorderEnd = ({ oldIndex, newIndex }) => {
        const newOrder = Array.from(columnsOrder);
        const [moved] = newOrder.splice(oldIndex, 1);
        newOrder.splice(newIndex, 0, moved);
        setColumnsOrder(newOrder);
        localStorage.setItem('assignedToSlaColumnsOrder', JSON.stringify(newOrder)); // Store new order
    };

    const sortedData = React.useMemo(() => {
        const stabilizedData = assignedToSlaData.map((el, index) => [el, index]);
        stabilizedData.sort((a, b) => {
            const orderModifier = order === 'asc' ? 1 : -1;
            if (a[0][orderBy] < b[0][orderBy]) {
                return -1 * orderModifier;
            }
            if (a[0][orderBy] > b[0][orderBy]) {
                return 1 * orderModifier;
            }
            return a[1] - b[1];
        });
        return stabilizedData.map((el) => el[0]);
    }, [assignedToSlaData, order, orderBy]);

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [viewSlaAssignToModal, setViewSlaAssignToModal] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [showColumnFilterModal, setShowColumnFilterModal] = useState(false);
    const [showValueFilterModal, setShowValueFilterModal] = useState(false);
    const [clickedCell, setClickedCell] = useState({ rowId: null, columnId: null }); // Track clicked cell
    const [files, setFiles] = useState([]);
    const [showAttachmentUploadModal, setShowAttachmentUploadModal] = useState(false);
    const [progress, setProgress] = useState([]);
    const [droppedFile, setDroppedFile] = useState(null);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [slaId, setSlaId] = useState("");

    const fileInputRef = useRef(null);

    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };
    const handleOpenModal = (slaID) => {
        setIsModalOpen(true);
        setIsModalOpen(true);
        if (slaID) {
            dispatch(loadSingleAssignedToSlaData(slaID));
            setSlaId(slaID);
        } else {
            setSlaId("");
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleOpenAssignToModal = (slaID) => {
        setViewSlaAssignToModal(true);
        if (slaID) {
            dispatch(loadSingleAssignedToSlaData(slaID));
            setSlaId(slaID);
        } else {
            setSlaId("");
        }
    };

    const handleCloseAssignToModal = () => {
        setViewSlaAssignToModal(false);
    };
    const handleOpenColumnFilterModal = () => {
        setShowFilterMenu(false);
        setShowColumnFilterModal(true);
    };

    const handleCloseColumnFilterModal = () => {
        setShowColumnFilterModal(false);
    };
    const handleOpenValueFilterModal = () => {
        setShowFilterMenu(false);
        setShowValueFilterModal(true);
    };

    const handleCloseValueFilterModal = () => {
        setShowValueFilterModal(false);
    };

    // Pagination logic
    useEffect(() => {
        const start = page * rowsPerPage; // Updated page logic to 0-based
        const end = start + rowsPerPage;
        setPaginatedData(sortedData.slice(start, end));
    }, [sortedData, page, rowsPerPage]);

    const handlePageChange = (newPage) => {
        setPage(newPage - 1); // Handle 1-based to 0-based page index for slicing
    };
    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset to the first page when rows per page changes
    };

    const handleOpenAttachmentUploadModal = () => {
        // Reset the state before opening the modal
        setDroppedFile(null);
        setFiles([]);
        setProgress([]);
        setUploadComplete(false);
        setShowAttachmentUploadModal(true);
    };

    const handleCloseBulkUploadModal = () => {
        setShowAttachmentUploadModal(false);
        // Reset states when closing the modal if needed
        setDroppedFile(null);
        setFiles([]);
        setProgress([]);
        setUploadComplete(false);
    };

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        processFiles(newFiles);
    };

    const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg']; // Allowed file types
    const maxFiles = 5; // Maximum number of files

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const newFiles = Array.from(event.dataTransfer.files);
        console.log('Dropped files:', newFiles);

        // Check for duplicates and validate file types
        const existingFiles = new Set(files.map(file => file.name));
        const uniqueFiles = newFiles.filter(file => {
            const isValidType = validFileTypes.includes(file.type);
            const isDuplicate = existingFiles.has(file.name);
            return isValidType && !isDuplicate;
        });

        console.log('Unique files to be added:', uniqueFiles);

        // Check if adding these files exceeds the limit
        if (files.length + uniqueFiles.length > maxFiles) {
            alert(`You can only upload a maximum of ${maxFiles} files.`);
            return;
        }

        if (uniqueFiles.length > 0) {
            processFiles(uniqueFiles);
        }
    };

    const processFiles = (newFiles) => {
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        setProgress((prevProgress) => [
            ...prevProgress,
            ...newFiles.map(() => 0)
        ]);
        simulateProgress(newFiles.length);
    };

    const simulateProgress = (newFileCount) => {
        const currentFileIndex = files.length;
        for (let i = 0; i < newFileCount; i++) {
            let progressValue = 0;
            const interval = setInterval(() => {
                if (progressValue >= 100) {
                    clearInterval(interval);
                    setUploadComplete(true); // Set completion status
                    return;
                }
                progressValue += 10;
                setProgress((prevProgress) => {
                    const newProgress = [...prevProgress];
                    newProgress[currentFileIndex + i] = progressValue;
                    return newProgress;
                });
            }, 500);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation(); // Stop the event from bubbling up
    };

    const removeFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        setProgress((prevProgress) => prevProgress.filter((_, i) => i !== index));
    };

    const handleColumnSelectionChange = (updatedSelectedColumns) => {
        setSelectedColumns(updatedSelectedColumns); // Update the selected columns
    };

    return (
        <>
            <Row className='customermanagement-btns-container'>
                <Col md={3} className='customermanagement-backbtn-div'>
                    <span>Service Level Agreement</span>
                </Col>
                <Col md={9} className='customermanagement-search-filter'>
                    <div className='customermanagement-header-btns filter-btn'>
                        <SbFilterBtn btnName="Filters" onClickEffect={toggleFilterMenu} />
                        {showFilterMenu && (
                            <Dropdown.Menu show className='filter-dropdown-options'>
                                <Dropdown.Item onClick={handleOpenColumnFilterModal}>
                                    <img src={columnFilter} alt="Column Filter" />&nbsp;&nbsp;
                                    Column Filter
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleOpenValueFilterModal}>
                                    <img src={valueFilter} alt="Value Filter" />&nbsp;&nbsp;
                                    Value Filter
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        )}
                    </div>
                    <Col md={4} className='filters-col'>
                        <div className='customermanagement-search'>
                            <Form.Control type="search" placeholder='Search' />
                            <img src={searchIcon} alt="Search" />
                        </div>
                    </Col>
                    <Col md={2} className='filters-col'>
                        <div className='calendar-container'>
                            <DatePicker
                                className="select from-date"
                                dateFormat="dd/MM/yyyy"
                                selectsStart
                                placeholderText='From'
                                startDate={fromDate}
                                endDate={toDate}
                                onChange={date => handleFromDateChange(date)}
                                maxDate={new Date()}
                            />
                            <img src={calendar} alt="Calendar" />
                        </div>
                    </Col>
                    <Col md={2} className='filters-col'>
                        <div className='calendar-container'>
                            <DatePicker
                                className="select to-date"
                                dateFormat="dd/MM/yyyy"
                                selectsEnd
                                placeholderText='To'
                                startDate={fromDate}
                                endDate={toDate}
                                onChange={date => handleToDateChange(date)}
                                maxDate={new Date()}
                            />
                            <img src={calendar} alt="Calendar" />
                        </div>
                    </Col>
                    <SbAddBtn btnName="Add SLA" onClickEffect={() => { handleOpenModal("") }} />
                </Col>
            </Row>
            <div className='customermanagement-table-div'>
                <TableContainer component={Paper} sx={{ maxHeight: 440 }} className='table-container'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }} className='table-header-div'>
                                            {column.label}
                                            {column.sortable !== false && (
                                                <IconButton
                                                    size="small"
                                                    onClick={(event) => handleRequestSort(event, column.id)}
                                                >
                                                    <img src={SortIcon} alt="Sort" />
                                                </IconButton>
                                            )}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                loading ? (
                                    [...Array(rowsPerPage)].map((_, index) => (
                                        <TableRow key={index}>
                                            {columns.map((column) => (
                                                <TableCell key={column.id} align={column.align}>
                                                    <Skeleton height={40} />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))
                                ) : paginatedData.length === 0 ? (
                                    // Display "No Data Found" row if no data is present in the paginated data
                                    <TableRow>
                                        <TableCell colSpan={columns.length} align="center">
                                            <img src={noDataFound} alt="" style={{ height: "250px", width: "280px" }} />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    paginatedData.map((customer) => (
                                        <TableRow key={customer.id}>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    className={clickedCell.rowId === customer.slaNo && clickedCell.columnId === column.id ? 'clicked-value' : ''}
                                                    onMouseDown={() => setClickedCell({ rowId: customer.slaNo, columnId: column.id })}
                                                    onMouseUp={() => setClickedCell({ rowId: null, columnId: null })}
                                                    onMouseLeave={() => setClickedCell({ rowId: null, columnId: null })}
                                                >
                                                    {column.id === 'action' ? (
                                                        <SbActionDropdownButton
                                                            options={[
                                                                { type: 'Edit' },
                                                                { type: 'View' },
                                                                { type: 'Download' }
                                                            ]}
                                                            openAddModal={() => { handleOpenModal(customer.SLAID) }}
                                                            addBulkModel={() => { handleOpenAssignToModal(customer.SLAID) }}
                                                            addAttachmentModal={handleOpenAttachmentUploadModal}
                                                        />
                                                    ) : column.id === 'StartDate/EndDate' ? (
                                                        // Format and display the StartDate and EndDate
                                                        <>
                                                            <span>
                                                                {moment(customer.StartDate).format('DD-MM-YYYY')} /
                                                                {moment(customer.EndDate).format('DD-MM-YYYY')}
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <span
                                                            onClick={() => {
                                                                setClickedCell({ rowId: customer.slaNo, columnId: column.id }); // Track clicked cell
                                                                handleOpenValueFilterModal(); // Open modal
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            {customer[column.id]}
                                                        </span>
                                                    )}
                                                </TableCell>
                                            )
                                            )}
                                        </TableRow>
                                    )))}
                        </TableBody>
                    </Table>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                    >
                        <MenuItem onClick={() => handleSortOrderChange('asc')}>
                            <img src={ascToDesc} alt="" />&nbsp;&nbsp;
                            Ascending to Descending
                        </MenuItem>
                        <MenuItem onClick={() => handleSortOrderChange('desc')}>
                            <img src={descToAsc} alt="" />&nbsp;&nbsp;
                            Descending to Ascending
                        </MenuItem>
                    </Menu>
                </TableContainer>
                {/* Use PaginationComponent */}
                <PaginationComponent
                    totalItems={assignedToSlaData.length}
                    itemsPerPage={rowsPerPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
                {/* Upload Attachment Modal */}
                <Modal
                    show={showAttachmentUploadModal}
                    onHide={handleCloseBulkUploadModal}
                    centered
                    size="lg"
                    onDrop={handleDrop}
                    onDragOver={(e) => e.preventDefault()}
                    className='add-customer-modal add-user-modal'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Upload Attachments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="bulkFileInput">
                            <div
                                className="drag-and-drop-area"
                                // onDragOver={(e) => e.preventDefault()}
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                            >
                                <div className='drag-drop-container'>
                                    {files.length > 0 ? (
                                        files.map((file, index) => (
                                            <div key={index} className="file-info">
                                                <span>{file.name}</span>&nbsp;&nbsp;
                                                <ProgressBar
                                                    now={progress[index] || 0}
                                                    label={`${progress[index] || 0}%`}
                                                    variant={progress[index] === 100 ? "success" : "danger"}
                                                />
                                                <div className='d-flex justify-content-between align-items-center pt-2'>
                                                    <p> ({(file.size / (1024 * 1024)).toFixed(2)} MB)</p>
                                                    <p style={{ color: progress[index] === 100 ? "green" : "red" }}>
                                                        {progress[index] === 100 ? "Completed" : "Loading..."}
                                                    </p>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className='drag-drop-div'>
                                            <p>Drag & Drop files(Supports only JPG,JPEG,PNG)</p>
                                        </div>
                                    )}
                                </div>
                                <div className='browse-btn-div'>
                                    <SBSaveUpdateBtn btnName="Browse Files" onClickEffect={() => fileInputRef.current.click()} />
                                    <input
                                        type="file"
                                        multiple
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                        accept=".jpg,.jpeg,.png"
                                    />
                                </div>
                            </div>
                        </Form.Group>
                    </Modal.Body>

                    <Modal.Footer>
                        <SbCancelBtn btnName="Cancel" onClickEffect={handleCloseBulkUploadModal} />
                        <SBSaveUpdateBtn btnName="Upload Attachment" />
                    </Modal.Footer>
                </Modal>
                {isModalOpen && <AddSLA
                    open={isModalOpen}
                    handleClose={handleCloseModal}
                    singleAssignedToSlaData={singleAssignedToSlaData}
                    assignedToSlaDatas={assignedToSlaDatas}
                    slaId={slaId}
                />}
                {viewSlaAssignToModal && <ViewAssignedToSla
                    viewSlaAssignToModal={viewSlaAssignToModal}
                    handleCloseAssignToModal={handleCloseAssignToModal}
                    singleAssignedToSlaData={singleAssignedToSlaData}
                />}
                {
                    showColumnFilterModal && (
                        <SlaColumnFilterModal
                            showColumnFilterModal={showColumnFilterModal}
                            handleCloseColumnFilterModal={handleCloseColumnFilterModal}
                            selectedColumns={selectedColumns}
                            handleSaveColumns={handleColumnSelectionChange}
                        />
                    )
                }
                {
                    showValueFilterModal && (
                        <SlaValueFilterModal
                            showValueFilterModal={showValueFilterModal}
                            handleCloseValueFilterModal={handleCloseValueFilterModal}
                        />
                    )
                }
            </div>
        </>
    );
};

export default AssignToTable;
