import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';
import { loadDepartmentDropdownData, loadReportingDesignationDropdownData, loadSingleDesignationData, postDesignationDatas } from "../../../redux/action";
import * as HeaderAuthentication from '../../HeaderAuthentication'
import { useDispatch, useSelector } from "react-redux";
import { ADDED_BY_ID } from "../../Constants";

const enablingOptions = [
    { value: 10, label: "Active" },
    { value: 11, label: "Inactive" }
]

const AddDesignation = (props) => {

    let dispatch = useDispatch();
    const getDesgData = props.getDesgData;
    const desgID = props.desgID;
    const singleDesignationData = props.singleDesignationData;
    const { reportingDesignationDropdown } = useSelector((state) => state.data);
    const { departmentDropdown } = useSelector((state) => state.data);

    const [designationName, setDesignationName] = useState("");
    const [department, setDepartment] = useState(null);
    const [reportingDesignation, setReportingDesignation] = useState(null);
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState(null);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        dispatch(loadReportingDesignationDropdownData());
        dispatch(loadDepartmentDropdownData());
    }, [])

    useEffect(() => {
        if (desgID && singleDesignationData && singleDesignationData.length > 0) {
            const designation = singleDesignationData[0];
            setDesignationName(designation.DesignationName);
            setDescription(designation.DesignationDescription);
            const foundDepartment = departmentDropdown.find(option => option.value === designation.DepartmentID);
            setDepartment(foundDepartment || null);

            const foundReportingDesignation = reportingDesignationDropdown.find(option => option.value === designation.ReportingDesignationID);
            setReportingDesignation(foundReportingDesignation || null);

            const statusOption = enablingOptions.find(option => option.value === designation.StatusID);
            setStatus(statusOption || null);
        } else {
            // Reset fields for adding new designation
            setDesignationName("");
            setDescription("");
            setDepartment(null);
            setReportingDesignation(null);
            setStatus(null);
        }
    }, [desgID, singleDesignationData, reportingDesignationDropdown, departmentDropdown]);

    const AddDesignationData = (e) => {
        e.preventDefault();
        e.preventDefault();
        const newErrors = {};

        // Validate required fields
        if (!designationName) newErrors.designationName = "Designation Name is required.";
        if (!department) newErrors.department = "Department is required.";
        if (!description) newErrors.description = "Description is required.";

        // Check if there are errors
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        const data = {
            DesignationID: desgID,
            DesignationName: designationName,
            StatusID: status?.value,
            DepartmentID: department.value,
            ReportingDesignationID: reportingDesignation.value,
            DesignationDescription: description,
            AddedByID: ADDED_BY_ID
        }
        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data),
        };
        dispatch(postDesignationDatas(requestOption, props.handleCloseModal, getDesgData, desgID));
    }

    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{desgID ? 'Edit Job Title & Designation' : 'Add Job Title & Designation'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Designation Name <span className="required-asterisk">*</span></label>
                                        <Form.Control
                                            type="text"
                                            value={designationName}
                                            // onChange={(e) => setDesignationName(e.target.value)}
                                            onChange={(e) => {
                                                setDesignationName(e.target.value);
                                                setErrors(prev => ({ ...prev, designationName: "" })); // Clear specific error
                                            }}
                                            // isInvalid={!!errors.designationName}
                                        />
                                    </div>
                                    {errors.designationName && <div className="invalid-feedback" style={{ display: 'block' }}>{errors.designationName}</div>}
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Departments <span className="required-asterisk">*</span></label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={departmentDropdown}
                                            placeholder="Select"
                                            value={department}
                                            // onChange={(selectedOption) => setDepartment(selectedOption)}
                                            onChange={(selectedOption) => {
                                                setDepartment(selectedOption);
                                                setErrors(prev => ({ ...prev, department: "" })); // Clear specific error
                                            }}
                                            // isInvalid={!!errors.department}
                                        />
                                        {errors.department && <div className="invalid-feedback" style={{ display: 'block' }}>{errors.department}</div>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Description <span className="required-asterisk">*</span></label>
                                        <Form.Control
                                            type="text"
                                            value={description}
                                            // onChange={(e) => setDescription(e.target.value)}
                                            onChange={(e) => {
                                                setDescription(e.target.value);
                                                setErrors(prev => ({ ...prev, description: "" })); // Clear specific error
                                            }}
                                            // isInvalid={!!errors.description}
                                        />
                                    </div>
                                    {errors.description && <div className="invalid-feedback" style={{ display: 'block' }}>{errors.description}</div>}
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Reporting Designation</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={reportingDesignationDropdown}
                                            placeholder="Select"
                                            value={reportingDesignation}
                                            onChange={(selectedOption) => setReportingDesignation(selectedOption)}
                                        />
                                    </div>
                                </Col>
                                {desgID && <Col md={4}>
                                    <div>
                                        <label>Active / Inactive</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={enablingOptions}
                                            placeholder="Select"
                                            value={status}
                                            onChange={(selectedOption) => setStatus(selectedOption)}
                                        />
                                    </div>
                                </Col>}
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={AddDesignationData} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddDesignation;
