import React, { useEffect, useState } from "react";

// region import from local file
// import closeIcon from '../../../assets/images/Sidebar/close-menu.png';
import closeIcon from '../../../assets/images/Sidebar/collapseImg.svg';
// import openIcon from '../../../assets/images/Sidebar/open-menu.png';
import openIcon from '../../../assets/images/Sidebar/openImg.svg';
import companyLogo from '../../../assets/images/Sidebar/company-logo.svg';
import companySmallLogo from '../../../assets/images/Sidebar/company-small-logo.svg';
import dashboard from '../../../assets/images/Sidebar/dashboard.svg';
import chevronUpImg from '../../../assets/images/Dashboard/chevron-down.svg';
import customerManagement from '../../../assets/images/Sidebar/customer-management.svg';
import userManagement from '../../../assets/images/Sidebar/user-management.svg';
import attendanceLeaves from '../../../assets/images/Sidebar/attendance-leaves.svg';
import complaintEscalation from '../../../assets/images/Sidebar/complaint-escalation.svg';
import serviceLevelAgreement from '../../../assets/images/Sidebar/service-level-agreement.svg';
import workQueues from '../../../assets/images/Sidebar/work-queues.svg';
import systemLogging from '../../../assets/images/Sidebar/system-logging.svg';
import { useLocation, useNavigate } from "react-router-dom";
// endregion

export default function SideBar({ setClick, click }) {
    const handleClick = () => setClick(!click);

    const [systemLoggingOpen, setSystemLoggingOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState('/dashboard');

    const navigate = useNavigate()
    const location = useLocation(); // Use useLocation to track route changes

    useEffect(() => {
        // Update activeMenu based on current route
        setActiveMenu(location.pathname);
    }, [location.pathname]);

    const handleNavigation = (path) => {
        setActiveMenu(path);
        navigate(path);
    }

    return (
        <div className="lm-sidevar">
            <div className={`container ${click ? "clicked" : ""}`} style={{ width: click ? "18.5vw" : "6vw" }}>
                <button className={`button ${click ? "clicked" : ""}`} onClick={handleClick}>
                    <img src={click ? closeIcon : openIcon} alt="open-close" />
                </button>
                <div className="sidebar-container">
                    {/* <div style={{ width: "100%" }}>
                        <img
                            src={click ? companyLogo : companySmallLogo}
                            alt="Company Logo"
                            className={click ? 'company-logo' : 'company-small-logo'}
                        />
                    </div> */}
                    <div style={{ width: "100%" }}>
                        <img
                            src={companyLogo}
                            alt="Company Logo"
                            className={`company-logo ${click ? "clicked" : ""}`}
                        />
                        <img
                            src={companySmallLogo}
                            alt="Company Small Logo"
                            className={`company-small-logo ${click ? "clicked" : ""}`}
                        />
                    </div>

                    <ul className={`slick-bar ${click ? "clicked" : ""}`}>
                        <li
                            // className="item pt-4" 
                            className={`item pt-4 ${activeMenu === "/dashboard" ? "clicked" : ""}`}
                            onClick={() => handleNavigation("/dashboard")}>
                            <img src={dashboard} alt="" />
                            <span className={`text ${click ? "clicked" : ""}`}>Dashboard</span>
                        </li>
                        <li
                            // className="item" 
                            className={`item ${activeMenu === "/customermanagement" ? "clicked" : ""}`}
                            onClick={() => handleNavigation("/customermanagement")}>
                            <img src={customerManagement} alt="" />
                            <span className={`text ${click ? "clicked" : ""}`}>Customer Management</span>
                        </li>
                        <li
                            // className="item" 
                            className={`item ${activeMenu === "/usermanagement" ? "clicked" : ""}`}
                            onClick={() => { handleNavigation("/usermanagement") }}>
                            <img src={userManagement} alt="" />
                            <span className={`text ${click ? "clicked" : ""}`}>User Management</span>
                        </li>
                        <li
                            // className="item" 
                            className={`item ${activeMenu === "/leaves" ? "clicked" : ""}`}
                            onClick={() => { handleNavigation("/leaves") }}>
                            <img src={attendanceLeaves} alt="" />
                            <span className={`text ${click ? "clicked" : ""}`}>Leave & Holiday</span>
                        </li>
                        <li
                            // className="item" 
                            className={`item ${activeMenu === "/complaint" ? "clicked" : ""}`}
                            onClick={() => { handleNavigation("/complaint") }}>
                            <img src={complaintEscalation} alt="" />
                            <span className={`text ${click ? "clicked" : ""}`}>Complaint / Escalation</span>
                        </li>
                        <li
                            // className="item"
                            className={`item ${activeMenu === "/sla" ? "clicked" : ""}`}
                            onClick={() => { handleNavigation("/sla") }}>
                            <img src={serviceLevelAgreement} alt="" />
                            <span className={`text ${click ? "clicked" : ""}`}>Service Level Agreement</span>
                        </li>
                        <li
                            // className="item" 
                            className={`item ${activeMenu === "/workqueues" ? "clicked" : ""}`}
                            onClick={() => { handleNavigation("/workqueues") }}>
                            <img src={workQueues} alt="" />
                            <span className={`text ${click ? "clicked" : ""}`}>Work Queues</span>
                        </li>
                        <li
                            // className="item"
                            // className={`item ${systemLoggingOpen ? "open" : ""}`} onClick={() => setSystemLoggingOpen(!systemLoggingOpen)}
                            className={`item ${systemLoggingOpen ? "open" : ""} ${activeMenu === "/systemlogging" ? "clicked" : ""}`}
                            onClick={() => setSystemLoggingOpen(!systemLoggingOpen)}
                        >
                            <img src={systemLogging} alt="" />
                            <span className={`text ${click ? "clicked" : ""}`}>System Logging</span>
                            <img src={chevronUpImg} alt="" className={systemLoggingOpen ? "sidebar-chevron-down" : "sidebar-chevron-right"} />
                        </li>
                        {systemLoggingOpen && (
                            <ul className="sub-menu">
                                <li
                                    // className="sub-item pt-2 pb-2"
                                    className={`sub-item pt-2 pb-2 ${activeMenu === "/audit-trial" ? "clicked" : ""}`}
                                    onClick={() => handleNavigation("/audit-trial")}>
                                    <img src={serviceLevelAgreement} alt="" />
                                    <span className={`text ${click ? "clicked" : ""}`}>Audit Trial</span>
                                </li>
                                <li
                                    //  className="sub-item pt-2 pb-2" 
                                    className={`sub-item pt-2 pb-2 ${activeMenu === "/exception-log" ? "clicked" : ""}`}
                                    onClick={() => handleNavigation("/exception-log")}>
                                    <img src={serviceLevelAgreement} alt="" />
                                    <span className={`text ${click ? "clicked" : ""}`}>Exception Log</span>
                                </li>
                                <li
                                    // className="sub-item pt-2 pb-2"
                                    className={`sub-item pt-2 pb-2 ${activeMenu === "/integration-log" ? "clicked" : ""}`}
                                    onClick={() => handleNavigation("/integration-log")}>
                                    <img src={serviceLevelAgreement} alt="" />
                                    <span className={`text ${click ? "clicked" : ""}`}>Integration Log</span>
                                </li>
                            </ul>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}
