import React from 'react'
import { Col, Row } from 'react-bootstrap'
import '../../assets/css/dashboard.scss'
import newRequest from '../../assets/images/Leaves&Attendance/newRequest.svg'
import totalRequest from '../../assets/images/Leaves&Attendance/totalPresent.svg'
import pendingRequest from '../../assets/images/Leaves&Attendance/pendingRequest.svg'
import rejectLeaves from '../../assets/images/Leaves&Attendance/rejectLeaves.svg'
import Main from '../Main'
import Download from '../MainSection/Download/Download'
import BarChart from '../Graphs/BarChart'
import RelationshipTypeChart from '../Graphs/RelationshipTypeChart'
import FunnelChart from '../Graphs/FunnelChart'
import LicenceSpliteUpChart from '../Graphs/LicenceSpliteUpChart'
import AssignedModules from '../Graphs/AssignedModules'
import AttendanceStatusChart from '../Graphs/AttendanceStatusChart'
import HolidaySchedules from '../Graphs/HolidaySchedules'
import CustomerStatusChart from '../Graphs/CustomerStatusChart'
import { useNavigate } from 'react-router-dom'

function AttendanceAndLeavesDashboard() {
    const labels = ['Approved', 'Rejected', 'Pending'];
    const colors = [
        'rgba(58, 201, 119, 1)',  // Approved
        'rgba(240, 207, 43, 1)', // Pending
        'rgba(255, 94, 94, 1)', // Rejected
    ];
    const data = [20, 30, 50];

    const attendenceLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const datasets = [
        {
            type: 'bar',
            label: 'Pending',
            data: [15, 18, 13, 22, 20, 25, 21, 22, 20, 15, 18, 22],
            backgroundColor: 'rgba(240, 207, 43, 1)',
            borderColor: 'rgba(240, 207, 43, 1)',
            borderWidth: 1,
            borderRadius: 4,
            barThickness: 8,
            borderSkipped: false,
        },
        {
            type: 'bar',
            label: 'Rejected',
            data: [5, 10, 5, 10, 5, 10, 5, 10, 5, 10, 5, 10],
            backgroundColor: 'rgba(255, 94, 94, 1)',
            borderColor: 'rgba(255, 94, 94, 1)',
            borderWidth: 1,
            borderRadius: 4,
            barThickness: 8,
            borderSkipped: false,
        },
        {
            type: 'line',
            label: 'Approved',
            data: [10, 12, 18, 15, 14, 15, 18, 16, 18, 14, 16, 17],
            backgroundColor: (context) => {
                const chart = context.chart;
                const ctx = chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
                gradient.addColorStop(0, 'rgba(58, 201, 119, 0.2)');
                gradient.addColorStop(1, 'rgba(58, 201, 119, 0)');
                return gradient;
            },
            borderColor: 'rgba(58, 201, 119, 1)',
            borderWidth: 2,
            fill: true,
            tension: 0.4,
        }
    ];

    const dropdownOptions = {
        user: {
            default: 'User Name',
            items: [
                { value: 'User Name', label: 'User Name' },
                { value: 'Other User', label: 'Other User' },
            ],
        },
        company: {
            default: 'Company Name',
            items: [
                { value: 'Company Name', label: 'Company Name' },
                { value: 'Other Company', label: 'Other Company' },
            ],
        },
        month: {
            default: 'Month',
            items: [
                { value: 'January', label: 'January' },
                { value: 'February', label: 'February' },
                // Add other months here
            ],
        },
        year: {
            default: 'Year',
            items: [
                { value: '2023', label: '2023' },
                { value: '2024', label: '2024' },
            ],
        },
    };

    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };

    const handleNavigation = () => {
        navigate('/leaves')
    }

    return (
        <div className='dashboard-container'>
            <Main>
                <div className='components-download-container'>
                    <Download
                        moduleName="Attendance & Leaves Dashboard"
                        showBackButton={true}
                        showDashboardLibraryButton={true}
                        showSaveCustomViewButton={true}
                        showAnalyticsButton={false}
                        showDownloadDropdown={true}
                        onBackClick={handleBack}
                    />
                </div>
                <Row className='dashboard-cards-container'>
                    <Col md={3} className='p-0 pe-3 dashboard-cards-cols'>
                        <div className='dashboard-cards' onClick={handleNavigation}>
                            <div className='dashboard-cards-counts'>
                                <p>New Request</p>
                                <b>200</b>
                            </div>
                            <div className='dashboard-cards-image'>
                                <img src={newRequest} alt="Total Customers" />
                            </div>
                        </div>
                    </Col>
                    <Col md={3} className='p-0 pe-3 dashboard-cards-cols' onClick={handleNavigation}>
                        <div className='dashboard-cards'>
                            <div className='dashboard-cards-counts'>
                                <p>Total Present</p>
                                <b>50 / 100</b>
                            </div>
                            <div className='dashboard-cards-image' style={{ backgroundColor: "rgba(52, 58, 64, 0.2)" }}>
                                <img src={totalRequest} alt="Active/Inactive" />
                            </div>
                        </div>
                    </Col>
                    <Col md={3} className='p-0 pe-3 dashboard-cards-cols'>
                        <div className='dashboard-cards' onClick={handleNavigation}>
                            <div className='dashboard-cards-counts'>
                                <p>Pending Request</p>
                                <b>200</b>
                            </div>
                            <div className='dashboard-cards-image' style={{ backgroundColor: "rgba(255, 159, 0, 0.2)" }}>
                                <img src={pendingRequest} alt="Customer Under Validity" />
                            </div>
                        </div>
                    </Col>
                    <Col md={3} className='p-0 dashboard-cards-cols'>
                        <div className='dashboard-cards' onClick={handleNavigation}>
                            <div className='dashboard-cards-counts'>
                                <p>Reject Leaves</p>
                                <b>20</b>
                            </div>
                            <div className='dashboard-cards-image' style={{ backgroundColor: "rgba(255, 94, 94, 0.2)" }}>
                                <img src={rejectLeaves} alt="Customer License Expiration" />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='m-0 attendence-leaves-chart-div'>
                    <Col md={9} className='p-0 attendence-leaves-chart-col-div'>
                        <Row className='dashboard-charts'>
                            <Col md={8} className='dashboard-charts-div p-0'>
                                <div className='charts-container'>
                                    <AttendanceStatusChart
                                        moduleName="Status"
                                        labels={attendenceLabels}
                                        datasets={datasets}
                                        dropdownOptions={dropdownOptions}
                                        defaultSelection={{ user: 'User Name', company: 'Company Name', month: 'Month', year: 'Year' }}
                                    />
                                </div>
                            </Col>
                            <Col md={4} className='dashboard-charts-div p-0'>
                                <div className='charts-container'>
                                    <RelationshipTypeChart
                                        moduleName="Reporting To"
                                        labels={labels}
                                        colors={colors}
                                        data={data}
                                        showYear={true}
                                        showCompany={true}
                                        showApprover={true}
                                        showMonth={true}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className='dashboard-charts pt-4 pb-4 pe-0'>
                            <Col md={8} className='dashboard-charts-div p-0'>
                                <div className='charts-container'>
                                    <BarChart
                                        moduleName="Leave Type"
                                        showTypeNameMonth={true}
                                        showYear={true}
                                        label="Leave Type"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3} className='dashboard-charts-div attendence-leaves-calender-container p-0'>
                        <div className='attendence-leaves-container'>
                            <HolidaySchedules />
                        </div>
                    </Col>
                </Row>
            </Main>
        </div>
    )
}

export default AttendanceAndLeavesDashboard
