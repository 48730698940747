import React, { useState } from 'react';
import Main from '../Main';
import { Button, Col, Dropdown, Form, Row, Tab, Nav } from 'react-bootstrap';
import searchIcon from '../../assets/images/Dashboard/search-icon.svg';
import calendar from '../../assets/images/Dashboard/calendar-icon.svg';
import columnFilter from '../../assets/images/CustomerManagement/columnFilter.svg';
import valueFilter from '../../assets/images/CustomerManagement/valueFilter.svg';
import add from '../../assets/images/Dashboard/add.svg';
import '../../assets/css/dashboard.scss';
import '../../assets/css/customermanagement.scss';
import '../../assets/css/WorkQueue.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SbAddBtn, SbFilterBtn } from '../Buttons/Buttons';
import Select from 'react-select';
import WorkQueuesViewAssignedTable from './WorkQueuesViewAssignedTable';
import WorkQueuesAssignToTable from './WorkQueuesAssignToTable';
import Download from '../MainSection/Download/Download';

const statusOptions = [
    { value: "All", label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "In-Process", label: "In-Process" },
    { value: "Resolved", label: "Resolved" },
    { value: "Re-Open", label: "Re-Open" },
    { value: "Cancel", label: "Cancel" },
];

const WorkQueues = () => {

    const [activeKey, setActiveKey] = useState('assignTo'); // Default active tab

    return (
        <div className="customer-management-container">
            <Main>
                <div className='components-download-container'>
                    <Download
                        showDashboardLibraryButton={false}
                        showSaveCustomViewButton={false}
                        showAnalyticsButton={false}
                        showDownloadDropdown={true}
                    />
                </div>
                {/* <div className='customermanagement-content-container'> */}
                {/* <div className="customermanagement-content"> */}

                <Row className='m-0 work-queue-tabs'>
                    <Col className='p-0'>
                        <Tab.Container activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                            <Nav variant="tabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="assignTo">
                                        Assign To
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="viewAssigned">
                                        View Assigned
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <div className="customer-management-container">
                                <div className='customermanagement-content-container pt-0'>
                                    <Tab.Content className="customermanagement-content">
                                        <Tab.Pane eventKey="assignTo">
                                            <WorkQueuesAssignToTable />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="viewAssigned">
                                            <WorkQueuesViewAssignedTable />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </Col>
                </Row>

                {/* </div> */}
                {/* </div> */}
            </Main>
        </div>
    );
};

export default WorkQueues;
