import React, { useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box, MenuItem, Select, FormControl, InputLabel } from '@mui/material';

const PaginationComponent = ({ totalItems, itemsPerPage, onPageChange, onRowsPerPageChange }) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(itemsPerPage);

  // Calculate total number of pages
  const totalPages = Math.ceil(totalItems / rowsPerPage);

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
    if (onPageChange) {
      onPageChange(value); // Send the 1-based page index to parent component
    }
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(1); // Reset to the first page when rows per page changes
    if (onRowsPerPageChange) {
      onRowsPerPageChange(newRowsPerPage); // Notify parent component
    }
  };

  return (
    <Stack spacing={2} alignItems="center" className='pagination-div'>
      {/* Select for Rows Per Page */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography id="rows-per-page-label" sx={{ marginRight: 1 }}>Show per page:</Typography>
        <FormControl variant="outlined" size="small" sx={{ minWidth: 50, marginRight: 2 }}>
          <Select
            labelId="rows-per-page-label"
            id="rows-per-page"
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            label="Show per page"
            className='pagination-select-count'
          >
            <MenuItem value={10} className='pagination-select-options'>10</MenuItem>
            <MenuItem value={25} className='pagination-select-options'>25</MenuItem>
            <MenuItem value={50} className='pagination-select-options'>50</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Display text like "Showing X of Y items" */}
      <Box sx={{ display: 'flex', alignItems: 'center' }} className='pagination-text-div'>
        <Typography variant="body1" sx={{ marginRight: 1 }}>
          Showing {Math.min(rowsPerPage * page, totalItems)} of {totalItems} entries
        </Typography>
      </Box>

      {/* Pagination */}
      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        shape="rounded"
        color="primary"
        className="pagination-count"
      />
    </Stack>
  );
};

export default PaginationComponent;
