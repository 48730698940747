import React, { useRef, useState } from 'react';
import { Row, Col, Form, Button, ListGroup } from 'react-bootstrap';
import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";
import customerImg from '../../../assets/images/CustomerManagement/customer-img.svg';
import Main from '../../Main';
import '../../../assets/css/userprofile.scss'
import { SBSaveUpdateBtn } from '../../Buttons/Buttons';
import Download from '../Download/Download';
import calendar from '../../../assets/images/Dashboard/calendar-icon.svg';
import DatePicker from "react-datepicker";
import SaveCustomeViewModal from './SetPasswordModal';
import SetPasswordModal from './SetPasswordModal';
import { useNavigate } from 'react-router-dom';

const UserProfileForm = () => {
    const contactOptions = [
        { value: '+1', label: '+1' },
        { value: '+91', label: '+91' },
    ];
    const roleOptions = [
        { value: 'Role1', label: 'Role1' },
        { value: 'Role2', label: 'Role2' },
    ];
    const options = [
        { label: "Option 1", value: "option1" },
        { label: "Option 2", value: "option2" },
    ];
    const emailNotificationOptions = [
        { value: 'Headquarter', label: 'Headquarter' },
        { value: 'Business Unit (BU)/Region', label: 'Business Unit (BU)/Region' },
        { value: 'Branch Office (Plant)', label: 'Branch Office (Plant)' },
        { value: 'Storage Location (SLOC)', label: 'Storage Location (SLOC)' },
    ];
    const timeZoneOptions = [
        { value: 'UTC', label: 'UTC (Coordinated Universal Time)' },
        { value: 'GMT', label: 'GMT (Greenwich Mean Time)' },
        { value: 'EST', label: 'EST (Eastern Standard Time, UTC-5)' },
        { value: 'CST', label: 'CST (Central Standard Time, UTC-6)' },
        { value: 'MST', label: 'MST (Mountain Standard Time, UTC-7)' },
        { value: 'PST', label: 'PST (Pacific Standard Time, UTC-8)' },
        { value: 'IST', label: 'IST (Indian Standard Time, UTC+5:30)' },
        { value: 'CET', label: 'CET (Central European Time, UTC+1)' },
        { value: 'JST', label: 'JST (Japan Standard Time, UTC+9)' },
        { value: 'AEDT', label: 'AEDT (Australian Eastern Daylight Time, UTC+11)' },
    ];
    const dateFormatOptions = [
        { value: 'mm/dd/yy', label: 'mm/dd/yy' },
    ];
    const timeFormatOptions = [
        { value: '12-hour', label: '12-Hour Format (HH:MM AM/PM)' },
        { value: '24-hour', label: '24-Hour Format (HH:MM)' },
        { value: 'ISO', label: 'ISO 8601 Format (YYYY-MM-DDTHH:MM:SSZ)' },
    ];
    const profileVisibilityOptions = [
        { value: 'Public', label: 'Public' },
        { value: 'Private', label: 'Private' },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };

    const fileInputRef = useRef(null);
    const [selectedImg, setSelectedImg] = useState(null); // State for the selected image

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImg(reader.result); // Update the state with the new image
            };
            reader.readAsDataURL(file); // Read the file as a data URL
        }
    };

    return (
        <div className="customer-management-container">
            <Main>
                <div className='components-download-container'>
                    <Download
                        showBackButton={true}
                        moduleName="Profile Details"
                        onBackClick={handleBack}
                    />
                </div>
                <Row className="user-profile-form-container">
                    <Col md={3} className="user-profile-form-first-col">
                        <img src={customerImg} alt="Profile" className="img-fluid rounded-circle mb-3" />
                        <ListGroup variant="flush" className='user-profile-list-items'>
                            <ListGroup.Item><p>ID</p><p>1001</p></ListGroup.Item>
                            <ListGroup.Item><p>Name</p><p>Karthik Karthik</p></ListGroup.Item>
                            <ListGroup.Item><p>Email ID</p><p>Karthikkarthik@gmail.com</p></ListGroup.Item>
                            <ListGroup.Item><p>Role</p><p>RoleDepartment1</p></ListGroup.Item>
                            <ListGroup.Item><p>Department</p><p>DepartmentDepartment1</p></ListGroup.Item>
                            <ListGroup.Item><p>Contact</p><p>9999999999</p></ListGroup.Item>
                            <ListGroup.Item><p>Address</p><p>Address1Address1Address1Address1Address1Address1Address1Address</p></ListGroup.Item>
                        </ListGroup>
                    </Col>

                    <Col md={8} className='user-profile-form-second-col'>
                        <h6>Add User Profile</h6>
                        <hr />
                        <Row className='user-profile-form-row'>
                            <Col className='text-center mb-4 user-profile-form-col'>
                                <div onClick={handleUploadClick}>
                                    <img src={selectedImg || customerImg} alt="" className="img-fluid rounded-circle" style={{ width: '100px', height: '100px' }} />
                                    <input type="file"
                                        hidden
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                    />
                                    <p className="mt-2">Upload Image</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className='user-profile-form-row'>
                            <Col md={4} className='user-profile-form-col'>
                                <Form.Group controlId="formFirstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" placeholder="First Name" />
                                </Form.Group>
                            </Col>
                            <Col md={4} className='user-profile-form-col'>
                                <Form.Group controlId="formMiddleName">
                                    <Form.Label>Middle Name</Form.Label>
                                    <Form.Control type="text" placeholder="Middle Name" />
                                </Form.Group>
                            </Col>
                            <Col md={4} className='user-profile-form-col'>
                                <Form.Group controlId="formLastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" placeholder="Last Name" />
                                </Form.Group>
                            </Col>

                        </Row>

                        <Row className='user-profile-form-row'>
                            <Col md={4} className='user-profile-form-col'>
                                <Form.Group controlId="formEmail">
                                    <Form.Label>Email ID</Form.Label>
                                    <Form.Control type="email" placeholder="Email ID" />
                                </Form.Group>
                            </Col>
                            <Col md={4} className='user-profile-form-col'>
                                <Form.Group controlId="formContactNumber">
                                    <Form.Label>Contact Number</Form.Label>
                                    <div className='user-profile-contact-div'>
                                        <Select
                                            className="react-select-container mr-2"
                                            classNamePrefix="react-select"
                                            options={contactOptions}
                                            placeholder="+91"
                                        />
                                        <Form.Control type="text" placeholder="9999999999" />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md={4} className='user-profile-form-col'>
                                <Form.Group controlId="formAddress">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" placeholder="Address" />
                                </Form.Group>
                            </Col>

                        </Row>

                        <Row className='user-profile-form-row'>
                            <Col md={4} className='user-profile-form-col'>
                                <Form.Group controlId="formZipCode">
                                    <Form.Label>Zip Code</Form.Label>
                                    <Form.Control type="number" placeholder="622151" />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <div className='add-customer-calendar-container'>
                                    <label>Date of Birth</label>
                                    {/* <div className='add-customer-calendar-container'> */}
                                    <DatePicker
                                        className="select from-date"
                                        id="date-range-picker"
                                        dateFormat="dd/MM/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        selectsRange
                                        dropdownMode="select"
                                        placeholderText='DD/MM/YYYY'
                                        // startDate={fromDate}
                                        // onChange={(data) => setSelectSearchDrop(data, "date")}
                                        maxDate={new Date()}
                                    />
                                    <img src={calendar} alt="" />
                                    {/* </div> */}
                                </div>
                            </Col>
                            <Col md={4} className='user-profile-form-col'>
                                <Form.Group controlId="formContactNumber">
                                    <Form.Label>Emergency Contact</Form.Label>
                                    <div className='user-profile-contact-div'>
                                        <Select
                                            className="react-select-container mr-2"
                                            classNamePrefix="react-select"
                                            options={contactOptions}
                                            placeholder="+91"
                                        />
                                        <Form.Control type="text" placeholder="9999999999" />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='user-profile-form-row'>
                            <Col md={4} className='user-profile-form-col'>
                                <Form.Group controlId="formRole">
                                    <Form.Label>Role</Form.Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={roleOptions}
                                        placeholder="Select"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4} className='user-profile-form-col'>
                                <Form.Group controlId="formDepartment">
                                    <Form.Label>Department</Form.Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder="Select"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4} className='user-profile-form-col'>
                                <Form.Group controlId="formJobTitle">
                                    <Form.Label>Job Title</Form.Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder="Select"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='user-profile-form-row'>
                            <Col md={4} className='user-profile-form-col'>
                                <Form.Group controlId="formDateFormat">
                                    <Form.Label>Date Format</Form.Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={dateFormatOptions}
                                        placeholder="Select"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4} className='user-profile-form-col'>
                                <Form.Group controlId="formTimeFormat">
                                    <Form.Label>Time Format</Form.Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={timeFormatOptions}
                                        placeholder="Select"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4} className='user-profile-form-col'>
                                <Form.Group controlId="formTimeZone">
                                    <Form.Label>Time Zone</Form.Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={timeZoneOptions}
                                        placeholder="Select"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='user-profile-form-row'>
                            <Col md={4} className='user-profile-form-col'>
                                <Form.Group controlId="formPassword">
                                    <Form.Label>Total Experience (Year)</Form.Label>
                                    <Form.Control type="number" placeholder="select" />
                                </Form.Group>
                            </Col>
                            <Col md={4} className='user-profile-form-col'>
                                <Form.Group controlId="formProfileVisibility">
                                    <Form.Label>Email Notifications</Form.Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={emailNotificationOptions}
                                        placeholder="Select"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <div className='add-customer-calendar-container'>
                                    <label>Joining Date</label>
                                    {/* <div className='add-customer-calendar-container'> */}
                                    <DatePicker
                                        className="select from-date"
                                        id="date-range-picker"
                                        dateFormat="dd/MM/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        selectsRange
                                        dropdownMode="select"
                                        placeholderText='DD/MM/YYYY'
                                        // startDate={fromDate}
                                        // onChange={(data) => setSelectSearchDrop(data, "date")}
                                        maxDate={new Date()}
                                    />
                                    <img src={calendar} alt="" />
                                    {/* </div> */}
                                </div>
                            </Col>
                        </Row>
                        <Row className='user-profile-form-row mt-3 mb-4 p-4'>
                            <Col md={12} className="d-flex justify-content-center">
                                <SBSaveUpdateBtn btnName="Set Password" onClickEffect={handleOpenModal} />
                            </Col>
                        </Row>
                        {/* <Row className='user-profile-form-row'>
                        <Col md={4} className='user-profile-form-col'>
                            <Form.Group controlId="formProfileVisibility">
                                <Form.Label>Profile Visibility</Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={profileVisibilityOptions}
                                    placeholder="Select"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4} className='user-profile-form-col'>
                            <Form.Group controlId="formEmailNotifications">
                                <Form.Label>Email Notifications</Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={emailNotificationOptions}
                                    placeholder="Select"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4} className='user-profile-form-col'>
                            <Form.Group controlId="formPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" />
                            </Form.Group>
                        </Col>
                        <Col md={4} className='user-profile-form-col'>
                            <Form.Group controlId="formConfirmPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type="password" placeholder="Confirm Password" />
                            </Form.Group>
                        </Col>
                    </Row> */}
                        <hr />
                        <div className="user-profile-submit-btn">
                            <SBSaveUpdateBtn btnName="Submit" />
                        </div>

                    </Col>

                </Row>
            </Main>
            {
                isModalOpen && <SetPasswordModal
                    open={isModalOpen}
                    handleCloseModal={handleCloseModal}
                />
            }
        </div>
    );
};

export default UserProfileForm;
