// import React, { useState } from 'react';
// import ReactECharts from 'echarts-for-react';
// import { Dropdown } from 'react-bootstrap';

// // Example options for dropdowns
// const companyOptions = [
//     { value: 'company-a', label: 'Company A' },
//     { value: 'company-b', label: 'Company B' },
//     { value: 'company-c', label: 'Company C' },
// ];

// const yearOptions = [
//     { value: '2022', label: '2022' },
//     { value: '2023', label: '2023' },
//     { value: '2024', label: '2024' },
// ];

// const FunnelChart = () => {
//     const [selectedCompany, setSelectedCompany] = useState(companyOptions[0]);
//     const [selectedYear, setSelectedYear] = useState(yearOptions[0]);

//     const handleCompanySelect = (company) => {
//         setSelectedCompany(company);
//     };

//     const handleYearSelect = (year) => {
//         setSelectedYear(year);
//     };

//     const option = {
//         // title: {
//         //     text: 'No of Licence',
//         //     left: 'center',
//         //     textStyle: {
//         //         fontSize: 16,
//         //     },
//         // },
//         tooltip: {
//             trigger: 'item',
//             formatter: '{b}: {c}',
//         },
//         legend: {
//             data: ['LC Provider', 'LC Used', 'LC Available'],
//             top: 0,
//         },
//         series: [
//             {
//                 name: 'Licences',
//                 type: 'funnel',
//                 left: 'center',
//                 top: 50,
//                 bottom: 20,
//                 width: '80%',
//                 minSize: '20%',
//                 maxSize: '100%',
//                 sort: 'descending',
//                 gap: 0,
//                 label: {
//                     show: true,
//                     position: 'inside',
//                     formatter: '{b}',
//                     color: '#fff',
//                 },
//                 labelLine: {
//                     length: 10,
//                     lineStyle: {
//                         width: 1,
//                         type: 'solid',
//                     },
//                 },
//                 itemStyle: {
//                     borderColor: '#fff',
//                     borderWidth: 1,
//                 },
//                 data: [
//                     { value: 200, name: 'LC Provider', itemStyle: { color: '#004AAD' } },
//                     { value: 150, name: 'LC Used', itemStyle: { color: '#0F6657' } },
//                     { value: 100, name: 'LC Available', itemStyle: { color: '#91B2FF' } },
//                 ],
//             },
//         ],
//     };

//     return (
//         <div className='barchart-container'>
//             <div className='funnel-chart-header-div'>
//                 <h6 style={{ margin: 0 }}>No of Licence</h6>

//                 <div className='funnel-chart-btns'>
//                     <Dropdown>
//                         <Dropdown.Toggle id="dropdown-company">
//                             {selectedCompany.label}
//                         </Dropdown.Toggle>
//                         <Dropdown.Menu>
//                             {companyOptions.map(option => (
//                                 <Dropdown.Item
//                                     key={option.value}
//                                     onClick={() => handleCompanySelect(option)}
//                                     active={selectedCompany.value === option.value}
//                                 >
//                                     {option.label}
//                                 </Dropdown.Item>
//                             ))}
//                         </Dropdown.Menu>
//                     </Dropdown>
//                     <Dropdown>
//                         <Dropdown.Toggle id="dropdown-year">
//                             {selectedYear.label}
//                         </Dropdown.Toggle>
//                         <Dropdown.Menu>
//                             {yearOptions.map(option => (
//                                 <Dropdown.Item
//                                     key={option.value}
//                                     onClick={() => handleYearSelect(option)}
//                                     active={selectedYear.value === option.value}
//                                 >
//                                     {option.label}
//                                 </Dropdown.Item>
//                             ))}
//                         </Dropdown.Menu>
//                     </Dropdown>
//                 </div>
//             </div>
//             <div className='funnel-chart'>
//                 <ReactECharts option={option} style={{ height: '100%' }} />
//             </div>
//         </div>
//     );
// };

// export default FunnelChart;



import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loadCompanyDropdownData, loadComplaintEscalationIssueTypeData } from '../../redux/action';

// Example options for dropdowns
const companyOptions = [
    { value: 'company-a', label: 'Company A' },
    { value: 'company-b', label: 'Company B' },
    { value: 'company-c', label: 'Company C' },
];

const yearOptions = [
    { value: '2022', label: '2022' },
    { value: '2023', label: '2023' },
    { value: '2024', label: '2024' },
];

const FunnelChart = ({ moduleName, colors, labels, data }) => {
    let dispatch = useDispatch();
    const { getCompanyDropdown } = useSelector((state) => state.data);
    const { complaintEscalationIssueTypeData } = useSelector((state) => state.data);
    console.log(complaintEscalationIssueTypeData, 'complaintEscalationIssueTypeData')

    const signInDetails = JSON.parse(sessionStorage.getItem('SignInDetails'));
    const storedCompanyId = signInDetails ? signInDetails[0].CompanyID : null;
    const storedCompanyName = signInDetails ? signInDetails[0].CompanyName : null;
    const currentYear = new Date().getFullYear();

    const [selectedCompany, setSelectedCompany] = useState({ value: storedCompanyId, label: storedCompanyName });
    const [selectedYear, setSelectedYear] = useState({ value: currentYear, label: currentYear });

    useEffect(() => {
        dispatch(loadCompanyDropdownData());
    }, [dispatch]);

    useEffect(() => {
        if (selectedCompany && selectedYear) {
            dispatch(loadComplaintEscalationIssueTypeData(selectedCompany.value, selectedYear.value))
        }
    }, [selectedCompany, selectedYear])

    const handleCompanySelect = (company) => {
        setSelectedCompany(company);
    };

    const handleYearSelect = (year) => {
        setSelectedYear(year);
    };

    // Function to generate an array of years from 2020 to current year
    function generateYearRange(startYear) {
        const currentYear = new Date().getFullYear();  // Get current year dynamically
        const years = [];

        // Generate years from startYear to currentYear
        for (let year = startYear; year <= currentYear; year++) {
            years.push({ value: year, label: year });
        }

        return years;
    }


    // // Update the data format to use the colors and labels dynamically
    // const formattedData = labels?.map((label, index) => ({
    //     value: data[index].value,
    //     name: label,
    //     itemStyle: { color: colors[index] },
    // }));

    // Map the data from complaintEscalationIssueTypeData to a format suitable for the funnel chart
    const formattedData = complaintEscalationIssueTypeData?.map((complaint, index) => ({
        value: complaint.ComplaintsCount,
        name: complaint.IssueTypeName,
        itemStyle: { color: colors ? colors[index] : '#000' }, // Use dynamic color if available
    }));

    // Get the maximum value of ComplaintsCount to dynamically set the Y-axis range
    const maxComplaintCount = Math.max(...complaintEscalationIssueTypeData?.Complaints?.map((complaint) => complaint.ComplaintsCount) || [0]);

    // Calculate interval based on maxComplaintCount for Y-axis scaling
    const interval = maxComplaintCount > 0 ? Math.ceil(maxComplaintCount / 4) : 1;

    const option = {
        tooltip: {
            trigger: 'item',
            formatter: '{b}: {c}',
        },
        legend: {
            data: labels,
            top: 0,
            selectedMode: false,
        },
        grid: {
            top: 60,
            left: '17%',
            right: '10%',
            bottom: 20,
        },
        xAxis: {
            type: 'category',
            show: false,
        },
        yAxis: {
            type: 'value',
            min: 0,
            max: maxComplaintCount,  // Dynamically set the maximum Y value based on the data
            interval: interval,      // Set the interval dynamically
            axisLine: { lineStyle: { color: 'rgba(246, 246, 246, 1)' } },
            axisLabel: { color: 'rgba(143, 145, 143, 1)' },
            splitLine: {
                show: true,
                lineStyle: {
                    color: 'rgba(246, 246, 246, 1)',
                    type: 'line',
                },
            },
        },
        series: [
            {
                name: 'Licences',
                type: 'funnel',
                left: 'right',
                top: 60,
                bottom: 20,
                width: '80%',
                minSize: '20%',
                maxSize: '100%',
                sort: 'descending',
                gap: 0,
                label: {
                    show: false,
                    position: 'inside',
                    formatter: '{b}',
                    color: '#fff',
                },
                labelLine: {
                    length: 10,
                    lineStyle: {
                        width: 1,
                        type: 'solid',
                    },
                },
                itemStyle: {
                    borderColor: '#fff',
                    borderWidth: 1,
                },
                data: formattedData,
            },
        ],
    };

    return (
        <div className='barchart-container'>
            <div className='funnel-chart-header-div'>
                <h6 style={{ margin: 0 }}>{moduleName}</h6>
                <div className='funnel-chart-btns'>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-company">
                            {selectedCompany.label}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {getCompanyDropdown.map(option => (
                                <Dropdown.Item
                                    key={option.value}
                                    onClick={() => handleCompanySelect(option)}
                                    active={selectedCompany.value === option.value}
                                >
                                    {option.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-year">
                            {selectedYear.label}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {generateYearRange(2022).map(option => (
                                <Dropdown.Item
                                    key={option.value}
                                    onClick={() => handleYearSelect(option)}
                                    active={selectedYear.value === option.value}
                                >
                                    {option.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className='funnel-chart' >
                <ReactECharts option={option} style={{ height: '100%' }} />
            </div>
        </div>
    );
};

export default FunnelChart;
