import React, { useState } from 'react'
import settingsImg from '../../../assets/images/Topbar/settingsImg.svg'
import notificationImg from '../../../assets/images/Topbar/notificationImg.svg'
import profileImg from '../../../assets/images/Topbar/profileImg.svg'
import { Dropdown, Modal } from 'react-bootstrap';
import singleUpload from '../../../assets/images/UserManagement/single upload.svg'
import bulkUpload from '../../../assets/images/UserManagement/bulk upload.svg'
import SbCancelBtn, { SBSaveUpdateBtn } from '../../Buttons/Buttons';
import { useNavigate } from 'react-router-dom';


function Header() {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showProfileDropdown, setShowProfileDropdown] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    const handleDropdownToggle = () => setShowDropdown(!showDropdown);
    const handleProfileDropdownToggle = () => setShowProfileDropdown(!showProfileDropdown);

    const navigate = useNavigate();
    const handleLogoutClick = () => {
        setShowLogoutModal(true); // Show the logout confirmation modal
        setShowProfileDropdown(false); // Close the profile dropdown
    };

    const handleLogoutConfirm = () => {
        sessionStorage.clear(); // Clear session storage
        window.location.href = "/";
    };
    return (
        <div className='header-container'>
            {/* <img src={settingsImg} alt="" /> */}
            <Dropdown show={showDropdown} onToggle={handleDropdownToggle} className='settings-dropdown'>
                <Dropdown.Toggle variant="link" className="no-chevron" onClick={handleDropdownToggle}>
                    <img src={settingsImg} alt="Settings" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#settings">
                        <img src={singleUpload} alt="" />&nbsp;&nbsp;
                        Settings</Dropdown.Item>
                    <Dropdown.Item href="#switch">
                        <img src={bulkUpload} alt="" />&nbsp;&nbsp;
                        Switch to Client</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <img src={notificationImg} alt="" />
            {/* <div className='header-profile-div'>
                <img src={profileImg} alt="" />
                <div className='header-user-name'>
                    <span>Thomas Fleming</span>
                    <p>info@gmail.com</p>
                </div>
            </div> */}
            <Dropdown show={showProfileDropdown} onToggle={handleProfileDropdownToggle} className='settings-dropdown'>
                <Dropdown.Toggle variant="link" className="no-chevron" onClick={handleProfileDropdownToggle}>
                    <div className='header-profile-div'>
                        <img src={profileImg} alt="" />
                        <div className='header-user-name'>
                            <span>SuperAdmin</span>
                            <p>info@gmail.com</p>
                        </div>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item href="#userProfile">
                        <img src={singleUpload} alt="" />&nbsp;&nbsp;
                        Profile Settings</Dropdown.Item>
                    <Dropdown.Item onClick={handleLogoutClick}>
                        <img src={bulkUpload} alt="" />&nbsp;&nbsp;
                        Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Modal show={showLogoutModal} onHide={() => setShowLogoutModal(false)} className='save-custom-view-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to logout?</p>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={() => setShowLogoutModal(false)} />
                    <SBSaveUpdateBtn btnName="Yes" onClickEffect={handleLogoutConfirm} />
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Header
