// import React, { useRef, useEffect, useState } from 'react';
// import { Chart, registerables } from 'chart.js';
// import { Dropdown, Button } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

// Chart.register(...registerables);

// const BarChart = (props) => {
//     const chartRef = useRef(null);
//     const chartInstance = useRef(null);
//     const [selectedYear, setSelectedYear] = useState('2023');

//     const dataForYear = {
//         '2023': [12, 19, 3, 5, 2, 3, 9, 15, 13, 8, 5, 18],
//         '2024': [10, 14, 6, 7, 2, 4, 12, 18, 17, 6, 9, 21],
//     };

//     useEffect(() => {
//         const ctx = chartRef.current.getContext('2d');

//         // Destroy the previous chart instance if it exists
//         if (chartInstance.current) {
//             chartInstance.current.destroy();
//         }

//         chartInstance.current = new Chart(ctx, {
//             type: 'bar',
//             data: {
//                 labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
//                 datasets: [
//                     {
//                         label: 'Customer Details',
//                         data: dataForYear[selectedYear],
//                         backgroundColor: 'rgba(52, 58, 64, 1)',
//                         borderColor: 'rgba(52, 58, 64, 1)',
//                         borderWidth: 1,
//                         borderRadius: 4,
//                         borderSkipped: false,
//                     },
//                 ],
//             },
//             options: {
//                 scales: {
//                     y: {
//                         beginAtZero: true,
//                     },
//                 },
//                 plugins: {
//                     tooltip: {
//                         callbacks: {
//                             label: function (tooltipItem) {
//                                 const data = [
//                                     '1. Company1',
//                                     '2. Company2',
//                                     '3. Company3',
//                                     '4. Company4',
//                                 ];
//                                 return data.join('\n');
//                             },
//                         },
//                     },
//                 },
//                 // Adjust bar width
//                 barPercentage: 0.5,
//                 categoryPercentage: 0.5,
//             },
//         });

//         // Cleanup function to destroy the chart instance on unmount
//         return () => {
//             if (chartInstance.current) {
//                 chartInstance.current.destroy();
//             }
//         };
//     }, [selectedYear]);

//     const handleYearChange = (year) => {
//         setSelectedYear(year);
//     };

//     const downloadChart = () => {
//         const link = document.createElement('a');
//         link.href = chartRef.current.toDataURL('image/png');
//         link.download = 'chart.png';
//         link.click();
//     };

//     return (
//         <div className="barchart-container">
//             <div className="d-flex justify-content-between align-items-center">
//                 <h6>{props.moduleName}</h6>
//                 <div className="d-flex align-items-center">
//                     <Dropdown onSelect={handleYearChange}>
//                         <Dropdown.Toggle id="dropdown-basic">
//                             {selectedYear}
//                         </Dropdown.Toggle>
//                         <Dropdown.Menu  className="custom-dropdown-menu">
//                             <Dropdown.Item eventKey="2023">2023</Dropdown.Item>
//                             <Dropdown.Item eventKey="2024">2024</Dropdown.Item>
//                         </Dropdown.Menu>
//                     </Dropdown>
//                     <Dropdown onSelect={handleYearChange}>
//                         <Dropdown.Toggle id="dropdown-basic">
//                             {selectedYear}
//                         </Dropdown.Toggle>
//                         <Dropdown.Menu  className="custom-dropdown-menu">
//                             <Dropdown.Item eventKey="2023">2023</Dropdown.Item>
//                             <Dropdown.Item eventKey="2024">2024</Dropdown.Item>
//                         </Dropdown.Menu>
//                     </Dropdown>
//                     <Dropdown onSelect={handleYearChange}>
//                         <Dropdown.Toggle id="dropdown-basic">
//                             {selectedYear}
//                         </Dropdown.Toggle>
//                         <Dropdown.Menu  className="custom-dropdown-menu">
//                             <Dropdown.Item eventKey="2023">2023</Dropdown.Item>
//                             <Dropdown.Item eventKey="2024">2024</Dropdown.Item>
//                         </Dropdown.Menu>
//                     </Dropdown>
//                     <Dropdown onSelect={handleYearChange}>
//                         <Dropdown.Toggle id="dropdown-basic">
//                             {selectedYear}
//                         </Dropdown.Toggle>
//                         <Dropdown.Menu  className="custom-dropdown-menu">
//                             <Dropdown.Item eventKey="2023">2023</Dropdown.Item>
//                             <Dropdown.Item eventKey="2024">2024</Dropdown.Item>
//                         </Dropdown.Menu>
//                     </Dropdown>
//                     {/* <Button variant="primary" onClick={downloadChart} className="ml-2">
//             Download
//           </Button> */}
//                 </div>
//             </div>
//             <canvas ref={chartRef} />
//         </div>
//     );
// };

// export default BarChart;



import React, { useRef, useEffect, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import { Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

Chart.register(...registerables);

const BarChart = (props) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    const [selectedYear, setSelectedYear] = useState('2023');
    const [selectedMonth, setSelectedMonth] = useState('Jan');
    const [selectedCompany, setSelectedCompany] = useState('Company1');
    const [selectedLeaveType, setSelectedLeaveType] = useState('Annual');

    const dataForYear = {
        '2023': [22, 19, 13, 25, 2, 3, 9, 15, 13, 8, 5, 18],
        '2024': [10, 14, 6, 7, 2, 4, 12, 18, 17, 6, 9, 21],
    };

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const companies = ['Company1', 'Company2', 'Company3', 'Company4'];
    const leaveTypes = ['Annual', 'Sick', 'Unpaid', 'Other'];

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');

        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        chartInstance.current = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: months,
                datasets: [
                    {
                        label: props.label,
                        data: dataForYear[selectedYear],
                        backgroundColor: 'rgba(52, 58, 64, 1)',
                        borderColor: 'rgba(52, 58, 64, 1)',
                        borderWidth: 1,
                        borderRadius: 4,
                        borderSkipped: false,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        grid: {
                            display: true,
                            borderColor: 'rgba(246, 246, 246, 1)',
                            borderWidth: 0.5,
                            lineWidth: 0.5,
                        },
                        beginAtZero: true,
                    },
                },
                plugins: {
                    legend: {
                        onClick: (e, legendItem, legend) => {
                            // No action to hide the dataset when clicking the legend
                            return;
                        },
                    },
                    tooltip: {
                        callbacks: {
                            label: function (tooltipItem) {
                                return `${selectedCompany}: ${tooltipItem.raw} ${selectedLeaveType}`;
                            },
                        },
                    },
                },
                barPercentage: 0.3,
                categoryPercentage: 0.5,
            },
        });

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [selectedYear, selectedMonth, selectedCompany, selectedLeaveType]);

    const handleYearChange = (year) => {
        setSelectedYear(year);
    };

    const handleMonthChange = (month) => {
        setSelectedMonth(month);
    };

    const handleCompanyChange = (company) => {
        setSelectedCompany(company);
    };

    const handleLeaveTypeChange = (leaveType) => {
        setSelectedLeaveType(leaveType);
    };

    const downloadChart = () => {
        const link = document.createElement('a');
        link.href = chartRef.current.toDataURL('image/png');
        link.download = 'chart.png';
        link.click();
    };

    return (
        <div className="barchart-container">
            <div className="d-flex justify-content-between align-items-center" style={{ marginBottom: '10px' }}>
                <h6>{props.moduleName}</h6>
                <div className="d-flex align-items-center gap-2">
                    {props.showTypeNameMonth && <div className="d-flex align-items-center gap-2">
                        <Dropdown onSelect={handleLeaveTypeChange}>
                            <Dropdown.Toggle id="dropdown-leave-type">
                                {selectedLeaveType}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {leaveTypes.map(type => (
                                    <Dropdown.Item key={type} eventKey={type}>
                                        {type}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown onSelect={handleCompanyChange}>
                            <Dropdown.Toggle id="dropdown-company">
                                {selectedCompany}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {companies.map(company => (
                                    <Dropdown.Item key={company} eventKey={company}>
                                        {company}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown onSelect={handleMonthChange}>
                            <Dropdown.Toggle id="dropdown-month">
                                {selectedMonth}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {months.map(month => (
                                    <Dropdown.Item key={month} eventKey={month}>
                                        {month}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>}
                    {props.showYear && <Dropdown onSelect={handleYearChange}>
                        <Dropdown.Toggle id="dropdown-year">
                            {selectedYear}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="2023">2023</Dropdown.Item>
                            <Dropdown.Item eventKey="2024">2024</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>}
                </div>
            </div>
            <div className="barchart-canvas-container">
                <canvas ref={chartRef} />
            </div>
        </div>
    );
};

export default BarChart;


