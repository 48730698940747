import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../Buttons/Buttons";
import Select from 'react-select';
import attachmentImg from '../../assets/images/attachmentImg.svg';
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadBranchDropdownData, loadCompanyDropdownData, loadIssueTypeDropdownData, loadReportingToDropdownData, postComplaintEscalationDatas } from "../../redux/action";
import { ADDED_BY_ID } from "../Constants";
import * as HeaderAuthentication from '../HeaderAuthentication';
import { baseURL } from "../BaseUrl";

const statusOptions = [
    { value: 36, label: "Pending" },
    { value: 37, label: "In-Process" },
    { value: 38, label: "Resolved" },
    { value: 39, label: "Re-Open" },
    { value: 40, label: "Cancel" },
]

const ticketTypeOptions = [
    { value: "Complaint Ticket", label: "Complaint Ticket" },
    { value: "Escalation Ticket", label: "Escalation Ticket" }
]

const priorityOptions = [
    { value: "Low", label: "Low" },
    { value: "Medium", label: "Medium" },
    { value: "High", label: "High" },
]

const AddComplaintEscalation = (props) => {

    let dispatch = useDispatch();
    const getComplaintData = props.getComplaintData;
    const singleComplaintEscalationData = props.singleComplaintEscalationData;

    const complaintId = props.complaintId;
    const { reportingToDropdown } = useSelector((state) => state.data);
    const { branchDropdown } = useSelector((state) => state.data);
    const { issueTypeDropdownData } = useSelector((state) => state.data);
    const { getCompanyDropdown } = useSelector((state) => state.data);

    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
    const [complaintData, setComplaintData] = useState({
        ComplaintID: null,
        TicketType: "",
        CompanyID: null,
        ReportedByUserID: null,
        BranchID: null,
        Priority: "",
        IssueTypeID: null,
        Email: "",
        ContactNo: "",
        ZIPCode: "",
        Address: "",
        AssignedToUserID: null,
        TATInDays: null,
        UserUploadedFileName: null,
        TempFileName: null,
        Remarks: "",
        StatusID: null,
        AddedByID: ADDED_BY_ID
    });

    const [errors, setErrors] = useState({}); // Error state to store validation errors

    // Handle file selection and upload
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file); // Store the selected file in the state
            // Update complaint data with file name
            setComplaintData(prevData => ({
                ...prevData,
                UserUploadedFileName: file.name,
                // TempFileName: file.name 
            }));

            // File upload API call
            uploadFile(file);
        }
    };

    // Trigger file input click
    const handleAttachmentClick = () => {
        fileInputRef.current.click();
    };

    // Effect hook for loading dropdown data
    useEffect(() => {
        dispatch(loadReportingToDropdownData());
        dispatch(loadBranchDropdownData());
        dispatch(loadIssueTypeDropdownData());
        dispatch(loadCompanyDropdownData());
    }, [dispatch]);

    useEffect(() => {
        // Check if getSingleComplainEscalationData is not empty
        if (complaintId && singleComplaintEscalationData && singleComplaintEscalationData.length > 0) {
            // Get the first complaint data
            const complaint = singleComplaintEscalationData[0];

            // Set the complaint data to populate the form
            setComplaintData({
                ComplaintID: complaintId,
                TicketType: complaint.TicketType || "",
                CompanyID: complaint.CompanyID || null,
                ReportedByUserID: complaint.ReportedByUserID || null,
                BranchID: complaint.BranchID || null,
                Priority: complaint.Priority || "",
                IssueTypeID: complaint.IssueTypeID || null,
                Email: complaint.Email || "",
                ContactNo: complaint.ContactNo || "",
                ZIPCode: complaint.ZIPCode || "",
                Address: complaint.Address || "",
                AssignedToUserID: complaint.AssignedToUserID || null,
                TATInDays: complaint.TATInDays || null,
                UserUploadedFileName: complaint.AttachedFileName || null,
                TempFileName: complaint.AttachedFileName || null,  // Or use another value if needed
                Remarks: complaint.Remarks || "",
                StatusID: complaint.StatusID || null,
                AddedByID: ADDED_BY_ID // Keep this constant if it's the same for all complaints
            });
        }
    }, [complaintId, singleComplaintEscalationData]);

    // File upload function
    const uploadFile = (file) => {
        const formData = new FormData();
        formData.append("file", file); // Attach the selected file to the form data

        fetch(`${baseURL}/complaintservices/api/Complaint/UploadFile`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.excomatixAuth,
            body: formData,
        })
            .then((resp) => resp.json())
            .then((resp) => {
                //  update the TempFileName from response
                setComplaintData(prevData => ({
                    ...prevData,
                    TempFileName: resp // Update with the TempFileName from the response
                }));
            })
            .catch((err) => {
                console.error("File upload failed:", err);
            });
    };

    // Validate form fields
    const validateForm = () => {
        const newErrors = {};
        if (!complaintData.TicketType) newErrors.TicketType = "Ticket Type is required.";
        if (!complaintData.CompanyID) newErrors.CompanyID = "Company Name is required.";
        if (!complaintData.ReportedByUserID) newErrors.ReportedByUserID = "Reported By is required.";
        if (!complaintData.BranchID) newErrors.BranchID = "Branch is required.";
        if (!complaintData.Priority) newErrors.Priority = "Priority is required.";
        if (!complaintData.IssueTypeID) newErrors.IssueTypeID = "Issue Type is required.";
        if (!complaintData.Email) newErrors.Email = "Email is required.";
        if (!complaintData.ContactNo) newErrors.ContactNo = "Contact is required.";
        if (!complaintData.ZIPCode) newErrors.ZIPCode = "Zip Code is required.";
        if (!complaintData.Address) newErrors.Address = "Address is required.";
        if (!complaintData.AssignedToUserID) newErrors.AssignedToUserID = "Assigned To is required.";
        if (!complaintData.TATInDays) newErrors.TATInDays = "TAT (Days) is required.";
        if (!complaintData.Remarks) newErrors.Remarks = "Remarks is required.";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle form submission (add complaint escalation data)
    const addComplaintEscalationData = (e) => {
        e.preventDefault();
        if (validateForm()) {
            let requestOption = {
                method: "POST",
                mode: "cors",
                headers: HeaderAuthentication.postExcomatixAuth,
                body: JSON.stringify(complaintData),
            };
            dispatch(postComplaintEscalationDatas(requestOption, props.handleCloseModal, getComplaintData, complaintId));
            // console.log(complaintData, 'complaintData');
        }
    }

    // Clear error for specific field
    const clearError = (field) => {
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors[field];
            return newErrors;
        });
    };

    return (
        <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-user-modal'>
            <Modal.Header closeButton>
                <Modal.Title>Add Complaints</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='customer-form-fields'>
                    <Col>
                        <Row>
                            <Col md={4}>
                                <div>
                                    <label>Ticket Type <span className="required-asterisk">*</span></label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={ticketTypeOptions}
                                        value={ticketTypeOptions.find(option => option.value === complaintData.TicketType)}
                                        // onChange={(selectedOption) => setComplaintData(prevData => ({ ...prevData, TicketType: selectedOption.value }))}
                                        onChange={(selectedOption) => {
                                            setComplaintData(prevData => ({ ...prevData, TicketType: selectedOption.value }));
                                            clearError("TicketType");
                                        }}
                                        placeholder="Select"
                                    />
                                </div>
                                {errors.TicketType && <small className="text-danger">{errors.TicketType}</small>}
                            </Col>
                            <Col md={4}>
                                <div>
                                    <label>Company Name <span className="required-asterisk">*</span></label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        value={getCompanyDropdown.find(option => option.value === complaintData.CompanyID)}
                                        // onChange={(selectedOption) => setComplaintData(prevData => ({ ...prevData, CompanyID: selectedOption.value }))}
                                        onChange={(selectedOption) => {
                                            setComplaintData(prevData => ({ ...prevData, CompanyID: selectedOption.value }));
                                            clearError("CompanyID");
                                        }}
                                        options={getCompanyDropdown}
                                        placeholder="Select"
                                    />
                                </div>
                                {errors.CompanyID && <small className="text-danger">{errors.CompanyID}</small>}
                            </Col>
                            <Col md={4}>
                                <div>
                                    <label>Reported By <span className="required-asterisk">*</span></label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        value={reportingToDropdown.find(option => option.value === complaintData.ReportedByUserID)}
                                        // onChange={(selectedOption) => setComplaintData(prevData => ({ ...prevData, ReportedByUserID: selectedOption.value }))}
                                        onChange={(selectedOption) => {
                                            setComplaintData(prevData => ({ ...prevData, ReportedByUserID: selectedOption.value }));
                                            clearError("ReportedByUserID");
                                        }}
                                        options={reportingToDropdown}
                                        placeholder="Select"
                                    />
                                </div>
                                {errors.ReportedByUserID && <small className="text-danger">{errors.ReportedByUserID}</small>}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <div>
                                    <label>Branch <span className="required-asterisk">*</span></label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        value={branchDropdown.find(option => option.value === complaintData.BranchID)}
                                        // onChange={(selectedOption) => setComplaintData(prevData => ({ ...prevData, BranchID: selectedOption.value }))}
                                        onChange={(selectedOption) => {
                                            setComplaintData(prevData => ({ ...prevData, BranchID: selectedOption.value }));
                                            clearError("BranchID");
                                        }}
                                        options={branchDropdown}
                                        placeholder="Select"
                                    />
                                </div>
                                {errors.BranchID && <small className="text-danger">{errors.BranchID}</small>}
                            </Col>
                            <Col md={4}>
                                <div>
                                    <label>Priority <span className="required-asterisk">*</span></label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        value={priorityOptions.find(option => option.value === complaintData.Priority)}
                                        // onChange={(selectedOption) => setComplaintData(prevData => ({ ...prevData, Priority: selectedOption.value }))}
                                        onChange={(selectedOption) => {
                                            setComplaintData(prevData => ({ ...prevData, Priority: selectedOption.value }));
                                            clearError("Priority");
                                        }}
                                        options={priorityOptions}
                                        placeholder="Select"
                                    />
                                </div>
                                {errors.Priority && <small className="text-danger">{errors.Priority}</small>}
                            </Col>
                            <Col md={4}>
                                <div>
                                    <label>Issue Type <span className="required-asterisk">*</span></label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        value={issueTypeDropdownData.find(option => option.value === complaintData.IssueTypeID)}
                                        // onChange={(selectedOption) => setComplaintData(prevData => ({ ...prevData, IssueTypeID: selectedOption.value }))}
                                        onChange={(selectedOption) => {
                                            setComplaintData(prevData => ({ ...prevData, IssueTypeID: selectedOption.value }));
                                            clearError("IssueTypeID");
                                        }}
                                        options={issueTypeDropdownData}
                                        placeholder="Select"
                                    />
                                </div>
                                {errors.IssueTypeID && <small className="text-danger">{errors.IssueTypeID}</small>}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <div>
                                    <label>Email <span className="required-asterisk">*</span></label>
                                    <Form.Control
                                        type="email"
                                        value={complaintData.Email}
                                        // onChange={(e) => setComplaintData({ ...complaintData, Email: e.target.value })}
                                        onChange={(e) => {
                                            const email = e.target.value;
                                            setComplaintData(prevData => ({ ...prevData, Email: email }));

                                            // Clear the email error when the user starts typing
                                            if (errors.Email) {
                                                clearError("Email");
                                            }
                                        }}
                                    />
                                </div>
                                {errors.Email && <small className="text-danger">{errors.Email}</small>}
                            </Col>
                            <Col md={4}>
                                <div>
                                    <label>Contact <span className="required-asterisk">*</span></label>
                                    <Form.Control
                                        type="tel"
                                        value={complaintData.ContactNo}
                                        // onChange={(e) => setComplaintData({ ...complaintData, ContactNo: e.target.value })}
                                        onChange={(e) => {
                                            const contact = e.target.value;
                                            setComplaintData(prevData => ({ ...prevData, ContactNo: contact }));

                                            // Clear the contact error when the user starts typing
                                            if (errors.ContactNo) {
                                                clearError("ContactNo");
                                            }
                                        }}
                                    />
                                </div>
                                {errors.ContactNo && <small className="text-danger">{errors.ContactNo}</small>}
                            </Col>
                            <Col md={4}>
                                <div>
                                    <label>Zip Code <span className="required-asterisk">*</span></label>
                                    <Form.Control
                                        type="number"
                                        value={complaintData.ZIPCode}
                                        // onChange={(e) => setComplaintData({ ...complaintData, ZIPCode: e.target.value })}
                                        onChange={(e) => {
                                            const zipCode = e.target.value;
                                            setComplaintData(prevData => ({ ...prevData, ZIPCode: zipCode }));

                                            // Clear the zipCode error when the user starts typing
                                            if (errors.ZIPCode) {
                                                clearError("ZIPCode");
                                            }
                                        }}
                                    />
                                </div>
                                {errors.ZIPCode && <small className="text-danger">{errors.ZIPCode}</small>}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div>
                                    <label>Address <span className="required-asterisk">*</span></label>
                                    <Form.Control
                                        type="text"
                                        value={complaintData.Address}
                                        // onChange={(e) => setComplaintData({ ...complaintData, Address: e.target.value })}
                                        onChange={(e) => {
                                            const address = e.target.value;
                                            setComplaintData(prevData => ({ ...prevData, Address: address }));

                                            // Clear the address error when the user starts typing
                                            if (errors.Address) {
                                                clearError("Address");
                                            }
                                        }}
                                    />
                                </div>
                                {errors.Address && <small className="text-danger">{errors.Address}</small>}
                            </Col>

                        </Row>
                        <Row>
                            <Col md={4}>
                                <div>
                                    <label>Assigned To <span className="required-asterisk">*</span></label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        value={reportingToDropdown.find(option => option.value === complaintData.AssignedToUserID)}
                                        // onChange={(selectedOption) => setComplaintData(prevData => ({ ...prevData, AssignedToUserID: selectedOption.value }))}
                                        onChange={(selectedOption) => {
                                            setComplaintData(prevData => ({ ...prevData, AssignedToUserID: selectedOption.value }));
                                            clearError("AssignedToUserID");
                                        }}
                                        options={reportingToDropdown}
                                        placeholder="Select"
                                    />
                                </div>
                                {errors.AssignedToUserID && <small className="text-danger">{errors.AssignedToUserID}</small>}
                            </Col>
                            <Col md={4}>
                                <div>
                                    <label>TAT(Days) <span className="required-asterisk">*</span></label>
                                    <Form.Control
                                        type="number"
                                        value={complaintData.TATInDays}
                                        // onChange={(e) => setComplaintData({ ...complaintData, TATInDays: e.target.value })}
                                        onChange={(e) => {
                                            const tat = e.target.value;
                                            setComplaintData(prevData => ({ ...prevData, TATInDays: tat }));

                                            // Clear the tat error when the user starts typing
                                            if (errors.TATInDays) {
                                                clearError("TATInDays");
                                            }
                                        }}
                                    />
                                </div>
                                {errors.TATInDays && <small className="text-danger">{errors.TATInDays}</small>}
                            </Col>
                            <Col md={4} className="d-flex justify-content-start align-items-end">
                                <div className="dropdown add-single-bulk attachment-section">
                                    <label>&nbsp;</label>
                                    <button
                                        className="btn btn-add-save-update attachment-btn"
                                        id="adduser-single-blulk"
                                        onClick={handleAttachmentClick}
                                    >
                                        <img src={attachmentImg} alt="" />&nbsp;&nbsp;
                                        <span>Attachment</span>
                                        <div className="attachment-note">
                                            <small>(jpg, PDF, word doc) Less than 5MB</small>
                                        </div>
                                    </button>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                    {selectedFile && <div className="attachment-note"><small>{selectedFile.name}</small></div>}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div>
                                    <label>Remarks <span className="required-asterisk">*</span></label>
                                    <Form.Control
                                        type="text"
                                        value={complaintData.Remarks}
                                        // onChange={(e) => setComplaintData({ ...complaintData, Remarks: e.target.value })}
                                        onChange={(e) => {
                                            const remarks = e.target.value;
                                            setComplaintData(prevData => ({ ...prevData, Remarks: remarks }));

                                            // Clear the remarks error when the user starts typing
                                            if (errors.Remarks) {
                                                clearError("Remarks");
                                            }
                                        }}
                                    />
                                </div>
                                {errors.Remarks && <small className="text-danger">{errors.Remarks}</small>}
                            </Col>
                            {complaintId && <Col md={4}>
                                <div>
                                    <label>Status</label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        value={statusOptions.find(option => option.value === complaintData.StatusID)}
                                        onChange={(selectedOption) => setComplaintData(prevData => ({ ...prevData, StatusID: selectedOption.value }))}
                                        options={statusOptions}
                                        placeholder="Select"
                                    />
                                </div>
                            </Col>}

                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                <SBSaveUpdateBtn btnName="Submit" onClickEffect={addComplaintEscalationData} />
            </Modal.Footer>
        </Modal>
    );
}

export default AddComplaintEscalation;

