import { Col, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../Buttons/Buttons";
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { loadDepartmentDropdownData, loadUserDropdownDataBasedDepartment, postSetAssignToDatas } from "../../redux/action";
import * as HeaderAuthentication from '../HeaderAuthentication'

const AssignToModal = (props) => {

    let dispatch = useDispatch();
    const workqueueId = props.workqueueId;
    const workqueuesDatas = props.workqueuesDatas;
    const { departmentDropdown } = useSelector((state) => state.data);
    const { userDropdownBasedDepartment } = useSelector((state) => state.data);

    // State to store selected department and user
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        dispatch(loadDepartmentDropdownData());
    }, [dispatch]);

    // Handle department selection change
    const handleDepartmentChange = (selectedOption) => {
        setSelectedDepartment(selectedOption); // Update selected department
        setSelectedUser(null); // Clear selected user when department changes
        if (selectedOption) {
            // Dispatch action to fetch users based on selected department
            dispatch(loadUserDropdownDataBasedDepartment(selectedOption.value)); // Pass the DepartmentID
        }
    };

    // Handle user selection change
    const handleUserChange = (selectedOption) => {
        setSelectedUser(selectedOption); // Update selected user
    };

    const addSetAssignTo = (e) => {
        e.preventDefault();
        const data = {
            WorkQueueID: workqueueId,
            DepartmentID: selectedDepartment ? selectedDepartment.value : '',
            AssignedToID: selectedUser ? selectedUser.value : '',
        }
        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data),
        };
        dispatch(postSetAssignToDatas(requestOption, props.handleClose, workqueuesDatas));
        // console.log(data, 'data')
    }

    return (
        <>
            <Modal show={props.open} onHide={props.handleClose} className='add-customer-modal add-user-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Set Assign To</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Select Department</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            value={selectedDepartment} // Bind selected department to the value prop
                                            onChange={handleDepartmentChange} // Set the handler for the change event
                                            options={departmentDropdown}
                                            placeholder="Select Department"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Select User</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            value={selectedUser} // Bind selected user to the value prop
                                            onChange={handleUserChange} // Set the handler for the change event
                                            options={userDropdownBasedDepartment}
                                            placeholder="Select User"
                                            isDisabled={!selectedDepartment} // Disable if no department is selected
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Modal.Body >
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={addSetAssignTo} />
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default AssignToModal;