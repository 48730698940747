// import React, { useEffect, useState } from 'react';
// import { Button, Card, Carousel, Col, Row } from 'react-bootstrap';
// import { useLocation } from 'react-router-dom';  // Import useLocation to access query parameters

// // Importing images (replace these with actual paths)
// import bg_img from './assets/images/Login/login-img1.svg';
// import bg_img1 from './assets/images/Login/login-img2.svg';
// import companyLogo from './assets/images/Login/signin-company-logo.svg';
// import { useDispatch } from 'react-redux';
// import { postResendVerifyEmail } from './redux/action';
// import * as HeaderAuthentication from '../src/components/HeaderAuthentication';
// import successImg from '../src/assets/images/Login/successImg.svg'
// import failedImg from '../src/assets/images/Login/failedImg.svg'

// function VerifyCompany() {
//     let dispatch = useDispatch();
//     const [verificationStatus, setVerificationStatus] = useState(null);  // Holds the status (success or failure)
//     const [responseMessage, setResponseMessage] = useState('');  // Holds the dynamic response message
//     const [success, setSuccess] = useState(null);  // Holds the success flag to show either 'Resend' or 'Continue'
//     const [resendEmail, setResendEmail] = useState(
//         {
//             Company_Code: ""
//         }
//     );

//     // Get query parameters from URL using useLocation()
//     const location = useLocation();
//     const params = new URLSearchParams(location.search);

//     const TK = params.get('TK');
//     const CC = params.get('CC');
//     const TD = params.get('TD');

//     useEffect(() => {
//         // If the parameters are available, call the API with the extracted parameters
//         if (TK && CC && TD) {
//             const requestData = {
//                 TokenGeneratedDate: TD,
//                 Company_Code: CC,
//                 VerificationToken: TK,
//             };

//             // Call the API with the extracted parameters
//             verifyCompany(requestData);
//         } else {
//             setVerificationStatus('failure');
//             setResponseMessage('Invalid or missing parameters.');
//         }
//     }, [TK, CC, TD]);  // Run the effect when params change

//     // Function to call the API using .then() and .catch()
//     const verifyCompany = (data) => {
//         fetch('http://excomatixapi.betaphase.in/companyservices/api/company/PostVerifyEmail', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 "X-Api-Key": "f238b70537400820398f84fa1e1d12344314914eb7803d96b2ad497efed288d5",
//             },
//             body: JSON.stringify(data),
//         })
//             .then((response) => {
//                 if (!response.ok) {
//                     throw new Error('Verification failed');
//                 }
//                 return response.json();
//             })
//             .then((result) => {
//                 // Check if the verification is successful and update state accordingly
//                 setVerificationStatus('success');
//                 setResponseMessage(result?.Msg);

//                 // Set the success flag to show the appropriate button
//                 if (result?.Success === 0) {
//                     setSuccess(0);  // Show 'Resend' button
//                 } else {
//                     setSuccess(1);  // Show 'Continue' button
//                 }
//             })
//             .catch((error) => {
//                 setVerificationStatus('failure');
//                 setResponseMessage(error?.message || 'An error occurred');
//                 setSuccess(0);  // If there's an error, assume 'Resend' should be shown
//             });
//     };

//     const navigateToSignin = () => {
//         window.location.href = '#/'
//     }

//     const handleResendEmailVerification = (e) => {
//         e.preventDefault();
//         let requestOption = {
//             method: "POST",
//             mode: "cors",
//             headers: HeaderAuthentication.postExcomatixAuth,
//             body: JSON.stringify({
//                 Company_Code: CC  // Set the Company_Code extracted from the URL
//             }),
//         };
//         dispatch(postResendVerifyEmail(requestOption));
//         // console.log(escalationApprovalData, 'escalationApprovalData')
//     }

//     return (
//         <div className="signin-main-container">
//             <Row className="lm-signin-login-row">
//                 <Col md={6} className="lm-signin-form-col">
//                     {/* <Card className="company-verification-card"> */}
//                     {/* <Card.Body> */}
//                     <div className="company-verification-content">
//                         {/* Display message based on verification status */}
//                         {verificationStatus === 'success' && (
//                             <>
//                                 <p>{responseMessage}</p> {/* Display the dynamic success message */}

//                             </>
//                         )}

//                         {verificationStatus === 'failure' && (
//                             <>
//                                 <p>{responseMessage}</p> {/* Display dynamic error message */}
//                                 <img src={failedImg} alt="" />
//                             </>
//                         )}
//                     </div>

//                     <div className="lm-login-form-btn">
//                         {success === 1 ? (
//                             <Button onClick={navigateToSignin}>Continue</Button>
//                         ) : (
//                             <Button onClick={handleResendEmailVerification}>Resend</Button>
//                         )}
//                     </div>
//                     {/* </Card.Body> */}
//                     {/* </Card> */}
//                 </Col>
//                 <Col md={6} className="lm-signin-image-col">
//                     <Carousel style={{ width: "100%" }} controls={false} indicators={false}>
//                         <Carousel.Item>
//                             <div className="lm-bg-img-div">
//                                 <img src={bg_img} alt="" className="lm-bg-img" />
//                                 <div className="carousel-content">
//                                     <img src={companyLogo} alt="Company Logo" className="carousel-logo" />
//                                 </div>
//                             </div>
//                         </Carousel.Item>
//                         <Carousel.Item>
//                             <div className="lm-bg-img-div">
//                                 <img src={bg_img1} alt="" className="lm-bg-img" />
//                                 <div className="carousel-content">
//                                     <img src={companyLogo} alt="Company Logo" className="carousel-logo" />
//                                 </div>
//                             </div>
//                         </Carousel.Item>
//                     </Carousel>
//                 </Col>
//             </Row>
//         </div>
//     );
// }

// export default VerifyCompany;



import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Carousel } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';  // Import useLocation to access query parameters
import { useDispatch } from 'react-redux';
import { postResendVerifyEmail } from './redux/action';
import * as HeaderAuthentication from '../src/components/HeaderAuthentication';
import successImg from '../src/assets/images/Login/successImg.svg';
import failedImg from '../src/assets/images/Login/failedImg.svg';
import bg_img from './assets/images/Login/login-img1.svg';
import bg_img1 from './assets/images/Login/login-img2.svg';
import companyLogo from './assets/images/Login/signin-company-logo.svg';

function VerifyCompany() {
    let dispatch = useDispatch();

    // Initialize state
    const [verificationState, setVerificationState] = useState({
        status: null,  // Holds the status ('success' or 'failure')
        message: '',   // Holds the dynamic response message
        successFlag: null  // Holds the success flag to show either 'Resend' or 'Continue'
    });

    const [loading, setLoading] = useState(true);  // State to track loading status

    // Extract query parameters
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const TK = params.get('TK');
    const CC = params.get('CC');
    const TD = params.get('TD');

    useEffect(() => {
        // Ensure valid parameters are passed
        if (TK && CC && TD) {
            const requestData = {
                TokenGeneratedDate: TD,
                Company_Code: CC,
                VerificationToken: TK,
            };
            // Call the API to verify company
            verifyCompany(requestData);
        } else {
            setVerificationState({
                status: 'failure',
                message: 'Invalid or missing parameters.',
                successFlag: 0
            });
            setLoading(false);  // Stop loading as response is received
        }
    }, [TK, CC, TD]);  // Effect to trigger when params change

    // Function to call the API
    const verifyCompany = (data) => {
        fetch('http://excomatixapi.betaphase.in/companyservices/api/company/PostVerifyEmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "X-Api-Key": "f238b70537400820398f84fa1e1d12344314914eb7803d96b2ad497efed288d5",
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Verification failed');
                }
                return response.json();
            })
            .then((result) => {
                // Update state based on the result
                setVerificationState({
                    status: result?.Success === 0 ? 'failure' : 'success',
                    message: result?.Msg,
                    successFlag: result?.Success === 0 ? 0 : 1
                });
                setLoading(false);  // Set loading to false after receiving response
            })
            .catch((error) => {
                setVerificationState({
                    status: 'failure',
                    message: error?.message || 'An error occurred',
                    successFlag: 0
                });
                setLoading(false);  // Set loading to false after receiving response
            });
    };

    // Function to navigate to SignIn page
    const navigateToSignin = () => {
        window.location.href = '#/';
    }

    // Function to resend verification email
    const handleResendEmailVerification = (e) => {
        e.preventDefault();
        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                Company_Code: CC  // Set the Company_Code extracted from the URL
            }),
        };
        dispatch(postResendVerifyEmail(requestOption));
    }

    return (
        <div className="signin-main-container">
            <Row className="lm-signin-login-row">
                <Col md={6} className="lm-signin-form-col verification-img-btn-col">
                    <div className="company-verification-content">
                        {/* Conditionally render loading message or verification status */}
                        {loading ? (
                            <p>Loading...</p>  // Display loading message until response is received
                        ) : (
                            <>
                                {/* Display message based on verification status */}
                                {verificationState.status === 'success' && (
                                    <>
                                        <span>Congratulations</span>
                                        <p>{verificationState.message}</p> {/* Display dynamic success message */}
                                    </>
                                )}

                                {verificationState.status === 'failure' && (
                                    <>
                                        <span>Failed !</span>
                                        <p>{verificationState.message}</p> {/* Display dynamic error message */}
                                    </>
                                )}
                            </>
                        )}
                    </div>

                    {/* Conditionally render the form and button */}
                    {!loading && (
                        <div className='lm-login-form'>
                            <div className="lm-login-form-btn verification-btn">
                                {verificationState.successFlag === 1 ? (
                                    <>
                                        <img src={successImg} alt="success" />
                                        <Button onClick={navigateToSignin}>Continue</Button>
                                    </>
                                ) : (
                                    <>
                                        <img src={failedImg} alt="failed" />
                                        <Button onClick={handleResendEmailVerification}>Resend</Button>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </Col>
                <Col md={6} className="lm-signin-image-col">
                    <Carousel style={{ width: "100%" }} controls={false} indicators={false}>
                        <Carousel.Item>
                            <div className="lm-bg-img-div">
                                <img src={bg_img} alt="background" className="lm-bg-img" />
                                <div className="carousel-content">
                                    <img src={companyLogo} alt="Company Logo" className="carousel-logo" />
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="lm-bg-img-div">
                                <img src={bg_img1} alt="background" className="lm-bg-img" />
                                <div className="carousel-content">
                                    <img src={companyLogo} alt="Company Logo" className="carousel-logo" />
                                </div>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                </Col>
            </Row>
        </div>
    );
}

export default VerifyCompany;

