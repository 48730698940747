import React, { useEffect, useState } from 'react';
import { Col, Row, Container, ListGroup, Tab, Nav, Accordion } from "react-bootstrap";
import Main from "../Main";
import customerImg from '../../assets/images/CustomerManagement/customer-img.svg';
import Download from '../MainSection/Download/Download';
import { useNavigate } from 'react-router-dom';

const CustomerViewDetails = ({ handleAnalyticsClick, singleCustomerData, setShowCustomerViewDetails }) => {

    // State to manage which accordion item is open
    const [activeKey, setActiveKey] = useState(null);

    // Separate state for each tab's active accordion item
    const [activeBusinessUnitKey, setActiveBusinessUnitKey] = useState(null);
    const [activeBranchPlantKey, setActiveBranchPlantKey] = useState(null);
    const [activeStorageLocationKey, setActiveStorageLocationKey] = useState(null);

    const handleBack = () => {
        setShowCustomerViewDetails(false);
    }

    // Extract data from the API response
    // Safely access customer data, and fallback to empty if undefined or empty
    const customers = singleCustomerData?.Customers || [];
    const customer = customers[0]; // Safely get the first customer or undefined if empty

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const businessUnits = singleCustomerData.BusinessUnits || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const branchPlants = singleCustomerData.Branch || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const storageLocations = singleCustomerData.Slocs || [];

    // Set the default active key for each accordion when data is loaded
    useEffect(() => {
        if (businessUnits.length > 0) {
            setActiveBusinessUnitKey(businessUnits[0].BusinessUnitID.toString()); // Set first business unit open by default
        }
        if (branchPlants.length > 0) {
            setActiveBranchPlantKey(branchPlants[0].BranchID.toString()); // Set first branch plant open by default
        }
        if (storageLocations.length > 0) {
            setActiveStorageLocationKey(storageLocations[0].SlockID.toString()); // Set first storage location open by default
        }
    }, [businessUnits, branchPlants, storageLocations]);  // Only run this when data is loaded

    return (
        <div className="customer-management-view-details-container">
            <Main>
                {/* <Container> */}
                <div className='components-download-container'>
                    <Download
                        moduleName="Back"
                        showBackButton={true}
                        showDashboardLibraryButton={false}
                        showSaveCustomViewButton={false}
                        showAnalyticsButton={true}
                        showDownloadDropdown={true}
                        onClick={handleAnalyticsClick}
                        onBackClick={handleBack}
                    />
                </div>
                <div className='customermanagement-content-container'>
                    <div>
                        <Row className="customermanagement-view-details-btns-container">
                            {/* Left Side - Head-Quarters Details */}
                            <Col md={4} className='customermanagement-view-details-first-col'>
                                <div className="customermanagement-view-details-header">
                                    <p>Head Quarters Details</p>
                                    <img src={customerImg} alt="" />
                                </div>
                                <ListGroup variant="flush" className='head-quarters-list-items'>
                                    <ListGroup.Item className='mt-3'><p>Company Code</p> <p>: </p><p>{customer?.CompanyCode}</p></ListGroup.Item>
                                    <ListGroup.Item><p>Company Name</p> <p>: </p><p>{customer?.CompanyName}</p></ListGroup.Item>
                                    <ListGroup.Item><p>Contact Name</p> <p>:</p><p>{customer?.ContactPerson}</p></ListGroup.Item>
                                    <ListGroup.Item><p>Email ID</p><p>:</p> <p>{customer?.EmailID}</p></ListGroup.Item>
                                    <ListGroup.Item><p>No. of Licenses</p> <p>:</p><p>{customer?.NoOfLicenses}</p></ListGroup.Item>
                                    <ListGroup.Item><p>Contact Number</p> <p>:</p><p>{customer?.Mobile}</p></ListGroup.Item>
                                    <ListGroup.Item><p>Classification</p> <p>:</p><p>{customer?.Classification}</p></ListGroup.Item>
                                    <ListGroup.Item><p>Address</p> <p>:</p><p>{customer?.Address}</p></ListGroup.Item>
                                    <ListGroup.Item><p>Zip Code</p> <p>:</p><p>{customer?.ZipCode}</p></ListGroup.Item>
                                    {/* <ListGroup.Item><p>Relationship Type</p> <p>:</p><p>{customer.CompanyCode}</p></ListGroup.Item> */}
                                    <ListGroup.Item><p>Start Date</p> <p>:</p><p>{customer?.StartDate}</p></ListGroup.Item>
                                    <ListGroup.Item><p>End Date</p> <p>:</p><p>{customer?.EndDate}</p></ListGroup.Item>
                                    {/* <ListGroup.Item><p>Email ID</p> <p>:</p><p>{customer.CompanyCode}</p></ListGroup.Item> */}
                                    <ListGroup.Item><p>Assigned Modules</p><p>:</p><p>{customer?.AssignedModuleNames}</p>
                                    </ListGroup.Item>
                                    <ListGroup.Item><p>Website</p> <p>:</p><p>{customer?.Website}</p></ListGroup.Item>
                                    <ListGroup.Item><p>Status</p><p>:</p> <p className="text-success-color">{customer?.Status}</p></ListGroup.Item>
                                </ListGroup>
                            </Col>

                            {/* Right Side - Dynamic Content */}
                            <Col md={8}>
                                <div className="customermanagement-view-details-second-header">
                                    <p>Branch Variant Details</p>
                                </div>
                                <Tab.Container defaultActiveKey="businessUnit">
                                    <Nav variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="businessUnit">Business Unit ({businessUnits.length} units)</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="branchPlant"> Plant ({branchPlants.length} branches)</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="storageLocation">Storage Location ({storageLocations.length} Slots)</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content className='customermanagement-tab-accordion'>
                                        <Tab.Pane eventKey="businessUnit">
                                            <Accordion activeKey={activeBusinessUnitKey} onSelect={(key) => setActiveBusinessUnitKey(key)}>
                                                {businessUnits.map((unit, index) => (
                                                    <Accordion.Item eventKey={unit.BusinessUnitID.toString()} key={unit.BusinessUnitID}>
                                                        <Accordion.Header className='customermanagement-view-accordian-header'>Business Unit {index + 1}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Row>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Business Unit Code</p>
                                                                    <span>{unit.UnitCode}</span>
                                                                </Col>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Business Unit Name</p>
                                                                    <span>{unit.UnitName}</span>
                                                                </Col>
                                                                {/* <Col md={3} className='mb-3'>
                                                                    <p>Relationship Type</p>
                                                                    <span>{unit.ReportingType}</span>
                                                                </Col> */}
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Reporting To</p>
                                                                    <span>{unit.ReportingTo}</span>
                                                                </Col>
                                                            </Row>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                ))}
                                            </Accordion>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="branchPlant">
                                            <Accordion activeKey={activeBranchPlantKey} onSelect={(key) => setActiveBranchPlantKey(key)}>
                                                {branchPlants.map((branch, index) => (
                                                    <Accordion.Item eventKey={branch.BranchID.toString()} key={branch.BranchID}>
                                                        <Accordion.Header className='customermanagement-view-accordian-header'> Plant {index + 1}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Row>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Branch/Plant Code</p>
                                                                    <span>{branch.BranchCode}</span>
                                                                </Col>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Branch/Plant Name</p>
                                                                    <span>{branch.BranchName}</span>
                                                                </Col>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Email</p>
                                                                    <span>{branch.EmailID}</span>
                                                                </Col>

                                                            </Row>
                                                            <Row>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Contact number</p>
                                                                    <span>{branch.Phone}</span>
                                                                </Col>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Reporting Type</p>
                                                                    <span>{branch.ReportingType}</span>
                                                                </Col>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Reporting To</p>
                                                                    <span>{branch.ReportingTo || "N/A"}</span>
                                                                </Col>

                                                            </Row>
                                                            <Row>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Number Of Licenses</p>
                                                                    <span>{branch.NoOfLicenses}</span>
                                                                </Col>
                                                                {/* <Col md={4} className='mb-3'>
                                                                    <p>Relationship Type</p>
                                                                    <span>{branch.details.relationship}</span>
                                                                </Col> */}
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Address</p>
                                                                    <span>{branch.Address}</span>
                                                                </Col>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Zipcode</p>
                                                                    <span>{branch.ZipCode}</span>
                                                                </Col>
                                                            </Row>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                ))}
                                            </Accordion>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="storageLocation">
                                            <Accordion activeKey={activeStorageLocationKey} onSelect={(key) => setActiveStorageLocationKey(key)}>
                                                {storageLocations.map((location, index) => (
                                                    <Accordion.Item eventKey={location.SlockID.toString()} key={location.SlockID}>
                                                        <Accordion.Header className='customermanagement-view-accordian-header'> Storage Location {index + 1}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Row>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Storage Location Code</p>
                                                                    <span>{location.StorageLocationCode}</span>
                                                                </Col>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Storage Location Name</p>
                                                                    <span>{location.StorageLocationName}</span>
                                                                </Col>
                                                                {/* <Col md={4} className='mb-3'>
                                                                    <p>Relationship Type</p>
                                                                    <span>{location.details.type}</span>
                                                                </Col> */}
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Reporting Type</p>
                                                                    <span>{location.ReportingType}</span>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Reporting To</p>
                                                                    <span>{location.ReportingTo}</span>
                                                                </Col>
                                                            </Row>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                ))}
                                            </Accordion>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/* </Container> */}
            </Main >
        </div >
    );
}

export default CustomerViewDetails;

