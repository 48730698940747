import { Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../Buttons/Buttons";
import { useRef, useState } from "react";
import downloadImg from '../../assets/images/Dashboard/downloadImg.svg'

const ViewAssignedToSla = (props) => {

    const singleAssignedToSlaData = props.singleAssignedToSlaData[0];
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);

    // Handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            console.log('Selected file:', file);
        }
    };

    // Trigger file input click when attachment button is clicked
    const handleAttachmentClick = () => {
        fileInputRef.current.click();
    };

    const renderTextWithTooltip = (text) => {
        // Check if the text length exceeds 24 characters
        if (text && text.length > 24) {
            return (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{text}</Tooltip>}
                >
                    <span>{text.substring(0, 24)}...</span>
                </OverlayTrigger>
            );
        }
        return <span>{text}</span>;
    };

    const handledownloadFile = () => {
        const fileUrl = singleAssignedToSlaData?.FilePath;
        const fileName = singleAssignedToSlaData?.FileName;

        if (fileUrl) {
            // Create a temporary anchor element
            const link = document.createElement("a");
            link.href = fileUrl;
            link.download = fileName; // Suggest the file name to the user

            // Append the link to the body, click it, and remove it afterward
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    return (
        <>
            <Modal show={props.viewSlaAssignToModal} onHide={props.handleCloseAssignToModal} className='add-customer-modal add-user-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>View Service Level Agreement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>SLA Type</label>
                                        <span>{renderTextWithTooltip(singleAssignedToSlaData?.SLAType)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Company Name</label>
                                        <span>{renderTextWithTooltip(singleAssignedToSlaData?.CustomerName)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>SLA Category</label>
                                        <span>{renderTextWithTooltip(singleAssignedToSlaData?.SLACategoryName)}</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Value Type</label>
                                        <span>{renderTextWithTooltip(singleAssignedToSlaData?.ValueType)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Baseline</label>
                                        <span>{renderTextWithTooltip(singleAssignedToSlaData?.BaseLine)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Targe%</label>
                                        <span>{renderTextWithTooltip(singleAssignedToSlaData?.TargetPercentage)}</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Target Value</label>
                                        <span>{renderTextWithTooltip(singleAssignedToSlaData?.TargetValue)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4} className="d-flex justify-content-start align-items-center">
                                    <div className="dropdown add-single-bulk attachment-section">
                                        {/* <label>&nbsp;</label> */}
                                        <button
                                            className="download-document-btn"
                                            id="adduser-single-blulk"
                                        >
                                            <div className="ps-2">
                                                <span>File Name</span>
                                                <div className="attachment-note">
                                                    <small>(5MB)</small>
                                                </div>
                                            </div>
                                            <img src={downloadImg} alt="" className="download-img" onClick={handledownloadFile} />
                                        </button>
                                        {/* {selectedFile && <div className="attachment-note"><small>{selectedFile.name}</small></div>} */}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className='add-customer-calendar-container view-sla-data'>
                                        <label>Start Date</label>
                                        <span>{renderTextWithTooltip(singleAssignedToSlaData?.StartDate)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className='add-customer-calendar-container view-sla-data'>
                                        <label>End Date</label>
                                        <span>{renderTextWithTooltip(singleAssignedToSlaData?.EndDate)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Assigned To</label>
                                        <span>{renderTextWithTooltip(singleAssignedToSlaData?.AssignedTo)}</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="view-sla-data">
                                        <label>Description</label>
                                        <span className="description-text">{renderTextWithTooltip(singleAssignedToSlaData?.Remarks)}</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Modal.Body >
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseAssignToModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={props.handleNextStep} />
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default ViewAssignedToSla;