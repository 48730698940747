import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';
import { loadApprovalLevelDropdownData, loadDepartmentDropdownData, postFinancialApprovalDatas } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import * as HeaderAuthentication from '../../HeaderAuthentication'
import { ADDED_BY_ID } from "../../Constants";

const enablingOptions = [
    { value: 54, label: "Active" },
    { value: 55, label: "Inactive" }
]

const finacialApprovalOrderOptions = [
    { value: 'Sequential', label: "Sequential" },
    { value: 'Parallel', label: "Parallel" }
]

const lowThresholdOptions = [
    { value: 0, label: 0 },
    { value: 1001, label: 1001 },
    { value: 10001, label: 10001 },
    { value: 100001, label: 100001 },
    { value: 1000001, label: 1000001 },
]

const notToExceedThreshold = [
    { value: 1000, label: 1000 },
    { value: 10001, label: 10001 },
    { value: 100001, label: 100001 },
    { value: 1000001, label: 1000001 },
    { value: 10000001, label: 10000001 },
]

const AddFinancialApproval = (props) => {
    let dispatch = useDispatch();
    const financialApproval = props.financialApproval;
    const finanacialApprovalId = props.finanacialApprovalId;
    const singleFinancialApprovalData = props.singleFinancialApprovalData;
    const { approvalLevelDropdown } = useSelector((state) => state.data);
    const { departmentDropdown } = useSelector((state) => state.data);

    const [financialApprovalData, setFinancialApprovalData] = useState(
        {
            LevelID: null,
            Title: "",
            DepartmentID: null,
            Order: "",
            Condition: "",
            StatusID: null,
            LowThreshold: null,
            NotToExceedThreshold: null,
            AddedByID: ADDED_BY_ID
        }
    )
    const [errors, setErrors] = useState({});

    useEffect(() => {
        dispatch(loadApprovalLevelDropdownData());
        dispatch(loadDepartmentDropdownData());
    }, []);

    useEffect(() => {
        // Check if financialApprovalId and singleFinancialApprovalData are available
        if (finanacialApprovalId && singleFinancialApprovalData && singleFinancialApprovalData.length > 0) {
            const singleApproval = singleFinancialApprovalData[0]; // Access the first item in the array

            setFinancialApprovalData({
                FinancialApprovalID: finanacialApprovalId,
                LevelID: singleApproval.FinancialApprovalLevelID,
                Title: singleApproval.FinancialApprovalTitle,
                DepartmentID: singleApproval.FinancialApprovalDepartmentID,
                Order: singleApproval.FinancialApprovalOrder,
                Condition: singleApproval.FinancialApprovalCondition,
                StatusID: singleApproval.StatusID,
                LowThreshold: singleApproval.FinancialApprovalLowThreshold,
                NotToExceedThreshold: singleApproval.FinancialApprovalNotToExceedThreshold,
                AddedByID: ADDED_BY_ID,  // Assuming ADDED_BY_ID remains the same
            });
        }
    }, [finanacialApprovalId, singleFinancialApprovalData]);


    const handleSelectChange = (selectedOption, fieldName) => {
        setFinancialApprovalData((prevData) => ({
            ...prevData,
            [fieldName]: selectedOption ? selectedOption.value : null,
        }));

        // Clear the specific error when the user selects a value
        setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: null,
        }));
    };

    const handleTextChange = (e, fieldName) => {
        setFinancialApprovalData((prevData) => ({
            ...prevData,
            [fieldName]: e.target.value,
        }));

        // Clear the specific error when the user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: null,
        }));
    };

    const validateForm = () => {
        let formErrors = {};

        if (!financialApprovalData.LevelID) {
            formErrors.LevelID = "Approval Level is required.";
        }

        if (!financialApprovalData.Title) {
            formErrors.Title = "Title is required.";
        }

        if (!financialApprovalData.DepartmentID) {
            formErrors.DepartmentID = "Department is required.";
        }

        if (!financialApprovalData.Order) {
            formErrors.Order = "Approval Order is required.";
        }

        if (!financialApprovalData.Condition) {
            formErrors.Condition = "Approval Condition is required.";
        }

        if (!financialApprovalData.LowThreshold) {
            formErrors.LowThreshold = "Low Threshold is required.";
        }

        if (!financialApprovalData.NotToExceedThreshold) {
            formErrors.NotToExceedThreshold = "Not To Exceed Threshold is required.";
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };


    const AddFinancialApprovalData = (e) => {
        e.preventDefault();
        if (validateForm()) {
            let requestOption = {
                method: "POST",
                mode: "cors",
                headers: HeaderAuthentication.postExcomatixAuth,
                body: JSON.stringify(financialApprovalData),
            };
            dispatch(postFinancialApprovalDatas(requestOption, props.handleCloseModal, financialApproval, finanacialApprovalId));
        }
    }

    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Financial Approval</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Approval Levels</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={approvalLevelDropdown}
                                            placeholder="Select"
                                            value={approvalLevelDropdown.find(option => option.value === financialApprovalData.LevelID)}
                                            onChange={(selectedOption) => handleSelectChange(selectedOption, 'LevelID')}
                                        />
                                    </div>
                                    {errors.LevelID && <div className="text-danger">{errors.LevelID}</div>}
                                </Col>
                                <Col md={8}>
                                    <div>
                                        <label>Title</label>
                                        <Form.Control
                                            type="text"
                                            value={financialApprovalData.Title}
                                            onChange={(e) => handleTextChange(e, 'Title')}
                                        />
                                    </div>
                                    {errors.Title && <div className="text-danger">{errors.Title}</div>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Department</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={departmentDropdown}
                                            placeholder="Select"
                                            value={departmentDropdown.find(option => option.value === financialApprovalData.DepartmentID)}
                                            onChange={(selectedOption) => handleSelectChange(selectedOption, 'DepartmentID')}
                                        />
                                    </div>
                                    {errors.DepartmentID && <div className="text-danger">{errors.DepartmentID}</div>}
                                </Col>
                                <Col md={8}>
                                    <div>
                                        <label>Approval Order</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={finacialApprovalOrderOptions}
                                            placeholder="Select"
                                            value={finacialApprovalOrderOptions.find(option => option.value === financialApprovalData.Order)}
                                            onChange={(selectedOption) => handleSelectChange(selectedOption, 'Order')}
                                        />
                                    </div>
                                    {errors.Order && <div className="text-danger">{errors.Order}</div>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Approval Condition</label>
                                        <Form.Control
                                            type="text"
                                            value={financialApprovalData.Condition}
                                            onChange={(e) => handleTextChange(e, 'Condition')}
                                        />
                                    </div>
                                    {errors.Condition && <div className="text-danger">{errors.Condition}</div>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Low Threshold</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={lowThresholdOptions}
                                            placeholder="Select"
                                            value={lowThresholdOptions.find(option => option.value === financialApprovalData.LowThreshold)}
                                            onChange={(selectedOption) => handleSelectChange(selectedOption, 'LowThreshold')}
                                        />
                                    </div>
                                    {errors.LowThreshold && <div className="text-danger">{errors.LowThreshold}</div>}
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Not To Exceed Threshold</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={notToExceedThreshold}
                                            placeholder="Select"
                                            value={notToExceedThreshold.find(option => option.value === financialApprovalData.NotToExceedThreshold)}
                                            onChange={(selectedOption) => handleSelectChange(selectedOption, 'NotToExceedThreshold')}
                                        />
                                    </div>
                                    {errors.NotToExceedThreshold && <div className="text-danger">{errors.NotToExceedThreshold}</div>}
                                </Col>
                                {finanacialApprovalId && <Col md={4}>
                                    <div>
                                        <label>Status</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={enablingOptions}
                                            placeholder="Select"
                                            value={enablingOptions.find(option => option.value === financialApprovalData.StatusID)}
                                            onChange={(selectedOption) => handleSelectChange(selectedOption, 'StatusID')}
                                        />
                                    </div>
                                </Col>}
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={AddFinancialApprovalData} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddFinancialApproval;
