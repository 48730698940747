import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Menu,
    MenuItem
} from '@mui/material';
import SortIcon from '../../../assets/images/Dashboard/chevron-down.svg';
import ascToDesc from '../../../assets/images/CustomerManagement/ascToDesc.svg';
import descToAsc from '../../../assets/images/CustomerManagement/descToAsc.svg';
import SbActionDropdownButton from '../../Buttons/SbActionDropdownButton';
import StatusBadgeWithDropdown from '../../Buttons/StatusBadgeWithDropdown';
import PaginationComponent from '../../PaginationComponent';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const columns = [
    { id: 'ComplaintsApprovalLevel', label: 'COMPLAINTS LEVEL', align: 'left', minWidth: '180px' },
    { id: 'ComplaintsApprovalTitle', label: 'TITLE', align: 'left', minWidth: '150px' },
    { id: 'ComplaintsApprovalDepartmentsName', label: 'DEPARTMENT', align: 'left', minWidth: '150px' },
    { id: 'ComplaintsApprovalOrder', label: 'COMPLAINTS ORDER', align: 'left', minWidth: '180px' },
    { id: 'ComplaintsApprovalCondition', label: 'COMPLAINTS CONDITIONS', align: 'left', minWidth: '230px' },
    { id: 'Status', label: 'STATUS', align: 'left', minWidth: '80px' },
    { id: 'action', label: 'ACTION', align: 'left', minWidth: '80px', sortable: false },
];

const SortableHead = SortableContainer(({ children }) => {
    return (
        <TableHead>
            <TableRow>{children}</TableRow>
        </TableHead>
    );
});

const SortableCell = SortableElement(({ value }) => {
    return <TableCell>{value}</TableCell>;
});
const ComplaintsApprovalTable = (props) => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const complaintsApprovalData = props.complaintsApprovalData || [];

    // Get selected columns (based on `selectedColumns` prop)
    const filteredColumns = columns.filter((column) =>
        props.selectedColumns.includes(column.label)
    );

    const getStoredColumnsOrder = () => {
        const savedOrder = localStorage.getItem('complaintsApprovalColumnsOrder');
        return savedOrder ? JSON.parse(savedOrder) : columns.map((col) => col.id);
    };

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [actionAnchorEl, setActionAnchorEl] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Set initial rows per page
    const [paginatedData, setPaginatedData] = useState([]);
    const [columnsOrder, setColumnsOrder] = useState(getStoredColumnsOrder());

    const handleRequestSort = (event, property) => {
        setCurrentSortColumn(property);
        setAnchorEl(event.currentTarget);
    };

    const handleSortOrderChange = (order) => {
        setOrder(order);
        setOrderBy(currentSortColumn);
        setAnchorEl(null);
    };

    const handleActionClick = (event, customer) => {
        setSelectedCustomer(customer);
        setActionAnchorEl(event.currentTarget);
    };

    const handleActionClose = () => {
        setActionAnchorEl(false);
    };

    const onReorderEnd = ({ oldIndex, newIndex }) => {
        const newOrder = Array.from(columnsOrder);
        const [moved] = newOrder.splice(oldIndex, 1);
        newOrder.splice(newIndex, 0, moved);
        setColumnsOrder(newOrder);
        localStorage.setItem('complaintsApprovalColumnsOrder', JSON.stringify(newOrder)); // Store new order
    };

    const sortedData = React.useMemo(() => {
        const stabilizedData = complaintsApprovalData.map((el, index) => [el, index]);
        stabilizedData.sort((a, b) => {
            const orderModifier = order === 'asc' ? 1 : -1;
            if (a[0][orderBy] < b[0][orderBy]) {
                return -1 * orderModifier;
            }
            if (a[0][orderBy] > b[0][orderBy]) {
                return 1 * orderModifier;
            }
            return a[1] - b[1];
        });
        return stabilizedData.map((el) => el[0]);
    }, [complaintsApprovalData, order, orderBy]);

    // Pagination logic
    useEffect(() => {
        const start = page * rowsPerPage; // Updated page logic to 0-based
        const end = start + rowsPerPage;
        setPaginatedData(sortedData.slice(start, end));
    }, [sortedData, page, rowsPerPage]);

    const handlePageChange = (newPage) => {
        setPage(newPage - 1); // Handle 1-based to 0-based page index for slicing
    };
    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset to the first page when rows per page changes
    };

    return (
        <>
            <TableContainer component={Paper} sx={{ maxHeight: 440 }} className='table-container'>
                <Table>
                    <SortableHead axis="x" onSortEnd={onReorderEnd}>
                        {columnsOrder.map((columnId, index) => {
                            const column = columns.find(col => col.id === columnId);
                            if (!column) return null; // Skip if column doesn't exist

                            // Only display columns that are in filteredColumns
                            if (!filteredColumns.find(col => col.id === columnId)) {
                                return null;
                            }
                            return (
                                <SortableCell key={column.id} index={index} value={
                                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', minWidth: column.minWidth }} className='table-header-div'>
                                        {column.label}
                                        {column.sortable !== false && (
                                            <IconButton
                                                size="small"
                                                onClick={(event) => handleRequestSort(event, column.id)}
                                            >
                                                <img src={SortIcon} alt="Sort" />
                                            </IconButton>
                                        )}
                                    </div>
                                } />
                            );
                        })}
                    </SortableHead>
                    <TableBody>
                        {paginatedData.map((customer) => (
                            <TableRow key={customer.id}>
                                {columnsOrder.map((columnId) => {
                                    const column = columns.find((col) => col.id === columnId);
                                    if (!column || !filteredColumns.find(col => col.id === columnId)) {
                                        return null; // Skip if column doesn't exist or not selected
                                    }
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                            {column.id === 'Status' ? (
                                                <StatusBadgeWithDropdown
                                                    customer={customer}
                                                    activeText="Active"
                                                    inactiveText="Inactive"
                                                    onStatusChange={(status) => { props.handleStatusChange(customer, status) }}
                                                />
                                            ) : column.id === 'action' ? (
                                                <SbActionDropdownButton
                                                    options={[{ type: 'Edit' }]}
                                                    openAddModal={() => { props.handleOpenModal(customer.ComplaintApprovalID) }}
                                                />
                                            ) : (
                                                customer[column.id]
                                            )}
                                        </TableCell>

                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem onClick={() => handleSortOrderChange('asc')}>
                        <img src={ascToDesc} alt="" />&nbsp;&nbsp;
                        Ascending to Descending
                    </MenuItem>
                    <MenuItem onClick={() => handleSortOrderChange('desc')}>
                        <img src={descToAsc} alt="" />&nbsp;&nbsp;
                        Descending to Ascending
                    </MenuItem>
                </Menu>
            </TableContainer>
            {/* Use PaginationComponent */}
            <PaginationComponent
                totalItems={complaintsApprovalData.length}
                itemsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </>
    );
};

export default ComplaintsApprovalTable;
