import { Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../Buttons/Buttons";
import { useRef, useState } from "react";
import downloadImg from '../../assets/images/Dashboard/downloadImg.svg'
import { baseURL } from "../BaseUrl";
import * as HeaderAuthentication from '../HeaderAuthentication';

const ViewComplaints = (props) => {

    const singleComplaintEscalationData = props.singleComplaintEscalationData[0];
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);

    // Handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            console.log('Selected file:', file);
        }
    };

    // Trigger file input click when attachment button is clicked
    const handleAttachmentClick = () => {
        fileInputRef.current.click();
    };
    const renderTextWithTooltip = (text) => {
        // Check if the text length exceeds 24 characters
        if (text && text.length > 24) {
            return (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{text}</Tooltip>}
                >
                    <span>{text.substring(0, 24)}...</span>
                </OverlayTrigger>
            );
        }
        return <span>{text}</span>;
    };


    const downloadFile = () => {
        const { ComplaintsID, AttachedFileName } = singleComplaintEscalationData;

        if (ComplaintsID && AttachedFileName) {
            // Format the URL with query parameters
            const url = `${baseURL}/complaintservices/api/Complaint/DownloadFile?ComplaintID=${ComplaintsID}&FileName=${encodeURIComponent(AttachedFileName)}`;

            // Make the GET request
            fetch(url, HeaderAuthentication.getRequestOptions)
                .then((resp) => resp.blob()) // Assuming the file is returned as a Blob
                .then((blob) => {
                    // Create a link to download the file
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = AttachedFileName; // Set the file name for download
                    link.click(); // Trigger the download
                })
                .catch((err) => {
                    console.error("File download failed:", err);
                });
        } else {
            console.error("Invalid file data");
        }
    };

    return (
        <>
            <Modal show={props.viewComplaintModal} onHide={props.handleCloseViewModal} className='add-customer-modal add-user-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>View Complaints</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Ticket Type</label>
                                        <span>{renderTextWithTooltip(singleComplaintEscalationData?.TicketType)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Company Name</label>
                                        <span>{renderTextWithTooltip(singleComplaintEscalationData?.CompanyName)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Reported by</label>
                                        <span>{renderTextWithTooltip(singleComplaintEscalationData?.ReportedByUser)}</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Branch</label>
                                        <span>{renderTextWithTooltip(singleComplaintEscalationData?.BranchName)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Priority</label>
                                        <span>{renderTextWithTooltip(singleComplaintEscalationData?.Priority)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Issue Type</label>
                                        <span>{renderTextWithTooltip(singleComplaintEscalationData?.IssueType)}</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Email</label>
                                        <span className="description-text">{renderTextWithTooltip(singleComplaintEscalationData?.Email)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Contact</label>
                                        <span>{renderTextWithTooltip(singleComplaintEscalationData?.ContactNo)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Zip Code</label>
                                        <span>{renderTextWithTooltip(singleComplaintEscalationData?.ZIPCode)}</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="view-sla-data">
                                        <label>Address</label>
                                        <span className="description-text">{renderTextWithTooltip(singleComplaintEscalationData?.Address)}</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>Assigned To</label>
                                        <span>{renderTextWithTooltip(singleComplaintEscalationData?.AssignedToUser)}</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4}>
                                    <div className="view-sla-data">
                                        <label>TAT</label>
                                        <span>{renderTextWithTooltip(singleComplaintEscalationData?.TATInDays)} Day(s)</span>
                                    </div>
                                    <hr />
                                </Col>
                                <Col md={4} className="d-flex flex-column">
                                    <div className="d-flex justify-content-start align-items-center">
                                        <div className="dropdown add-single-bulk attachment-section">
                                            {/* <label>&nbsp;</label> */}
                                            <button
                                                className="download-document-btn"
                                                id="adduser-single-blulk"
                                            >
                                                <div className="ps-2">
                                                    <span>File Name</span>
                                                    <div className="attachment-note">
                                                        <small>(5MB)</small>
                                                    </div>
                                                </div>
                                                <img src={downloadImg} alt="" className="download-img" onClick={downloadFile} />
                                            </button>
                                            {/* {selectedFile && <div className="attachment-note"><small>{selectedFile.name}</small></div>} */}
                                        </div>
                                    </div>
                                    <small>{renderTextWithTooltip(singleComplaintEscalationData?.AttachedFileName)} </small>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className='add-customer-calendar-container view-sla-data'>
                                        <label>Status</label>
                                        <span>{renderTextWithTooltip(singleComplaintEscalationData?.Status)}</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="view-sla-data">
                                        <label>Feedback</label>
                                        <span className="description-text">{renderTextWithTooltip(singleComplaintEscalationData?.Remarks)}</span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Modal.Body >
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseViewModal} />
                    <SBSaveUpdateBtn btnName="Ok" />
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default ViewComplaints;