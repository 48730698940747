// import React, { useState, useRef, useEffect } from 'react';
// import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
// import { Dropdown } from 'react-bootstrap';

// Chart.register(ArcElement, Tooltip, Legend);

// const companyOptions = [
//     { value: 'company-a', label: 'Company A' },
//     { value: 'company-b', label: 'Company B' },
//     { value: 'company-c', label: 'Company C' },
// ];

// const yearOptions = [
//     { value: '2022', label: '2022' },
//     { value: '2023', label: '2023' },
//     { value: '2024', label: '2024' },
// ];

// const CustomerStatusChart = () => {
//     const [selectedCompany, setSelectedCompany] = useState(companyOptions[0]);
//     const [selectedYear, setSelectedYear] = useState(yearOptions[0]);
//     const chartRef = useRef(null);
//     const chartInstance = useRef(null);

//     const handleCompanySelect = (company) => {
//         setSelectedCompany(company);
//     };

//     const handleYearSelect = (year) => {
//         setSelectedYear(year);
//     };

//     useEffect(() => {
//         const ctx = chartRef.current.getContext('2d');

//         if (chartInstance.current) {
//             chartInstance.current.destroy();
//         }

//         chartInstance.current = new Chart(ctx, {
//             type: 'doughnut',
//             data: {
//                 labels: ['Active', 'In-Active', 'New'],
//                 datasets: [{
//                     data: [350, 50, 100],
//                     backgroundColor: ['#00C49F', '#FF4848', '#FFBB28'],
//                     borderWidth: 0,
//                 }]
//             },
//             options: {
//                 responsive: true,
//                 maintainAspectRatio: false,
//                 cutout: '70%',
//                 plugins: {
//                     legend: {
//                         display: false
//                     },
//                     tooltip: {
//                         callbacks: {
//                             label: function (tooltipItem) {
//                                 return `${tooltipItem.label}: ${tooltipItem.raw}`;
//                             },
//                         },
//                     },
//                 },
//             },
//         });

//         return () => {
//             if (chartInstance.current) {
//                 chartInstance.current.destroy();
//             }
//         };
//     }, [selectedCompany, selectedYear]);

//     return (
//         <div className='barchart-container relation-type-chart-container'>
//             <div>
//                 <h6>Customer Status</h6>
//                 <div className='relation-type-chart-btns'>
//                     <Dropdown>
//                         <Dropdown.Toggle id="dropdown-company">
//                             {selectedCompany.label}
//                         </Dropdown.Toggle>
//                         <Dropdown.Menu>
//                             {companyOptions.map(option => (
//                                 <Dropdown.Item
//                                     key={option.value}
//                                     onClick={() => handleCompanySelect(option)}
//                                     active={selectedCompany.value === option.value}
//                                 >
//                                     {option.label}
//                                 </Dropdown.Item>
//                             ))}
//                         </Dropdown.Menu>
//                     </Dropdown>
//                     <Dropdown>
//                         <Dropdown.Toggle id="dropdown-year">
//                             {selectedYear.label}
//                         </Dropdown.Toggle>
//                         <Dropdown.Menu>
//                             {yearOptions.map(option => (
//                                 <Dropdown.Item
//                                     key={option.value}
//                                     onClick={() => handleYearSelect(option)}
//                                     active={selectedYear.value === option.value}
//                                 >
//                                     {option.label}
//                                 </Dropdown.Item>
//                             ))}
//                         </Dropdown.Menu>
//                     </Dropdown>
//                 </div>
//             </div>
//             <div className='customer-status-chart'>
//                 <div style={{ width: '50%', height: '150px' }}>
//                     <canvas ref={chartRef} />
//                 </div>
//                 <div style={{ fontSize: '14px', textAlign: 'right' }}>
//                     <p>Total: <strong>500</strong></p>
//                     <p style={{ color: '#00C49F' }}>Active: <strong>350</strong></p>
//                     <p style={{ color: '#FF4848' }}>In-Active: <strong>50</strong></p>
//                     <p style={{ color: '#FFBB28' }}>New: <strong>100</strong></p>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default CustomerStatusChart;




import React, { useState, useRef, useEffect } from 'react';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { Carousel, Dropdown } from 'react-bootstrap';

Chart.register(ArcElement, Tooltip, Legend);

const companyOptions = [
    { value: 'company-a', label: 'Company A' },
    { value: 'company-b', label: 'Company B' },
    { value: 'company-c', label: 'Company C' },
];

const yearOptions = [
    { value: '2022', label: '2022' },
    { value: '2023', label: '2023' },
    { value: '2024', label: '2024' },
];

const CustomerStatusChart = ({
    moduleName = 'Customer Status',
    labels = [],
    data = [],
    colors = []
}) => {
    const [selectedCompany, setSelectedCompany] = useState(companyOptions[0]);
    const [selectedYear, setSelectedYear] = useState(yearOptions[0]);
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    const handleCompanySelect = (company) => {
        setSelectedCompany(company);
        // Add logic to update chart data based on selected company
    };

    const handleYearSelect = (year) => {
        setSelectedYear(year);
        // Add logic to update chart data based on selected year
    };

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');

        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        chartInstance.current = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: labels,
                datasets: [{
                    data: data,
                    backgroundColor: colors,
                    borderWidth: 0,
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                cutout: '70%',
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            label: function (tooltipItem) {
                                return `${tooltipItem.label}: ${tooltipItem.raw}`;
                            },
                        },
                    },
                },
            },
        });

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [labels, data, colors, selectedCompany, selectedYear]);

    // Calculate the total value
    const total = data.reduce((acc, value) => acc + value, 0);

    return (
        <div className='barchart-container relation-type-chart-container'>
            <div>
                <h6>{moduleName}</h6>
                <div className='relation-type-chart-btns'>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-company">
                            {selectedCompany.label}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {companyOptions.map(option => (
                                <Dropdown.Item
                                    key={option.value}
                                    onClick={() => handleCompanySelect(option)}
                                    active={selectedCompany.value === option.value}
                                >
                                    {option.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-year">
                            {selectedYear.label}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {yearOptions.map(option => (
                                <Dropdown.Item
                                    key={option.value}
                                    onClick={() => handleYearSelect(option)}
                                    active={selectedYear.value === option.value}
                                >
                                    {option.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className='customer-status-chart'>
                <div style={{ width: '70%', height: '225px' }} className='customer-status-canvas-div'>
                    <canvas ref={chartRef} />
                </div>
                {/* <div style={{ fontSize: '14px', textAlign: 'right' }}>
                    <p>Total: <strong>{total}</strong></p>
                    {labels.map((label, index) => (
                        <p key={label} style={{ color: colors[index] }}>
                            {label}: <strong>{data[index]}</strong>
                        </p>
                    ))}
                </div> */}

                <div className="carousel-container">
                    <Carousel>
                        {/* Slide showing the total */}
                        <Carousel.Item>
                            <div className="customer-status-indicator-names">
                                <p>Total: <strong>{total}</strong></p>
                            </div>
                        </Carousel.Item>

                        {/* Slide for each label */}
                        {labels.map((label, index) => (
                            <Carousel.Item key={label}>
                                <div className="d-flex flex-column align-items-end p-3">
                                    <p style={{ color: colors[index] }}>
                                        {label}: <strong>{data[index]}</strong>
                                    </p>
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
                <div style={{ fontSize: '14px', textAlign: 'right' }} className='carousel-none-div'>
                    <p>Total: <strong>{total}</strong></p>
                    {labels.map((label, index) => (
                        <p key={label} style={{ color: colors[index] }}>
                            {label}: <strong>{data[index]}</strong>
                        </p>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CustomerStatusChart;
