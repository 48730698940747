// import React from "react";
// import action from '../../assets/images/Dashboard/action-img.svg';
// import edit from '../../assets/images/Dashboard/edit-img.svg';
// import view from '../../assets/images/Dashboard/view-img.svg';

// function SbActionDropdownButton(props) {
//     return (
//         <div className="dropdown add-single-bulk">
//             <button
//                 className="btn btn-action-dropdown"
//                 id="adduser-single-blulk"
//                 data-bs-toggle="dropdown"
//                 aria-expanded="false"
//             >
//                 <div className="btn-inline-item d-flex align-items-center">
//                     <img src={action} alt="" />
//                 </div>
//             </button>
//             <ul
//                 className="dropdown-menu single-bulk-upload"
//                 aria-labelledby="adduser-single-blulk"
//             >
//                 <li>
//                     <button
//                         className="dropdown-item single-user"
//                         onClick={props.openAddModal}
//                     >
//                         <img src={edit} alt="" /> &nbsp; Edit Details {props.pageName}
//                     </button>
//                 </li>
//                 <li>
//                     <button
//                         className="dropdown-item bulk-user"
//                         onClick={props.addBulkModel}
//                     >
//                         <img src={view} alt="" /> &nbsp; View Details
//                     </button>
//                 </li>
//             </ul>
//         </div>
//     );
// }

// export default SbActionDropdownButton;



import React from "react";
import action from '../../assets/images/Dashboard/action-img.svg';
import edit from '../../assets/images/Dashboard/edit-img.svg';
import view from '../../assets/images/Dashboard/view-img.svg';
import attachment from '../../assets/images/Dashboard/attachment-download-img.svg';

function SbActionDropdownButton(props) {
    const { btnName, options, openAddModal, addBulkModel, addAttachmentModal, pageName } = props;

    // Determine which options to display
    const displayOptions = () => {
        if (options && Array.isArray(options) && options.length > 0) {
            return options.map((option, index) => {
                switch (option.type) {
                    case 'Edit':
                        return (
                            <li key={index}>
                                <button
                                    className="dropdown-item single-user"
                                    onClick={openAddModal}
                                >
                                    <img src={edit} alt="Edit" /> &nbsp; Edit Details {pageName}
                                </button>
                                <hr />
                            </li>
                        );
                    case 'View':
                        return (
                            <li key={index}>
                                <button
                                    className="dropdown-item bulk-user"
                                    onClick={addBulkModel}
                                >
                                    <img src={view} alt="View" /> &nbsp; View Details
                                </button>
                                <hr />
                            </li>
                        );
                    case 'Download':
                        return (
                            <li key={index}>
                                <button
                                    className="dropdown-item download-user"
                                    onClick={addAttachmentModal} // Replace with your download function
                                >
                                    <img src={attachment} alt="Download" /> &nbsp; Download
                                </button>
                            </li>
                        );
                    default:
                        return null;
                }
            });
        } else {
            // Default to showing both options if no `options` prop is provided
            return (
                <div className="action-button-div">
                    <li>
                        <button
                            className="dropdown-item single-user"
                            onClick={openAddModal}
                        >
                            <img src={edit} alt="Edit" /> &nbsp; Edit Details {pageName}
                        </button>
                    </li>
                    <hr />
                    <li>
                        <button
                            className="dropdown-item bulk-user"
                            onClick={addBulkModel}
                        >
                            <img src={view} alt="View" /> &nbsp; View Details
                        </button>
                    </li>
                </div>
            );
        }
    };

    return (
        <div className="dropdown add-single-bulk">
            <button
                className="btn btn-action-dropdown"
                id="action-dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <div className="btn-inline-item d-flex align-items-center">
                    <img src={action} alt="Action" />
                    &nbsp;&nbsp;
                    {btnName}
                </div>
            </button>
            <ul
                className="dropdown-menu single-bulk-upload"
                aria-labelledby="action-dropdown"
            >
                {displayOptions()}
            </ul>
        </div>
    );
}

export default SbActionDropdownButton;

