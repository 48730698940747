import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import chevronLeft from '../../assets/images/Dashboard/chevron-left.svg';
import chevronRight from '../../assets/images/Dashboard/chevron-right.svg';

const HolidaySchedules = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [activeChevron, setActiveChevron] = useState(null);

    // Example list of US holidays for 2024
    const usHolidays = [
        { date: '2024-01-01', name: 'New Year\'s Day' },
        { date: '2024-01-15', name: 'Martin Luther King Jr. Day' },
        { date: '2024-02-19', name: 'Washington\'s Birthday' },
        { date: '2024-05-27', name: 'Memorial Day' },
        { date: '2024-07-04', name: 'Independence Day' },
        { date: '2024-09-02', name: 'Labor Day' },
        { date: '2024-10-14', name: 'Columbus Day' },
        { date: '2024-11-11', name: 'Veterans Day' },
        { date: '2024-11-28', name: 'Thanksgiving Day' },
        { date: '2024-12-25', name: 'Christmas Day' },
    ];

    const getDaysInMonth = (month, year) => new Date(year, month, 0).getDate();
    const getFirstDayOfMonth = (month, year) => new Date(year, month, 1).getDay();

    const renderCalendar = (date) => {
        const month = date.getMonth();
        const year = date.getFullYear();
        const daysInMonth = getDaysInMonth(month + 1, year);
        const firstDay = getFirstDayOfMonth(month, year);

        const days = [];
        const dayNames = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

        // Adding day names
        dayNames.forEach((dayName, index) => {
            days.push(
                <div className="calendar-date calendar-day-name" key={`day-name-${index}`}>
                    {dayName}
                </div>
            );
        });

        // Adding empty cells for days before the start of the month
        for (let i = 0; i < firstDay; i++) {
            days.push(<div className="calendar-date empty" key={`empty-${i}`}></div>);
        }

        // Adding actual days of the month
        for (let day = 1; day <= daysInMonth; day++) {
            const currentDay = new Date(year, month, day);
            const formattedDate = currentDay.toISOString().split('T')[0]; // Format as YYYY-MM-DD
            const isHoliday = usHolidays.some(holiday =>
                holiday.date === formattedDate
            );
            days.push(
                <div
                    className={`calendar-date ${isHoliday ? 'holiday' : ''}`}
                    key={`day-${day}`}
                >
                    <div className="calendar-date-day">{day}</div>
                </div>
            );
        }

        return days;
    };

    const handleChevronClick = (direction) => {
        setActiveChevron(direction);
        setCurrentDate(prevDate => {
            const newDate = new Date(prevDate);
            if (direction === 'left') {
                newDate.setMonth(newDate.getMonth() - 1);
            } else if (direction === 'right') {
                newDate.setMonth(newDate.getMonth() + 1);
            }
            return newDate;
        });
        // Reset active chevron after changing the date
        setActiveChevron(null);
    };

    return (
        <div className="barchart-container holiday-schedule-card">
            <div className="holiday-schedule-card-body">
                <div className="holiday-schedule-card-header">
                    <h6>Holidays Schedules</h6>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                            Year
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item>2024</Dropdown.Item>
                            <Dropdown.Item>2023</Dropdown.Item>
                            <Dropdown.Item>2022</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <div className='holiday-schedule-chevron-month-div'>
                    <div
                        className={`holiday-schedule-chevron-img ${activeChevron === 'left' ? 'active' : ''}`}
                        onClick={() => handleChevronClick('left')}
                    >
                        <img src={chevronLeft} alt="Previous Month" />
                    </div>
                    <h6 className="m-0">{currentDate.toLocaleString('default', { month: 'long' })} {currentDate.getFullYear()}</h6>
                    <div
                        className={`holiday-schedule-chevron-img ${activeChevron === 'right' ? 'active' : ''}`}
                        onClick={() => handleChevronClick('right')}
                    >
                        <img src={chevronRight} alt="Next Month" />
                    </div>
                </div>

                <div className="calendar-grid">
                    {renderCalendar(currentDate)}
                </div>
                <hr />
                <div>
                    <h6>List Of</h6>
                    <div className="holiday-list">
                        {usHolidays.map(holiday => (
                            <div key={holiday.date} className="d-flex align-items-center mb-3">
                                <div className="holiday-date">
                                    <h6 className="m-0">{new Date(holiday.date).getDate()}</h6>
                                    <small>{new Date(holiday.date).toLocaleDateString('en-US', { weekday: 'short' })}</small>
                                </div>
                                <div className="ms-3 holiday-names">
                                    <h6 className="m-0">{holiday.name}</h6>
                                    <small>{holiday.name}</small>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HolidaySchedules;
