import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Menu,
    MenuItem
} from '@mui/material';
import SortIcon from '../../../assets/images/Dashboard/chevron-down.svg';
import ascToDesc from '../../../assets/images/CustomerManagement/ascToDesc.svg';
import descToAsc from '../../../assets/images/CustomerManagement/descToAsc.svg';
import SbActionDropdownButton from '../../Buttons/SbActionDropdownButton';
import StatusBadgeWithDropdown from '../../Buttons/StatusBadgeWithDropdown';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { SbAddBtn, SbFilterBtn } from '../../Buttons/Buttons';
import Select from 'react-select';
import columnFilter from '../../../assets/images/CustomerManagement/columnFilter.svg'
import valueFilter from '../../../assets/images/CustomerManagement/valueFilter.svg';
import AddYearlyHolidays from './AddYearlyHolidays';
import PaginationComponent from '../../PaginationComponent';
import LPValueFilterModal from './LPValueFilterModal';
import { useDispatch, useSelector } from 'react-redux';
import { loadSettingsHolidayPolicyData, loadSettingsSingleHolidayPolicyData, postHolidayPolicyDatas } from '../../../redux/action';
import moment from 'moment';
import HolidayPolicyColumnFilterModal from './HolidayPolicyColumnFilterModal';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import noDataFound from '../../../assets/images/no-data-found.svg'
import Skeleton from 'react-loading-skeleton';
import * as HeaderAuthentication from '../../HeaderAuthentication'
import { ADDED_BY_ID } from '../../Constants';

const columns = [
    { id: 'HolidayName', label: 'HOLIDAY NAME', align: 'left', minWidth: '150px' },
    { id: 'HolidayFromDate/HolidayToDate', label: 'DATE', align: 'left', minWidth: '200px' },
    { id: 'CountryName', label: 'LOCATION', align: 'left', minWidth: '130px' },
    { id: 'Description', label: 'DESCRIPTION', align: 'left', minWidth: '250px' },
    { id: 'Status', label: 'STATUS', align: 'left', minWidth: '80px' },
    { id: 'action', label: 'ACTION', align: 'left', minWidth: '80px', sortable: false },
];

const SortableHead = SortableContainer(({ children }) => {
    return (
        <TableHead>
            <TableRow>{children}</TableRow>
        </TableHead>
    );
});

const SortableCell = SortableElement(({ value }) => {
    return <TableCell>{value}</TableCell>;
});


const HolidayPolicyTable = (props) => {

    let dispatch = useDispatch();

    const getStoredColumnsOrder = () => {
        const savedOrder = localStorage.getItem('holidayPolicyColumnsOrder');
        return savedOrder ? JSON.parse(savedOrder) : columns.map((col) => col.id);
    };

    const { getHolidayPolicyData = [] } = useSelector((state) => state.data);
    const { getSingleHolidayPolicyData } = useSelector((state) => state.data);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [actionAnchorEl, setActionAnchorEl] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedColumns, setSelectedColumns] = useState([
        "HOLIDAY NAME", "DATE", "LOCATION", "DESCRIPTION", "STATUS", "ACTION"
    ]);

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Set initial rows per page
    const [paginatedData, setPaginatedData] = useState([]);
    const [columnsOrder, setColumnsOrder] = useState(getStoredColumnsOrder());
    const [loading, setLoading] = useState(false);

    // Get selected columns (based on `selectedColumns` prop)
    const filteredColumns = columns.filter((column) =>
        selectedColumns.includes(column.label)
    );

    const handleRequestSort = (event, property) => {
        setCurrentSortColumn(property);
        setAnchorEl(event.currentTarget);
    };

    const handleSortOrderChange = (order) => {
        setOrder(order);
        setOrderBy(currentSortColumn);
        setAnchorEl(null);
    };

    useEffect(() => {
        holidayPolicyData();
    }, []);

    const holidayPolicyData = () => {
        dispatch(loadSettingsHolidayPolicyData(setLoading));
    }

    const onReorderEnd = ({ oldIndex, newIndex }) => {
        const newOrder = Array.from(columnsOrder);
        const [moved] = newOrder.splice(oldIndex, 1);
        newOrder.splice(newIndex, 0, moved);
        setColumnsOrder(newOrder);
        localStorage.setItem('holidayPolicyColumnsOrder', JSON.stringify(newOrder)); // Store new order
    };

    const sortedData = React.useMemo(() => {
        const stabilizedData = getHolidayPolicyData.map((el, index) => [el, index]);
        stabilizedData.sort((a, b) => {
            const orderModifier = order === 'asc' ? 1 : -1;
            if (a[0][orderBy] < b[0][orderBy]) {
                return -1 * orderModifier;
            }
            if (a[0][orderBy] > b[0][orderBy]) {
                return 1 * orderModifier;
            }
            return a[1] - b[1];
        });
        return stabilizedData.map((el) => el[0]);
    }, [getHolidayPolicyData, order, orderBy]);

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [showColumnFilterModal, setShowColumnFilterModal] = useState(false);
    const [showValueFilterModal, setShowValueFilterModal] = useState(false);
    const [holidayId, setHolidayId] = useState("")

    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };
    const handleOpenModal = (holidayID) => {
        setIsModalOpen(true);
        if (holidayID) {
            dispatch(loadSettingsSingleHolidayPolicyData(holidayID));
            setHolidayId(holidayID);
        } else {
            setHolidayId("");
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenColumnFilterModal = () => {
        setShowFilterMenu(false);
        setShowColumnFilterModal(true);
    };

    const handleCloseColumnFilterModal = () => {
        setShowColumnFilterModal(false);
    };
    const handleOpenValueFilterModal = () => {
        setShowFilterMenu(false);
        setShowValueFilterModal(true);
    };

    const handleCloseValueFilterModal = () => {
        setShowValueFilterModal(false);
    };

    // Pagination logic
    useEffect(() => {
        const start = page * rowsPerPage; // Updated page logic to 0-based
        const end = start + rowsPerPage;
        setPaginatedData(sortedData.slice(start, end));
    }, [sortedData, page, rowsPerPage]);

    const handlePageChange = (newPage) => {
        setPage(newPage - 1); // Handle 1-based to 0-based page index for slicing
    };
    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset to the first page when rows per page changes
    };

    const handleColumnSelectionChange = (updatedSelectedColumns) => {
        setSelectedColumns(updatedSelectedColumns); // Update the selected columns
    };

    const handleStatusChange = (customer, statusLabel) => {
        const statusID = statusLabel === "Active" ? 44 : 45; // 44 for Active, 45 for Inactive

        console.log(customer, 'customer')
        const data = {
            HolidayID: customer.HolidayID,
            HolidayName: customer.HolidayName,
            HolidayFromDate: moment(customer.HolidayFromDate).format('YYYY-MM-DD'),
            HolidayToDate: moment(customer.HolidayToDate).format('YYYY-MM-DD'),
            HolidayCountryID: customer.CountryID,
            HolidayDescription: customer.Description,
            HolidayStatusID: statusID,
            AddedByID: ADDED_BY_ID
        };

        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data),
        };

        // Dispatch the action to update the department status
        dispatch(postHolidayPolicyDatas(requestOption, handleCloseModal, holidayPolicyData, customer.HolidayID));
        // console.log(data, 'data'); // For debugging purposes
    };

    return (
        <>
            <Row className='customermanagement-btns-container'>
                <Col md={3} className='customermanagement-backbtn-div'>
                    <span>Leave Policy</span>
                </Col>
                <Col md={9} className='customermanagement-search-filter'>
                    <div className='customermanagement-header-btns filter-btn'>
                        <SbFilterBtn btnName="Filters" onClickEffect={toggleFilterMenu} />
                        {showFilterMenu && (
                            <Dropdown.Menu show className='filter-dropdown-options'>
                                <Dropdown.Item onClick={handleOpenColumnFilterModal}>
                                    <img src={columnFilter} alt="Column Filter" />&nbsp;&nbsp;
                                    Column Filter
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleOpenValueFilterModal}>
                                    <img src={valueFilter} alt="Value Filter" />&nbsp;&nbsp;
                                    Value Filter
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        )}
                    </div>
                    <Col md={3} className='filters-col'>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder="Search Leave Type"
                            // options={statusOptions}
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <Col md={3} className='filters-col'>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder="Search Status"
                            // options={statusOptions}
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <SbAddBtn btnName="Add" onClickEffect={() => { handleOpenModal("") }} />
                </Col>
            </Row>
            <div className='customermanagement-table-div'>
                <TableContainer component={Paper} sx={{ maxHeight: 440 }} className='table-container'>
                    <Table>
                        {/* <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }} className='table-header-div'>
                                            {column.label}
                                            {column.sortable !== false && (
                                                <IconButton
                                                    size="small"
                                                    onClick={(event) => handleRequestSort(event, column.id)}
                                                >
                                                    <img src={SortIcon} alt="Sort" />
                                                </IconButton>
                                            )}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead> */}
                        <SortableHead axis="x" onSortEnd={onReorderEnd}>
                            {columnsOrder.map((columnId, index) => {
                                const column = columns.find(col => col.id === columnId);
                                if (!column) return null; // Skip if column doesn't exist

                                // Only display columns that are in filteredColumns
                                if (!filteredColumns.find(col => col.id === columnId)) {
                                    return null;
                                }
                                return (
                                    <SortableCell key={column.id} index={index} value={
                                        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', minWidth: column.minWidth }} className='table-header-div'>
                                            {column.label}
                                            {column.sortable !== false && (
                                                <IconButton
                                                    size="small"
                                                    onClick={(event) => handleRequestSort(event, column.id)}
                                                >
                                                    <img src={SortIcon} alt="Sort" />
                                                </IconButton>
                                            )}
                                        </div>
                                    } />
                                );
                            })}
                        </SortableHead>
                        <TableBody>
                            {
                                loading ? (
                                    [...Array(rowsPerPage)].map((_, index) => (
                                        <TableRow key={index}>
                                            {columns.map((column) => (
                                                <TableCell key={column.id} align={column.align}>
                                                    <Skeleton height={40} />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))
                                ) : paginatedData.length === 0 ? (
                                    // Display "No Data Found" row if no data is present in the paginated data
                                    <TableRow>
                                        <TableCell colSpan={columns.length} align="center">
                                            <img src={noDataFound} alt="" style={{ height: "250px", width: "280px" }} />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    paginatedData.map((customer) => (
                                        <TableRow key={customer.id}>
                                            {columnsOrder.map((columnId) => {
                                                const column = columns.find((col) => col.id === columnId);
                                                if (!column || !filteredColumns.find(col => col.id === columnId)) {
                                                    return null; // Skip if column doesn't exist or not selected
                                                }
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {
                                                            column.id === 'HolidayFromDate/HolidayToDate' ? (
                                                                // Use moment to format both dates
                                                                `${moment(customer.HolidayFromDate).format('DD/MM/YYYY')} to ${moment(customer.HolidayToDate).format('DD/MM/YYYY')}`
                                                            ) :
                                                                column.id === 'Status' ? (
                                                                    <StatusBadgeWithDropdown
                                                                        customer={customer}
                                                                        activeText="Active"
                                                                        inactiveText="Inactive"
                                                                        onStatusChange={(status) => { handleStatusChange(customer, status) }}
                                                                    />
                                                                ) : column.id === 'action' ? (
                                                                    <SbActionDropdownButton
                                                                        options={[{ type: 'Edit' }]}
                                                                        openAddModal={() => { handleOpenModal(customer.HolidayID) }}
                                                                    />
                                                                ) : (
                                                                    customer[column.id]
                                                                )}
                                                    </TableCell>
                                                )
                                            }
                                            )}
                                        </TableRow>
                                    )))}
                        </TableBody>
                    </Table>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                    >
                        <MenuItem onClick={() => handleSortOrderChange('asc')}>
                            <img src={ascToDesc} alt="" />&nbsp;&nbsp;
                            Ascending to Descending
                        </MenuItem>
                        <MenuItem onClick={() => handleSortOrderChange('desc')}>
                            <img src={descToAsc} alt="" />&nbsp;&nbsp;
                            Descending to Ascending
                        </MenuItem>
                    </Menu>
                </TableContainer>
                {/* Use PaginationComponent */}
                <PaginationComponent
                    totalItems={getHolidayPolicyData.length}
                    itemsPerPage={rowsPerPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
                {isModalOpen && <AddYearlyHolidays
                    open={isModalOpen}
                    handleCloseModal={handleCloseModal}
                    holidayPolicyData={holidayPolicyData}
                    getSingleHolidayPolicyData={getSingleHolidayPolicyData}
                    holidayId={holidayId}
                />}
                {
                    showColumnFilterModal && (
                        <HolidayPolicyColumnFilterModal
                            showColumnFilterModal={showColumnFilterModal}
                            handleCloseColumnFilterModal={handleCloseColumnFilterModal}
                            selectedColumns={selectedColumns}
                            handleSaveColumns={handleColumnSelectionChange}
                        />
                    )
                }
                {
                    showValueFilterModal && (
                        <LPValueFilterModal
                            showValueFilterModal={showValueFilterModal}
                            handleCloseValueFilterModal={handleCloseValueFilterModal}
                        />
                    )
                }
            </div>
        </>
    );
};

export default HolidayPolicyTable;


