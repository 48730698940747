// integration started on 21/10/2024

// SignIn Page
export const post_SignIn_Data = "post_SignIn_Data";
export const post_resendOtp_Data = "post_resendOtp_Data";
// SignIn Page

// Verify Company
export const post_Resend_Verify_Email = "post_Resend_Verify_Email";
// Verify Company

// Forgot Password
export const post_Forgot_Password = "post_Forgot_Password";
// Forgot Password

// Reset Password
export const post_Reset_Password = "post_Reset_Password";
// Reset Password

// Customer Management
export const post_Customer_Data = "post_Customer_Data";
export const get_Assigned_Modules = "get_Assigned_Modules";
export const get_Company_Data = "get_Company_Data";
export const get_Single_Company_Data = "get_Single_Company_Data";
export const get_Country_Code = "get_Country_Code";
export const get_Company_Dropdown = "get_Company_Dropdown";
export const get_generated_Company_Code = "get_generated_Company_Code";
export const get_Custom_Fields_For_Company = "get_Custom_Fields_For_Company";
export const post_Custom_Fields_Value_For_Company = "post_Custom_Fields_Value_For_Company";
export const get_Customer_Classification_Dropdown = "get_Customer_Classification_Dropdown";
// Customer Management

// User Management
export const get_User_Data = "get_User_Data";
export const get_Single_User_Data = "get_Single_User_Data";
export const get_Role_Dropdown_Data = "get_Role_Dropdown_Data";
export const get_ReportingTo_Dropdown_Data = "get_ReportingTo_Dropdown_Data";
export const post_User_Data = "post_User_Data";
export const post_User_Sloc_Data = "post_User_Sloc_Data";
export const get_Sloc_Dropdown_Data = "get_Sloc_Dropdown_Data";
export const get_Branch_Dropdown_Data = "get_Branch_Dropdown_Data";
// User Management

// Complaint/Escalation 
export const get_Complaint_Data = "get_Complaint_Data";
export const get_Single_Complaint_Data = "get_Single_Complaint_Data";
export const get_IssueType_Dropdown_Data = "get_IssueType_Dropdown_Data";
export const post_Complaint_Escalation_Data = "post_Complaint_Escalation_Data";
export const upload_File = "upload_File";
export const get_Complaint_Escalation_Dashboard_CardData = "get_Complaint_Escalation_Dashboard_CardData";
export const get_Complaint_Escalation_Ticket_Data = "get_Complaint_Escalation_Ticket_Data";
export const get_Complaint_Escalation_PriorityStatus_Data = "get_Complaint_Escalation_PriorityStatus_Data";
export const get_Complaint_Escalation_Status_Data = "get_Complaint_Escalation_Status_Data";
export const get_Complaint_Escalation_BranchStatus_Data = "get_Complaint_Escalation_BranchStatus_Data";
export const get_Complaint_Escalation_IssueType_Data = "get_Complaint_Escalation_IssueType_Data";
// Complaint/Escalation 

// Service Level Agreement
export const get_AssignedTo_SLA_Data = "get_AssignedTo_SLA_Data";
export const get_Single_AssignedTo_SLA_Data = "get_Single_AssignedTo_SLA_Data";
export const post_AssignedTo_SLA_Data = "post_AssignedTo_SLA_Data";
export const get_AssignedTo_SLA_Type_Dropdown = "get_AssignedTo_SLA_Type_Dropdown";
export const get_SLA_Category_Dropdown = "get_SLA_Category_Dropdown";
// Service Level Agreement

// Workqueues
export const get_Workqueue_Data = "get_Workqueue_Data";
export const get_Single_Workqueue_Data = "get_Single_Workqueue_Data";
export const update_Workqueue_Priority_Status = "update_Workqueue_Priority_Status";
export const post_Set_AssignTo_Data = "post_Set_AssignTo_Data";
export const get_User_Dropdown_Based_Department = "get_User_Dropdown_Based_Department";
// Workqueues

// Settings Department
export const post_Department_Data = "post_Department_Data";
export const get_Department_Data = "get_Department_Data";
export const get_Single_Department_Data = "get_Single_Department_Data";
// Settings Department

// Settings Customer Classification
export const post_Customer_Classification_Data = "post_Customer_Classification_Data";
export const get_Customer_Classification_Data = "get_Customer_Classification_Data";
export const get_Single_Customer_Classification_Data = "get_Single_Customer_Classification_Data";
// Settings Customer Classification

// Settings Designation
export const get_Designation_Data = "get_Designation_Data";
export const post_Designation_Data = "post_Designation_Data";
export const get_Department_Dropdown = "get_Department_Dropdown";
export const get_Reporting_Designation_Dropdown = "get_Reporting_Designation_Dropdown";
export const get_Single_Designation_Data = "get_Single_Designation_Data";
// Settings Designation

// Settings Issue Types
export const get_Issue_Type_Data = "get_Issue_Type_Data";
export const post_Issue_Types_Data = "post_Issue_Types_Data";
export const get_Single_Issue_Types_Data = "get_Single_Issue_Types_Data";
// Settings Issue Types

// Settings SLA
export const get_Settings_SLA_Data = "get_Settings_SLA_Data";
export const post_Settings_SLA_Data = "post_Settings_SLA_Data";
export const get_Single_Settings_SLA_Data = "get_Single_Settings_SLA_Data";
// Settings SLA

// Settings Module
export const post_Modules_Data = "post_Modules_Data";
export const get_Module_Data = "get_Module_Data";
export const get_Single_Module_Data = "get_Single_Module_Data";
// Settings Module

// Settings Sub Module
export const post_Sub_Modules_Data = "post_Sub_Modules_Data";
export const get_Sub_Module_Data = "get_Sub_Module_Data";
export const get_Single_Sub_Module_Data = "get_Single_Sub_Module_Data";
export const get_SubModule_Dropdown = "get_SubModule_Dropdown";
// Settings Sub Module

// Settings System Numbering
export const get_System_Numbering = "get_System_Numbering";
export const post_System_Numbering = "post_System_Numbering";
// Settings System Numbering

// Settings Work Queues Rules
export const get_Settings_WorkQueues_Rule = "get_Settings_WorkQueues_Rule";
export const get_Settings_Single_WorkQueues_Rule = "get_Settings_Single_WorkQueues_Rule";
export const get_Settings_WorkQueues_FieldName_Rule = "get_Settings_WorkQueues_FieldName_Rule";
export const post_Settings_WorkQueues_Rule_Data = "post_Settings_WorkQueues_Rule_Data";
export const post_Run_WorkQueue_Data = "post_Run_WorkQueue_Data";
// Settings Work Queues Rules

// Settings Role
export const get_Roles_Data = "get_Roles_Data";
export const get_Single_Roles_Data = "get_Single_Roles_Data";
export const post_Roles_Data = "post_Roles_Data";
// Settings Role

// Settings Custom Fields
export const get_CustomFields_Data = "get_CustomFields_Data";
export const get_Single_CustomFields_Data = "get_Single_CustomFields_Data";
export const post_CustomFields_Data = "post_CustomFields_Data";
// Settings Custom Fields

// Settings Leave Policy
export const post_Leave_Policy_Data = "post_Leave_Policy_Data";
export const get_Leave_Policy_Data = "get_Leave_Policy_Data";
export const get_Single_Leave_Policy_Data = "get_Single_Leave_Policy_Data";
// Settings Leave Policy

// Settings Holiday Policy
export const get_Holiday_Policy_Data = "get_Holiday_Policy_Data";
export const get_SingleHoliday_Policy_Data = "get_SingleHoliday_Policy_Data";
export const post_Holiday_Policy_Data = "post_Holiday_Policy_Data";
export const get_Country_Name_Dropdown = "get_Country_Name_Dropdown;"
// Settings Holiday Policy

// Settings Financial Approval
export const get_Financial_Approval_Data = "get_Financial_Approval_Data";
export const get_Single_Financial_Approval_Data = "get_Single_Financial_Approval_Data";
export const get_Approval_Level_Dropdown = "get_Approval_Level_Dropdown";
export const post_Financial_Approval_Data = "post_Financial_Approval_Data";
// Settings Financial Approval

// Settings Functional Approval
export const get_Functional_Approval_Data = "get_Functional_Approval_Data";
export const get_Single_Functional_Approval_Data = "get_Single_Functional_Approval_Data";
export const post_Functional_Approval_Data = "post_Functional_Approval_Data";
// Settings Functional Approval

// Settings Leave Approval
export const get_Leave_Approval_Data = "get_Leave_Approval_Data";
export const get_Single_Leave_Approval_Data = "get_Single_Leave_Approval_Data";
export const post_Leave_Approval_Data = "post_Leave_Approval_Data";
// Settings Leave Approval

// Settings Escalation Approval
export const get_Escalation_Approval_Data = "get_Escalation_Approval_Data";
export const get_Single_Escalation_Approval_Data = "get_Single_Escalation_Approval_Data";
export const post_Escalation_Approval_Data = "post_Escalation_Approval_Data";
// Settings Escalation Approval

// Settings Complaints Approval
export const get_Complaints_Approval_Data = "get_Complaints_Approval_Data";
export const get_Single_Complaints_Approval_Data = "get_Single_Complaints_Approval_Data";
export const post_Complaints_Approval_Data = "post_Complaints_Approval_Data";
// Settings Complaints Approval
