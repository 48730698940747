import React from "react";
import add from '../../assets/images/Dashboard/add.svg'
import singleUpload from '../../assets/images/UserManagement/single upload.svg'
import bulkUpload from '../../assets/images/UserManagement/bulk upload.svg'

function SbDropdownButton(props) {
    return (
        <div className="dropdown add-single-bulk">
            <button
                className="btn btn-add-dropdown"
                id="adduser-single-blulk"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <div className="btn-inline-item d-flex align-items-center">
                    <img src={add} alt="" />
                    &nbsp;&nbsp;
                    {/* <h6 style={{ fontSize: "13px", fontWeight: "500" }}> */}
                    {props.btnName}
                    {/* </h6> */}
                </div>
            </button>
            <ul
                className="dropdown-menu single-bulk-upload"
                aria-labelledby="adduser-single-blulk"
            >
                <li>
                    <button
                        className="dropdown-item single-user"
                        onClick={props.openAddModal}
                    >
                        <img src={singleUpload} alt="" /> &nbsp; Single {props.pageName}
                    </button>
                </li>
                <li>
                    <button
                        className="dropdown-item bulk-user"
                        onClick={props.addBulkModel}
                    >
                        <img src={bulkUpload} alt="" /> &nbsp; Bulk Upload
                    </button>
                </li>
            </ul>
        </div>
    );
}

export default SbDropdownButton;
