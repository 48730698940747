import { Button, Col, Form, Row } from "react-bootstrap";
import '../src/assets/css/Dimpusignin.scss'
import signImg from '../src/assets/images/dimpu-signin-img.svg'
import metricinfo from '../src/assets/images/metricinfo-log.svg'
import smallLogo from '../src/assets/images/metricinfo-small-logo.svg'
import { Link } from "react-router-dom";

const DimpuSignin = () => {
    return (
        <Row className="dimpu-sign-in-container">
            <Col md={6} className="dimpu-right-img-div">
                <img src={smallLogo} alt="" className="dimpu-right-small-img" />
                <img src={signImg} alt="" className="dimpu-right-img" />
            </Col>
            <Col md={6} className="signin-form-container">
                <div className="signin-form-inner-container">
                    <div className="company-logo-div">
                        <img src={metricinfo} alt="" className="company-logo" />
                    </div>
                    <h4 className="lm-wlcm-txt">Sign In</h4>
                    <p>Sign in to your account to start using MetricInfo</p>
                    <Form className='lm-login-form'>
                        <div className='input-box'>
                            <div className='input-box1'>
                                <label>Email</label>
                                <Form.Control type="text" id="userId" name="userId" />
                            </div>
                            <div className='input-box2'>
                                <label>Password</label>
                                <Form.Control type="text" id="userId" name="userId" />
                            </div>
                        </div>
                        {/* <br /> */}
                        <div className="forgot-pass-n-remind-me">
                            <div className="signIn-footer ">
                                <div className="create-account-div d-flex align-items-center">
                                    <input
                                        type="checkbox"
                                    />&nbsp;&nbsp;
                                    <div
                                        className="login-remember-me"
                                    >
                                        Remember my preferences
                                    </div>
                                </div>
                            </div>
                            <div className="forget-pasword-div">
                                <Link
                                    to="/forgetpassword"
                                    className="lm-pswd-reset"
                                >
                                    Forgot Password?
                                </Link>
                            </div>
                        </div>
                        <div className='lm-login-form-btn'>
                            {/* <p className='lm-pswd-reset'>Forgot password?</p> */}
                            <Button>Sign In</Button>
                            <p className="m-4"><span className='lm-signup-link'>&copy; 2024 MetricInfo</span></p>
                        </div>
                    </Form>
                </div>
            </Col>
        </Row>
    );
}

export default DimpuSignin;