import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SbApprovalBtn, SBSaveUpdateBtn } from "../Buttons/Buttons";
import customerImg from '../../assets/images/CustomerManagement/customer-img.svg'
import Select from 'react-select';
import { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import calendar from '../../assets/images/Dashboard/calendar-icon.svg'

const options = [
    { label: "Functional Approval", value: "functional_approval" },
    { label: "Financial Approval", value: "financial_approval" },
    { label: "Complaints Approval", value: "complaints_approval" },
    { label: "Escalation Approval", value: "escalation_approval" },
    { label: "Leave Approval", value: "leave_approval" },
    { label: "Leave Approval", value: "leave_approval" },
    { label: "Leave Approval", value: "leave_approval" },
    { label: "Leave Approval", value: "leave_approval" },
];

const roleOptions = [
    { label: "Role", value: "Role" }
]
const contactOptions = [
    { label: "+91", value: "+91" }
]
const ApplyLeavesModal = (props) => {
    const [selected, setSelected] = useState([]);
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const handleSetApproval = () => {
        setShowApprovalModal(true);
    };

    return (
        <>
            <Modal show={props.open} onHide={props.handleClose} className='add-customer-modal add-user-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Apply Leaves</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>User</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className='add-customer-calendar-container'>
                                        <label>From Date</label>
                                        {/* <div className='add-customer-calendar-container'> */}
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            selectsRange
                                            placeholderText='Start Date'
                                            // startDate={fromDate}
                                            // onChange={(data) => setSelectSearchDrop(data, "date")}
                                            maxDate={new Date()}
                                        />
                                        <img src={calendar} alt="" />
                                        {/* </div> */}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className='add-customer-calendar-container'>
                                        <label>To Date</label>
                                        {/* <div className='add-customer-calendar-container'> */}
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            selectsRange
                                            placeholderText='End Date'
                                            // startDate={fromDate}
                                            // onChange={(data) => setSelectSearchDrop(data, "date")}
                                            maxDate={new Date()}
                                        />
                                        <img src={calendar} alt="" />
                                        {/* </div> */}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Leave Type</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // value={selectedOption}
                                            // onChange={handleChange}
                                            // options={contactOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Reporting To</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // value={selectedOption}
                                            // onChange={handleChange}
                                            // options={contactOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>

                                <Col md={12}>
                                    <div>
                                        <label>Reason</label>
                                        <Form.Control as="textarea" rows={3} />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Modal.Body >
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={props.handleNextStep} />
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default ApplyLeavesModal;