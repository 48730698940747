// import React, { useState, useRef, useEffect } from 'react';
// import { Bar } from 'react-chartjs-2';
// import { Dropdown } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend,
//     PointElement,
//     LineElement,
//     LineController,
//     BarController,
// } from 'chart.js';

// ChartJS.register(
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend,
//     PointElement,
//     LineElement,
//     LineController,
//     BarController
// );

// const options = {
//     responsive: true,
//     plugins: {
//         legend: {
//             position: 'top',
//         },
//         tooltip: {
//             mode: 'index',
//             intersect: false,
//         },
//     },
//     scales: {
//         x: {
//             stacked: false, // Change this to false
//         },
//         y: {
//             stacked: false, // Change this to false
//             beginAtZero: true,
//         },
//     },
// };

// const data = {
//     labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
//     datasets: [
//         {
//             type: 'bar',
//             label: 'Pending',
//             data: [15, 18, 13, 22, 20, 25, 21, 22, 20, 15, 18, 22],
//             backgroundColor: 'rgba(240, 207, 43, 1)', // Yellow
//             borderColor: 'rgba(240, 207, 43, 1)',
//             borderWidth: 1,
//             borderRadius: 4,
//             barThickness: 8,
//             borderSkipped: false,
//         },
//         {
//             type: 'bar',
//             label: 'Rejected',
//             data: [5, 10, 5, 10, 5, 10, 5, 10, 5, 10, 5, 10],
//             backgroundColor: 'rgba(255, 94, 94, 1)', // Red
//             borderColor: 'rgba(255, 94, 94, 1)',
//             borderWidth: 1,
//             borderRadius: 4,
//             barThickness: 8,
//             borderSkipped: false,
//         },
//         {
//             type: 'line',
//             label: 'Approved',
//             data: [10, 12, 18, 15, 14, 15, 18, 16, 18, 14, 16, 17],
//             backgroundColor: function (context) {
//                 const chart = context.chart;
//                 const ctx = chart.ctx;
//                 const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
//                 gradient.addColorStop(0, 'rgba(58, 201, 119, 0.2)');
//                 gradient.addColorStop(1, 'rgba(58, 201, 119, 0)');
//                 return gradient;
//             },
//             borderColor: 'rgba(58, 201, 119, 1)',
//             borderWidth: 2,
//             fill: true,
//             tension: 0.4,
//         },
//     ],
// };

// const AttendanceStatusChart = () => {
//     const [selectedUser, setSelectedUser] = useState('User Name');
//     const [selectedCompany, setSelectedCompany] = useState('Company Name');
//     const [selectedMonth, setSelectedMonth] = useState('Month');
//     const [selectedYear, setSelectedYear] = useState('Year');

//     const handleSelectUser = (eventKey) => setSelectedUser(eventKey);
//     const handleSelectCompany = (eventKey) => setSelectedCompany(eventKey);
//     const handleSelectMonth = (eventKey) => setSelectedMonth(eventKey);
//     const handleSelectYear = (eventKey) => setSelectedYear(eventKey);

//     const chartRef = useRef(null);

//     useEffect(() => {
//         if (chartRef.current) {
//             const chart = chartRef.current.chartInstance;
//             // Additional chart customization can be done here if needed
//         }
//     }, []);

//     return (
//         <div className="barchart-container">
//             <div className="attendance-status-container mb-3">
//                 <h6>Status</h6>
//                 <div className="d-flex">
//                     <Dropdown onSelect={handleSelectUser} className="me-2">
//                         <Dropdown.Toggle id="dropdown-user">
//                             {selectedUser}
//                         </Dropdown.Toggle>
//                         <Dropdown.Menu>
//                             <Dropdown.Item eventKey="User Name">User Name</Dropdown.Item>
//                             <Dropdown.Item eventKey="Other User">Other User</Dropdown.Item>
//                         </Dropdown.Menu>
//                     </Dropdown>
//                     <Dropdown onSelect={handleSelectCompany} className="me-2">
//                         <Dropdown.Toggle id="dropdown-company">
//                             {selectedCompany}
//                         </Dropdown.Toggle>
//                         <Dropdown.Menu>
//                             <Dropdown.Item eventKey="Company Name">Company Name</Dropdown.Item>
//                             <Dropdown.Item eventKey="Other Company">Other Company</Dropdown.Item>
//                         </Dropdown.Menu>
//                     </Dropdown>
//                     <Dropdown onSelect={handleSelectMonth} className="me-2">
//                         <Dropdown.Toggle id="dropdown-month">
//                             {selectedMonth}
//                         </Dropdown.Toggle>
//                         <Dropdown.Menu>
//                             <Dropdown.Item eventKey="Month">Month</Dropdown.Item>
//                             <Dropdown.Item eventKey="January">January</Dropdown.Item>
//                             <Dropdown.Item eventKey="February">February</Dropdown.Item>
//                             {/* Add other months here */}
//                         </Dropdown.Menu>
//                     </Dropdown>
//                     <Dropdown onSelect={handleSelectYear}>
//                         <Dropdown.Toggle id="dropdown-year">
//                             {selectedYear}
//                         </Dropdown.Toggle>
//                         <Dropdown.Menu>
//                             <Dropdown.Item eventKey="2023">2023</Dropdown.Item>
//                             <Dropdown.Item eventKey="2024">2024</Dropdown.Item>
//                         </Dropdown.Menu>
//                     </Dropdown>
//                 </div>
//             </div>
//             <Bar ref={chartRef} data={data} options={options} />
//         </div>
//     );
// };

// export default AttendanceStatusChart;




import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    LineController,
    BarController,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    LineController,
    BarController
);

const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false, // Allow chart to resize with container
    plugins: {
        legend: {
            position: 'top',
        },
        tooltip: {
            mode: 'index',
            intersect: false,
        },
    },
    scales: {
        x: {
            stacked: false,
            grid: {
                display: false, // Hide grid lines if needed
            },
        },
        y: {
            stacked: false,
            beginAtZero: true,
            grid: {
                display: true,
                borderColor: 'rgba(246, 246, 246, 1)',
                borderWidth: 0.5,
            },
        },
    },
};

const AttendanceStatusChart = ({
    moduleName,
    labels,
    datasets,
    chartOptions = {},
    dropdownOptions = {},
    defaultSelection = {},
    selectedOption,
    handleSelect
}) => {

    return (
        <div className="barchart-container">
            <div className="attendance-status-container">
                <h6>{moduleName}</h6>
                <div className="d-flex">
                    {Object.keys(dropdownOptions).map((key) => (
                        <Dropdown
                            key={key}
                            onSelect={(eventKey) => handleSelect(eventKey, key)}
                            className="me-2"
                        >
                            <Dropdown.Toggle id={`dropdown-${key}`}>
                                {/* {selectedOption[key] || dropdownOptions[key].default} */}
                                {key === 'company' 
                                    ? selectedOption?.company?.name || dropdownOptions[key].default 
                                    : selectedOption[key] || dropdownOptions[key].default
                                }
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {dropdownOptions[key].items.map((item) => (
                                    <Dropdown.Item key={item.value} eventKey={item.value}>
                                        {item.label}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    ))}
                </div>
            </div>
            <div className="barchart-canvas-container">
                <Bar
                    data={{ labels, datasets }}
                    options={{ ...defaultOptions, ...chartOptions }}
                />
            </div>
        </div>
    );
};

export default AttendanceStatusChart;
