import React, { useEffect, useState } from 'react'
import { Button, Carousel, Col, Form, Row } from 'react-bootstrap';

// region Local Import
import logo_img from './assets/images/Login/logo-dark.png';
// import bg_img from './assets/images/Login/login-bg.svg';
import bg_img from './assets/images/Login/login-img1.svg';
import bg_img1 from './assets/images/Login/login-img2.svg';
import googleLogo from './assets/images/Login/google-icon.svg';
import linkedInLogo from './assets/images/Login/linkedin-icon.svg'
import oktaLogo from './assets/images/Login/okta-logo.svg'
import microsoftLogo from './assets/images/Login/microsoft-icon.svg'
import { Link } from 'react-router-dom';
import companyLogo from './assets/images/Login/signin-company-logo.svg';
import eyeClose from './assets/images/Login/eye-close.svg';
import eyeOpen from './assets/images/Login/eye-open.svg';
import companySmallLogo from './assets/images/Sidebar/company-small-logo.svg';
import { postSignInDatas } from './redux/action';
import { useDispatch } from 'react-redux';
import * as HeaderAuthentication from '../src/components/HeaderAuthentication';
// endregion

function Signin() {
    let dispatch = useDispatch();
    const [passwordType, setPasswordType] = useState("password");
    const [signInData, setSignInData] = useState(
        {
            EmailID: "",
            Password: ""
        }
    )
    const [errorMessage, setErrorMessage] = useState({ EmailID: '', Password: '' }); // State for error messages
    const [successMessage, setSuccessMessage] = useState(''); // State to manage success message
    const [rememberMe, setRememberMe] = useState(false); // State for Remember Me checkbox
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    };
    const navigateToOtpPage = () => {
        setTimeout(() => {
            window.location.href = '#/verifyOtp'
        }, 1500); // Delay
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSignInData({
            ...signInData,
            [name]: value
        });

        // Clear error message when the user starts typing
        setErrorMessage({
            ...errorMessage,
            [name]: ''
        });
    };

    // Load credentials from localStorage on mount
    useEffect(() => {
        const rememberedCredentials = JSON.parse(localStorage.getItem("rememberedCredentials"));
        if (rememberedCredentials) {
            setSignInData({
                EmailID: rememberedCredentials.email,
                Password: rememberedCredentials.pswd
            });
            setRememberMe(true);
        }
    }, []);

    // Handle "Remember me" toggle
    const toggleRememberMe = () => {
        setRememberMe(!rememberMe);
    };

    // Validate form fields
    const validateFields = () => {
        const { EmailID, Password } = signInData;
        let errors = {};
        let formIsValid = true;

        if (!EmailID) {
            formIsValid = false;
            errors.EmailID = 'Email is required';
        }

        if (!Password) {
            formIsValid = false;
            errors.Password = 'Password is required';
        }

        setErrorMessage(errors);
        return formIsValid;
    };

    const AddSignInData = (e) => {
        e.preventDefault();

        // Validate the fields before making the API call
        if (!validateFields()) return;

        // If "Remember Me" is checked, save credentials to localStorage
        if (rememberMe) {
            const credentials = { email: signInData.EmailID, pswd: signInData.Password, remember: rememberMe };
            localStorage.setItem("rememberedCredentials", JSON.stringify(credentials));
        } else {
            localStorage.removeItem("rememberedCredentials"); // Clear saved credentials
        }

        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(signInData),
        };
        dispatch(postSignInDatas(requestOption, navigateToOtpPage, handleResponse));
    }

    // Handle the server response for success or failure
    const handleResponse = (response) => {
        if (response.Success === '1') {
            setSuccessMessage(response.Msg || "Successfully signed in!");
            setErrorMessage({ EmailID: '', Password: '' });
            // Redirect to another page or trigger other actions if needed
        } else {
            setErrorMessage({ EmailID: '', Password: response.Msg || 'An error occurred. Please try again.' });
            setSuccessMessage('');
        }
    };

    return (
        <div className='signin-main-container'>
            <Row className='lm-signin-login-row'>

                <Col md={6} className='lm-signin-form-col'>
                    <img
                        src={companySmallLogo}
                        alt="Company Logo"
                        className='company-small-logo'
                    />
                    <h4 className="lm-wlcm-txt">Sign In</h4>
                    <p>Sign in to your account to start using EXCOMATIX</p>
                    <Form className='lm-login-form'>
                        <div className='input-box'>
                            <div className='input-box1'>
                                <label>Email</label>
                                <Form.Control
                                    type="email"
                                    id="EmailID"
                                    name="EmailID"
                                    value={signInData.EmailID}
                                    onChange={handleChange}
                                />
                            </div>
                            {errorMessage.EmailID && <div className="text-danger d-flex pt-2">{errorMessage.EmailID}</div>}
                            <div style={{ position: "relative" }}>
                                <div className='input-box2'>
                                    <label>Password</label>
                                    <Form.Control
                                        type={passwordType}
                                        id="Password"
                                        name="Password"
                                        value={signInData.Password}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="pass-hide-show">
                                    {passwordType === "password" ? (
                                        <img src={eyeClose} alt="" onClick={togglePassword} />
                                    ) : (
                                        <img src={eyeOpen} alt="" onClick={togglePassword} />
                                    )}
                                </div>
                            </div>
                            {errorMessage.Password && <div className="text-danger d-flex pt-2">{errorMessage.Password}</div>}
                        </div>
                        {/* <br /> */}
                        <div className="forgot-pass-n-remind-me">
                            <div className="signIn-footer ">
                                <div className="create-account-div d-flex align-items-center">
                                    <input
                                        type="checkbox"
                                        checked={rememberMe}
                                        onChange={toggleRememberMe}
                                    />&nbsp;&nbsp;
                                    <div
                                        className="login-remember-me"
                                    >
                                        Remember my preferences
                                    </div>
                                </div>
                            </div>
                            <div className="forget-pasword-div">
                                <Link
                                    to="/forgetpassword"
                                    className="lm-pswd-reset"
                                >
                                    Forgot Password?
                                </Link>
                            </div>
                        </div>
                        <div className='lm-login-form-btn'>
                            {/* <p className='lm-pswd-reset'>Forgot password?</p> */}
                            <Button onClick={AddSignInData}>Sign In</Button>
                        </div>

                        {/* Display error or success message */}
                        {/* {errorMessage && <div className="text-danger mt-3">{errorMessage}</div>} */}
                        {successMessage && <div className="text-success mt-3">{successMessage}</div>}

                        {/* <p className='lm-devider'><span>Or</span></p> */}
                        <div class="divider-container">
                            <div class="line"></div>
                            <p class="text">Or continue with</p>
                            <div class="line"></div>
                        </div>

                        <div className='lm-login-btns-section'>
                            <div className='btn-div'>
                                <img src={googleLogo} alt="google" className='login-cmp-icons' />
                                <span class="sr-only">Google</span>
                                <span aria-hidden="true" class="login-tooltip">Google</span>
                            </div>
                            <div className='btn-div'>
                                <img src={linkedInLogo} alt="linkedin" className='login-cmp-icons' />
                                <span class="sr-only">LinkedIn</span>
                                <span aria-hidden="true" class="login-tooltip">LinkedIn</span>
                            </div>
                            <div className='btn-div'>
                                <img src={microsoftLogo} alt="linkedin" className='login-cmp-icons' />
                                <span class="sr-only">Microsoft</span>
                                <span aria-hidden="true" class="login-tooltip">Microsoft</span>
                            </div>
                            <div className='btn-div'>
                                <img src={oktaLogo} alt="linkedin" className='login-cmp-icons' />
                                <span class="sr-only">Okta</span>
                                <span aria-hidden="true" class="login-tooltip">Okta</span>
                            </div>
                        </div>
                        {/* <p className='m-0'><span className='not-registered'>Not registered?</span> <span className='lm-signup-link'>Register</span></p> */}
                    </Form>
                </Col>
                <Col md={6} className='lm-signin-image-col'>
                    <Carousel
                        style={{ width: "100%" }}
                        controls={false}
                        indicators={false}
                    // interval={2000}
                    >
                        <Carousel.Item>
                            <div className='lm-bg-img-div'>
                                <img src={bg_img} alt="" className='lm-bg-img' />
                                <div className="carousel-content">
                                    <img src={companyLogo} alt="Company Logo" className="carousel-logo" />
                                    {/* <p className="carousel-text">
                                        CRM dashboard uses line charts to visualize customer-related metrics and trends over time.
                                    </p> */}
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className='lm-bg-img-div'>
                                <img src={bg_img1} alt="" className='lm-bg-img' />
                                <div className="carousel-content">
                                    <img src={companyLogo} alt="Company Logo" className="carousel-logo" />
                                    {/* <p className="carousel-text">
                                        CRM dashboard uses line charts to visualize customer-related metrics and trends over time.
                                    </p> */}
                                </div>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                </Col>
            </Row>
        </div>
    )
}

export default Signin;
