import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, styled } from "@mui/material";
import * as HeaderAuthentication from '../../HeaderAuthentication'
import { loadAssignedModulesDropdownData, postDepartmentDatas, postSubModulesDatas } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { ADDED_BY_ID } from "../../Constants";

const enablingOptions = [
    { value: "3", label: "Active" },
    { value: "4", label: "Inactive" }
]

const AddSubModules = (props) => {
    let dispatch = useDispatch();
    const subModuleDatas = props.subModuleDatas;
    const singleSubModuleData = props.singleSubModuleData;
    const subModuleId = props.subModuleId;
    const { assignedModulesDropdown } = useSelector((state) => state.data);

    const [moduleNames, setModuleNames] = useState(null);
    const [subModuleName, setSubModuleName] = useState("");
    const [selectedOption, setSelectedOption] = useState(null);
    const [errors, setErrors] = useState({});

    const handleSelectChange = (option) => {
        setSelectedOption(option);
    };

    useEffect(() => {
        if (subModuleId && singleSubModuleData) {
            setSubModuleName(singleSubModuleData[0]?.SubModulesName);
            const moduleOption = assignedModulesDropdown.find(option => option.label === singleSubModuleData[0]?.ModulesName);
            setModuleNames(moduleOption || null);
            const statusOption = enablingOptions.find(option => option.label === singleSubModuleData[0]?.Status);
            setSelectedOption(statusOption || null);
        } else {
            // Reset fields for adding new classification
            setSubModuleName("");
            setSelectedOption(null);
        }
    }, [subModuleId, singleSubModuleData]);

    useEffect(() => {
        dispatch(loadAssignedModulesDropdownData())
    }, [])

    const AddDepartmentData = (e) => {
        e.preventDefault();
        const newErrors = {};

        // Validate required fields
        if (!moduleNames) newErrors.moduleNames = "Module Name required.";
        if (!subModuleName) newErrors.subModuleName = "Sub Module Name required.";

        // Check if there are errors
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        const data = {
            SubModulesID: subModuleId,
            SubModulesName: subModuleName,
            ModulesID: moduleNames?.value,
            StatusID: parseInt(selectedOption?.value),
            AddedByID: ADDED_BY_ID
        }
        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data),
        };
        dispatch(postSubModulesDatas(requestOption, props.handleCloseModal, subModuleDatas, subModuleId));
    }

    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Modules</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Sub Module Name</label>
                                        <Form.Control
                                            type="text"
                                            value={subModuleName}
                                            // onChange={(e) => (setSubModuleName(e.target.value))}
                                            onChange={(e) => {
                                                setSubModuleName(e.target.value);
                                                setErrors(prev => ({ ...prev, subModuleName: "" })); // Clear specific error
                                            }}
                                            isInvalid={!!errors.subModuleName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.subModuleName}
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Modules</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={assignedModulesDropdown}
                                            placeholder="Select"
                                            value={moduleNames}
                                            // onChange={(options) => (setModuleNames(options))}
                                            onChange={(selectedOption) => {
                                                setModuleNames(selectedOption);
                                                setErrors(prev => ({ ...prev, moduleNames: "" })); // Clear specific error
                                            }}
                                            isInvalid={!!errors.moduleNames}
                                        />
                                        {errors.moduleNames && <div className="invalid-feedback" style={{ display: 'block' }}>{errors.moduleNames}</div>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Enable / Disable</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={enablingOptions}
                                            placeholder="Select"
                                            value={selectedOption}
                                            onChange={handleSelectChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={AddDepartmentData} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddSubModules;
