import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/leaves.scss'; // Ensure this path is correct
import cheverondown from '../../assets/images/Dashboard/chevron-down.svg';
import { Modal } from 'react-bootstrap';
import SbCancelBtn, { SBSaveUpdateBtn } from './Buttons';

const SbComplaintStatusDropdown = ({ status, onStatusChange }) => {
    const [localStatus, setLocalStatus] = useState(status); // Use the prop status as initial value
    const [showModal, setShowModal] = useState(false);
    const [action, setAction] = useState(null);
    const [rejectionReason, setRejectionReason] = useState(''); // State for rejection reason

    // Handle status change and show modal for certain actions
    const handleStatusChange = (newStatus) => {
        setAction(newStatus);
        setShowModal(true);
    };

    // Confirm the action and update status
    const handleConfirm = () => {
        if (action) {
            setLocalStatus(action);
            onStatusChange(action); // Update the parent status
            setAction(null);
            setRejectionReason(''); // Reset reason after confirming
        }
        setShowModal(false);
    };

    // Close the modal without updating status
    const handleClose = () => {
        setShowModal(false);
        setAction(null);
        setRejectionReason(''); // Reset reason on close
    };

    // Determine the button class based on status
    const getButtonClass = () => {
        switch (localStatus) {
            case 'Pending':
                return 'status-pending status-btn-pending';
            case 'In-Process':
                return 'status-inprogress status-btn-inprogress';
            case 'Resolved':
                return 'status-approved status-btn-resolved';
            case 'Re-Open':
                return 'status-reopen';
            case 'Cancel':
                return 'status-rejected status-btn-rejected';
            default:
                return '';
        }
    };

    // Determine whether to show the chevron
    const shouldShowChevron = localStatus !== 'Approved' && localStatus !== 'Rejected';

    return (
        <>
            <div className="dropdown add-single-bulk">
                <span
                    className={`btn status-btn dropdown-toggle ${getButtonClass()}`}
                >
                    {localStatus}
                    {shouldShowChevron && (
                        <span className="dropdown-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                            &nbsp;&nbsp;&nbsp;
                            <img src={cheverondown} alt="dropdown-icon" />
                        </span>
                    )}

                    <ul className="dropdown-menu" aria-labelledby="statusDropdown">
                        <li>
                            <button
                                className="dropdown-item"
                                onClick={() => handleStatusChange('Pending')}
                            >
                                Pending
                            </button>
                        </li>
                        <li>
                            <button
                                className="dropdown-item"
                                onClick={() => handleStatusChange('In-Process')}
                            >
                                In-Process
                            </button>
                        </li>
                        <li>
                            <button
                                className="dropdown-item"
                                onClick={() => handleStatusChange('Resolved')}
                            >
                                Resolved
                            </button>
                        </li>
                        <li>
                            <button
                                className="dropdown-item"
                                onClick={() => handleStatusChange('Re-Open')}
                            >
                                Re-Open
                            </button>
                        </li>
                        <li>
                            <button
                                className="dropdown-item"
                                onClick={() => handleStatusChange('Cancel')}
                            >
                                Cancel
                            </button>
                        </li>
                    </ul>
                </span>
            </div>

            <Modal show={showModal} onHide={handleClose} className='add-customer-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm {action}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to {action?.toLowerCase()} this Leave?
                    {action === 'Rejected' && (
                        <div className="mt-3">
                            <label htmlFor="rejectionReason">Reason</label>
                            <textarea
                                id="rejectionReason"
                                className="form-control"
                                rows="3"
                                value={rejectionReason}
                                onChange={(e) => setRejectionReason(e.target.value)}
                            />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="No" onClickEffect={handleClose} />
                    <SBSaveUpdateBtn btnName="Yes" onClickEffect={handleConfirm} />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SbComplaintStatusDropdown;
