import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import { loadApprovalLevelDropdownData, loadDepartmentDropdownData, postComplaintsApprovalDatas } from "../../../redux/action";
import * as HeaderAuthentication from '../../HeaderAuthentication'
import { ADDED_BY_ID } from "../../Constants";

const enablingOptions = [
    { value: 46, label: "Active" },
    { value: 47, label: "Inactive" }
]

const complaintsApprovalOrderOptions = [
    { value: 'Sequential', label: "Sequential" },
    { value: 'Parallel', label: "Parallel" }
]

const AddComplaintsApproval = (props) => {
    let dispatch = useDispatch();
    const complaintsApprovalDatas = props.complaintsApprovalDatas;
    const complaintsApprovalId = props.complaintsApprovalId;
    const singleComplaintsApprovalData = props.singleComplaintsApprovalData;

    const { approvalLevelDropdown } = useSelector((state) => state.data);
    const { departmentDropdown } = useSelector((state) => state.data);

    const [complaintsApprovalData, setComplaintsApprovalData] = useState(
        {
            LevelID: null,
            Title: "",
            DepartmentID: null,
            Order: "",
            Condition: "",
            StatusID: null,
            AddedByID: ADDED_BY_ID
        }
    )

    const [errors, setErrors] = useState({
        LevelID: "",
        Title: "",
        DepartmentID: "",
        Order: "",
        Condition: "",
    });

    useEffect(() => {
        dispatch(loadApprovalLevelDropdownData());
        dispatch(loadDepartmentDropdownData());
    }, []);

    useEffect(() => {
        if (complaintsApprovalId && singleComplaintsApprovalData && singleComplaintsApprovalData.length > 0) {
            const singleApproval = singleComplaintsApprovalData[0]; // Access the first item

            // Set the form data based on the singleComplaintsApprovalData
            setComplaintsApprovalData({
                ComplaintApprovalID: complaintsApprovalId,
                LevelID: singleApproval.ComplaintsApprovalLevelID,
                Title: singleApproval.ComplaintsApprovalTitle,
                DepartmentID: singleApproval.ComplaintsApprovalDepartmentID,
                Order: singleApproval.ComplaintsApprovalOrder,
                Condition: singleApproval.ComplaintsApprovalCondition,
                StatusID: singleApproval.StatusID,
                AddedByID: ADDED_BY_ID
            });
        }
    }, [complaintsApprovalId, singleComplaintsApprovalData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setComplaintsApprovalData(prevState => ({
            ...prevState,
            [name]: value
        }));

        // Clear the error for the specific field
        setErrors((prevState) => ({
            ...prevState,
            [name]: ""
        }));
    };

    const handleSelectChange = (name, selectedOption) => {
        setComplaintsApprovalData(prevState => ({
            ...prevState,
            [name]: selectedOption ? selectedOption.value : null
        }));

        // Clear the error for the specific field
        setErrors((prevState) => ({
            ...prevState,
            [name]: ""
        }));
    };

    const validateForm = () => {
        const newErrors = {
            LevelID: complaintsApprovalData.LevelID ? "" : "Approval level is required",
            Title: complaintsApprovalData.Title ? "" : "Title is required",
            DepartmentID: complaintsApprovalData.DepartmentID ? "" : "Department is required",
            Order: complaintsApprovalData.Order ? "" : "Complaints order is required",
            Condition: complaintsApprovalData.Condition ? "" : "Condition is required",
        };

        setErrors(newErrors);

        // Return true if there are no errors
        return Object.values(newErrors).every((error) => error === "");
    };


    const AddComplaintsApprovalData = (e) => {
        e.preventDefault();
        if (validateForm()) {
            let requestOption = {
                method: "POST",
                mode: "cors",
                headers: HeaderAuthentication.postExcomatixAuth,
                body: JSON.stringify(complaintsApprovalData),
            };
            dispatch(postComplaintsApprovalDatas(requestOption, props.handleCloseModal, complaintsApprovalDatas, complaintsApprovalId));
            // console.log(complaintsApprovalData, 'complaintsApprovalData')
        }
    }

    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Complaints Approval</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Approval Levels</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={approvalLevelDropdown}
                                            placeholder="Select"
                                            value={approvalLevelDropdown.find(option => option.value === complaintsApprovalData.LevelID)}
                                            onChange={(selectedOption) => handleSelectChange('LevelID', selectedOption)}
                                        />
                                    </div>
                                    {errors.LevelID && <span className="text-danger">{errors.LevelID}</span>}
                                </Col>
                                <Col md={8}>
                                    <div>
                                        <label>Title</label>
                                        <Form.Control
                                            type="text"
                                            name="Title"
                                            value={complaintsApprovalData.Title}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    {errors.Title && <span className="text-danger">{errors.Title}</span>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Department</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={departmentDropdown}
                                            placeholder="Select"
                                            value={departmentDropdown.find(option => option.value === complaintsApprovalData.DepartmentID)}
                                            onChange={(selectedOption) => handleSelectChange('DepartmentID', selectedOption)}
                                        />
                                    </div>
                                    {errors.DepartmentID && <span className="text-danger">{errors.DepartmentID}</span>}
                                </Col>
                                <Col md={8}>
                                    <div>
                                        <label>Complaints Order</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={complaintsApprovalOrderOptions}
                                            placeholder="Select"
                                            value={complaintsApprovalOrderOptions.find(option => option.value === complaintsApprovalData.Order)}
                                            onChange={(selectedOption) => handleSelectChange('Order', selectedOption)}
                                        />
                                    </div>
                                    {errors.Order && <span className="text-danger">{errors.Order}</span>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Complaints Conditions</label>
                                        <Form.Control
                                            type="text"
                                            name="Condition"
                                            value={complaintsApprovalData.Condition}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    {errors.Condition && <span className="text-danger">{errors.Condition}</span>}
                                </Col>
                            </Row>
                            {complaintsApprovalId && <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Status</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={enablingOptions}
                                            placeholder="Select"
                                            value={enablingOptions.find(option => option.value === complaintsApprovalData.StatusID)}
                                            onChange={(selectedOption) => handleSelectChange('StatusID', selectedOption)}
                                        />
                                    </div>
                                </Col>
                            </Row>}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={AddComplaintsApprovalData} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddComplaintsApproval;
