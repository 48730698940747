import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton
} from '@mui/material';
import editPenImg from '../../assets/images/CustomerManagement/edit-pen-img.svg';
import { SbAddBtn } from '../Buttons/Buttons';
import calendar from '../../assets/images/Dashboard/calendar-icon.svg';
import searchIcon from '../../assets/images/Dashboard/search-icon.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Col, Form, Row } from 'react-bootstrap';
import AddLeave from './AddLeave';
import Main from '../Main';
import customerImg from '../../assets/images/CustomerManagement/customer-img.svg';
import { useNavigate } from 'react-router-dom';
import Download from '../MainSection/Download/Download';
import PaginationComponent from '../PaginationComponent';

// Sample data
const data = [
    {
        fromdate: "13/08/24",
        todate: '14/08/24',
        leaveDuration: '01 day',
        reportingTo: 'Vijaykumar',
        leaveType: "Sick Leave",
        reason: 'Injury or illness. If you were injured or sick and therefore unable to work effectively, you can ask to take leave from work for a certain period period.',
    },
    {
        fromdate: "13/08/24",
        todate: '14/08/24',
        leaveDuration: '01 day',
        reportingTo: 'Vijaykumar',
        leaveType: "Sick Leave",
        reason: 'Injury or illness. If you were injured or sick and therefore unable to work effectively, you can ask to take leave from work for a certain period period.',
    },
    {
        fromdate: "13/08/24",
        todate: '14/08/24',
        leaveDuration: '01 day',
        reportingTo: 'Vijaykumar',
        leaveType: "Sick Leave",
        reason: 'Injury or illness. If you were injured or sick and therefore unable to work effectively, you can ask to take leave from work for a certain period period.',
    },
    {
        fromdate: "13/08/24",
        todate: '14/08/24',
        leaveDuration: '01 day',
        reportingTo: 'Vijaykumar',
        leaveType: "Sick Leave",
        reason: 'Injury or illness. If you were injured or sick and therefore unable to work effectively, you can ask to take leave from work for a certain period period.',
    },
    {
        fromdate: "13/08/24",
        todate: '14/08/24',
        leaveDuration: '01 day',
        reportingTo: 'Vijaykumar',
        leaveType: "Sick Leave",
        reason: 'Injury or illness. If you were injured or sick and therefore unable to work effectively, you can ask to take leave from work for a certain period period.',
    },
    {
        fromdate: "13/08/24",
        todate: '14/08/24',
        leaveDuration: '01 day',
        reportingTo: 'Vijaykumar',
        leaveType: "Sick Leave",
        reason: 'Injury or illness. If you were injured or sick and therefore unable to work effectively, you can ask to take leave from work for a certain period period.',
    },
    {
        fromdate: "13/08/24",
        todate: '14/08/24',
        leaveDuration: '01 day',
        reportingTo: 'Vijaykumar',
        leaveType: "Sick Leave",
        reason: 'Injury or illness. If you were injured or sick and therefore unable to work effectively, you can ask to take leave from work for a certain period period.',
    },
    {
        fromdate: "13/08/24",
        todate: '14/08/24',
        leaveDuration: '01 day',
        reportingTo: 'Vijaykumar',
        leaveType: "Sick Leave",
        reason: 'Injury or illness. If you were injured or sick and therefore unable to work effectively, you can ask to take leave from work for a certain period period.',
    },
    {
        fromdate: "13/08/24",
        todate: '14/08/24',
        leaveDuration: '01 day',
        reportingTo: 'Vijaykumar',
        leaveType: "Sick Leave",
        reason: 'Injury or illness. If you were injured or sick and therefore unable to work effectively, you can ask to take leave from work for a certain period period.',
    },
];

// Table columns
const columns = [
    { id: 'fromdate', label: 'FROM DATE', align: 'left', minWidth: '120px' },
    { id: 'todate', label: 'TO DATE', align: 'left', minWidth: '120px' },
    { id: 'leaveDuration', label: 'LEAVE DURATION', align: 'left', minWidth: '150px' },
    { id: 'reportingTo', label: 'REPORTING TO', align: 'left', minWidth: '150px' },
    { id: 'leaveType', label: 'LEAVE TYPE', align: 'left', minWidth: '100px' },
    { id: 'reason', label: 'REASON', align: 'left', minWidth: '300px' },
];

const LeaveDetails = () => {
    const navigate = useNavigate();
    const [openAddLeave, setAddLeave] = useState(false);

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Set initial rows per page
    const [paginatedData, setPaginatedData] = useState([]);

    const handleAddLeave = () => {
        setAddLeave(true);
    };

    const handleCloseAddLeave = () => {
        setAddLeave(false);
    };

    const handleEditLeave = () => {
        setAddLeave(true);
    };

    const handleAnalyticsClick = () => {
        navigate('/attendanceDashboard')
    }

    const handleBack = () => {
        navigate(-1);
    };

    // Pagination logic
    useEffect(() => {
        const start = page * rowsPerPage; // Updated page logic to 0-based
        const end = start + rowsPerPage;
        setPaginatedData(data.slice(start, end));
    }, [data, page, rowsPerPage]);

    const handlePageChange = (newPage) => {
        setPage(newPage - 1); // Handle 1-based to 0-based page index for slicing
    };
    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset to the first page when rows per page changes
    };

    return (
        <div className="customer-management-container">
            <Main>
                <div className='components-download-container'>
                    <Download
                        showBackButton={true}
                        moduleName="Leaves Details"
                        showDashboardLibraryButton={false}
                        showSaveCustomViewButton={false}
                        showAnalyticsButton={true}
                        showDownloadDropdown={true}
                        onClick={handleAnalyticsClick}
                        onBackClick={handleBack}
                    />
                </div>

                {/* <div className="customermanagement-content"> */}
                <Row className='leaves-cards-container'>
                    <Col md={2} className='leave-cards leave-details-card'>
                        <div className='leave-cards-counts'>
                            <b>2</b>
                            <p>Earned Leaves</p>
                        </div>
                    </Col>
                    <Col md={2} className='leave-cards leave-details-card'>
                        <div className='leave-cards-counts'>
                            <b>02</b>
                            <p>Floating Holiday</p>
                        </div>
                    </Col>
                    <Col md={2} className='leave-cards leave-details-card'>
                        <div className='leave-cards-counts'>
                            <b>02</b>
                            <p>Personal Time Off</p>
                        </div>
                    </Col>
                    <Col md={2} className='leave-cards leave-details-card'>
                        <div className='leave-cards-counts'>
                            <b>02</b>
                            <p>Sick Leaves</p>
                        </div>
                    </Col>
                    <Col md={2} className='leave-cards leave-details-card'>
                        <div className='leave-cards-counts'>
                            <b>02</b>
                            <p>Casual Leaves</p>
                        </div>
                    </Col>
                    <Col md={2} className='leave-cards leave-details-card'>
                        <div className='leave-cards-counts'>
                            <b>04</b>
                            <p>Compensatory Leaves</p>
                        </div>
                    </Col>
                    <Col md={2} className='leave-cards leave-details-card'>
                        <div className='leave-cards-counts'>
                            <b style={{ height: "60px", width: "60px", margin: "0px" }}>10 / 16</b>
                            <p style={{ margin: "0px" }}>Leave Balance</p>
                        </div>
                    </Col>
                </Row>
                <Row className='m-0 employee-leaves-details-container'>
                    <Col md={9} className='customermanagement-search-filter p-0'>
                        <div className='customermanagement-content-container'>
                            <div className="customermanagement-content">
                                <Row className='customermanagement-btns-container'>
                                    <Col md={3} className='customermanagement-backbtn-div'>
                                        <span>Karthik</span>
                                    </Col>
                                    <Col md={9} className='customermanagement-search-filter'>
                                        <Col md={3} className='filters-col'>
                                            <div className='customermanagement-search'>
                                                <Form.Control type="search" placeholder='Search' />
                                                <img src={searchIcon} alt="Search" />
                                            </div>
                                        </Col>
                                        <Col md={3} className='filters-col'>
                                            <div className='calendar-container'>
                                                <DatePicker
                                                    className="select from-date"
                                                    id="date-range-picker-from"
                                                    dateFormat="dd/MM/yyyy"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    selectsRange
                                                    dropdownMode="select"
                                                    placeholderText='From'
                                                    maxDate={new Date()}
                                                />
                                                <img src={calendar} alt="Calendar" />
                                            </div>
                                        </Col>
                                        <Col md={3} className='filters-col'>
                                            <div className='calendar-container'>
                                                <DatePicker
                                                    className="select from-date"
                                                    id="date-range-picker-to"
                                                    dateFormat="dd/MM/yyyy"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    selectsRange
                                                    dropdownMode="select"
                                                    placeholderText='To'
                                                    maxDate={new Date()}
                                                />
                                                <img src={calendar} alt="Calendar" />
                                            </div>
                                        </Col>
                                        <SbAddBtn btnName="Add Leaves" onClickEffect={handleAddLeave} />
                                    </Col>
                                </Row>

                                <div className='customermanagement-table-div leave-details-table'>
                                    <TableContainer component={Paper} sx={{ maxHeight: 440 }} className='table-container'>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }} className='table-header-div'>
                                                                {column.label}
                                                            </div>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {paginatedData.map((row, index) => (
                                                    <TableRow key={index} className='table-row-data-container'>
                                                        {columns.map((column) => (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {row[column.id]}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {/* Use PaginationComponent */}
                                    <PaginationComponent
                                        totalItems={data.length}
                                        itemsPerPage={rowsPerPage}
                                        onPageChange={handlePageChange}
                                        onRowsPerPageChange={handleRowsPerPageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col md={3} className='employee-profile-col'>
                        {/* Employee Profile Section */}
                        <div className="employee-profile-container">
                            <img src={customerImg} alt="Profile" className="employee-profile-img" />
                            <div className="employee-details">
                                <div className='pb-3'>
                                    <p>ID</p>
                                    <span>1001</span>
                                </div>
                                <div className='pb-3'>
                                    <p>Name</p>
                                    <span> Karthik Karthik</span>
                                </div>
                                <div className='pb-3'>
                                    <p>Email </p>
                                    <span>karthikkarthik@gmail.com</span>
                                </div>
                                <div className='pb-3'>
                                    <p>Role </p>
                                    <span>Sr. Software Engineer</span>
                                </div>
                                <div className='pb-3'>
                                    <p>Department </p>
                                    <span>IT</span>
                                </div>
                                <div className='pb-3'>
                                    <p>Contact </p>
                                    <span>+91 9999999999</span>
                                </div>
                                <div className='pb-3'>
                                    <p>Address</p>
                                    <span> 1234, MG Road, Bangalore, India</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Main >
        </div>
    );
};

export default LeaveDetails;
