import { createStore, applyMiddleware } from "redux";
import { logger } from "redux-logger";
import { thunk } from "redux-thunk"; // Named import
import rootReducer from "./root-reducer";

const middlewares = [thunk]; // Use the named import

if (process.env.NODE_ENV === "development") {
    middlewares.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;
