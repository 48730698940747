import add from '../../assets/images/Dashboard/add.svg'
import filterIcon from '../../assets/images/Dashboard/filter-icon.svg'
import reset from '../../assets/images/CustomerManagement/reset.svg'
import approvalImg from '../../assets/images/UserManagement/approval-Img.svg'

export function SBSaveUpdateBtn(props) {
    return (
        <div className="dropdown add-single-bulk">
            <button
                type={!props.onClickEffect ? "submit" : "button"}
                className="btn btn-add-save-update"
                id="adduser-single-blulk"
                onClick={props.onClickEffect && props.onClickEffect}
                disabled={props.disableProp ?? false}
            >
                {props.btnName}
            </button>
        </div>
    );
}

function SbCancelBtn(props) {
    return (
        <div className="dropdown add-single-bulk">
            <button
                className="btn btn-sb-cancel"
                id="adduser-single-blulk"
                onClick={props.onClickEffect && props.onClickEffect}
                disabled={props.disableProp}
            >
                {props.btnName}
            </button>
        </div>
    );
}

export default SbCancelBtn;

export function SbAddBtn(props) {
    return (
        <div className="dropdown add-single-bulk">
            <button
                className="btn btn-add-dropdown"
                id="adduser-single-blulk"
                onClick={props.onClickEffect && props.onClickEffect}
                disabled={props.disableProp}
            >
                <div className="btn-inline-item d-flex align-items-center">
                    <img src={add} alt="" />
                    &nbsp;&nbsp;
                    {props.btnName}
                </div>
            </button>
        </div>
    );
}
export function SbApprovalBtn(props) {
    return (
        <div className="dropdown add-single-bulk">
            <button
                className="btn btn-approval-dropdown"
                id="adduser-single-blulk"
                onClick={props.onClickEffect && props.onClickEffect}
                disabled={props.disableProp}
            >
                <div className="btn-inline-item d-flex align-items-center" style={{width:"max-content"}}>
                    {props.btnName}&nbsp;&nbsp;
                    <img src={approvalImg} alt="" />
                    <img src={approvalImg} alt="" />
                </div>
            </button>
        </div>
    );
}
export function SbFilterBtn(props) {
    return (
        <div className="dropdown add-single-bulk">
            <button
                className="btn btn-add-dropdown"
                id="adduser-single-blulk"
                onClick={props.onClickEffect && props.onClickEffect}
                disabled={props.disableProp}
            >
                <div className="btn-inline-item d-flex align-items-center">
                    <img src={filterIcon} alt="" />
                    &nbsp;&nbsp;
                    {props.btnName}
                </div>
            </button>
        </div>
    );
}
export function SbResetBtn(props) {
    return (
        <div className="dropdown add-single-bulk">
            <button
                className="btn btn-add-dropdown"
                id="adduser-single-blulk"
                onClick={props.onClickEffect && props.onClickEffect}
                disabled={props.disableProp}
            >
                <div className="btn-inline-item d-flex align-items-center">
                    <img src={reset} alt="" />
                    &nbsp;&nbsp;
                    {props.btnName}
                </div>
            </button>
        </div>
    );
}

export function SBPreviousBtn(props) {
    return (
        <div className="dropdown add-single-bulk">
            <button
                className="btn btn-previous"
                id="adduser-single-blulk"
                onClick={props.onClickEffect && props.onClickEffect}
                disabled={props.disableProp ?? false}
            >
                {props.btnName}
            </button>
        </div>
    );
}
export function SBAdvanceBtn(props) {
    return (
        <div className="dropdown add-single-bulk">
            <button
                className="btn btn-advance"
                id="adduser-single-blulk"
                onClick={props.onClickEffect && props.onClickEffect}
                disabled={props.disableProp ?? false}
            >
                {props.btnName}
            </button>
        </div>
    );
}

