import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';
import * as HeaderAuthentication from '../../HeaderAuthentication'
import { useDispatch, useSelector } from "react-redux";
import { loadAssignedModulesDropdownData, postSystemNumberingDatas } from "../../../redux/action";
import { ADDED_BY_ID } from "../../Constants";

const AddSystemNumbering = (props) => {

    const dispatch = useDispatch();
    const systemNumbering = props.systemNumbering;
    const { assignedModulesDropdown } = useSelector((state) => state.data);
    const [module, setModule] = useState(null);
    const [codePrefix, setCodePrefix] = useState("");
    const [startNumber, setStartNumber] = useState("");
    const [numberLength, setNumberLength] = useState("");
    const [errors, setErrors] = useState({});

    useEffect(() => {
        dispatch(loadAssignedModulesDropdownData())
    }, [])

    const AddSystemNumberingData = (e) => {
        e.preventDefault();

        const newErrors = {};

        // Validate required fields
        if (!module) newErrors.module = "Module Name required.";
        if (!codePrefix) newErrors.codePrefix = "Code Prefix required.";
        if (!startNumber) newErrors.startNumber = "Start Number required.";
        if (!numberLength) newErrors.numberLength = "Number Length required.";

        // Set errors state if there are any errors
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return; // Stop further execution
        }

        const data = {
            SystemNumbering_ModuleIDFK: module.value,
            SystemNumbering_CodePrefix: codePrefix,
            SystemNumbering_StartNumber: startNumber,
            SystemNumbering_NumberLength: numberLength,
            AddedByID: ADDED_BY_ID
        }
        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data),
        };
        dispatch(postSystemNumberingDatas(requestOption, props.handleCloseModal, systemNumbering));
    }

    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Add System Numbering</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Set Modules <span className="required-asterisk">*</span></label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={assignedModulesDropdown}
                                            placeholder="Select"
                                            value={module}
                                            onChange={(option) => {
                                                setModule(option)
                                                setErrors(prev => ({ ...prev, module: "" }));
                                            }}
                                            isInvalid={!!errors.module}
                                        />
                                        {errors.module && <div className="invalid-feedback" style={{ display: 'block' }}>{errors.module}</div>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Set Code Prefix <span className="required-asterisk">*</span></label>
                                        <Form.Control
                                            type="text"
                                            value={codePrefix}
                                            onChange={(e) => {setCodePrefix(e.target.value)
                                                setErrors(prev => ({ ...prev, codePrefix: "" }));
                                            }}
                                        />
                                        {errors.codePrefix && <div className="invalid-feedback" style={{ display: 'block' }}>{errors.codePrefix}</div>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Start Number <span className="required-asterisk">*</span></label>
                                        <Form.Control
                                            type="text"
                                            value={startNumber}
                                            onChange={(e) => {setStartNumber(e.target.value)
                                                setErrors(prev => ({ ...prev, startNumber: "" }));
                                            }}
                                        />
                                         {errors.startNumber && <div className="invalid-feedback" style={{ display: 'block' }}>{errors.startNumber}</div>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Number Length (excluding prefix) <span className="required-asterisk">*</span></label>
                                        <Form.Control
                                            type="text"
                                            value={numberLength}
                                            onChange={(e) => {setNumberLength(e.target.value)
                                                setErrors(prev => ({ ...prev, numberLength: "" }));
                                            }}
                                        />
                                         {errors.numberLength && <div className="invalid-feedback" style={{ display: 'block' }}>{errors.numberLength}</div>}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={AddSystemNumberingData} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddSystemNumbering;
