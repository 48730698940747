import { toast } from "react-toastify";
import { baseURL } from "../components/BaseUrl";
import * as types from "./actionType";
import Swal from "sweetalert2";
import * as HeaderAuthentication from '../../src/components/HeaderAuthentication'
import { ADDED_BY_ID, Company_Code, Company_ID } from "../components/Constants";

const signInDetails = JSON.parse(sessionStorage.getItem('SignInDetails'));
const storedEmail = signInDetails ? signInDetails[0].EmailID : null;

// SignIn Page
const postSignInData = () => ({
    type: types.post_SignIn_Data,
})
const postResendOtpData = () => ({
    type: types.post_resendOtp_Data,
})
// SignIn Page

// Verify Company
const postResendVerifyCompanyEmail = () => ({
    type: types.post_Resend_Verify_Email,
})
// Verify Company

// Forgot Password 
const postForgotPasswordEmail = () => ({
    type: types.post_Forgot_Password,
})
// Forgot Password 

// Reset Password
const postResetPasswordData = () => ({
    type: types.post_Reset_Password,
})
// Reset Password

// Customer Management
const postCustomerManagementData = () => ({
    type: types.post_Customer_Data,
});
const postCustomFieldValuesForCompany = () => ({
    type: types.post_Custom_Fields_Value_For_Company,
});
const getAssignedModulesDropdownData = (assignedModulesDropdown) => ({
    type: types.get_Assigned_Modules,
    payload: assignedModulesDropdown,
});
const getCompanyData = (companyData) => ({
    type: types.get_Company_Data,
    payload: companyData,
});
const getSingleCompanyData = (singleCustomerData) => ({
    type: types.get_Single_Company_Data,
    payload: singleCustomerData,
});
const getCountryCodeDropdownData = (getCountryCodeDropdownData) => ({
    type: types.get_Country_Code,
    payload: getCountryCodeDropdownData,
});
const getCompanyDropdownData = (getCompanyDropdown) => ({
    type: types.get_Company_Dropdown,
    payload: getCompanyDropdown,
});
const getGeneratedCompanyCode = (getGeneratedCompanyCode) => ({
    type: types.get_generated_Company_Code,
    payload: getGeneratedCompanyCode,
});
const getCustomFieldsForCompany = (getCompanyCustomFields) => ({
    type: types.get_Custom_Fields_For_Company,
    payload: getCompanyCustomFields,
});
const getCustomerClassificationDropdownData = (getCustomerClassificationDropdown) => ({
    type: types.get_Customer_Classification_Dropdown,
    payload: getCustomerClassificationDropdown,
});
// Customer Management

// User Management
const getUserManagementData = (userData) => ({
    type: types.get_User_Data,
    payload: userData,
});
const getSingleUserManagementData = (singleUserData) => ({
    type: types.get_Single_User_Data,
    payload: singleUserData,
});
const getRoleDropdown = (rolesDropdown) => ({
    type: types.get_Role_Dropdown_Data,
    payload: rolesDropdown,
});
const getReportingToDropdown = (reportingToDropdown) => ({
    type: types.get_ReportingTo_Dropdown_Data,
    payload: reportingToDropdown,
});
const postUserManagementData = () => ({
    type: types.post_User_Data,
});
const postUserManagementSlocData = () => ({
    type: types.post_User_Sloc_Data,
});
const getSlocDropdown = (slocDropdown) => ({
    type: types.get_Sloc_Dropdown_Data,
    payload: slocDropdown,
});
const getBranchDropdown = (branchDropdown) => ({
    type: types.get_Branch_Dropdown_Data,
    payload: branchDropdown,
});
// User Management

// Complaint/Escalation 
const getComplainEscalationData = (complainEscalationData) => ({
    type: types.get_Complaint_Data,
    payload: complainEscalationData,
});
const getSingleComplainEscalationData = (singleComplaintEscalationData) => ({
    type: types.get_Single_Complaint_Data,
    payload: singleComplaintEscalationData,
});
const getIssueTypeDropdownData = (issueTypeDropdownData) => ({
    type: types.get_IssueType_Dropdown_Data,
    payload: issueTypeDropdownData,
});
const postComplaintEscalationData = () => ({
    type: types.post_Complaint_Escalation_Data,
});
const postUploadFile = () => ({
    type: types.upload_File,
});
const getComplaintEscalationsDashboardCardData = (complaintEscalationDashboardCardData) => ({
    type: types.get_Complaint_Escalation_Dashboard_CardData,
    payload: complaintEscalationDashboardCardData,
});
const getComplaintEscalationsBarChartTicketData = (complaintEscalationBarChartTicketData) => ({
    type: types.get_Complaint_Escalation_Ticket_Data,
    payload: complaintEscalationBarChartTicketData,
});
const getComplaintEscalationsPriorityStatusData = (complaintEscalationPriorityStatusData) => ({
    type: types.get_Complaint_Escalation_PriorityStatus_Data,
    payload: complaintEscalationPriorityStatusData,
});
const getComplaintEscalationsStatusData = (complaintEscalationStatusData) => ({
    type: types.get_Complaint_Escalation_Status_Data,
    payload: complaintEscalationStatusData,
});
const getComplaintEscalationsBranchStatusData = (complaintEscalationBranchStatusData) => ({
    type: types.get_Complaint_Escalation_BranchStatus_Data,
    payload: complaintEscalationBranchStatusData,
});
const getComplaintEscalationsIssueTypeData = (complaintEscalationIssueTypeData) => ({
    type: types.get_Complaint_Escalation_IssueType_Data,
    payload: complaintEscalationIssueTypeData,
});
// Complaint/Escalation 

// Service Level Agreement
const getAssignedToServiceLevelAgreementData = (assignedToSlaData) => ({
    type: types.get_AssignedTo_SLA_Data,
    payload: assignedToSlaData,
});
const getSingleAssignedToServiceLevelAgreementData = (singleAssignedToSlaData) => ({
    type: types.get_Single_AssignedTo_SLA_Data,
    payload: singleAssignedToSlaData,
});
const getAssignedToServiceLevelAgreementDropdownData = (assignedToSlaTypeDropdownData) => ({
    type: types.get_AssignedTo_SLA_Type_Dropdown,
    payload: assignedToSlaTypeDropdownData,
});
const getServiceLevelAgreementCategoryDropdownData = (getSlaCategoryDropdown) => ({
    type: types.get_SLA_Category_Dropdown,
    payload: getSlaCategoryDropdown,
});
const postAssignedToServiceLevelAgreementData = () => ({
    type: types.post_AssignedTo_SLA_Data,
});
// Service Level Agreement

// Workqueues
const getWorkqueuesData = (workqueuesData) => ({
    type: types.get_Workqueue_Data,
    payload: workqueuesData,
});
const getSingleWorkqueuesData = (singleWorkqueuesData) => ({
    type: types.get_Single_Workqueue_Data,
    payload: singleWorkqueuesData,
});
const updateWorkqueuesPriorityStatusData = () => ({
    type: types.update_Workqueue_Priority_Status,
})
const postSetAssignToData = () => ({
    type: types.post_Set_AssignTo_Data,
})
const getUserdropdownBasedOnDepartment = (userDropdownBasedDepartment) => ({
    type: types.get_User_Dropdown_Based_Department,
    payload: userDropdownBasedDepartment,
});
// Workqueues

// Settings Department
const postDepartmentData = () => ({
    type: types.post_Department_Data
})
const getDepartmentData = (departmentData) => ({
    type: types.get_Department_Data,
    payload: departmentData,
});
const getSingleDepartmentData = (singleDepartmentData) => ({
    type: types.get_Single_Department_Data,
    payload: singleDepartmentData,
})
// Settings Department

// Settings Customer Classification
const postCustomerClassificationData = () => ({
    type: types.post_Customer_Classification_Data
})

const getCustomerClassificationData = (customerClassificationData) => ({
    type: types.get_Customer_Classification_Data,
    payload: customerClassificationData,
})
const getSingleCustomerClassificationData = (singleCustomerClassificationData) => ({
    type: types.get_Single_Customer_Classification_Data,
    payload: singleCustomerClassificationData,
})
// Settings Customer Classification

// Settings Designation
const getDesignationData = (designationData) => ({
    type: types.get_Designation_Data,
    payload: designationData
})
const postDesignationData = () => ({
    type: types.post_Designation_Data
})
const getDepartmentDropdownData = (departmentDropdown) => ({
    type: types.get_Department_Dropdown,
    payload: departmentDropdown,
});
const getReportingDesignationDropdownData = (reportingDesignationDropdown) => ({
    type: types.get_Reporting_Designation_Dropdown,
    payload: reportingDesignationDropdown,
});
const getSingleDesignationData = (singleDesignationData) => ({
    type: types.get_Single_Designation_Data,
    payload: singleDesignationData,
});
// Settings Designation

// Settings Issue Types
const getIssueTypesData = (issueTypesData) => ({
    type: types.get_Issue_Type_Data,
    payload: issueTypesData,
});
const getSingleIssueTypesData = (singleIssueTypesData) => ({
    type: types.get_Single_Issue_Types_Data,
    payload: singleIssueTypesData,
});
const postIssueTypesData = () => ({
    type: types.post_Issue_Types_Data,
});
// Settings Issue Types

// Settings SLA
const getSettingsSlaData = (SettingSlaData) => ({
    type: types.get_Settings_SLA_Data,
    payload: SettingSlaData,
})
const getSingleSettingsSlaData = (SingleSettingSlaData) => ({
    type: types.get_Single_Settings_SLA_Data,
    payload: SingleSettingSlaData,
})
const postSettingsSlaData = () => ({
    type: types.post_Settings_SLA_Data,
})
// Settings SLA

// Settings Module
const getModuleData = (moduleData) => ({
    type: types.get_Module_Data,
    payload: moduleData,
})
const getSingleModuleData = (singleModuleData) => ({
    type: types.get_Single_Module_Data,
    payload: singleModuleData,
})
const postModuleData = () => ({
    type: types.post_Modules_Data,
})
// Settings Module

// Settings Sub Module
const getSubModuleData = (subModuleData) => ({
    type: types.get_Sub_Module_Data,
    payload: subModuleData,
})
const getSingleSubModuleData = (singleSubModuleData) => ({
    type: types.get_Single_Sub_Module_Data,
    payload: singleSubModuleData,
})
const getSubModuleDropdown = (subModuleDropdown) => ({
    type: types.get_SubModule_Dropdown,
    payload: subModuleDropdown,
})
const postSubModuleData = () => ({
    type: types.post_Sub_Modules_Data,
})
// Settings Sub Module

// Settings System Numbering
const getSystemNumberingData = (systemNumberingData) => ({
    type: types.get_System_Numbering,
    payload: systemNumberingData,
})
const postSystemNumberingData = () => ({
    type: types.post_System_Numbering,
})
// Settings System Numbering

// Settings Work Queues Rules
const postSettingsWorkQueueData = () => ({
    type: types.post_Settings_WorkQueues_Rule_Data,
})
const postSettingsRunWorkQueueData = () => ({
    type: types.post_Run_WorkQueue_Data,
})
const getSystemWorkQueuesRulesData = (settingsWorkQueuesRulesData) => ({
    type: types.get_Settings_WorkQueues_Rule,
    payload: settingsWorkQueuesRulesData,
})
const getSettingsSingleWorkQueuesRulesData = (settingsSingleWorkQueuesRulesData) => ({
    type: types.get_Settings_Single_WorkQueues_Rule,
    payload: settingsSingleWorkQueuesRulesData,
})
const getSettingsWorkQueuesRulesFieldNameData = (getSettingsWorkQuesRulesFieldNameData) => ({
    type: types.get_Settings_WorkQueues_FieldName_Rule,
    payload: getSettingsWorkQuesRulesFieldNameData,
})
// Settings Work Queues Rules

// Settings Role
const getRolesDatas = (getRolesData) => ({
    type: types.get_Roles_Data,
    payload: getRolesData,
})
const getSingleRolesDatas = (getSingleRolesData) => ({
    type: types.get_Single_Roles_Data,
    payload: getSingleRolesData,
})
const postRolesData = () => ({
    type: types.post_Roles_Data,
})
// Settings Role

// Settings Custom Fields
const getCustomFieldsData = (getCustomFields) => ({
    type: types.get_CustomFields_Data,
    payload: getCustomFields,
})
const getSingleCustomFieldsData = (getSingleCustomFields) => ({
    type: types.get_Single_CustomFields_Data,
    payload: getSingleCustomFields,
})
const postCustomFieldsData = () => ({
    type: types.post_CustomFields_Data,
})
// Settings Custom Fields

// Settings Leave Policy
const postLeavePolicyData = () => ({
    type: types.post_Leave_Policy_Data,
})
const getSettingsLeavePolicyData = (getLeavePolicyData) => ({
    type: types.get_Leave_Policy_Data,
    payload: getLeavePolicyData,
})
const getSettingsSingleLeavePolicyData = (getSingleLeavePolicyData) => ({
    type: types.get_Single_Leave_Policy_Data,
    payload: getSingleLeavePolicyData,
})
// Settings Leave Policy

// Settings Holiday Policy
const getSettingsHolidayPolicyData = (getHolidayPolicyData) => ({
    type: types.get_Holiday_Policy_Data,
    payload: getHolidayPolicyData,
})
const getSettingsSingleHolidayPolicyData = (getSingleHolidayPolicyData) => ({
    type: types.get_SingleHoliday_Policy_Data,
    payload: getSingleHolidayPolicyData,
})
const postHolidayPolicyData = () => ({
    type: types.post_Holiday_Policy_Data,
})
const getCountryNameDropdown = (getCountryNameDropdown) => ({
    type: types.get_Country_Name_Dropdown,
    payload: getCountryNameDropdown,
})
// Settings Holiday Policy

// Settings Financial Approval
const getFinancialApprovalData = (financialApprovalData) => ({
    type: types.get_Financial_Approval_Data,
    payload: financialApprovalData,
})
const getSingleFinancialApprovalData = (singleFinancialApprovalData) => ({
    type: types.get_Single_Financial_Approval_Data,
    payload: singleFinancialApprovalData,
})
const postFinancialApprovalData = () => ({
    type: types.post_Financial_Approval_Data,
})
const getApprovalLevelDropdownData = (approvalLevelDropdown) => ({
    type: types.get_Approval_Level_Dropdown,
    payload: approvalLevelDropdown,
})
// Settings Financial Approval

// Settings Functional Approval
const getFunctionalApprovalData = (functionalApprovalData) => ({
    type: types.get_Functional_Approval_Data,
    payload: functionalApprovalData,
})
const getSingleFunctionalApprovalData = (singleFunctionalApprovalData) => ({
    type: types.get_Single_Functional_Approval_Data,
    payload: singleFunctionalApprovalData,
})
const postFunctionalApprovalData = () => ({
    type: types.post_Functional_Approval_Data,
})
// Settings Functional Approval

// Settings Leave Approval
const getLeaveApprovalData = (leaveApprovalData) => ({
    type: types.get_Leave_Approval_Data,
    payload: leaveApprovalData,
})
const getSingleLeaveApprovalData = (singleLeaveApprovalData) => ({
    type: types.get_Single_Leave_Approval_Data,
    payload: singleLeaveApprovalData,
})
const postLeaveApprovalData = () => ({
    type: types.post_Leave_Approval_Data,
})
// Settings Leave Approval

// Settings Escalation Approval
const getEscalationApprovalData = (escalationApprovalData) => ({
    type: types.get_Escalation_Approval_Data,
    payload: escalationApprovalData,
})
const getSingleEscalationApprovalData = (singleEscalationApprovalData) => ({
    type: types.get_Single_Escalation_Approval_Data,
    payload: singleEscalationApprovalData,
})
const postEscalationApprovalData = () => ({
    type: types.post_Escalation_Approval_Data,
})
// Settings Escalation Approval

// Settings Complaints Approval
const getComplaintsApprovalData = (complaintsApprovalData) => ({
    type: types.get_Complaints_Approval_Data,
    payload: complaintsApprovalData,
})
const getSingleComplaintsApprovalData = (singleComplaintsApprovalData) => ({
    type: types.get_Single_Complaints_Approval_Data,
    payload: singleComplaintsApprovalData,
})
const postComplaintsApprovalData = () => ({
    type: types.post_Complaints_Approval_Data,
})
// Settings Complaints Approval

// SignIn Page
export const postSignInDatas = (
    postRequest,
    navigateToOtpPage,
    handleResponse
) => {
    return function (dispatch) {
        fetch(`${baseURL}/accountservices/api/account/PostSignIn`, postRequest)
            // fetch('http://excomatixapi.betaphase.in/accountservices/api/account/PostSignIn', postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === '1') {
                    // Dispatch the action to update the store (you may already have this action)
                    dispatch(postSignInData());

                    // Store the response data in sessionStorage
                    sessionStorage.setItem("SignInDetails", JSON.stringify(resp.SignInDetails));
                    sessionStorage.setItem("AssignedModules", JSON.stringify(resp.AssignedModules));
                    sessionStorage.setItem("Success", resp.Success);
                    navigateToOtpPage();
                    handleResponse(resp);
                } else {
                    console.error("Sign-in failed:", resp.Msg);
                    handleResponse(resp);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
};
export const postResendOtpDatas = (
    postRequest,
    setSuccessMessage,
    setError
) => {
    return function (dispatch) {
        fetch(`${baseURL}/accountservices/api/account/PostResendOTPEmail`, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === '1') {
                    // Dispatch the action to update the store (you may already have this action)
                    dispatch(postResendOtpData());
                    sessionStorage.setItem("ResendSignInDetails", JSON.stringify(resp.SignInDetails));
                    setSuccessMessage("Otp sent successfully");
                } else {
                    console.error("Resend-OTP failed:", resp.Msg);
                    setError("Resend-OTP failed");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
};

// SignIn Page

// Verify Company
export const postResendVerifyEmail = (
    postRequest,
) => {
    return function (dispatch) {
        fetch(`${baseURL}/companyservices/api/company/PostResendVerificationEmail`, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    // Dispatch the action to update the store (you may already have this action)
                    dispatch(postResendVerifyCompanyEmail());

                } else {
                    console.error("Resend Email failed:", resp.Msg);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
};
// Verify Company

// Forgot Password 
export const postForgotPassword = (
    postRequest,
    setSuccessMessage,
    setErrorMessage,
) => {
    return function (dispatch) {
        fetch(`${baseURL}/accountservices/api/account/PostResetPasswordLink`, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    // Dispatch the action to update the store (you may already have this action)
                    dispatch(postForgotPasswordEmail());
                    setSuccessMessage(resp.Msg);

                } else {
                    console.error("Send Reset-Password link failed:", resp.Msg);
                    setErrorMessage(resp.Msg);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
};
// Forgot Password 

// Reset Password
export const postResetPasswordDatas = (
    postRequest,
    setSuccessMessage,
    setErrorMessage,
    navigateToSignInPage
) => {
    return function (dispatch) {
        fetch(`${baseURL}/accountservices/api/account/PostResetPassword`, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    // Dispatch the action to update the store (you may already have this action)
                    dispatch(postResetPasswordData());
                    setSuccessMessage(resp.Msg);
                    navigateToSignInPage()
                } else {
                    console.error("Reset-Password failed:", resp.Msg);
                    setErrorMessage(resp.Msg);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
};
// Reset Password

// Customer Management
export const postCustomerManagementDatas = (
    postRequest,
    companyId,
    cancelModal,
    customerManagementData,
    imgChange,
    setImgChange,
    customFields
) => {
    return function (dispatch) {
        const url = companyId ?
            `${baseURL}/companyservices/api/company/PostUpdateCompany`
            :
            `${baseURL}/companyservices/api/company/PostAddCompany`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postCustomerManagementData());
                    // if (customFields.length !== 0) {
                    //     // Filter customFields to send only the required fields
                    //     const filteredCustomFields = customFields.map(field => {
                    //         return {
                    //             CustomField_Value: field.CustomField_Value,
                    //             CustomField_IDFK: field.CustomFieldsID, // or use field.CustomField_IDFK if already set
                    //             AddedByID: ADDED_BY_ID // Assuming AddedByID is static or comes from a context
                    //         };
                    //     });
                    //     console.log(filteredCustomFields, 'filteredCustomFields')
                    //     let postCustomData = {
                    //         method: "POST",
                    //         mode: "cors",
                    //         headers: HeaderAuthentication.postExcomatixAuth,
                    //         body: JSON.stringify(filteredCustomFields),
                    //     };
                    //     dispatch(postCustomFieldValuesOfCompany(postCustomData, cancelModal));
                    // }
                    customerManagementData()
                    cancelModal();
                    if (imgChange) {
                        setImgChange(false)
                    }
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};

export const postCustomFieldValuesOfCompany = (
    postRequest,
    companyId,
    cancelModal,
    customerManagementData
) => {
    return function (dispatch) {
        // const url = companyId ?
        //     `${baseURL}/excomatixservices/api/CustomFieldValue/PostUpdateCustomFieldValue`
        //     :
        //     `${baseURL}/excomatixservices/api/CustomFieldValue/PostAddCustomFieldValue`
        fetch(`${baseURL}/excomatixservices/api/CustomFieldValue/PostAddCustomFieldValue`, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postCustomFieldValuesForCompany());
                    // customerManagementData()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                // toast.error(`${err}`);
                console.log(err);
            });
    }
};

export const loadAssignedModulesDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/modules/GetModules`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "ModulesID": "",
                "StatusID": 1,
                "Modules_Name": ""
            }),
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Modules) {
                    dispatch(
                        getAssignedModulesDropdownData(
                            resp.Modules.map((data) => {
                                return { value: data.ModuleID, label: data.Module, statusID: data.StatusID };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadCountryCodeDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/companyservices/api/company/PostCountryCode`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({}),
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.CountryCodes) {
                    dispatch(
                        getCountryCodeDropdownData(
                            resp.CountryCodes.map((data) => {
                                return { value: data.CountryCodeID, label: data.CountryCode };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadCompanyData = (
    setLoading
) => {
    return function (dispatch) {
        fetch(`${baseURL}/companyservices/api/company/GetCompanies`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "CompanyID": 0,
                "Company_StatusIDFK": 0,
                "Company_Code": "",
                "Company_Name": "",
                "PageSize": 10,
                "PageIndex": 1
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getCompanyData(resp.Customers));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSingleCompanyData = (
    companyCode
) => {
    return function (dispatch) {
        fetch(`${baseURL}/companyservices/api/company/postCompanyDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "Company_Code": companyCode,
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleCompanyData(resp));
            })
            .catch((err) => { console.log(err) });
    };
};

export const loadCompanyDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/companyservices/api/company/GetCompanies`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "CompanyID": Company_ID,
                "Company_StatusIDFK": 5,
                "Company_Code": Company_Code,
                "Company_Name": "",
                "PageSize": 10,
                "PageIndex": 1
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Customers) {
                    dispatch(
                        getCompanyDropdownData(
                            resp.Customers.map((data) => {
                                return { value: data.CompanyID, label: data.CompanyName, statusID: 5 };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};

export const loadGeneratedCompanyCode = () => {
    return function (dispatch) {
        // fetch(`${baseURL}/excomatixservices/api/SystemNumbering/GetModuleNextGeneratedCode?ModuleName=Customer Management`, HeaderAuthentication.getRequestOptions)
        fetch(`${baseURL}/excomatixservices/api/SystemNumbering/GetModuleNextGeneratedCode?ModuleID=2`, HeaderAuthentication.getRequestOptions)
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getGeneratedCompanyCode(resp))
            })
    }
}

export const loadCustomFieldsForCompany = () => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/CustomField/GetCustomFieldDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ CustomFields_ModuleIDFK: 2 })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getCustomFieldsForCompany(resp.CustomFields));
                // setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadCustomerClassificationDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/CustomerClassification/GetCustomerClassificationDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "StatusID": 8 })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.CustomerClassifications) {
                    dispatch(
                        getCustomerClassificationDropdownData(
                            resp.CustomerClassifications.map((data) => {
                                return { value: data.CustomerClassificationID, label: data.CustomerClassificationName };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};
// Customer Management

// User Management
export const loadUserManagementData = (
    setLoading
) => {
    return function (dispatch) {
        fetch(`${baseURL}/userservices/api/User/GetUserDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getUserManagementData(resp.Users));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSingleUserManagementData = (userId) => {
    return function (dispatch) {
        fetch(`${baseURL}/userservices/api/User/GetUserDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "UserID": userId
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleUserManagementData(resp.Users));
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadRolesDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/roles/PostRoleDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "StatusID": 24
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Roles) {
                    dispatch(
                        getRoleDropdown(
                            resp.Roles.map((data) => {
                                return { value: data.RoleID, label: data.RoleName, StatusID: 24 };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadReportingToDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/userservices/api/User/GetUserDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ StatusID: 16 })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Users) {
                    dispatch(
                        getReportingToDropdown(
                            resp.Users.map((data) => {
                                return { value: data.UserID, label: `${data.FirstName} ${data.LastName}`, StatusID: 16 };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};

export const postUserManagementDatas = (
    postRequest,
    cancelModal,
    userManagenemtData,
    userID,
    imgChange,
    setImgChange
) => {
    return function (dispatch) {
        const url = userID ?
            `${baseURL}/userservices/api/User/PostUpdateUser`
            :
            `${baseURL}/userservices/api/User/PostAddUser`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postUserManagementData());
                    cancelModal();
                    userManagenemtData()
                    if (imgChange) {
                        setImgChange(false)
                    }
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => { console.log(err) });
    }
};
export const postUserManagementSlocDatas = (
    postRequest,
    cancelModal,
    userManagenemtData,
    // userID
) => {
    return function (dispatch) {
        // const url = userID ?
        //     `${baseURL}/userservices/api/User/PostUpdateUser`
        //     :
        //     `${baseURL}/userservices/api/User/PostAddUser`
        fetch(`${baseURL}/userservices/api/User/PostAddUserSloc`, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postUserManagementSlocData());
                    cancelModal();
                    userManagenemtData();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => { console.log(err) });
    }
};
export const loadSlocDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/companyservices/api/company/postCompanyDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "Company_Code": Company_Code,
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Slocs) {
                    dispatch(
                        getSlocDropdown(
                            resp.Slocs.map((data) => {
                                return { value: data.SlockID, label: data.StorageLocationName, statusID: 5 };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};

export const loadBranchDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/companyservices/api/company/postCompanyDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "Company_Code": Company_Code,
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Branch) {
                    dispatch(
                        getBranchDropdown(
                            resp.Branch.map((data) => {
                                return { value: data.BranchID, label: data.BranchName, statusID: 5 };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};

// User Management

// Complaint/Escalation 
export const loadComplaintEscalationData = (
    TicketType,
    StatusID,
    setLoading
) => {
    return function (dispatch) {
        let data = {}
        if (TicketType) {
            data.TicketType = TicketType
        }
        if (StatusID) {
            data.StatusID = StatusID
        }
        fetch(`${baseURL}/complaintservices/api/Complaint/GetComplaintDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify(data)
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getComplainEscalationData(resp.Complaints));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadIssueTypeDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/IssueType/GetIssueTypeDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "statusID": 12
            }),
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.IssueTypes) {
                    dispatch(
                        getIssueTypeDropdownData(
                            resp.IssueTypes.map((data) => {
                                return { value: data.IssueTypeID, label: data.IssueTypeName };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};

export const loadSingleComplaintEscalationData = (
    complaintId,
) => {
    return function (dispatch) {
        fetch(`${baseURL}/complaintservices/api/Complaint/GetComplaintDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "ComplaintID": complaintId
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                console.log(resp, 'resp')
                dispatch(getSingleComplainEscalationData(resp.Complaints));
            })
            .catch((err) => { console.log(err) });
    };
};

export const postComplaintEscalationDatas = (
    postRequest,
    cancelModal,
    handleCloseModal,
    complaintId
) => {
    return function (dispatch) {
        const url = complaintId ?

            `${baseURL}/complaintservices/api/Complaint/PostUpdateComplaint`
            :
            `${baseURL}/complaintservices/api/Complaint/PostAddComplaint`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postComplaintEscalationData());
                    handleCloseModal()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
};
export const loadComplaintEscalationDashboardCardData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/complaintservices/api/Complaint/GetComplaintsSummaryStats`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "EmailID": storedEmail })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getComplaintEscalationsDashboardCardData(resp.Complaints));
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadComplaintEscalationBarChartTicketData = (companyName, year) => {
    return function (dispatch) {
        fetch(`${baseURL}/complaintservices/api/Complaint/GetComplaintStatsOfYear`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "EmailID": storedEmail, "CompanyID": companyName, "Year": year })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getComplaintEscalationsBarChartTicketData(resp.Complaints));
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadComplaintEscalationPriorityStatusData = (companyName, year) => {
    return function (dispatch) {
        fetch(`${baseURL}/complaintservices/api/Complaint/GetComplaintsPriorityStats`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "EmailID": storedEmail, "CompanyID": companyName, "Year": year })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getComplaintEscalationsPriorityStatusData(resp.Complaints));
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadComplaintEscalationStatusData = (companyName, year, ticketType) => {
    return function (dispatch) {
        fetch(`${baseURL}/complaintservices/api/Complaint/GetComplaintsStatusStatsOfYear`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "EmailID": storedEmail, "CompanyID": companyName, "Year": year, "TicketType": ticketType })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getComplaintEscalationsStatusData(resp.Complaints));
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadComplaintEscalationBranchStatusData = (companyName, year) => {
    return function (dispatch) {
        fetch(`${baseURL}/complaintservices/api/Complaint/GetComplaintsBranchStatusStats`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "EmailID": storedEmail, "CompanyID": companyName, "Year": year, })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getComplaintEscalationsBranchStatusData(resp.Complaints));
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadComplaintEscalationIssueTypeData = (companyName, year) => {
    return function (dispatch) {
        fetch(`${baseURL}/complaintservices/api/Complaint/GetComplaintsIssuesStats`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "EmailID": storedEmail, "CompanyID": companyName, "Year": year, })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getComplaintEscalationsIssueTypeData(resp.Complaints));
            })
            .catch((err) => { console.log(err) });
    };
};
// Complaint/Escalation 

// Service Level Agreement
export const loadAssignedToSlaData = (
    setLoading
) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/ServiceLevelAgreement/GetServiceLevelAgreementDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getAssignedToServiceLevelAgreementData(resp.ServiceLevelAgreement));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSingleAssignedToSlaData = (slaId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/ServiceLevelAgreement/GetServiceLevelAgreementDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                SLAID: slaId
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleAssignedToServiceLevelAgreementData(resp.ServiceLevelAgreement));
            })
            .catch((err) => { console.log(err) });
    };
};

export const postAssignedToSlaDatas = (
    postRequest,
    cancelModal,
    assignedToSlaDatas,
    slaId,
    imgChange,
    setImgChange
) => {
    return function (dispatch) {
        const url = slaId ?
            `${baseURL}/excomatixservices/api/ServiceLevelAgreement/PostUpdateServiceLevelAgreement`
            :
            `${baseURL}/excomatixservices/api/ServiceLevelAgreement/PostAddServiceLevelAgreement`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postAssignedToServiceLevelAgreementData());
                    assignedToSlaDatas()
                    cancelModal();
                    if (imgChange) {
                        setImgChange(false)
                    }
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                // toast.error(`${err}`);
                console.log(err);
            });
    }
};
export const loadAssignedToSlaTypeDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/ServiceLevelAgreement/GetSlaTypes`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.SLAType) {
                    dispatch(
                        getAssignedToServiceLevelAgreementDropdownData(
                            resp.SLAType.map((data) => {
                                return { value: data.SLATypeID, label: data.SLATypeName };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSlaCategoryDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/SLACategories/GetSLACategoriesDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ StatusID: 14 })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp['SLA Categories']) {
                    dispatch(
                        getServiceLevelAgreementCategoryDropdownData(
                            resp['SLA Categories'].map((data) => {
                                return { value: data.SLACategoriesID, label: data.SLACategoriesName };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};

// Service Level Agreement

// Workqueues
export const loadWorkquesData = (
    setLoading
) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/WorkQueue/GetWorkQueueExecutedList`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getWorkqueuesData(resp.WorkQueueExeccutedList));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSingleWorkquesData = (
    // setLoading,
    WorkQueueId
) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/WorkQueue/GetWorkQueueExecutedData`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "WorkQueueID": WorkQueueId })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleWorkqueuesData(resp.Data));
                // setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};

export const updateWorkqueuesPriorityStatus = (
    postRequest,
    cancelModal,
    workqueuesDatas,
) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/WorkQueue/PostUpdateWorkQueuePriority`, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(updateWorkqueuesPriorityStatusData());
                    workqueuesDatas()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
};
export const postSetAssignToDatas = (
    postRequest,
    cancelModal,
    workqueuesDatas,
) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/WorkQueue/PostAssignWorkQueue`, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postSetAssignToData());
                    workqueuesDatas();
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
};
export const loadUserDropdownDataBasedDepartment = (departmentId) => {
    return function (dispatch) {
        fetch(`${baseURL}/userservices/api/User/GetUserDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ DepartmentID: departmentId, StatusID: 16 })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Users) {
                    dispatch(
                        getUserdropdownBasedOnDepartment(
                            resp.Users.map((data) => {
                                return { value: data.UserID, label: `${data.FirstName} ${data.LastName}`, StatusID: 16 };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};
// Workqueues

// Settings Department
export const postDepartmentDatas = (
    postRequest,
    cancelModal,
    deptData,
    deptID
) => {
    return function (dispatch) {
        const url = deptID ?
            `${baseURL}/excomatixservices/api/department/PostUpdateDepartment`
            :
            `${baseURL}/excomatixservices/api/department/PostAddDepartment`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postDepartmentData());
                    deptData()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};

export const loadDepartmentData = (setLoading, pageSize, currentPage) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/department/GetDepartmentDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "DeptID": 0,
                "StatusID": 0,
                "DepartmentName": "",
                "PageSize": pageSize,
                "PageIndex": currentPage
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getDepartmentData(resp.Departments));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};

export const loadSingleDepartmentData = (deptID) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/department/GetDepartmentDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "DeptID": deptID
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleDepartmentData(resp.Departments))
            })
            .catch((err) => { console.log(err) });
    };
};
// Settings Department

// Settings Customer Classification
export const loadCustomerClassificationData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/CustomerClassification/GetCustomerClassificationDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getCustomerClassificationData(resp.CustomerClassifications))
                setLoading(false)
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSingleCustomerClassificationData = (clsID) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/CustomerClassification/GetCustomerClassificationDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "CustomerClassificationID": clsID
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleCustomerClassificationData(resp.CustomerClassifications))
            })
            .catch((err) => { console.log(err) });
    };
};

export const postCustomerClassificationDatas = (
    postRequest,
    cancelModal,
    clsID,
    getData
) => {
    return function (dispatch) {
        if (clsID) {
            fetch(`${baseURL}/excomatixservices/api/CustomerClassification/PostUpdateCustomerClassification`, postRequest)
                .then((resp) => resp.json())
                .then((resp) => {
                    if (resp.Success === 1) {
                        dispatch(postCustomerClassificationData());
                        getData();
                        cancelModal();
                        Swal.fire({
                            title: 'Success',
                            text: resp.Msg,
                            icon: 'success',
                        });
                    } else {
                        // toast.error(resp.Error);
                        const errorMessage = resp.Msg; // Extract error message
                        Swal.fire({
                            title: 'Failed',
                            text: errorMessage,
                            icon: 'error',
                        });
                    }
                })
                .catch((err) => {
                    toast.error(`${err}`);
                });
        } else {
            fetch(`${baseURL}/excomatixservices/api/CustomerClassification/PostAddCustomerClassification`, postRequest)
                .then((resp) => resp.json())
                .then((resp) => {
                    if (resp.Success === 1) {
                        dispatch(postCustomerClassificationData());
                        getData();
                        cancelModal();
                        Swal.fire({
                            title: 'Success',
                            text: resp.Msg,
                            icon: 'success',
                        });
                    } else {
                        // toast.error(resp.Error);
                        const errorMessage = resp.Msg; // Extract error message
                        Swal.fire({
                            title: 'Failed',
                            text: errorMessage,
                            icon: 'error',
                        });
                    }
                })
                .catch((err) => {
                    toast.error(`${err}`);
                });
        }
    }
};

// Settings Customer Classification

// Settings Designation
export const loadDesignationData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/Designation/GetDesignationDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getDesignationData(resp.Designation))
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};

export const loadSingleDesignationData = (desgID) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/Designation/GetDesignationDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "DesignationID": desgID })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleDesignationData(resp.Designation))
            })
            .catch((err) => { console.log(err) });
    };
};

export const postDesignationDatas = (
    postRequest,
    cancelModal,
    getDesgData,
    desgID
) => {
    return function (dispatch) {
        const url = desgID ?
            `${baseURL}/excomatixservices/api/Designation/PostUpdateDesignation`
            :
            `${baseURL}/excomatixservices/api/Designation/PostAddDesignation`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postDesignationData());
                    getDesgData()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};

export const loadDepartmentDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/department/GetDepartmentDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "statusID": 3
            }),
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Departments) {
                    dispatch(
                        getDepartmentDropdownData(
                            resp.Departments.map((data) => {
                                return { value: data.DeptID, label: data.DepartmentName };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};

export const loadReportingDesignationDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/Designation/GetDesignationDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "statusID": 10
            }),
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Designation) {
                    dispatch(
                        getReportingDesignationDropdownData(
                            resp.Designation.map((data) => {
                                return { value: data.DesignationID, label: data.DesignationName };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};


// Settings Designation

// Settings Issue Types
export const loadIssueTypesData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/IssueType/GetIssueTypeDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getIssueTypesData(resp.IssueTypes));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};

export const loadSingleIssueTypesData = (issueId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/IssueType/GetIssueTypeDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                IssueTypeID: issueId
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleIssueTypesData(resp.IssueTypes))
            })
            .catch((err) => { console.log(err) });
    };
};

export const postIssueTypesDatas = (
    postRequest,
    cancelModal,
    issueTypes,
    issueTypeId
) => {
    return function (dispatch) {
        const url = issueTypeId ?
            `${baseURL}/excomatixservices/api/IssueType/PostUpdateIssueType`
            :
            `${baseURL}/excomatixservices/api/IssueType/PostAddIssueType`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postIssueTypesData());
                    issueTypes()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};

// Settings Issue Types

// Settings SLA
export const loadSettingsSlaData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/SLACategories/GetSLACategoriesDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSettingsSlaData(resp['SLA Categories']));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSingleSettingsSlaData = (settSlaId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/SLACategories/GetSLACategoriesDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "SLACategoriesID": settSlaId, })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleSettingsSlaData(resp['SLA Categories']))
            })
            .catch((err) => { console.log(err) });
    };
};

export const postSettingsSlaCategoriesData = (
    postRequest,
    cancelModal,
    getSettingsSlaData,
    settSlaId
) => {
    return function (dispatch) {
        const url = settSlaId ?
            `${baseURL}/excomatixservices/api/SLACategories/PostUpdateSLACategories`
            :
            `${baseURL}/excomatixservices/api/SLACategories/PostAddSLACategories`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postSettingsSlaData());
                    getSettingsSlaData()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};

// Settings SLA

// Settings  Module
export const loadModuleData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/modules/GetModules`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getModuleData(resp.Modules));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSingleModuleData = (moduleId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/modules/GetModules`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "ModulesID": moduleId })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleModuleData(resp.Modules));
            })
            .catch((err) => { console.log(err) });
    };
};

export const postModulesDatas = (
    postRequest,
    cancelModal,
    modulesData,
    moduleId
) => {
    return function (dispatch) {
        const url = moduleId ?
            `${baseURL}/excomatixservices/api/Modules/PostUpdateModule`
            :
            `${baseURL}/excomatixservices/api/Modules/PostAddModule`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postModuleData());
                    modulesData()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};

// Settings Module

// Settings Sub Module
export const loadSubModuleData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/SubModules/GetAllSubModules`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSubModuleData(resp['Sub Modules']));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSingleSubModuleData = (subModuleId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/SubModules/GetAllSubModules`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "SubModulesID": subModuleId })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleSubModuleData(resp['Sub Modules']));
            })
            .catch((err) => { console.log(err) });
    };
};

export const postSubModulesDatas = (
    postRequest,
    cancelModal,
    subModuleDatas,
    subModuleId
) => {
    return function (dispatch) {
        const url = subModuleId ?
            `${baseURL}/excomatixservices/api/SubModules/PostUpdateSubModules`
            :
            `${baseURL}/excomatixservices/api/SubModules/PostAddSubModules`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postSubModuleData());
                    subModuleDatas()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    console.log("Error Message:", errorMessage); // Optional: Log to console
                    toast.error(errorMessage);
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};

export const loadSubModulesDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/SubModules/GetAllSubModules`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "StatusID": 18
            }),
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp["Sub Modules"]) {
                    dispatch(
                        getSubModuleDropdown(
                            resp["Sub Modules"].map((data) => {
                                return { value: data.SubModulesID, label: data.SubModulesName, statusID: 18 };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};

// Settings Sub Module

// Settings System Numbering
export const loadSystemNumberingData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/SystemNumbering/GetSystemNumberingDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSystemNumberingData(resp.SystemNumberings));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};

export const postSystemNumberingDatas = (
    postRequest,
    cancelModal,
    systemNumbering,
    // subModuleId
) => {
    return function (dispatch) {
        // const url = subModuleId ?
        //     `${baseURL}/excomatixservices/api/SubModules/PostUpdateSubModules`
        //     :
        //     `${baseURL}/excomatixservices/api/SubModules/PostAddSubModules`
        fetch(`${baseURL}/excomatixservices/api/SystemNumbering/PostAddSystemNumbering`, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postSystemNumberingData());
                    systemNumbering()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    console.log("Error Message:", errorMessage); // Optional: Log to console
                    toast.error(errorMessage);
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};


// Settings System Numbering

// Settings Work Queues Rules
export const postSettingsWorkQueuesDatas = (
    postRequest,
    cancelModal,
    getSettingsWorkQueuesRulesData,
    workqueueId
) => {
    return function (dispatch) {
        const url = workqueueId ?
            `${baseURL}/excomatixservices/api/WorkQueue/PostUpdateWorkQueue`
            :
            `${baseURL}/excomatixservices/api/WorkQueue/PostAddWorkQueue`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postSettingsWorkQueueData());
                    getSettingsWorkQueuesRulesData()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                // toast.error(`${err}`);
                console.log(err)
            });
    }
};
export const postSettingsRunWorkQueuesDatas = (
    postRequest,
    cancelModal,
    getSettingsWorkQueuesRulesData,
    // workqueueId
) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/WorkQueue/PostRunWorkQueue`, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postSettingsRunWorkQueueData());
                    getSettingsWorkQueuesRulesData()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                // toast.error(`${err}`);
                console.log(err)
            });
    }
};

export const loadSettingsWorkQueuesRulesData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/WorkQueue/GetWorkQueueDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSystemWorkQueuesRulesData(resp.WorkQueue));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSettingsSingleWorkQueuesRulesData = (workqueueId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/WorkQueue/GetWorkQueueDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "WorkQueueID": workqueueId })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSettingsSingleWorkQueuesRulesData(resp.WorkQueue));
                // setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};

export const loadSettingsWorkQueuesRulesFieldNameData = (moduleId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/WorkQueue/GetModuleFieldDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ "CompanyID": Company_ID, "ModuleID": moduleId, "StatusID": '34,26' })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.ModuleField) {
                    dispatch(
                        getSettingsWorkQueuesRulesFieldNameData(
                            resp.ModuleField.map((data) => {
                                return { value: data.ModuleID, label: data.MouleFieldAliasName, IsCustom: data.IsCustom, ModuleFieldID: data.ModuleFieldID, StatusID: data.StatusID };
                            })
                        )
                    );
                }
            })
    }
}


// Settings Work Queues Rules

//  Settings Role
export const loadSettingsRolesData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/roles/PostRoleDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getRolesDatas(resp.Roles));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSingleSettingsRolesData = (roleId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/roles/PostRoleDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ RoleID: roleId })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleRolesDatas(resp.Roles));
            })
            .catch((err) => { console.log(err) });
    };
};

export const postRolesDatas = (
    postRequest,
    cancelModal,
    getRoles,
    roleId
) => {
    return function (dispatch) {
        const url = roleId ?
            `${baseURL}/excomatixservices/api/roles/PostupdateRole`
            :
            `${baseURL}/excomatixservices/api/roles/PostAddRole`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postRolesData());
                    getRoles()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg; // Extract error message
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};
//  Settings Role

// Settings Custom Fields
export const loadCustomFieldsData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/CustomField/GetCustomFieldDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getCustomFieldsData(resp.CustomFields));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSingleCustomFieldsData = (customFieldsId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/CustomField/GetCustomFieldDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ CustomFields_IDPK: customFieldsId })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleCustomFieldsData(resp.CustomFields));
                // setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};

export const postCustomFieldsDatas = (
    postRequest,
    cancelModal,
    getCustomField,
    customFieldsId
) => {
    return function (dispatch) {

        const url = customFieldsId ?
            `${baseURL}/excomatixservices/api/CustomField/PostUpdateCustomField`
            :
            `${baseURL}/excomatixservices/api/CustomField/PostAddCustomField`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postCustomFieldsData());
                    getCustomField()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg;
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};
// Settings Custom Fields

// Settings Leave Policy
export const postLeavePolicyDatas = (
    postRequest,
    cancelModal,
    leavePolicyData,
    leavePolicyId
) => {
    return function (dispatch) {

        const url = leavePolicyId ?
            `${baseURL}/excomatixservices/api/LeavePolicy/PostUpdateLeavePolicy`
            :
            `${baseURL}/excomatixservices/api//LeavePolicy/PostAddLeavePolicy`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postLeavePolicyData());
                    leavePolicyData()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg;
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`);
            });
    }
};
export const loadSettingsLeavePolicyData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/LeavePolicy/PostLeavePolicyDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSettingsLeavePolicyData(resp['Leave Policies']));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSettingsSingleLeavePolicyData = (leavePolicyId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/LeavePolicy/PostLeavePolicyDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "LeavePolicyID": leavePolicyId
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSettingsSingleLeavePolicyData(resp['Leave Policies']));
                // setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
// Settings Leave Policy

// Settings Holiday Policy
export const loadSettingsHolidayPolicyData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/Holiday/GetHolidayDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSettingsHolidayPolicyData(resp.Holidays));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSettingsSingleHolidayPolicyData = (holidayId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/Holiday/GetHolidayDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({
                "HolidayID": holidayId
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSettingsSingleHolidayPolicyData(resp.Holidays));
            })
            .catch((err) => { console.log(err) });
    };
};

export const postHolidayPolicyDatas = (
    postRequest,
    cancelModal,
    holidayPolicyData,
    holidayId
) => {
    return function (dispatch) {
        const url = holidayId ?
            `${baseURL}/excomatixservices/api/Holiday/PostUpdateHoliday`
            :
            `${baseURL}/excomatixservices/api/Holiday/PostAddHoliday`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postHolidayPolicyData());
                    holidayPolicyData()
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg;
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
};
export const loadCountryNameDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/companyservices/api/company/PostCountryCode`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({}),
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.CountryCodes) {
                    dispatch(
                        getCountryNameDropdown(
                            resp.CountryCodes.map((data) => {
                                return { value: data.CountryCodeID, label: data.CountryName };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};
// Settings Holiday Policy

// Settings Financial Approval
export const loadSettingsFinancialApprovalData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/FinancialApproval/GetFinancialApprovalDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getFinancialApprovalData(resp.FinancialApprovals));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSettingsSingleFinancialApprovalData = (finanacialApprovalId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/FinancialApproval/GetFinancialApprovalDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ FinancialApprovalID: finanacialApprovalId })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleFinancialApprovalData(resp.FinancialApprovals));
            })
            .catch((err) => { console.log(err) });
    };
};

export const postFinancialApprovalDatas = (
    postRequest,
    cancelModal,
    financialApproval,
    finanacialApprovalId
) => {
    return function (dispatch) {
        const url = finanacialApprovalId ?
            `${baseURL}/excomatixservices/api/FinancialApproval/PostUpdateFinancialApproval`
            :
            `${baseURL}/excomatixservices/api/FinancialApproval/PostAddFinancialApproval`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postFinancialApprovalData());
                    financialApproval();
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg;
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
};
export const loadApprovalLevelDropdownData = () => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/Level/GetLevelDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({}),
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Levels) {
                    dispatch(
                        getApprovalLevelDropdownData(
                            resp.Levels.map((data) => {
                                return { value: data.LevelID, label: data.LevelsName };
                            })
                        )
                    );
                }
            })
            .catch((err) => { console.log(err) });
    };
};

// Settings Financial Approval

// Settings Functional Approval
export const loadSettingsFunctionalApprovalData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/FunctionalApproval/GetFunctionalApprovalDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getFunctionalApprovalData(resp.FunctionalApprovals));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const postFunctionalApprovalDatas = (
    postRequest,
    cancelModal,
    functionalApprovalDatas,
    functionalApprovalId
) => {
    return function (dispatch) {
        const url = functionalApprovalId ?
            `${baseURL}/excomatixservices/api/FunctionalApproval/PostUpdateFunctionalApproval`
            :
            `${baseURL}/excomatixservices/api/FunctionalApproval/PostAddFunctionalApproval`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postFunctionalApprovalData());
                    functionalApprovalDatas();
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg;
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
};
export const loadSettingsSingleFunctionalApprovalData = (functionalApprovalId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/FunctionalApproval/GetFunctionalApprovalDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ FunctionalApprovalID: functionalApprovalId })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleFunctionalApprovalData(resp.FunctionalApprovals));
            })
            .catch((err) => { console.log(err) });
    };
};
// Settings Functional Approval

// Settings Leave Approval
export const loadSettingsLeaveApprovalData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/LeaveApproval/GetLeaveApprovalDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getLeaveApprovalData(resp.LeaveApprovals));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSettingsSingleLeaveApprovalData = (LeaveApprovalID) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/LeaveApproval/GetLeaveApprovalDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ LeaveApprovalID: LeaveApprovalID })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleLeaveApprovalData(resp.LeaveApprovals));
            })
            .catch((err) => { console.log(err) });
    };
};
export const postLeaveApprovalDatas = (
    postRequest,
    cancelModal,
    leaveApproval,
    LeaveApprovalID
) => {
    return function (dispatch) {
        const url = LeaveApprovalID ?
            `${baseURL}/excomatixservices/api/LeaveApproval/PostUpdateLeaveApproval`
            :
            `${baseURL}/excomatixservices/api/LeaveApproval/PostAddLeaveApproval`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postLeaveApprovalData());
                    leaveApproval();
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg;
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
};
// Settings Leave Approval

// Settings Escalation Approval
export const loadSettingsEscalationApprovalData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/EscalationApproval/GetEscalationApprovalDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getEscalationApprovalData(resp.EscalationApprovals));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const postEscalationApprovalDatas = (
    postRequest,
    cancelModal,
    escalationApprovalDatas,
    EscalationApprovalId
) => {
    return function (dispatch) {
        const url = EscalationApprovalId ?
            `${baseURL}/excomatixservices/api/EscalationApproval/PostUpdateEscalationApproval`
            :
            `${baseURL}/excomatixservices/api/EscalationApproval/PostAddEscalationApproval`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postEscalationApprovalData());
                    escalationApprovalDatas();
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg;
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
};
export const loadSettingsSingleEscalationApprovalData = (EscalationApprovalId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/EscalationApproval/GetEscalationApprovalDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ EscalationApprovalID: EscalationApprovalId })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleEscalationApprovalData(resp.EscalationApprovals));
            })
            .catch((err) => { console.log(err) });
    };
};
// Settings Escalation Approval

// Settings Complaints Approval
export const loadSettingsComplaintsApprovalData = (setLoading) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/ComplaintApproval/GetComplaintApprovalDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({})
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getComplaintsApprovalData(resp.ComplaintApprovals));
                setLoading(false);
            })
            .catch((err) => { console.log(err) });
    };
};
export const loadSettingsSingleComplaintsApprovalData = (complaintsApprovalId) => {
    return function (dispatch) {
        fetch(`${baseURL}/excomatixservices/api/ComplaintApproval/GetComplaintApprovalDetails`, {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postExcomatixAuth,
            body: JSON.stringify({ ComplaintApprovalID: complaintsApprovalId })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getSingleComplaintsApprovalData(resp.ComplaintApprovals));
            })
            .catch((err) => { console.log(err) });
    };
};
export const postComplaintsApprovalDatas = (
    postRequest,
    cancelModal,
    complaintsApprovalDatas,
    EscalationApprovalId
) => {
    return function (dispatch) {
        const url = EscalationApprovalId ?
            `${baseURL}/excomatixservices/api/ComplaintApproval/PostUpdateComplaintApproval`
            :
            `${baseURL}/excomatixservices/api/ComplaintApproval/PostAddComplaintApproval`
        fetch(url, postRequest)
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.Success === 1) {
                    dispatch(postComplaintsApprovalData());
                    complaintsApprovalDatas();
                    cancelModal();
                    Swal.fire({
                        title: 'Success',
                        text: resp.Msg,
                        icon: 'success',
                    });
                } else {
                    // toast.error(resp.Error);
                    const errorMessage = resp.Msg;
                    Swal.fire({
                        title: 'Failed',
                        text: errorMessage,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
};

// Settings Complaints Approval