import { Col, ListGroup, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../Buttons/Buttons";

const NoOfLicenseExperingModal = (props) => {

    return (
        <>
            <Modal show={props.open} onHide={props.handleClose} className='add-customer-modal add-workqueue-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>No. of licenses expiring in next 30 days</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <ListGroup variant="flush" className='workqueue-list-items'>
                                <ListGroup.Item className='mt-3'><p>Company Name</p> <p>: </p><p>Lobotus Company</p></ListGroup.Item>
                                <ListGroup.Item><p>Contact Name</p> <p>:</p><p>karthik</p></ListGroup.Item>
                                <ListGroup.Item><p>Email ID</p><p>:</p> <p>KarthikKarthik@gmail.com</p></ListGroup.Item>
                                <ListGroup.Item><p>No. of Licenses</p> <p>:</p><p>09</p></ListGroup.Item>
                                <ListGroup.Item><p>Contact Number</p> <p>:</p><p>9999999999</p></ListGroup.Item>
                                <ListGroup.Item><p>Classification</p> <p>:</p><p>Internal</p></ListGroup.Item>
                                <ListGroup.Item><p>Address</p> <p>:</p><p>AddressAddressAddressAddress</p></ListGroup.Item>
                                <ListGroup.Item><p>Branch Name</p> <p>:</p><p>Unit 1</p></ListGroup.Item>
                                <ListGroup.Item><p>Date Created</p> <p>:</p><p>dd/mm/yyyy</p></ListGroup.Item>
                                <ListGroup.Item><p>Expering On</p> <p>:</p><p>dd/mm/yyyy</p></ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>

                </Modal.Body >
                <Modal.Footer className="no-of-license-footer">
                    <p>Items 03 out of 03</p>
                    <div className="no-of-license-btns">
                        <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
                        {/* <SBPreviousBtn btnName="Previous" /> */}
                        <SBSaveUpdateBtn btnName="Submit" onClickEffect={props.handleNextStep} />
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default NoOfLicenseExperingModal;