import { Col, Dropdown, Form, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Main from '../Main';
import Download from '../MainSection/Download/Download';
import '../../assets/css/dashboardlibrary.scss';
import threedots from '../../assets/images/three-dot-img.svg';
import DashboardLibraryImg1 from '../../assets/images/dashboard-library-1.png';
import DashboardLibraryImg2 from '../../assets/images/dashboard-library-2.png';
import DashboardLibraryImg3 from '../../assets/images/dashboard-library-3.png';
import DashboardLibraryImg4 from '../../assets/images/dashboard-library-4.png';
import DashboardLibraryImg5 from '../../assets/images/dashboard-library-5.png';
import mobileImg from '../../assets/images/mobile-img.svg';
import contentShow from '../../assets/images/content-show.svg';
import ShareModal from './ShareModal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const DashboardLibrary = () => {
    const navigate = useNavigate(); // Initialize useNavigate

    // Array holding the different labels, image paths, and their corresponding routes
    const dashboardItems = [
        { label: 'Customer', imgSrc: DashboardLibraryImg1, route: '/dashboard' },
        { label: 'Licence', imgSrc: DashboardLibraryImg2, route: '/licenceDashboard' },
        { label: 'Attendance', imgSrc: DashboardLibraryImg3, route: '/attendanceDashboard' },
        { label: 'Complaint / Escalation', imgSrc: DashboardLibraryImg4, route: '/complaintDashboard' },
        { label: 'SLA', imgSrc: DashboardLibraryImg5, route: '/slaDashboard' },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mobileViewState, setMobileViewState] = useState(Array(dashboardItems.length).fill(false));

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleImageClick = (route) => {
        navigate(route); // Navigate to the corresponding dashboard
    };

    const toggleMobileView = (index) => {
        const newState = [...mobileViewState];
        newState[index] = !newState[index]; // Toggle the mobile view state for the selected item
        setMobileViewState(newState);
    };

    const handleBack = () => {
        navigate(-1);
    };
    return (
        <div className="customer-management-container">
            <Main>
                <div className='components-download-container'>
                    <Download
                        showBackButton={true}
                        moduleName="Dashboard Library"
                        onBackClick={handleBack}
                    />
                    <Row className="dashboard-library-container">
                        {dashboardItems.map((item, index) => (
                            <Col md={3} key={index} className="dashboard-library-col">
                                <div className="dashboard-library-div">
                                    <Form.Group>
                                        <Form.Check
                                            type="radio"
                                            id={`exampleRadio${index}`}
                                            name="radioGroup"
                                        />
                                    </Form.Group>
                                    <p>{item.label}&nbsp;
                                        {mobileViewState[index] && <img src={mobileImg} alt="Mobile View" />}
                                    </p>
                                    <Dropdown align="end" className='dashboard-library-dropdown'>
                                        <Dropdown.Toggle as="div" className="dropdown-toggle">
                                            <img src={threedots} alt="options" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => toggleMobileView(index)}>
                                                {mobileViewState[index] ? 'Remove Mobile View' : 'Set Mobile View'}
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={handleOpenModal}>
                                                Share
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <hr />
                                {/* The onClick is now only on the main image */}
                                <div className='dashboard-library-img'>
                                    <img
                                        src={item.imgSrc}
                                        alt={item.label}
                                        className="library-img"
                                        onClick={() => handleImageClick(item.route)}
                                    />
                                    {/* OverlayTrigger only on the content icon */}
                                    <OverlayTrigger
                                        trigger="hover"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-${index}`} className="dashboard-library-tooltip">
                                                It is a long established fact that a reader
                                                will be distracted by the readable content
                                                of a page when looking at its layout.
                                            </Tooltip>
                                        }
                                    >
                                        <img
                                            src={contentShow}
                                            alt="content show"
                                            className='content-icon'
                                            style={{ cursor: 'pointer' }} // Add pointer cursor to indicate clickability
                                        />
                                    </OverlayTrigger>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </Main>
            {
                isModalOpen && <ShareModal
                    open={isModalOpen}
                    handleClose={handleCloseModal}
                />
            }
        </div>
    );
};

export default DashboardLibrary;
