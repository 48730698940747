import React from 'react';
import Main from "../../Main";
import OrgChart from 'react-orgchart';
import 'react-orgchart/index.css';
import '../../../assets/css/organizationChart.scss';
import '../../../assets/css/customermanagement.scss';
import Download from '../../MainSection/Download/Download';
import { useNavigate } from 'react-router-dom';

// Sample data structure for OrgChart
const data = {
    name: 'CEO',
    title: 'Chief Executive Officer',
    children: [
        {
            name: 'CTO',
            title: 'Chief Technology Officer',
            children: [
                { name: 'Dev Manager', title: 'Development Manager' },
                { name: 'QA Manager', title: 'Quality Assurance Manager' }
            ]
        },
        {
            name: 'CFO',
            title: 'Chief Financial Officer',
            children: [
                { name: 'Accountant', title: 'Accountant' }
            ]
        }
    ]
};

// Custom Node Component
const MyNodeComponent = ({ node }) => {
    return (
        <div className="initechNode">
            <div className="hierarchy-image">
                <img src="https://accounts.metricinfo.com/static/media/Admin1.8437b5803eede29b4bb9.png" alt="Org Chart" />
            </div>
            <div className="hierarchy-positions">
                <b>{node.name}</b>
                <p>{node.title}</p>
            </div>
        </div>
    );
};

const OrganizationChart = () => {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };
    return (
        <div className="customer-management-container">
            <Main>
                <div className='components-download-container'>
                    <Download
                        showBackButton={true}
                        moduleName="Settings / Organization Chart"
                        onBackClick={handleBack}
                    />
                </div>

                <div className='customermanagement-content-container'>
                    <div className="customermanagement-content">
                        <div className='organization-cahrt-container'>
                            <span>Organization Chart</span>
                            <div className="hierarchy-div" id="initechOrgChart">
                                <OrgChart tree={data} NodeComponent={MyNodeComponent} />
                            </div>
                        </div>
                    </div>
                </div>
            </Main>
        </div>
    );
}

export default OrganizationChart;
