import React from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";

const SaveCustomeViewModal = (props) => {
    return (
        <Modal show={props.open} onHide={props.handleCloseModal} className='save-custom-view-modal'>
            <Modal.Header closeButton>
                <Modal.Title>Save Custom View</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='customer-view-form-fields'>
                    <Col>
                        <Row>
                            <Col>
                                <p>
                                    Save your own custom view with all filters applied, to eliminate the need to filter every time you open the dashboard.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div>
                                    <label>Address</label>
                                    <Form.Control type="text" />
                                </div>
                            </Col>
                        </Row>
                        <Row className="custom-view-form-checkbox-container">
                            <Col md={6}>
                                <div className="checkbox-container">
                                    <Form.Check
                                        type="checkbox"
                                        id="default-checkbox"
                                        defaultChecked
                                        label="Make it my default"
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="checkbox-container">
                                    <Form.Check
                                        type="checkbox"
                                        id="visible-checkbox"
                                        label="Make visible to others"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                <SBSaveUpdateBtn btnName="Submit" onClickEffect={props.handleNextStep} />
            </Modal.Footer>
        </Modal>
    );
};

export default SaveCustomeViewModal;
