import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../Buttons/Buttons";
import DatePicker from "react-datepicker";
import calendar from '../../assets/images/Dashboard/calendar-icon.svg'

const AddLeave = (props) => {

    return (
        <>
            <Modal show={props.openAddLeave} onHide={props.handleCloseAddLeave} className='add-customer-modal add-user-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Leaves</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>User Name</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className='add-customer-calendar-container'>
                                        <label>From Date</label>
                                        {/* <div className='add-customer-calendar-container'> */}
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            selectsRange
                                            dropdownMode="select"
                                            placeholderText='Start Date'
                                            // startDate={fromDate}
                                            // onChange={(data) => setSelectSearchDrop(data, "date")}
                                            maxDate={new Date()}
                                        />
                                        <img src={calendar} alt="" />
                                        {/* </div> */}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className='add-customer-calendar-container'>
                                        <label>To Date</label>
                                        {/* <div className='add-customer-calendar-container'> */}
                                        <DatePicker
                                            className="select from-date"
                                            id="date-range-picker"
                                            dateFormat="dd/MM/yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            selectsRange
                                            dropdownMode="select"
                                            placeholderText='End Date'
                                            // startDate={fromDate}
                                            // onChange={(data) => setSelectSearchDrop(data, "date")}
                                            maxDate={new Date()}
                                        />
                                        <img src={calendar} alt="" />
                                        {/* </div> */}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>ID</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Role</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Department</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Earned Leave</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Floating Holiday</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Personal Time Off</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Sick Leave</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Casual Leave</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Compensatory Leave</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Modal.Body >
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseAddLeave} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={props.handleNextStep} />
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default AddLeave;