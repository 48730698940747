import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import { loadAssignedModulesDropdownData, loadCompanyDropdownData, loadSubModulesDropdownData, postCustomFieldsDatas } from "../../../redux/action";
import * as HeaderAuthentication from '../../HeaderAuthentication'
import { ADDED_BY_ID } from "../../Constants";

const enablingOptions = [
    { value: 26, label: "Active" },
    { value: 27, label: "In active" }
]

const isRequestedOptions = [
    { value: 0, label: "Optional" },
    { value: 1, label: "Mandatory" },
]
const fieldTypeOptions = [
    { value: "Text", label: "Text" },
    { value: "Email", label: "Email" },
    { value: "Text Area", label: "Text Area" },
    { value: "Dropdown", label: "Dropdown" },
    { value: "Check box", label: "Check box" },
    { value: "Radio", label: "Radio" },
    { value: "File", label: "File" },
    { value: "Date", label: "Date" },
]
const AddCustomFields = (props) => {

    let dispatch = useDispatch();
    const getCustomField = props.getCustomField;
    const getSingleCustomFields = props.getSingleCustomFields;
    const customFieldsId = props.customFieldsId;
    const { assignedModulesDropdown } = useSelector((state) => state.data);
    const { subModuleDropdown } = useSelector((state) => state.data);
    const { getCompanyDropdown } = useSelector((state) => state.data);

    const [formData, setFormData] = useState({
        CustomFields_CompanyIDFK: null,
        CustomFields_ModuleIDFK: null,
        CustomFields_SubModulesIDFK: null,
        CustomFields_LabelName: "",
        CustomFields_PlaceHolder: "",
        CustomFields_FieldType: "",
        CustomFields_IsRequired: null,
        CustomFields_StatusIDFK: null,
        AddedByID: ADDED_BY_ID
    });

    const [formErrors, setFormErrors] = useState({
        CustomFields_CompanyIDFK: "",
        CustomFields_ModuleIDFK: "",
        // CustomFields_SubModulesIDFK: "",
        CustomFields_LabelName: "",
        CustomFields_PlaceHolder: "",
        CustomFields_FieldType: "",
        CustomFields_IsRequired: "",
        CustomFields_StatusIDFK: ""
    });

    useEffect(() => {
        dispatch(loadAssignedModulesDropdownData());
        dispatch(loadSubModulesDropdownData());
        dispatch(loadCompanyDropdownData());
    }, [])

    useEffect(() => {
        if (customFieldsId && getSingleCustomFields && getSingleCustomFields.length > 0) {
            const existingData = getSingleCustomFields.find(field => field.CustomFieldsID === customFieldsId);
            if (existingData) {
                // Find the company ID based on the company label (from getCompanyDropdown)
                const selectedCompany = getCompanyDropdown.find(option => option.label === existingData.CompanyName);
                const selectedModule = assignedModulesDropdown.find(option => option.label === existingData.ModuleName);
                const selectedSubModule = subModuleDropdown.find(option => option.label === existingData.SubModuleName);
                const selectedStatus = enablingOptions.find(option => option.label === existingData.Status);

                setFormData({
                    CustomFields_IDPK: customFieldsId,
                    CustomFields_CompanyIDFK: selectedCompany ? selectedCompany.value : "", // Use the company ID here
                    CustomFields_ModuleIDFK: selectedModule ? selectedModule.value : "", // Use the module ID here
                    CustomFields_SubModulesIDFK: selectedSubModule ? selectedSubModule.value : "", // Use the submodule ID here
                    CustomFields_LabelName: existingData.LabelName,
                    CustomFields_PlaceHolder: existingData.PlaceHolder,
                    CustomFields_FieldType: existingData.FieldType,
                    CustomFields_IsRequired: existingData.IsRequired,
                    CustomFields_StatusIDFK: selectedStatus ? selectedStatus.value : "",
                    AddedByID: ADDED_BY_ID
                });
            }
        }
    }, [customFieldsId, getSingleCustomFields, getCompanyDropdown, assignedModulesDropdown, subModuleDropdown]);

    const handleInputChange = (fieldName, value) => {
        setFormData((prevState) => ({
            ...prevState,
            [fieldName]: value,
        }));
    };

    const handleSelectChange = (fieldName, selectedOption) => {
        setFormData((prevState) => ({
            ...prevState,
            [fieldName]: selectedOption ? selectedOption.value : "",
        }));
    };

    const validateForm = () => {
        const errors = {};

        // Validate all fields
        if (!formData.CustomFields_CompanyIDFK) errors.CustomFields_CompanyIDFK = "Customer Name is required";
        if (!formData.CustomFields_ModuleIDFK) errors.CustomFields_ModuleIDFK = "Module Name is required";
        // if (!formData.CustomFields_SubModulesIDFK) errors.CustomFields_SubModulesIDFK = "Sub Module Name is required";
        if (!formData.CustomFields_LabelName) errors.CustomFields_LabelName = "Label Name is required";
        if (!formData.CustomFields_PlaceHolder) errors.CustomFields_PlaceHolder = "Place Holder is required";
        if (!formData.CustomFields_FieldType) errors.CustomFields_FieldType = "Field Type is required";
        if (formData.CustomFields_IsRequired === null) errors.CustomFields_IsRequired = "Is Requested is required";
        // if (!formData.CustomFields_StatusIDFK) errors.CustomFields_StatusIDFK = "Status is required";

        setFormErrors(errors);

        return Object.keys(errors).length === 0; // Return true if no errors, otherwise false
    }

    const AddCustomeFieldsData = (e) => {
        e.preventDefault();
        if (validateForm()) {
            let requestOption = {
                method: "POST",
                mode: "cors",
                headers: HeaderAuthentication.postExcomatixAuth,
                body: JSON.stringify(formData),
            };

            dispatch(postCustomFieldsDatas(requestOption, props.handleCloseModal, getCustomField, customFieldsId));
            console.log(formData, 'formData')
        }
    }

    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{getCustomField ? 'Edit Custom Field' : 'Add Custom Field'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={6}>
                                    <div>
                                        <label>Customer Name</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={getCompanyDropdown}
                                            placeholder="Select"
                                            value={getCompanyDropdown.find(option => option.value === formData.CustomFields_CompanyIDFK)}
                                            onChange={(selectedOption) => handleSelectChange("CustomFields_CompanyIDFK", selectedOption)}
                                        />
                                        {formErrors.CustomFields_CompanyIDFK && <span className="invalid-feedback d-block">{formErrors.CustomFields_CompanyIDFK}</span>}
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div>
                                        <label>Module Name</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={assignedModulesDropdown}
                                            placeholder="Select"
                                            value={assignedModulesDropdown.find(option => option.value === formData.CustomFields_ModuleIDFK)}
                                            onChange={(selectedOption) => handleSelectChange("CustomFields_ModuleIDFK", selectedOption)}
                                        />
                                        {formErrors.CustomFields_ModuleIDFK && <span className="invalid-feedback d-block">{formErrors.CustomFields_ModuleIDFK}</span>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <div>
                                        <label>Sub Module Name</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={subModuleDropdown}
                                            placeholder="Select"
                                            value={subModuleDropdown.find(option => option.value === formData.CustomFields_SubModulesIDFK)}
                                            onChange={(selectedOption) => handleSelectChange("CustomFields_SubModulesIDFK", selectedOption)}
                                        />
                                        {/* {formErrors.CustomFields_SubModulesIDFK && <span className="invalid-feedback d-block">{formErrors.CustomFields_SubModulesIDFK}</span>} */}
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div>
                                        <label>Label Name</label>
                                        <Form.Control
                                            type="text"
                                            value={formData.CustomFields_LabelName}
                                            onChange={(e) => handleInputChange("CustomFields_LabelName", e.target.value)}
                                        />
                                        {formErrors.CustomFields_LabelName && <span className="invalid-feedback d-block">{formErrors.CustomFields_LabelName}</span>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Place Holder</label>
                                        <Form.Control
                                            type="text"
                                            value={formData.CustomFields_PlaceHolder}
                                            onChange={(e) => handleInputChange("CustomFields_PlaceHolder", e.target.value)}
                                        />
                                        {formErrors.CustomFields_PlaceHolder && <span className="invalid-feedback d-block">{formErrors.CustomFields_PlaceHolder}</span>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <div>
                                        <label>Select Field Type</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={fieldTypeOptions}
                                            placeholder="Select"
                                            value={fieldTypeOptions.find(option => option.value === formData.CustomFields_FieldType)}
                                            onChange={(selectedOption) => handleSelectChange("CustomFields_FieldType", selectedOption)}
                                        />
                                        {formErrors.CustomFields_FieldType && <span className="invalid-feedback d-block">{formErrors.CustomFields_FieldType}</span>}
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div>
                                        <label>Is Requested</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={isRequestedOptions}
                                            placeholder="Select"
                                            value={isRequestedOptions.find(option => option.value === formData.CustomFields_IsRequired)} // Find the full object
                                            onChange={(selectedOption) => handleSelectChange("CustomFields_IsRequired", selectedOption)}
                                        />
                                        {formErrors.CustomFields_IsRequired && <span className="invalid-feedback d-block">{formErrors.CustomFields_IsRequired}</span>}
                                    </div>
                                </Col>
                            </Row>
                            {customFieldsId && <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Status</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={enablingOptions}
                                            placeholder="Select"
                                            value={enablingOptions.find(option => option.value === formData.CustomFields_StatusIDFK)}
                                            onChange={(selectedOption) => handleSelectChange("CustomFields_StatusIDFK", selectedOption)}
                                        />
                                    </div>
                                </Col>
                            </Row>}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={AddCustomeFieldsData} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddCustomFields;
