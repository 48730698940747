import { useState, useEffect } from 'react';
import { Modal, Row, Col, Form, Accordion } from 'react-bootstrap';
import Select from 'react-select';
import SbCancelBtn, { SbAddBtn, SBAdvanceBtn, SBSaveUpdateBtn } from '../../Buttons/Buttons';
import crossmark from '../../../assets/images/CustomerManagement/crossmark.svg';

const RTValueFilterModal = ({ showValueFilterModal, handleCloseValueFilterModal }) => {
    const [filters, setFilters] = useState([{ field: null, filterType: 'Was', condition: null, value: '', logic: 'AND' }]);
    const [filterText, setFilterText] = useState('');
    const [activeKey, setActiveKey] = useState("0");

    const items = [
        { value: 'company', label: 'Company' },
        { value: 'name', label: 'Name' },
        { value: 'relationshipType', label: 'Relationship Type' },
        { value: 'noOfLicence', label: 'No.of Licence' },
        { value: 'address', label: 'Address' },
        { value: 'dateRange', label: 'Start Date / End Date' },
        { value: 'customerCategory', label: 'Customer Category' },
        { value: 'assignedModules', label: 'Assigned Modules' },
    ];

    const conditionOptions = [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
        { value: 'greaterThan', label: 'Greater Than' },
        { value: 'lessThan', label: 'Less Than' },
    ];

    const setTypeOptions = [
        { value: 'Value', label: 'Value' },
        { value: 'Fields', label: 'Fields' },
    ];

    const handleFilterChange = (index, field, value) => {
        const updatedFilters = [...filters];
        updatedFilters[index][field] = value;
        setFilters(updatedFilters);
    };

    const handleAddFilter = () => {
        const newIndex = filters.length;
        setFilters([...filters, { field: null, filterType: 'Was', condition: null, value: '', logic: 'AND' }]);
        setActiveKey(String(newIndex));
    };

    const resetState = () => {
        setFilters([{ field: null, filterType: 'Was', condition: null, value: '', logic: 'AND' }]);
        setFilterText('');
        setActiveKey("0");
    };

    const generateFilterText = () => {
        let text = '';
        filters.forEach((filter, index) => {
            if (filter.field && filter.condition && filter.value) {
                const logic = index > 0 ? `${filter.logic} ` : '';
                text += `${logic}${filter.field.label} ${filter.filterType} ${filter.condition.label} "${filter.value}"\n`;
            }
        });
        setFilterText(text.trim());
    };

    useEffect(() => {
        generateFilterText();
    }, [filters]);

    return (
        <Modal show={showValueFilterModal} onHide={() => { handleCloseValueFilterModal(); resetState(); }} className='value-filter-modal add-customer-modal' centered>
            <Modal.Header closeButton>
                <Modal.Title>Value Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='pb-2'>
                    <Col>
                        <div className="value-filter-btn-div">
                            <SBSaveUpdateBtn btnName="Basic" />
                            <SBAdvanceBtn btnName="Advance" />
                        </div>
                    </Col>
                </Row>

                <Accordion activeKey={activeKey}>
                    {filters.map((filter, index) => (
                        <Accordion.Item eventKey={String(index)} key={index}>
                            <Accordion.Header onClick={() => setActiveKey(activeKey === String(index) ? null : String(index))}>
                                {filter.field ? `${filter.field.label} ${filter.filterType} ${filter.condition ? filter.condition.label : ''} "${filter.value}"` : 'Empty Filter'}
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    <Col className='value-filter-radio-btn-div'>
                                        <Form.Check
                                            type="radio"
                                            label="AND"
                                            name={`logic-${index}`}
                                            value="AND"
                                            checked={filter.logic === 'AND'}
                                            onChange={() => handleFilterChange(index, 'logic', 'AND')}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="OR"
                                            name={`logic-${index}`}
                                            value="OR"
                                            checked={filter.logic === 'OR'}
                                            onChange={() => handleFilterChange(index, 'logic', 'OR')}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6}>
                                        <p>Select Field</p>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={items}
                                            placeholder="Select"
                                            onChange={(option) => handleFilterChange(index, 'field', option)}
                                            value={filter.field}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <div className="customer-value-filter-checkbox-group mt-3">
                                            <Form.Check
                                                type="radio"
                                                label="Was"
                                                checked={filter.filterType === 'Was'}
                                                onChange={() => handleFilterChange(index, 'filterType', 'Was')}
                                            />
                                            <Form.Check
                                                type="radio"
                                                label="Not"
                                                checked={filter.filterType === 'Not'}
                                                onChange={() => handleFilterChange(index, 'filterType', 'Not')}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={4}>
                                        <p>Set Condition</p>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={conditionOptions}
                                            placeholder="Condition"
                                            onChange={(option) => handleFilterChange(index, 'condition', option)}
                                            value={filter.condition}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <p>Set Type</p>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={setTypeOptions}
                                            placeholder="Select Type"
                                        />
                                    </Col>
                                    <Col md={4} className='value-filter-text-add-div'>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter value"
                                            value={filter.value}
                                            onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>

                {/* Add New Filter Button */}
                <Row className="mt-3">
                    <Col md={12}>
                        <SbAddBtn btnName="Add Filter" onClickEffect={handleAddFilter} />
                    </Col>
                </Row>

                {/* Textarea for displaying filter conditions */}
                <Row className="mt-3">
                    <Col md={12}>
                        <div className="filter-textarea">
                            {filterText && (
                                <img
                                    src={crossmark}
                                    alt="Delete filter"
                                    className='filter-delete-img'
                                    onClick={resetState}
                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                />
                            )}
                            <textarea
                                rows={3}
                                placeholder="Filter conditions will appear here..."
                                value={filterText}
                                readOnly
                                style={{
                                    border: 'none',
                                    outline: 'none',
                                    flex: 1,
                                    resize: 'none',
                                    background: 'transparent'
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <SbCancelBtn btnName="Cancel" onClickEffect={() => { handleCloseValueFilterModal(); resetState(); }} />
                <SBSaveUpdateBtn btnName="Save" />
            </Modal.Footer>
        </Modal>
    );
};

export default RTValueFilterModal;
